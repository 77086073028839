import React from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert } from '../utils/Alert';
import { log } from '../utils/logs';
import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import Loader from '../utils/Loader';
import {
  cargoRubroFiltroOptions,
  estudioOrigenOptions,
  estudioTipoOptions,
} from '../utils/constants';
import { TopAlert } from '../ui/alert/Alert';
const { TextInput } = require('../utils/TextInput');

function CrearEstudioMercadoView({
  topAlert,
  modelOportunidades,
  tipoOption,
  setTipoOption,
  titulo,
  setTitulo,
  cargoOption,
  cargosOptions,
  setCargoOption,
  rubroOption,
  rubrosOptions,
  setRubroOption,
  cargoRubroFiltroOption,
  setCargoRubroFiltroOption,
  oportunidadOption,
  oportunidadOptions,
  setOportunidadOption,
  etapaOption,
  etapaOptions,
  setEtapaOption,
  submit,
  estudioMercadoCreate,
  origenOption,
  setOrigenOption,
  submitAlert,
}) {
  const title = 'Crear estudio de mercado';

  return (
    <Content title={title} browserTitle={title} show={false}>
      {topAlert.text && <TopAlert alert={topAlert} />}

      <Loader isLoading={modelOportunidades.loading}>
        <Row>
          <Col md="10" mdOffset="1">
            <Box>
              <ReferenciaObligatorios />
              <SelectInput
                label="Tipo de estudio"
                value={tipoOption}
                options={estudioTipoOptions}
                setValue={setTipoOption}
                required
              />

              <SelectInput
                label="Origen de candidatos"
                value={origenOption}
                options={estudioOrigenOptions}
                setValue={setOrigenOption}
                required
              />
            </Box>
          </Col>
        </Row>

        {origenOption?.value === 'cargoRubro' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box title="Desde candidatos">
                <ReferenciaObligatorios />

                <TextInput
                  label="Título"
                  initialValue={titulo}
                  setValue={setTitulo}
                  required
                />

                <SelectInput
                  label="Cargo"
                  value={cargoOption}
                  options={cargosOptions}
                  setValue={setCargoOption}
                  required
                />

                <SelectInput
                  label="Rubro"
                  value={rubroOption}
                  options={rubrosOptions}
                  setValue={setRubroOption}
                  required
                />

                <SelectInput
                  label="Incluir candidatos"
                  value={cargoRubroFiltroOption}
                  options={cargoRubroFiltroOptions}
                  setValue={setCargoRubroFiltroOption}
                  required
                  isMulti
                />

                <AlertYGuardar
                  submitAlert={submitAlert}
                  submit={submit}
                  estudioMercadoCreate={estudioMercadoCreate}
                  cargoRubroFiltroOption={cargoRubroFiltroOption}
                />
              </Box>
            </Col>
          </Row>
        )}

        {origenOption?.value === 'oportunidades' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box title="Desde oportunidad">
                <SelectInput
                  label="Oportunidad"
                  value={oportunidadOption}
                  options={oportunidadOptions}
                  setValue={setOportunidadOption}
                  required
                />

                <SelectInput
                  label="Etapa"
                  value={etapaOption}
                  options={etapaOptions}
                  setValue={setEtapaOption}
                  required
                />

                <AlertYGuardar
                  submitAlert={submitAlert}
                  submit={submit}
                  estudioMercadoCreate={estudioMercadoCreate}
                  cargoRubroFiltroOption={cargoRubroFiltroOption}
                />
              </Box>
            </Col>
          </Row>
        )}

        {origenOption?.value === 'manual' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box title="Manual">
                <TextInput
                  label="Título"
                  initialValue={titulo}
                  setValue={setTitulo}
                  required
                />

                <AlertYGuardar
                  submitAlert={submitAlert}
                  submit={submit}
                  estudioMercadoCreate={estudioMercadoCreate}
                  cargoRubroFiltroOption={cargoRubroFiltroOption}
                />
              </Box>
            </Col>
          </Row>
        )}

        <div style={{ height: 250 }}></div>
      </Loader>
    </Content>
  );
}

const AlertYGuardar = ({
  submitAlert,
  submit,
  estudioMercadoCreate,
  cargoRubroFiltroOption,
}) => (
  <>
    {submitAlert.text && <TopAlert alert={submitAlert} />}
    <ButtonWithLoading
      text="Guardar"
      submit={submit}
      pullRight
      loading={estudioMercadoCreate.loading}
      disabled={estudioMercadoCreate.loading}
    />
  </>
);

export { CrearEstudioMercadoView };
