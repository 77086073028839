import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getDatosAuxiliaresDuck = createDuck(
  'GET_DATOS_AUXILIARES',
  newActions,
  newReducers
);

const getDatosAuxiliaresThunk = (params) => {
  const url = `${baseUrl}/v1/datos-auxiliares`;

  return createThunk(
    axios,
    'get',
    getDatosAuxiliaresDuck.actions,
    url,
    null,
    params
  );
};

const createDatoAuxiliarDuck = createDuck(
  'CREATE_DATO_AUXILIAR',
  newActions,
  newReducers
);

const createDatoAuxiliarThunk = (params) => {
  const url = `${baseUrl}/v1/dato-auxiliar`;

  return createThunk(
    axios,
    'post',
    createDatoAuxiliarDuck.actions,
    url,
    params
  );
};

const editDatoAuxiliarDuck = createDuck(
  'EDIT_DATO_AUXILIAR',
  newActions,
  newReducers
);

const editDatoAuxiliarThunk = (params) => {
  const url = `${baseUrl}/v1/dato-auxiliar`;

  return createThunk(axios, 'patch', editDatoAuxiliarDuck.actions, url, params);
};

const deleteDatoAuxiliarDuck = createDuck(
  'DELETE_DATO_AUXILIAR',
  newActions,
  newReducers
);

const deleteDatoAuxiliarThunk = (params) => {
  const url = `${baseUrl}/v1/dato-auxiliar`;

  return createThunk(
    axios,
    'delete',
    deleteDatoAuxiliarDuck.actions,
    url,
    params
  );
};

const getGeografiaDuck = createDuck('GET_GEOGRAFIA', newActions, newReducers);

const getGeografiaThunk = (params) => {
  const url = `${baseUrl}/v1/geografia`;

  return createThunk(axios, 'get', getGeografiaDuck.actions, url, null, params);
};

const createDatoGeograficoDuck = createDuck(
  'CREATE_DATO_GEOGRAFICO',
  newActions,
  newReducers
);

const createDatoGeograficoThunk = (params) => {
  const url = `${baseUrl}/v1/geografia`;

  return createThunk(
    axios,
    'post',
    createDatoGeograficoDuck.actions,
    url,
    params
  );
};

const editDatoGeograficoDuck = createDuck(
  'EDIT_DATO_GEOGRAFICO',
  newActions,
  newReducers
);

const editDatoGeograficoThunk = (params) => {
  const url = `${baseUrl}/v1/geografia`;

  return createThunk(
    axios,
    'patch',
    editDatoGeograficoDuck.actions,
    url,
    params
  );
};

const deleteDatoGeograficoDuck = createDuck(
  'DELETE_DATO_GEOGRAFICO',
  newActions,
  newReducers
);

const deleteDatoGeograficoThunk = (params) => {
  const url = `${baseUrl}/v1/geografia`;

  return createThunk(
    axios,
    'delete',
    deleteDatoGeograficoDuck.actions,
    url,
    params
  );
};

export {
  getDatosAuxiliaresDuck,
  getDatosAuxiliaresThunk,
  createDatoAuxiliarDuck,
  createDatoAuxiliarThunk,
  editDatoAuxiliarDuck,
  editDatoAuxiliarThunk,
  getGeografiaDuck,
  getGeografiaThunk,
  createDatoGeograficoDuck,
  createDatoGeograficoThunk,
  editDatoGeograficoDuck,
  editDatoGeograficoThunk,
  deleteDatoAuxiliarDuck,
  deleteDatoAuxiliarThunk,
  deleteDatoGeograficoDuck,
  deleteDatoGeograficoThunk,
};
