import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';
import {
  getOportunidadDuck,
  getOportunidadesDuck,
  getOportunidadesThunk,
  getOportunidadThunk,
  getUsuariosDuck,
  getUsuariosThunk,
} from './duck';
import {
  constants,
  getDate,
  getDateOrEmpty,
  responseSelector,
  sumarDias,
} from '../utils/utils';
import { ListaOportunidadesView } from './ListaOportunidadesView';
import {
  oportunidadEstadoOptionsWithAll,
  oportunidadEstadoOptByValWithAll,
} from '../utils/constants';
import { log } from '../utils/logs';
import {
  borrarOportunidadDuck,
  borrarOportunidadThunk,
  incluirEnOportunidadDuck,
  incluirEnOportunidadThunk,
} from '../candidatos/duck';
import { oportunidadesUtils } from './utils';

const queryString = require('query-string');

function ListaOportunidades({ history, match, test, token }) {
  const isMountedRef = React.useRef(false);
  const isCalledRef = React.useRef(false);

  const queryParams = history?.location?.search;
  const parsedQueryParams = queryString.parse(queryParams);

  const candidatoAIncluirId = parsedQueryParams?.candidatoAIncluirId;

  const dispatch = useDispatch();

  const modelGetOportunidad = useSelector((state) => state.getOportunidad);
  const modelOportunidades = useSelector((state) => state.getOportunidades);
  const modelUsuarios = useSelector((state) => state.getUsuarios);
  const modelBorrarOportunidad = useSelector(
    (state) => state.borrarOportunidad
  );
  const modelIncluirEnOportunidad = useSelector(
    (state) => state.incluirEnOportunidad
  );

  const [oportunidades, setOportunidades] = useState();
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );
  const [sortField, setSortField] = useState(parsedQueryParams?.sortField);
  const [sortOrder, setSortOrder] = useState(parsedQueryParams?.sortOrder);
  const [oportunidad, setOportunidad] = useState();

  const estadosOptions = oportunidadEstadoOptionsWithAll;
  const [estadoOption, setEstadoOption] = useState(
    parsedQueryParams?.estado
      ? oportunidadEstadoOptByValWithAll[parsedQueryParams.estado]
      : oportunidadEstadoOptByValWithAll['todos']
  );

  const [usuariosLoadStatus, setUsuariosLoadStatus] = useState('IDLE');
  const [usuariosOptions, setUsuariosOptions] = useState();
  const [usuarioOption, setUsuarioOption] = useState();

  const [isModalConfirmarBorrarShown, setIsModalConfirmarBorrarShown] =
    useState(false);

  const [selectedOportunidad, setSelectedOportunidad] = useState();

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const minDate = sumarDias(new Date(), -30);

  const dateForInicio = parsedQueryParams?.creacionTsDesde
    ? moment(parsedQueryParams?.creacionTsDesde).toDate()
    : null;

  const [inicio, setInicio] = useState(dateForInicio);

  const dateForFin = parsedQueryParams?.creacionTsHasta
    ? moment(parsedQueryParams?.creacionTsHasta).toDate()
    : null;

  const [fin, setFin] = useState(dateForFin);

  const [empresaIdNumerico, setEmpresaIdNumerico] = useState(
    parsedQueryParams?.empresaIdNumerico
  );
  const [empresaNombre, setEmpresaNombre] = useState(
    parsedQueryParams?.empresaNombre
  );

  const [isModalIncluirCandidatoShown, setisModalIncluirCandidatoShown] =
    useState(false);

  const [etapaOption, setEtapaOption] = useState({});
  const [etapasOptions, setEtapasOptions] = useState(null);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelBorrarOportunidad);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalConfirmarBorrar();

      dispatch(borrarOportunidadDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(borrarOportunidadDuck.actions.clear());

      handlePage(0);

      setTopAlert({
        text: 'Oportunidad borrada',
        type: alertTypes.success,
        closable: false,
      });

      hideModalConfirmarBorrar();
    }
  }, [modelBorrarOportunidad]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelOportunidades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadesDuck.actions.clear());
    }

    if (data) {
      setOportunidades(data.items);
      setTotalResults(data.total);
      dispatch(getOportunidadesDuck.actions.clear());
    }
  }, [modelOportunidades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportunidad);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadDuck.actions.clear());
    }

    if (data) {
      setOportunidad(data.oportunidad);
      setEtapasOptions(
        oportunidadesUtils.prepareEtapasOptions(
          data.oportunidad.etapasWithDescription
        )
      );

      dispatch(getOportunidadDuck.actions.clear());
    }
  }, [modelGetOportunidad]);

  const prepareUsuariosOptions = (usuarios) => {
    const mUsuariosOptions =
      usuarios &&
      usuarios.map((usuario) => ({
        value: usuario?.idNumerico,
        label: usuario?.nombre,
      }));

    return [{ value: 'todos', label: 'Todos' }, ...mUsuariosOptions];
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUsuarios);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getUsuariosDuck.actions.clear());
    }

    if (data) {
      log({ 'modelUsuarios.useEffect.data': data });

      const mUsuariosOptions = prepareUsuariosOptions(data.items);
      log({ mUsuariosOptions });

      setUsuariosOptions(mUsuariosOptions);

      if (
        parsedQueryParams?.usuario &&
        parsedQueryParams?.usuario !== 'todos'
      ) {
        log('usuarioEnUrl');

        const usuarioSeleccionado = mUsuariosOptions.find(
          (usuarioOption) => usuarioOption.value == parsedQueryParams?.usuario
        );

        if (usuarioSeleccionado) {
          log('usuarioEnUrlEncontrado');

          setUsuarioOption(usuarioSeleccionado);
        } else {
          log('usuarioEnUrlDesconocido');

          setUsuarioOption({
            value: parsedQueryParams?.usuario,
            label: 'Desconocido',
          });
        }
      } else {
        log('noUsuarioEnUrl');
        setUsuarioOption(oportunidadEstadoOptByValWithAll.todos);
      }

      setUsuariosLoadStatus('LOADED');
      dispatch(getUsuariosDuck.actions.clear());
    }
  }, [modelUsuarios]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelIncluirEnOportunidad);

    if (error) {
      setTopAlert({
        title: null,
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setisModalIncluirCandidatoShown(false);

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }

    if (data) {
      let itemsMsgs = [];

      if (data.incluidosExitosamente.length) {
        itemsMsgs.push(
          `Se incluyó exitosamente ${data.incluidosExitosamente.length} candidato.`
        );
      }

      if (data.omitidos.length) {
        itemsMsgs.push(
          `Se omitió el candidato con ID: ${data.omitidos.join(
            ', '
          )} porque ya es parte de la oportunidad.`
        );
      }

      handlePage(0);

      setTopAlert({
        title: null,
        text: (
          <ul>
            {itemsMsgs.map((msj) => (
              <li>{msj}</li>
            ))}
          </ul>
        ),
        type: alertTypes.success,
        closable: false,
      });

      setisModalIncluirCandidatoShown(false);

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }
  }, [modelIncluirEnOportunidad]);

  useEffect(() => {
    setUsuariosLoadStatus('LOADING');
    dispatch(
      getUsuariosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      log('sort.useEffect.run');
      handlePage(0);
    } else {
      log('sort.useEffect.dontRun');
    }
  }, [sortOrder, sortField]);

  function handlePage(page) {
    setActivePage(page);

    const params = {
      page: page + 1,
      limit: pageSize,
      sortField,
      sortOrder,
      busquedaRapida,
      estado: estadoOption?.value,
      usuario: usuarioOption?.value,
      creacionTsDesde: getDateOrEmpty(inicio),
      creacionTsHasta: getDateOrEmpty(fin),
      candidatoAIncluirId,
      empresaIdNumerico,
      empresaNombre,
    };

    log({ 'handlePage.params': params });

    let newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getOportunidadesThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function irAIncluir(oportunidad) {
    const params = {
      oportunidadId: oportunidad.idNumerico,
      cargosIdsNumericos: oportunidad.cargoIdNumerico ?? undefined,
      rubrosIdsNumericos: oportunidad.rubroIdNumerico ?? undefined,
    };
    log({ 'irAIncluir.params': params });
    const qParams = queryString.stringify(params);
    history.push(`/candidatos?${qParams}`);
  }

  function hideModalConfirmarBorrar() {
    setIsModalConfirmarBorrarShown(false);
  }

  function showModalConfirmarBorrar() {
    setIsModalConfirmarBorrarShown(true);
  }

  function borrarOportunidad() {
    dispatch(
      borrarOportunidadThunk({
        userToken: token.raw,
        oportunidadIdNumerico: selectedOportunidad?.idNumerico,
      })
    );
  }

  function incluirEnOportunidad() {
    dispatch(
      incluirEnOportunidadThunk({
        userToken: token.raw,
        oportunidadIdNumerico: selectedOportunidad?.idNumerico,
        candidatosIds: [candidatoAIncluirId],
        etapa: etapaOption?.value,
      })
    );
  }

  function getOportunidadWithEtapas(oportunidad) {
    dispatch(
      getOportunidadThunk({
        userToken: token.raw,
        idNumerico: oportunidad.idNumerico,
      })
    );
  }

  return (
    <ListaOportunidadesView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      sortField={sortField}
      setSortField={setSortField}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      oportunidades={oportunidades}
      modelOportunidades={modelOportunidades}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      estadoOption={estadoOption}
      estadosOptions={estadosOptions}
      setEstadoOption={setEstadoOption}
      usuarioOption={usuarioOption}
      usuariosOptions={usuariosOptions}
      setUsuarioOption={(o) => {
        log({ option: o });
        setUsuarioOption(o);
      }}
      inicio={inicio}
      setInicio={setInicio}
      fin={fin}
      setFin={setFin}
      usuariosLoadStatus={usuariosLoadStatus}
      irAIncluir={irAIncluir}
      hideModalConfirmarBorrar={hideModalConfirmarBorrar}
      showModalConfirmarBorrar={showModalConfirmarBorrar}
      borrarOportunidad={borrarOportunidad}
      isModalConfirmarBorrarShown={isModalConfirmarBorrarShown}
      selectedOportunidad={selectedOportunidad}
      setSelectedOportunidad={setSelectedOportunidad}
      modelBorrarOportunidad={modelBorrarOportunidad}
      candidatoAIncluirId={candidatoAIncluirId}
      isModalIncluirCandidatoShown={isModalIncluirCandidatoShown}
      setisModalIncluirCandidatoShown={setisModalIncluirCandidatoShown}
      incluirEnOportunidad={incluirEnOportunidad}
      etapaOption={etapaOption}
      modelIncluirEnOportunidad={modelIncluirEnOportunidad}
      etapasOptions={etapasOptions}
      setEtapaOption={setEtapaOption}
      empresaIdNumerico={empresaIdNumerico}
      empresaNombre={empresaNombre}
      oportunidad={oportunidad}
      setOportunidad={setOportunidad}
      getOportunidadWithEtapas={getOportunidadWithEtapas}
      modelGetOportunidad={modelGetOportunidad}
    />
  );
}

export { ListaOportunidades };
