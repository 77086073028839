import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function ModalConfirmarEliminarCargoPot({
  isModalEliminarCargoPotShown,
  hideModalEliminarCargoPot,
  showModalEliminarCargoPot,
  eliminarCargoPot,
  isEliminarCargoPotButtonLoading,
}) {

  

  return (
    <Modal
      show={isModalEliminarCargoPotShown}
      onHide={hideModalEliminarCargoPot}
      onShow={showModalEliminarCargoPot}
    >
      <Modal.Header>
        <Modal.Title>Eliminar cargo potencial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se <b>eliminará</b> el cargo potencial
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="danger"
          text="Eliminar cargo potencial"
          submit={eliminarCargoPot}
          loading={isEliminarCargoPotButtonLoading}
          disabled={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalConfirmarEliminarCargoPot };
