const imgRestrictionsByConcepto = {
  candidatoFoto: {
    upload: {
      maxFileCount: 1,
      maxFileSize: 4.3,
      minWidth: 128,
      minHeight: 95,
    },
    crop: {
      minCroppedWidth: 128,
      minCroppedHeight: 128,
      // maxCroppedWidth: 384,
      // maxCroppedHeight: 285,
      aspectRatioNumerator: 128,
      aspectRatioDenominator: 128,
    },
  },
};

const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    key: process.env.REACT_APP_API_KEY,
  },
  imgRestrictionsByConcepto,
};

export default config;
