import React from 'react';
import { Content, Col, Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Loader from '../utils/Loader';
import { submitSearchOnEnterPress, toTitleCase } from '../utils/utils';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

import { Alert } from '../ui/alert';
import { ModalGenerico } from '../ui/ModalGenerico';
import { TextInput } from '../utils/TextInput';

function ListaInstitutosView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  institutos,
  modelGetInstitutos,
  busquedaRapida,
  setBusquedaRapida,
  deleteInstituto,
  isDeleteConfirmModalShown,
  setIsDeleteConfirmModalShown,
  selectedInstituto,
  setSelectedInstituto,
  modelDeleteInstituto,
  seleccion,
  seleccionRetorno,
}) {
  const getAcciones = (instituto) => {
    return (
      <div style={{ display: 'inline-block', marginRight: 5 }}>
        <ButtonWithLoading
          type="default"
          size="sm"
          text="Editar"
          submit={() =>
            history.push(`/instituto/editar/${instituto.idNumerico}`)
          }
        />
      </div>
    );
  };

  return (
    <Content
      title="Institutos"
      subTitle="Lista"
      browserTitle="Institutos"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {selectedInstituto && (
        <ModalGenerico
          isModalShown={isDeleteConfirmModalShown}
          hideModal={() => setIsDeleteConfirmModalShown(false)}
          title="Eliminar"
          body="Se eliminará el instituto. ¿Continuar?"
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={!selectedInstituto}
          buttonLoading={modelDeleteInstituto.loading}
          submit={deleteInstituto}
        />
      )}

      <Loader isLoading={modelGetInstitutos.loading}>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <TextInput
              label="Nombre"
              placeholder=""
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={(event) =>
                submitSearchOnEnterPress(event, () => handlePage(0))
              }
            />

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>
        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            border={false}
            customOptions={
              <div>
                <Button
                  size="sm"
                  type="default"
                  text="Agregar"
                  onClick={() => history.push('/instituto/agregar')}
                />
              </div>
            }
          >
            {/*
            idNumerico
            nombre
            direccion
            telefono
            contacto */}

            <Loader isLoading={modelGetInstitutos.loading}>
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />

              <DataTable
                lazy
                pt={{
                  table: {
                    className: 'table table-hover',
                  },
                }}
                removableSort
                // sortField={sortField}
                // sortOrder={sortOrder}
                // onSort={(e) => {
                //   setSortField(e.sortField);
                //   setSortOrder(e.sortOrder);
                //   log({
                //     'onSort.sortField': e.sortField,
                //     'onSort.sortOrder': e.sortOrder,
                //   });
                // }}
                value={institutos}
                emptyMessage="No se encontraron registros"
              >
                {seleccion === 's' && (
                  <Column
                    header="Seleccionar"
                    body={(instituto) => (
                      <ButtonWithLoading
                        size="sm"
                        text="Seleccionar"
                        type="primary"
                        submit={() =>
                          history.push(
                            `${seleccionRetorno}&institutoId=${instituto.idNumerico}&institutoDescripcion=${instituto.nombre}`
                          )
                        }
                      />
                    )}
                  ></Column>
                )}

                <Column
                  field="acciones"
                  header="Acciones"
                  body={(instituto) => getAcciones(instituto)}
                ></Column>

                <Column
                  field="idNumerico"
                  header="ID"
                  body={(instituto) => {
                    return instituto.idNumerico;
                  }}
                ></Column>
                <Column field="nombre" header="Nombre"></Column>
                <Column
                  field="direccion"
                  header="Dirección"
                  body={(instituto) => {
                    return toTitleCase(instituto.direccion);
                  }}
                ></Column>

                <Column field="telefono" header="Teléfono"></Column>

                <Column
                  field="contactoNombre"
                  header="Contacto"
                  body={(instituto) => toTitleCase(instituto.contacto?.nombre)}
                ></Column>

                <Column
                  field="contactoTelefono"
                  header="Teléfono de contacto"
                  body={(instituto) => instituto.contacto?.telefono}
                ></Column>

                <Column
                  field="eliminar"
                  header="Eliminar"
                  body={(instituto) => (
                    <div style={{}}>
                      <div style={{ display: 'inline-block' }}>
                        <ButtonWithLoading
                          type="warning"
                          size="sm"
                          text="Eliminar"
                          submit={() => {
                            setSelectedInstituto(instituto);
                            setIsDeleteConfirmModalShown(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                ></Column>
              </DataTable>
              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>
            </Loader>
          </Box>
        </Col>
      </Loader>
    </Content>
  );
}

export { ListaInstitutosView };
