import React from 'react';

import { Row, Col } from 'adminlte-2-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateRangeInput({
  inicio,
  setInicio,
  fin,
  setFin,
  xs = '6',
  md = '6',
  showLabels = false,
  labelStyle = null,
}) {
  return (
    <Row>
      <Col xs={xs} md={md}>
        <div style={{ marginBottom: 10 }}>
          {showLabels && <label style={labelStyle}>Inicio</label>}
          <DatePicker
            customInput={<input type="text" className="form-control" />}
            selected={inicio}
            onChange={(date) => setInicio(date)}
            maxDate={fin}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </Col>
      <Col xs={xs} md={md}>
        {showLabels && <label style={labelStyle}>Fin</label>}
        <DatePicker
          customInput={<input type="text" className="form-control" />}
          selected={fin}
          onChange={(date) => setFin(date)}
          minDate={inicio}
          dateFormat="dd/MM/yyyy"
        />
      </Col>
    </Row>
  );
}

export { DateRangeInput };
