/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Content,
  Row,
  Col,
  Box,
  Button,
  Pagination,
  Inputs,
} from 'adminlte-2-react';

import { SelectInput } from '../../utils/SelectInput';
import Loader from '../../utils/Loader';
import { Alert, alertTypes } from '../../ui/alert';
import {
  constants,
  getDateUtc,
  log,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../../utils/utils';
import { TextInput } from '../../utils/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useWindowSize } from '../../utils/useWindowSize';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

const { Checkbox } = Inputs;

function ListaCandidatosNormalizarView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  candidatos,
  busquedaRapida,
  setBusquedaRapida,
  modelCandidatos,
}) {
  const { isMobile } = useWindowSize();

  const goToNextPage = (page) => {
    handlePage(page);
  };

  return (
    <Content
      title="Candidatos a actualizar"
      subTitle=""
      browserTitle="Candidatos a actualizar"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Loader isLoading={modelCandidatos.loading}>
        <Row>
          <Col xs="12" sm="4" lg="3">
            <Box title="Filtro">
              <TextInput
                label="Búsqueda rápida"
                placeholder="Nombre, apellido, teléfono"
                initialValue={busquedaRapida}
                setValue={setBusquedaRapida}
                onKeyDown={(event) =>
                  submitSearchOnEnterPress(event, () => handlePage(0))
                }
              />

              <div style={{ float: 'right' }}>
                <div style={{ display: 'inline-block', marginRight: 10 }}>
                  <Button
                    type="primary"
                    text="Filtrar"
                    onClick={() => handlePage(0)}
                  />
                </div>
              </div>
            </Box>
          </Col>

          <Col xs="12" sm="8" lg="9">
            <Box title="Lista">
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Pagination
                    activePage={activePage}
                    pageSize={pageSize}
                    totalElements={totalResults}
                    onChange={goToNextPage}
                    labels={{
                      first: 'Primera',
                      last: 'Última',
                      previous: 'Anterior',
                      next: 'Siguiente',
                    }}
                  />
                </div>
              </div>

              <Loader isLoading={modelCandidatos.loading}>
                {candidatos && candidatos.length > 0 ? (
                  <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                    <div className="table-responsive">
                      <DataTable
                        lazy
                        pt={{
                          table: {
                            className: 'table table-hover',
                          },
                        }}
                        value={candidatos}
                        emptyMessage="No se encontraron registros"
                      >
                        <Column
                          field="nombreCompleto"
                          header="Nombre"
                          body={(candidato) =>
                            toTitleCase(candidato.nombreCompleto)
                          }
                        ></Column>
                        <Column
                          header="Acciones"
                          body={(candidato) => (
                            <ButtonWithLoading
                              text="Actualizar"
                              size="sm"
                              type="default"
                              submit={() =>
                                history.push(
                                  `/actualizarCandidato/${candidato.idNumerico}`
                                )
                              }
                            />
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  </Box>
                ) : (
                  <h4>No se han encontrado candidatos a actualizar</h4>
                )}
              </Loader>
              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={goToNextPage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
              <div>
                <span>Total: {totalResults}</span>
              </div>

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { ListaCandidatosNormalizarView };
