import React from "react";

import IconoObligatorio from "./IconoObligatorio";

export default function Etiqueta({ obligatorio = false, texto }) {
  if (obligatorio) {
    return (
      <>
        <IconoObligatorio /> {texto}
      </>
    );
  }
  return <>{texto}</>;
}
