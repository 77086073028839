import React from 'react';
import { CheckBox } from '../ui/Checkbox';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_CONFIG, TINYMCE_SCRIPT_SRC } from '../utils/utils';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';

function CampoPublicacion({
  mRef,
  publicar,
  setPublicar,
  titulo,
  initialValue,
  permiteCambiarValorPublicar = true,
  required = false,
}) {
  return (
    <div style={{ marginBottom: 30 }}>
      <Etiqueta
        texto={<span style={{ fontSize: 16 }}>{titulo}</span>}
        obligatorio={required}
      />

      {permiteCambiarValorPublicar && (
        <CheckBox checked={publicar} text="Publicar" onChange={setPublicar} />
      )}

      <Editor
        tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
        onInit={(evt, editor) => (mRef.current = editor)}
        initialValue={initialValue}
        init={TINYMCE_CONFIG}
      />
    </div>
  );
}

export { CampoPublicacion };
