import React from 'react';
import { Content, Col, Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import {
  getDateOrEmpty,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { oportunidadEstadoDescById } from '../utils/constants';
import { DateRangeInput } from '../ui/DateRangeInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { monedaFormat } from '../ui/moneda';
import { log } from '../utils/logs';
import { ModalConfirmarBorrar } from './ModalConfirmarBorrar';
import { Alert } from '../ui/alert';
import { ModalAgregarAOportunidad } from '../candidatos/ModalAgregarAOportunidad';

function ListaOportunidadesView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  oportunidades,
  modelOportunidades,
  busquedaRapida,
  setBusquedaRapida,
  estadoOption,
  estadosOptions,
  setEstadoOption,
  usuarioOption,
  usuariosOptions,
  setUsuarioOption,
  inicio,
  setInicio,
  fin,
  setFin,
  usuariosLoadStatus,
  irAIncluir,
  isModalConfirmarBorrarShown,
  hideModalConfirmarBorrar,
  showModalConfirmarBorrar,
  selectedOportunidad,
  borrarOportunidad,
  setSelectedOportunidad,
  modelBorrarOportunidad,
  candidatoAIncluirId,
  isModalIncluirCandidatoShown,
  setisModalIncluirCandidatoShown,
  incluirEnOportunidad,
  incluirEtapaOption,
  modelIncluirEnOportunidad,
  etapaOption,
  etapasOptions,
  setEtapaOption,
  empresaIdNumerico,
  empresaNombre,
  getOportunidadWithEtapas,
  modelGetOportunidad,
  oportunidad,
  setOportunidad,
}) {
  const getOportunidadEstado = (oportunidad) => {
    return <div>{oportunidadEstadoDescById[oportunidad.estado] ?? 'N/A'}</div>;
  };

  const getFecha = (fecha) => {
    return <div>{getDateOrEmpty(fecha)}</div>;
  };

  const getAcciones = (oportunidad) => {
    const candidatoYaIncluido = oportunidad.candidatos?.find(
      (candidato) => candidato.idNumerico === candidatoAIncluirId
    );
    return (
      <div style={{ width: candidatoAIncluirId ? 110 : 200 }}>
        {candidatoYaIncluido && (
          <span class="text-muted">Candidato ya incluido</span>
        )}

        {candidatoAIncluirId && !candidatoYaIncluido && (
          <div style={{ display: 'inline-block', marginRight: 5 }}>
            <ButtonWithLoading
              type="primary"
              size="sm"
              text="Incluir candidato"
              submit={() => {
                setOportunidad(null);
                setSelectedOportunidad(oportunidad);
                getOportunidadWithEtapas(oportunidad);
                setisModalIncluirCandidatoShown(true);
              }}
            />
          </div>
        )}

        {!candidatoAIncluirId && (
          <div style={{ display: 'inline-block', marginRight: 5 }}>
            <ButtonWithLoading
              type="default"
              size="sm"
              text="Incluir candidatos"
              submit={() => irAIncluir(oportunidad)}
            />
          </div>
        )}

        {!candidatoAIncluirId && (
          <div style={{ display: 'inline-block' }}>
            <ButtonWithLoading
              type="default"
              size="sm"
              text="Detalles"
              submit={() =>
                history.push(`/editarOportunidad/${oportunidad.idNumerico}`)
              }
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Content
      title="Oportunidades"
      subTitle=""
      browserTitle="Oportunidades"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {selectedOportunidad && (
        <ModalConfirmarBorrar
          isModalConfirmarBorrarShown={isModalConfirmarBorrarShown}
          hideModalConfirmarBorrar={hideModalConfirmarBorrar}
          showModalConfirmarBorrar={showModalConfirmarBorrar}
          selectedOportunidad={selectedOportunidad}
          borrarOportunidad={borrarOportunidad}
          isBorrarButtonLoading={modelBorrarOportunidad.loading}
        />
      )}

      {isModalIncluirCandidatoShown && (
        <ModalAgregarAOportunidad
          isModalAgregarShown={isModalIncluirCandidatoShown}
          hideModalAgregar={() => setisModalIncluirCandidatoShown(false)}
          showModalAgregar={() => setisModalIncluirCandidatoShown(true)}
          incluirEnOportunidad={incluirEnOportunidad}
          isIncluirButtonDisabled={!etapaOption?.value}
          isIncluirButtonLoading={modelIncluirEnOportunidad.loading}
          etapaOption={etapaOption}
          etapasOptions={etapasOptions}
          setEtapaOption={setEtapaOption}
          oportunidad={oportunidad}
          selectEtapaDisabled={false}
        />
      )}

      <Loader isLoading={usuariosLoadStatus !== 'LOADED'}>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            {empresaIdNumerico && (
              <TextInput
                label="Empresa"
                initialValue={empresaNombre}
                disabled
              />
            )}

            <TextInput
              label="Cargo, título, cliente"
              placeholder="Cargo, título, cliente"
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={(event) =>
                submitSearchOnEnterPress(event, () => handlePage(0))
              }
            />

            <SelectInput
              label="Estado"
              value={estadoOption}
              options={estadosOptions}
              setValue={setEstadoOption}
            />

            <SelectInput
              label="Selector"
              hint="Busca oportunidades a cargo del selector"
              value={usuarioOption}
              options={usuariosOptions}
              setValue={setUsuarioOption}
              disabled={usuariosLoadStatus !== 'LOADED'}
              isLoading={usuariosLoadStatus !== 'LOADED'}
            />

            <div className="form-group">
              <label>Fecha de creación</label>

              <DateRangeInput
                showLabels
                labelStyle={{ fontWeight: 'normal' }}
                inicio={inicio}
                setInicio={setInicio}
                fin={fin}
                setFin={setFin}
                xs={12}
                md={12}
              />
            </div>

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>
        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            border={false}
            customOptions={
              <div>
                {!candidatoAIncluirId && (
                  <Button
                    size="sm"
                    type="default"
                    text="Agregar"
                    onClick={() => history.push('/crearOportunidad')}
                  />
                )}
              </div>
            }
          >
            <Loader isLoading={modelOportunidades.loading}>
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />

              <DataTable
                lazy
                pt={{
                  table: {
                    className: 'table table-hover',
                  },
                }}
                removableSort
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={(e) => {
                  setSortField(e.sortField);
                  setSortOrder(e.sortOrder);
                  log({
                    'onSort.sortField': e.sortField,
                    'onSort.sortOrder': e.sortOrder,
                  });
                }}
                value={oportunidades}
                emptyMessage="No se encontraron registros"
              >
                <Column
                  field="acciones"
                  header="Acciones"
                  body={(oportunidad) => getAcciones(oportunidad)}
                ></Column>

                <Column
                  field="empresaDescripcion"
                  header="Cliente"
                  body={(oportunidad) => {
                    return toTitleCase(oportunidad.empresaDescripcion);
                  }}
                ></Column>

                <Column field="titulo" header="Título"></Column>

                <Column
                  field="cargoDescripcion"
                  header="Cargo"
                  body={(oportunidad) => {
                    return toTitleCase(oportunidad.cargoDescripcion);
                  }}
                ></Column>

                <Column
                  field="estado"
                  header="Estado"
                  body={getOportunidadEstado}
                ></Column>

                {/* <Column
                  header="Fecha Inicio"
                  style={{ minWidth: 90 }}
                  body={(oportunidad) => getFecha(oportunidad.inicioFecha)}
                ></Column> */}

                <Column
                  field="solicitudFecha"
                  header="Fecha Solicitud"
                  body={(oportunidad) => getFecha(oportunidad.solicitudFecha)}
                  style={{ minWidth: 90 }}
                  sortable
                ></Column>

                <Column
                  field="cierreFecha"
                  header="Fecha Cierre"
                  body={(oportunidad) => getFecha(oportunidad.cierreFecha)}
                  style={{ minWidth: 90 }}
                  sortable
                ></Column>

                <Column
                  field="candidatosCantidad"
                  header="Cant. Candidatos"
                  body={(oportunidad) => oportunidad.candidatos?.length ?? 0}
                ></Column>

                <Column
                  field="salarioMaximo"
                  header="Salario Máx."
                  body={(oportunidad) => {
                    return (
                      <div style={{ width: 100 }}>
                        {monedaFormat({ valor: oportunidad.salarioMaximo })}
                      </div>
                    );
                  }}
                ></Column>

                <Column
                  field="creacionUsuarioNombre"
                  header="Usuario Creación"
                ></Column>

                <Column
                  header="Fecha Creación"
                  style={{ minWidth: 90 }}
                  body={(oportunidad) => getFecha(oportunidad.creacionTs)}
                ></Column>

                <Column
                  field="borrar"
                  header="Borrar"
                  body={(oportunidad) => (
                    <div style={{}}>
                      <div style={{ display: 'inline-block' }}>
                        <ButtonWithLoading
                          type="warning"
                          size="sm"
                          text="Borrar"
                          submit={() => {
                            setSelectedOportunidad(oportunidad);
                            showModalConfirmarBorrar(oportunidad.idNumerico);
                          }}
                        />
                      </div>
                    </div>
                  )}
                ></Column>
              </DataTable>

              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>
            </Loader>
          </Box>
        </Col>
      </Loader>
    </Content>
  );
}

export { ListaOportunidadesView };
