import React, { useEffect, useState } from "react";
import AdminLTE, {
  Sidebar,
  Content,
  Row,
  Col,
  Box,
  Button,
  Inputs,
  Label,
} from "adminlte-2-react";
import _ from "lodash";
import controller from "./duck";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import {
  constants,
  parseJwt,
  responseSelector,
  validateEmail,
} from "../utils/utils";
import { Alert, alertTypes } from "../utils/Alert";

const { Select2, Date, DateTime, Text } = Inputs;

function OlvidePass({ history, match }) {
  const dispatch = useDispatch();
  const modelOlvide = useSelector((state) => state.olvidePass);
  const [username, setUsername] = useState(null);

  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const submitAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [submitAlert, setSubmitAlert] = useState(submitAlertDefaultValue);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelOlvide);

    if (error) {
      setSubmitAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(controller.duck.actions.clear());
    }
    if (httpStatus === constants.HTTP_SUCCESS) {
      setSubmitAlert({
        title: "Éxito",
        text: "Se ha enviado un mail con el link para la recuperacion",
        type: alertTypes.success,
        closable: false,
      });
      dispatch(controller.duck.actions.clear());
    }
  }, [modelOlvide]);

  function submit() {
    if (!validateEmail(username)) {
      setSubmitAlert({
        title: "Error",
        text: "Por favor proporcione un email con el formato correcto",
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    if (!username) {
      setSubmitAlert({
        title: "Error",
        text: "Por favor complete todos los campos",
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    let data = {
      username,
    };
    dispatch(controller.olvidePassThunk(data));
  }
  return (
    <Content
      title="Olvide mi contraseña"
      browserTitle="Olvide mi contraseña"
      show={false}
    >
      <Row>
        <Col md="4" mdOffset="4">
          <Box title="RECUPERAR CONTRASEÑA">
            <Text
              labelPosition="above"
              size="sm"
              label="Email"
              onChange={(evt) => setUsername(evt.target.value)}
            />
            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
            <Button
              type="primary"
              text="Enviar"
              pullRight={true}
              onClick={() => submit()}
            />
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export default OlvidePass;
