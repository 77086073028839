/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Row, Col, Box } from 'adminlte-2-react';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert } from '../utils/Alert';
import { log } from '../utils/logs';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import Loader from '../utils/Loader';
import { CheckBox } from '../ui/Checkbox';
import { SelectInput } from '../utils/SelectInput';
import { ModalGenerico } from '../ui/ModalGenerico';
import { alertTypes } from '../ui/alert';
import { toTitleCase } from '../utils/utils';

const queryString = require('query-string');
const { TextInput, knownRegexes } = require('../utils/TextInput');

const getFoundEmpresasText = ({ history, foundEmpresas }) => {
  const items = foundEmpresas.searchResultsByTerm.map((item, index) => {
    if (!item?.items?.length) {
      return null;
    }

    return (
      <li key={index} style={{ marginBottom: 10 }}>
        {toTitleCase(item.term)} ({item.count})
        <ul>
          {item.items.map((empresa) => (
            <li key={empresa._id}>{toTitleCase(empresa.nombre)}</li>
          ))}
        </ul>
        <a
          href="javascript:;"
          onClick={() =>
            history.push(
              `/empresas/?busquedaRapida=${item.term}&esCliente=TODOS`
            )
          }
        >
          Ver más
        </a>
      </li>
    );
  });

  return (
    <>
      <ul>{items}</ul>
    </>
  );
};

function CrearEmpresaView({
  mode,
  history,
  match,
  modelCreateEmpresa,
  submit,
  submitAlert,
  topAlert,
  modelGetEmpresa,
  nombre,
  setNombre,
  direccion,
  setDireccion,
  telefono,
  setTelefono,
  correo,
  setCorreo,
  razonSocial,
  setRazonSocial,
  documento,
  setDocumento,
  gerenteGral,
  setGerenteGral,
  gerenteRrhh,
  setGerenteRrhh,
  esCliente,
  setEsCliente,
  esGrupo,
  setEsGrupo,
  contactoNombre,
  setContactoNombre,
  contactoTelefono,
  setContactoTelefono,
  rubroOption,
  rubrosOptions,
  setRubroOption,
  grupoEmpresarialOptions,
  grupoEmpresarialOption,
  setGrupoEmpresarialOption,
  empresaId,
  empresaNombreOriginal,
  empresaLoadStatus,
  modalRedirect,
  setModalRedirect,
  foundEmpresas,
  modelSearchEmpresasByName,
}) {
  const title = mode === 'edit' ? 'Editar empresa' : 'Crear empresa';

  return (
    <Content title={title} browserTitle={title} show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {modalRedirect && (
        <ModalGenerico
          isModalShown={!!modalRedirect}
          hideModal={() => setModalRedirect(null)}
          title="Alerta"
          body="Si no guardaste los cambios hechos en el formulario, se perderán. ¿Continuar?"
          buttonText="Continuar"
          submit={() => {
            history.push(modalRedirect);
          }}
        />
      )}

      <Loader
        isLoading={
          mode === 'edit' &&
          (modelGetEmpresa.loading || empresaLoadStatus !== 'LOADED')
        }
      >
        <Row>
          <Col md="10" mdOffset="1">
            <Box
              title="Datos"
              customOptions={
                mode === 'edit' && (
                  <div>
                    <ButtonWithLoading
                      size="sm"
                      type="default"
                      text="Candidatos"
                      submit={() => {
                        const strQueryParams = queryString.stringify({
                          empresaIdNumerico: empresaId,
                          empresaNombre: empresaNombreOriginal,
                        });
                        return setModalRedirect(
                          `/candidatos/?${strQueryParams}`
                        );
                      }}
                    />
                    <ButtonWithLoading
                      size="sm"
                      type="default"
                      text="Oportunidades"
                      submit={() => {
                        const strQueryParams = queryString.stringify({
                          empresaIdNumerico: empresaId,
                          empresaNombre: empresaNombreOriginal,
                        });
                        return setModalRedirect(
                          `/oportunidades/?${strQueryParams}`
                        );
                      }}
                    />
                  </div>
                )
              }
            >
              <ReferenciaObligatorios />

              <TextInput
                label="Nombre"
                initialValue={nombre}
                setValue={setNombre}
                required
              />

              <Loader isLoading={modelSearchEmpresasByName?.loading}>
                {foundEmpresas?.total > 0 && (
                  <Alert
                    title={'Se encontraron empresas similares'}
                    type={alertTypes.warning}
                    text={getFoundEmpresasText({ foundEmpresas, history })}
                    closable={false}
                  />
                )}
              </Loader>

              <SelectInput
                label="Grupo empresarial"
                value={grupoEmpresarialOption}
                options={grupoEmpresarialOptions}
                setValue={setGrupoEmpresarialOption}
              />

              <TextInput
                label="Dirección"
                initialValue={direccion}
                setValue={setDireccion}
              />

              <TextInput
                label="Teléfono"
                initialValue={telefono}
                setValue={setTelefono}
              />

              <TextInput
                label="Correo"
                initialValue={correo}
                setValue={setCorreo}
              />

              <TextInput
                label="Razón social"
                initialValue={razonSocial}
                setValue={setRazonSocial}
              />

              <TextInput
                label="Documento"
                initialValue={documento}
                setValue={setDocumento}
              />

              <TextInput
                label="Gerente general"
                initialValue={gerenteGral}
                setValue={setGerenteGral}
              />

              <TextInput
                label="Gerente RRHH"
                initialValue={gerenteRrhh}
                setValue={setGerenteRrhh}
              />

              <CheckBox
                text="Es cliente"
                checked={esCliente}
                onChange={setEsCliente}
              />

              <CheckBox
                text="Es grupo empresarial"
                checked={esGrupo}
                onChange={setEsGrupo}
              />

              <SelectInput
                label="Rubro"
                value={rubroOption}
                options={rubrosOptions}
                setValue={setRubroOption}
              />

              <TextInput
                label="Nombre de contacto"
                initialValue={contactoNombre}
                setValue={setContactoNombre}
              />

              <TextInput
                label="Teléfono de contacto"
                initialValue={contactoTelefono}
                setValue={setContactoTelefono}
              />

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}

              <ButtonWithLoading
                text="Guardar"
                submit={submit}
                loading={modelCreateEmpresa.loading}
                pullRight
              />
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { CrearEmpresaView };
