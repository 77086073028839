import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
  constants,
  prepareCiudadesOptions,
  prepareNacionalidadesOptions,
  preparePaisesOptions,
  responseSelector,
  toTitleCase,
} from '../../utils/utils';
import { useAlerts } from '../../ui/alert';
import { alertTypes } from '../../utils/Alert';
import { log } from '../../utils/logs';
import {
  getCandidatoDuck,
  getCandidatoThunk,
  editarCandidatoDuck,
  editarCandidatoThunk,
  getCandidatoExperienciasDuck,
  getCandidatoExperienciasThunk,
  getCandidatoFormacionesDuck,
  getCandidatoFormacionesThunk,
  crearCandidatoExpDuck,
  crearCandidatoExpThunk,
  crearCandidatoFormacionDuck,
  crearCandidatoFormacionThunk,
  getSalidaMotivosDuck,
  getSalidaMotivosThunk,
  eliminarCandidatoExpDuck,
  eliminarCandidatoExpThunk,
  editarCandidatoExpDuck,
  editarCandidatoExpThunk,
  getCargosPotDuck,
  getCargosPotThunk,
  eliminarCargoPotThunk,
  eliminarCargoPotDuck,
  crearCargoPotThunk,
  crearCargoPotDuck,
  editarCargoPotDuck,
  editarCargoPotThunk,
  getInstitutosDuck,
  getInstitutosThunk,
  getTitulacionesDuck,
  getTitulacionesThunk,
  eliminarCandFormDuck,
  eliminarCandFormThunk,
  editarCandFormDuck,
  editarCandFormThunk,
  getNotasThunk,
  crearNotaThunk,
  updateNotaThunk,
  deleteNotaThunk,
  getNotasDuck,
  crearNotaDuck,
  deleteNotaDuck,
  updateNotaDuck,
  getNacionalidadesDuck,
  getPaisesDuck,
  getCiudadesDuck,
  getNacionalidadesThunk,
  getPaisesThunk,
  getCiudadesThunk,
  getCandIdiomasDuck,
  getCandIdiomasThunk,
  crearCandIdiomaDuck,
  crearCandIdiomaThunk,
  eliminarCandIdiomaDuck,
  eliminarCandIdiomaThunk,
  editarCandIdiomaDuck,
  editarCandIdiomaThunk,
  getIdiomasDuck,
  getIdiomasThunk,
  getCandArchivosDuck,
  getCandArchivosThunk,
  eliminarCandArchivoDuck,
  eliminarCandArchivoThunk,
  notaFavoritaSetThunk,
  notaFavoritaSetDuck,
} from '../duck';
import { EditarCandidatoView } from './EditarCandidatoView';
import {
  getCargosDuck,
  getCargosThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  getRubrosDuck,
  getRubrosThunk,
} from '../../oportunidades/duck';
import {
  empleoTipoOptionsForCandExp,
  nivelFormacionOptions,
  asuncionOption,
  nacionalidadPyOption,
  paraguayOption,
  sexoOptions,
  estadoCivilOptions,
  sexoOptionsById,
  estadoCivilOptionsById,
  nivelIdiomaOptionsById,
} from '../../utils/constants';

function EditarCandidato({ history, match, token, datosUsuario }) {
  const candidatoIdNumerico = match.params.candidatoId;

  const rol = token?.decoded?.rol;

  const dispatch = useDispatch();

  const modelEditarCandidato = useSelector((state) => state.editarCandidato);

  const notaFavoritaSet = useSelector((state) => state.notaFavoritaSet);

  const modelGetCandidato = useSelector((state) => state.getCandidato);
  const modelGetCandidatoExperiencias = useSelector(
    (state) => state.getCandidatoExperiencias
  );
  const modelGetCargos = useSelector((state) => state.getCargos);
  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelGetCargosPot = useSelector((state) => state.getCargosPot);
  const modelCrearCandidatoExp = useSelector(
    (state) => state.crearCandidatoExp
  );
  const modelGetSalidaMotivos = useSelector((state) => state.getSalidaMotivos);
  const modelEliminarCandidatoExp = useSelector(
    (state) => state.eliminarCandidatoExp
  );
  const modelEditarCandidatoExp = useSelector(
    (state) => state.editarCandidatoExp
  );
  const modelGetIdiomas = useSelector((state) => state.getIdiomas);

  // cargo potencial

  const modelCrearCargoPot = useSelector((state) => state.crearCargoPot);
  const modelEditarCargoPot = useSelector((state) => state.editarCargoPot);
  const modelEliminarCargoPot = useSelector((state) => state.eliminarCargoPot);

  // formacion

  const modelGetCandidatoFormaciones = useSelector(
    (state) => state.getCandidatoFormaciones
  );
  const modelCrearCandidatoFormacion = useSelector(
    (state) => state.crearCandidatoFormacion
  );
  const modelEliminarCandForm = useSelector((state) => state.eliminarCandForm);
  const modelEditarCandForm = useSelector((state) => state.editarCandForm);

  // idiomas

  const modelGetCandIdiomas = useSelector((state) => state.getCandIdiomas);
  const modelCrearCandIdioma = useSelector((state) => state.crearCandIdioma);
  const modelEliminarCandIdioma = useSelector(
    (state) => state.eliminarCandIdioma
  );
  const modelEditarCandIdioma = useSelector((state) => state.editarCandIdiomas);

  const modelGetInstitutos = useSelector((state) => state.getInstitutos);

  const modelGetTitulaciones = useSelector((state) => state.getTitulaciones);

  const modelNotas = useSelector((state) => state.getNotas);
  const modelCrearNota = useSelector((state) => state.crearNota);
  const modelUpdateNota = useSelector((state) => state.updateNota);
  const modelDeleteNota = useSelector((state) => state.deleteNota);
  const modelGetNacionalidades = useSelector(
    (state) => state.getNacionalidades
  );
  const modelGetPaises = useSelector((state) => state.getPaises);
  const modelGetCiudades = useSelector((state) => state.getCiudades);

  const modelGetCandArchivos = useSelector((state) => state.getCandArchivos);
  const modelEliminarCandArchivo = useSelector(
    (state) => state.eliminarCandArchivo
  );

  const [isModalIncluirEmShown, setIsModalIncluirEmShown] = useState(false);

  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [documento, setDocumento] = useState('');
  const [telefono1, setTelefono1] = useState('');
  const [telefono2, setTelefono2] = useState('');
  const [telefono3, setTelefono3] = useState('');
  const [correo, setCorreo] = useState('');
  const [nacimientoFecha, setNacimientoFecha] = useState();
  const [sexoOption, setSexoOption] = useState();
  const [estadoCivilOption, setEstadoCivilOption] = useState();
  const [direccion, setDireccion] = useState('');

  const [otroCargoInputShown, setOtroCargoInputShown] = useState(false);
  const [otraEmpresaInputShown, setOtraEmpresaInputShown] = useState(false);

  const [nacionalidadesOptions, setNacionalidadesOptions] = useState();
  const [nacionalidadOption, setNacionalidadOption] =
    useState(nacionalidadPyOption);

  const [paisesOptions, setPaisesOptions] = useState();
  const [paisOption, setPaisOption] = useState(paraguayOption);

  const [ciudadesOptions, setCiudadesOptions] = useState();
  const [ciudadOption, setCiudadOption] = useState(asuncionOption);
  const [ciudadLibre, setCiudadLibre] = useState();

  const [expectativaSalarial, setExpectativaSalarial] = useState();
  const [descripcionInterna, setDescripcionInterna] = useState();
  const [idiomasOptions, setIdiomasOptions] = useState();
  const [idiomaOption, setIdiomaOption] = useState();
  const [candIdiomas, setCandIdiomas] = useState();
  const [selectedCandIdioma, setSelectedCandIdioma] = useState();
  const [leeOption, setLeeOption] = useState();
  const [escribeOption, setEscribeOption] = useState();
  const [hablaOption, setHablaOption] = useState();
  const [idiomaById, setIdiomaById] = useState();
  const [candArchivos, setCandArchivos] = useState();
  const [yaIncluidoEnEmGeneral, setYaIncluidoEnEmGeneral] = useState();

  // [START experiencias]

  const [experiencias, setExperiencias] = useState([]);
  const [empresasOptions, setEmpresasOptions] = useState();
  const [empresaOption, setEmpresaOption] = useState(null);
  const [empresaOtro, setEmpresaOtro] = useState();
  const [observacion, setObservacion] = useState('');
  const [vigente, setVigente] = useState(false);
  const [inicioFecha, setInicioFecha] = useState();
  const [finFecha, setFinFecha] = useState();
  const [salario, setSalario] = useState('');
  const [condicionesActuales, setCondicionesActuales] = useState('');
  const [cargosOptions, setCargosOptions] = useState();
  const [cargoOption, setCargoOption] = useState();
  const [cargoOtro, setCargoOtro] = useState();
  const [rubrosOptions, setRubrosOptions] = useState();
  const [rubroOption, setRubroOption] = useState();
  const [tipoEmpleoOption, setTipoEmpleoOption] = useState();
  const [salidaMotivoOptions, setSalidaMotivoOptions] = useState();
  const [salidaMotivoOption, setSalidaMotivoOption] = useState();
  const [isModalAgregarExpShown, setIsModalAgregarExpShown] = useState();
  const [isModalEliminarCandExpShown, setIsModalEliminarCandExpShown] =
    useState();
  const [selectedExperiencia, setSelectedExperiencia] = useState();
  const [isModalEditarExpShown, setIsModalEditarExpShown] = useState(false);

  // [END experiencias]

  const [modalRedirect, setModalRedirect] = useState(null);

  const [isModalEliminarCandArchivoShown, setIsModalEliminarCandArchivoShown] =
    useState(false);
  const [selectedArchivo, setSelectedArchivo] = useState(null);

  const [isModalAgregarCandIdiomaShown, setIsModalAgregarCandIdiomaShown] =
    useState(false);

  const [isModalEliminarCandIdiomaShown, setIsModalEliminarCandIdiomaShown] =
    useState(false);

  const [isModalEditarCandIdiomaShown, setIsModalEditarCandIdiomaShown] =
    useState();

  const [titulo, setTitulo] = useState();

  const [contenido, setContenido] = useState();

  const [cuentaFiltroChecked, setCuentaFiltroChecked] = useState(true);
  const [entrevistaCortesiaChecked, setEntrevistaCortesiaChecked] =
    useState(false);

  const [isModalGenericoShown, setIsModalGenericoShown] = useState(false);

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();
  const [experienciaSubmitAlert, setExperienciaSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [formacionSubmitAlert, setFormacionSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [idiomaSubmitAlert, setIdiomaSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [archivoSubmitAlert, setArchivoSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [isModalAgregarFormacionShown, setIsModalAgregarFormacionShown] =
    useState();

  const [formaciones, setFormaciones] = useState();

  const [institutoOption, setInstitutoOption] = useState();
  const [institutosOptions, setInstitutosOptions] = useState();

  const [titulacionOption, setTitulacionOption] = useState();
  const [titulacionesOptions, setTitulacionesOptions] = useState();

  const [nivelFormacionOption, setNivelFormacionOption] = useState();
  const [candFormacInicioFecha, setCandFormacInicioFecha] = useState();
  const [candFormacFinFecha, setCandFormacFinFecha] = useState();
  const [candFormacDescripcion, setCandFormacDescripcion] = useState();
  const [selectedFormacion, setSelectedFormacion] = useState();
  const [otroInstitutoInputShown, setOtroInstitutoInputShown] = useState(false);
  const [institutoOtro, setInstitutoOtro] = useState('');
  const [otraTitulacionInputShown, setOtraTitulacionInputShown] =
    useState(false);
  const [titulacionOtro, setTitulacionOtro] = useState('');

  const [cargoPotSubmitAlert, setCargoPotSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [isModalAgregarCargoPotShown, setIsModalAgregarCargoPotShown] =
    useState();

  const [isModalEditarCargoPotShown, setIsModalEditarCargoPotShown] =
    useState(false);

  const [isModalEditarCandFormShown, setIsModalEditarCandFormShown] =
    useState(false);

  const [cargosPotenciales, setCargosPotenciales] = useState([]);
  const [selectedCargoPot, setSelectedCargoPot] = useState();

  const [isModalEliminarCargoPotShown, setIsModalEliminarCargoPotShown] =
    useState(false);

  const [isModalEliminarCandFormShown, setIsModalEliminarCandFormShown] =
    useState(false);

  const [notaSelected, setNotaSelected] = useState('');
  const [isModalNotasShown, setIsModalNotasShown] = useState();
  const [notas, setNotas] = useState([]);
  const [totalResultsNotas, setTotalResultsNotas] = useState(0);
  const [activePageNotas, setActivePageNotas] = useState(0);
  const [pageSizeNotas, setPageSizeNotas] = useState(5);

  const prepareDatosAuxOptions = (datos) => {
    const options = datos.map((dato) => ({
      value: dato.idNumerico,
      label: toTitleCase(dato.descripcion),
    }));

    return options;
  };

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(notaFavoritaSet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(notaFavoritaSetDuck.actions.clear());
    }

    if (httpStatus) {
      handlePageNotas(activePageNotas);

      dispatch(notaFavoritaSetDuck.actions.clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notaFavoritaSet]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetIdiomas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getIdiomasDuck.actions.clear());
    }

    if (data) {
      const mIdiomasOptions = prepareDatosAuxOptions(data.items);

      // log({ 'modelGetIdiomas.useEffect.mIdiomasOptions': mIdiomasOptions });
      setIdiomasOptions(mIdiomasOptions);
      setIdiomaById(_.keyBy(data.items, 'idNumerico'));

      dispatch(getIdiomasDuck.actions.clear());
    }
  }, [modelGetIdiomas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetNacionalidades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNacionalidadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetNacionalidades.useEffect.data': data });

      const mNacOptions = prepareNacionalidadesOptions(data.items);
      // log({ mNacOptions });

      setNacionalidadesOptions(mNacOptions);

      dispatch(getNacionalidadesDuck.actions.clear());
    }
  }, [modelGetNacionalidades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetPaises);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getPaisesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetPaises.useEffect.data': data });

      const mPaisesOptions = preparePaisesOptions(data.items);
      // log({ mPaisesOptions });

      setPaisesOptions(mPaisesOptions);

      dispatch(getPaisesDuck.actions.clear());
    }
  }, [modelGetPaises]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCiudades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCiudadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCiudades.useEffect.data': data });

      const mCiudadesOptions = prepareCiudadesOptions(data.items);
      // log({ mCiudadesOptions });

      setCiudadesOptions(mCiudadesOptions);

      dispatch(getCiudadesDuck.actions.clear());
    }
  }, [modelGetCiudades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelNotas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasDuck.actions.clear());
    }

    if (data) {
      setNotas(data.items);
      setTotalResultsNotas(data.total);
      // showModalNotas();

      dispatch(getNotasDuck.actions.clear());
    }
  }, [modelNotas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCargos.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetRubros.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setRubrosOptions(datosAuxOptions);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetInstitutos);

    // log('modelGetInstitutos.useEffect');

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getInstitutosDuck.actions.clear());
    }

    if (data) {
      // log({
      //   'modelGetInstitutos.useEffect.data.items.length': data.items.length,
      // });

      const options = data.items.map((dato) => ({
        value: dato.idNumerico,
        label: toTitleCase(dato.nombre),
      }));

      // log({ 'modelGetInstitutos.useEffect.options.length': options.length });

      setInstitutosOptions(options);

      dispatch(getInstitutosDuck.actions.clear());
    }
  }, [modelGetInstitutos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetTitulaciones);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getTitulacionesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetInstitutos.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setTitulacionesOptions(datosAuxOptions);

      dispatch(getTitulacionesDuck.actions.clear());
    }
  }, [modelGetTitulaciones]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetSalidaMotivos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getSalidaMotivosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetSalidaMotivos.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setSalidaMotivoOptions(datosAuxOptions);

      dispatch(getSalidaMotivosDuck.actions.clear());
    }
  }, [modelGetSalidaMotivos]);

  useEffect(() => {
    dispatch(
      getEmpresasThunk({
        esCliente: true,
        userToken: token.raw,
      })
    );
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getCandidatoThunk({
        userToken: token.raw,
        idNumerico: candidatoIdNumerico,
      })
    );
    dispatch(
      getCandidatoExperienciasThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    dispatch(
      getCandidatoFormacionesThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    dispatch(
      getSalidaMotivosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getCargosPotThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    dispatch(
      getInstitutosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getTitulacionesThunk({
        userToken: token.raw,
      })
    );
    dispatch(getNacionalidadesThunk({ userToken: token.raw }));
    dispatch(getPaisesThunk({ userToken: token.raw }));
    dispatch(getCiudadesThunk({ userToken: token.raw, padreId: null }));
    dispatch(getIdiomasThunk({ userToken: token.raw }));
    dispatch(
      getCandIdiomasThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    dispatch(
      getCandArchivosThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    if (rol !== 'candidato') {
      handlePageNotas(0);
    }
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidato);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandidato.data': data });

      dispatch(getCandidatoDuck.actions.clear());

      setNombre(data.candidato.nombre);
      setApellido(data.candidato.apellido);
      setDocumento(data.candidato.documento);
      setTelefono1(data.candidato.telefono1 ?? '');
      setTelefono2(data.candidato.telefono2 ?? '');
      setTelefono3(data.candidato.telefono3 ?? '');
      setExpectativaSalarial(data.candidato.expectativaSalarial);
      setDireccion(data.candidato.direccion);
      setCorreo(data.candidato.correo);
      setNacimientoFecha(
        data.candidato.nacimientoFecha
          ? moment.utc(data.candidato.nacimientoFecha).toDate()
          : null
      );
      setSexoOption(sexoOptionsById[data.candidato.sexo]);
      setEstadoCivilOption(estadoCivilOptionsById[data.candidato.estadoCivil]);
      setDescripcionInterna(data.candidato.descripcionInterna);
      setNacionalidadOption({
        value: data.candidato.nacionalidadIdNumerico,
        label: data.candidato.nacionalidadDescripcion,
      });
      setPaisOption({
        value: data.candidato.paisId,
        label: data.candidato.paisDescripcion,
      });
      setCiudadOption({
        value: data.candidato.ciudadId,
        label: data.candidato.ciudadDescripcion,
      });
      setCiudadLibre(data.candidato.ciudadLibre);
      setYaIncluidoEnEmGeneral(!!data.candidato.incluidoAEmGeneral);
    }
  }, [modelGetCandidato]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidatoExperiencias);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatoExperienciasDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandidatoExperiencias.data': data });

      dispatch(getCandidatoExperienciasDuck.actions.clear());

      // log({ rol });

      if (rol === 'candidato') {
        setExperiencias(data.experiencias);
      } else if (data?.experiencias?.length) {
        setExperiencias(
          data.experiencias.filter(
            (experiencia) =>
              experiencia.normalizado === true ||
              experiencia.normalizado === undefined ||
              experiencia.normalizado === null
          )
        );
      } else {
        setExperiencias([]);
      }
    }
  }, [modelGetCandidatoExperiencias]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidatoFormaciones);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatoFormacionesDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandidatoFormaciones.data': data });

      dispatch(getCandidatoFormacionesDuck.actions.clear());

      if (rol === 'candidato') {
        setFormaciones(data.formaciones);
      } else if (data?.formaciones?.length) {
        setFormaciones(
          data.formaciones.filter(
            (formacion) =>
              formacion.normalizado === true ||
              formacion.normalizado === undefined ||
              formacion.normalizado === null
          )
        );
      } else {
        setFormaciones([]);
      }
    }
  }, [modelGetCandidatoFormaciones]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandIdiomas);

    if (error) {
      setIdiomaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandIdiomasDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandIdiomas.data': data });

      setCandIdiomas(data.idiomas);

      dispatch(getCandIdiomasDuck.actions.clear());
    }
  }, [modelGetCandIdiomas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandArchivos);

    if (error) {
      setArchivoSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandArchivosDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandArchivos.data': data });

      setCandArchivos(data.archivos);

      dispatch(getCandArchivosDuck.actions.clear());
    }
  }, [modelGetCandArchivos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargosPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCargosPot.data': data });

      dispatch(getCargosPotDuck.actions.clear());

      setCargosPotenciales(data.cargosPotenciales);
    }
  }, [modelGetCargosPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandidato);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editarCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandidatoDuck.actions.clear());

      hideModalEditarCargoPot();

      setSubmitAlert({
        text: 'Candidato editado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelEditarCandidato]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandidatoExp);

    if (error) {
      setExperienciaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      hideModalEditarExp();

      dispatch(editarCandidatoExpDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandidatoExpDuck.actions.clear());

      dispatch(
        getCandidatoExperienciasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setExperienciaSubmitAlert({
        text: 'Experiencia editada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalEditarExp();
    }
  }, [modelEditarCandidatoExp]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandForm);

    if (error) {
      setFormacionSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEditarCandFormShown(false);

      dispatch(editarCandFormDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandFormDuck.actions.clear());

      dispatch(
        getCandidatoFormacionesThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setFormacionSubmitAlert({
        text: 'Formación editada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalEditarCandFormShown(false);
    }
  }, [modelEditarCandForm]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandIdioma);

    if (error) {
      setIdiomaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEditarCandIdiomaShown(false);

      dispatch(editarCandIdiomaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandIdiomaDuck.actions.clear());

      dispatch(
        getCandIdiomasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setIdiomaSubmitAlert({
        text: 'Idioma editado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalEditarCandIdiomaShown(false);
    }
  }, [modelEditarCandIdioma]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCargoPot);

    // log('modelEditarCargoPot');

    if (error) {
      // log('modelEditarCargoPot.error');

      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalEditarCargoPot();

      dispatch(editarCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log('modelEditarCargoPot.success');

      dispatch(editarCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial editado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalEditarCargoPot();
    }
  }, [modelEditarCargoPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCandidatoExp);

    if (error) {
      setExperienciaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalAgregarExp();

      dispatch(crearCandidatoExpDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCandidatoExpDuck.actions.clear());

      dispatch(
        getCandidatoExperienciasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setExperienciaSubmitAlert({
        text: 'Experiencia agregada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalAgregarExp();
    }
  }, [modelCrearCandidatoExp]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCargoPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalAgregarCargoPot();

      dispatch(crearCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial agregado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalAgregarCargoPot();
    }
  }, [modelCrearCargoPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCandidatoFormacion);

    if (error) {
      setFormacionSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setIsModalAgregarFormacionShown(false);

      dispatch(crearCandidatoFormacionDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCandidatoFormacionDuck.actions.clear());

      dispatch(
        getCandidatoFormacionesThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setFormacionSubmitAlert({
        text: 'Formacion agregada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalAgregarFormacionShown(false);
    }
  }, [modelCrearCandidatoFormacion]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCandIdioma);

    if (error) {
      setIdiomaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setIsModalAgregarCandIdiomaShown(false);

      dispatch(crearCandIdiomaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCandIdiomaDuck.actions.clear());

      dispatch(
        getCandIdiomasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setIdiomaSubmitAlert({
        text: 'Idioma agregado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalAgregarCandIdiomaShown(false);
    }
  }, [modelCrearCandIdioma]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarCandidatoExp);

    if (error) {
      setExperienciaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      hideModalEliminarCandExp();

      dispatch(eliminarCandidatoExpDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarCandidatoExpDuck.actions.clear());

      dispatch(
        getCandidatoExperienciasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setExperienciaSubmitAlert({
        text: 'Experiencia eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalEliminarCandExp();
    }
  }, [modelEliminarCandidatoExp]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarCandForm);

    if (error) {
      setExperienciaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEliminarCandFormShown(false);

      dispatch(eliminarCandFormDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarCandFormDuck.actions.clear());

      dispatch(
        getCandidatoFormacionesThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setExperienciaSubmitAlert({
        text: 'Formación eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalEliminarCandFormShown(false);
    }
  }, [modelEliminarCandForm]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarCandIdioma);

    if (error) {
      setIdiomaSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEliminarCandIdiomaShown(false);

      dispatch(eliminarCandIdiomaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarCandIdiomaDuck.actions.clear());

      dispatch(
        getCandIdiomasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setIdiomaSubmitAlert({
        text: 'Idioma eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalEliminarCandIdiomaShown(false);
    }
  }, [modelEliminarCandIdioma]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarCandArchivo);

    if (error) {
      setArchivoSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEliminarCandArchivoShown(false);
      setSelectedArchivo(null);

      dispatch(eliminarCandArchivoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarCandArchivoDuck.actions.clear());

      dispatch(
        getCandArchivosThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setArchivoSubmitAlert({
        text: 'Archivo eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsModalEliminarCandArchivoShown(false);
      setSelectedArchivo(null);
    }
  }, [modelEliminarCandArchivo]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarCargoPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalEliminarCargoPot();

      dispatch(eliminarCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      hideModalEliminarCargoPot();
    }
  }, [modelEliminarCargoPot]);

  useEffect(() => {
    if (notaSelected) {
      setTitulo(notaSelected.titulo);
      setContenido(notaSelected.contenido);
      setCuentaFiltroChecked(notaSelected.cuentaFiltro);
      setEntrevistaCortesiaChecked(notaSelected.entrevistaCortesia);
    }
  }, [notaSelected]);

  function hideModalAgregarExp() {
    setIsModalAgregarExpShown(false);
  }

  function showModalAgregarExp() {
    setEmpresaOption(null);
    setIsModalAgregarExpShown(true);
  }

  function hideModalEliminarCandExp() {
    setIsModalEliminarCandExpShown(false);
  }

  function showModalEliminarCandExp() {
    setIsModalEliminarCandExpShown(true);
  }

  function hideModalEliminarCargoPot() {
    setIsModalEliminarCargoPotShown(false);
  }

  function showModalEliminarCargoPot() {
    setIsModalEliminarCargoPotShown(true);
  }

  function eliminarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      cargoIdNumerico: selectedCargoPot.cargoIdNumerico,
    };

    // log({ 'eliminarCargoPot.data': data });

    dispatch(eliminarCargoPotThunk(data));
  }

  function hideModalAgregarCargoPot() {
    setIsModalAgregarCargoPotShown(false);
  }
  function showModalAgregarCargoPot() {
    setIsModalAgregarCargoPotShown(true);
  }

  function agregarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      cargoIdNumerico: cargoOption?.value,
    };

    log({ 'agregarCargoPot.data': data });

    dispatch(crearCargoPotThunk(data));
  }

  function hideModalEditarCargoPot() {
    log('hideModalEditarCargoPot');
    setIsModalEditarCargoPotShown(false);
  }

  function showModalEditarCargoPot() {
    setIsModalEditarCargoPotShown(true);
  }

  function editarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      cargoIdNumerico: cargoOption?.value,
      _id: selectedCargoPot._id,
    };

    log({ 'editarCargoPot.data': data });

    dispatch(editarCargoPotThunk(data));
  }

  function agregarExp() {
    crearExperiencia();
  }

  function submit() {
    const data = {
      candidatoIdNumerico,
      userToken: token.raw,
      nombre,
      apellido,
      documento,
      direccion,
      telefono1,
      telefono2,
      telefono3,
      correo,
      nacimientoFecha: nacimientoFecha
        ? moment(nacimientoFecha).format('YYYY-MM-DD')
        : null,
      sexo: sexoOption?.value,
      estadoCivil: estadoCivilOption?.value,
      nacionalidadIdNumerico: nacionalidadOption?.value,
      paisId: paisOption?.value,
      expectativaSalarial: expectativaSalarial
        ? expectativaSalarial.toString()
        : '0',
      descripcionInterna,
    };

    if (paisOption.value === paraguayOption.value) {
      data.ciudadId = ciudadOption?.value;
    } else {
      data.ciudadLibre = ciudadLibre;
    }

    log({ 'submit.data': data });

    dispatch(editarCandidatoThunk(data));
  }

  function crearExperiencia() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      empresaIdNumerico: empresaOption?.value,
      empresaOtro: otraEmpresaInputShown ? empresaOtro : null,
      observacion, // texto libre de descripcion del cargo
      vigente,
      inicioFecha,
      finFecha: vigente ? null : finFecha,
      salario: salario ? String(salario) : null,
      condicionesActuales,
      cargoIdNumerico: cargoOption?.value,
      cargoOtro: otroCargoInputShown ? cargoOtro : null,
      rubroIdNumerico: rubroOption?.value,
      tipoEmpleo: tipoEmpleoOption?.value,
      salidaMotivoIdNumerico: vigente ? null : salidaMotivoOption?.value,
    };

    log({ 'crearExperiencia.data': data });

    dispatch(crearCandidatoExpThunk(data));
  }

  function editarExperiencia() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoExpIdNumerico: selectedExperiencia.idNumerico,
      empresaIdNumerico: empresaOption?.value,
      empresaOtro: otraEmpresaInputShown ? empresaOtro : null,
      observacion, // texto libre de descripcion del cargo
      vigente,
      inicioFecha,
      finFecha: vigente ? null : finFecha,
      salario: salario ? String(salario) : null,
      condicionesActuales,
      cargoIdNumerico: cargoOption?.value,
      cargoOtro: otroCargoInputShown ? cargoOtro : null,
      rubroIdNumerico: rubroOption?.value,
      tipoEmpleo: tipoEmpleoOption?.value,
      salidaMotivoIdNumerico: vigente ? null : salidaMotivoOption?.value,
    };
    log({ 'editarExperiencia.data': data });
    dispatch(editarCandidatoExpThunk(data));
  }

  function eliminarCandidatoExp() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoExpIdNumerico: selectedExperiencia.idNumerico,
    };
    log({ 'eliminarCandidatoExp.data': data });
    dispatch(eliminarCandidatoExpThunk(data));
  }

  function hideModalEditarExp() {
    setIsModalEditarExpShown(false);
  }

  function showModalEditarExp() {
    setIsModalEditarExpShown(true);
  }

  const prepareExpEditAndShowModal = useCallback(
    (selectedExperiencia) => {

      setEmpresaOption({
        value: selectedExperiencia.empresaIdNumerico,
        label: selectedExperiencia.empresaDescripcion,
      });

      if (selectedExperiencia?.empresaOtro) {
        setEmpresaOtro(selectedExperiencia.empresaOtro);
        setOtraEmpresaInputShown(true);
      } else {
        setEmpresaOtro('');
        setOtraEmpresaInputShown(false);
      }

      setObservacion(selectedExperiencia.observacion);
      setVigente(selectedExperiencia.vigente);
      setInicioFecha(
        selectedExperiencia.inicioFecha
          ? moment(selectedExperiencia.inicioFecha).toDate()
          : undefined
      );
      setFinFecha(
        selectedExperiencia.finFecha
          ? moment(selectedExperiencia.finFecha).toDate()
          : undefined
      );
      setSalario(selectedExperiencia.salario);
      setCondicionesActuales(selectedExperiencia.condicionesActuales);

      const cargoEncontrado = cargosOptions?.find(
        (e) => e.value == selectedExperiencia.cargoIdNumerico
      );

      setCargoOption(cargoEncontrado);

      if (selectedExperiencia?.cargoOtro) {
        setCargoOtro(selectedExperiencia.cargoOtro);
        setOtroCargoInputShown(true);
      } else {
        setCargoOtro('');
        setOtroCargoInputShown(false);
      }

      setRubroOption(
        rubrosOptions?.find(
          (e) => e.value == selectedExperiencia.rubroIdNumerico
        )
      );
      setTipoEmpleoOption(
        empleoTipoOptionsForCandExp?.find(
          (e) => e.value == selectedExperiencia.tipoEmpleo
        )
      );

      setSalidaMotivoOption(
        salidaMotivoOptions?.find(
          (e) => e.value == selectedExperiencia.salidaMotivoIdNumerico
        )
      );

      showModalEditarExp();
    },
    [
      selectedExperiencia,
      cargosOptions,
      rubrosOptions,
      salidaMotivoOptions,
      empresasOptions,
    ]
  );

  const prepareCandFormEditAndShowModal = useCallback(
    (selectedFormacion) => {
      log('prepareCandFormEditAndShowModal');

      const institutoEncontrado = institutosOptions?.find(
        (e) => e.value == selectedFormacion.institutoIdNumerico
      );

      setInstitutoOption(institutoEncontrado);

      if (selectedFormacion?.institutoOtro) {
        setInstitutoOtro(selectedFormacion.institutoOtro);
        setOtroInstitutoInputShown(true);
      } else {
        setInstitutoOtro('');
        setOtroInstitutoInputShown(false);
      }

      setNivelFormacionOption(
        nivelFormacionOptions?.find(
          (e) => e.value == selectedFormacion.nivelFormacion
        )
      );

      setTitulacionOption(
        titulacionesOptions?.find(
          (e) => e.value == selectedFormacion.titulacionIdNumerico
        )
      );

      if (selectedFormacion?.titulacionOtro) {
        setTitulacionOtro(selectedFormacion.titulacionOtro);
        setOtraTitulacionInputShown(true);
      } else {
        setTitulacionOtro('');
        setOtraTitulacionInputShown(false);
      }

      setCandFormacInicioFecha(
        selectedFormacion.inicioFecha
          ? moment(selectedFormacion.inicioFecha).toDate()
          : undefined
      );
      setCandFormacFinFecha(
        selectedFormacion.finFecha
          ? moment(selectedFormacion.finFecha).toDate()
          : undefined
      );
      setCandFormacInicioFecha(
        selectedFormacion.inicioFecha
          ? moment(selectedFormacion.inicioFecha).toDate()
          : undefined
      );
      setCandFormacFinFecha(
        selectedFormacion.finFecha
          ? moment(selectedFormacion.finFecha).toDate()
          : undefined
      );

      setCandFormacDescripcion(selectedFormacion.descripcion);

      setIsModalEditarCandFormShown(true);
    },
    [selectedFormacion, institutosOptions, titulacionesOptions]
  );

  function prepareCandIdiomaFormEditAndShowModal(selectedCandIdioma) {
    setIdiomaOption({
      value: selectedCandIdioma.idiomaIdNumerico,
      label:
        idiomaById[selectedCandIdioma.idiomaIdNumerico]?.descripcion ?? 'N/A',
    });
    setLeeOption(
      nivelIdiomaOptionsById?.[selectedCandIdioma.lee] ??
        nivelIdiomaOptionsById.desconocido
    );
    setEscribeOption(
      nivelIdiomaOptionsById?.[selectedCandIdioma.escribe] ??
        nivelIdiomaOptionsById.desconocido
    );
    setHablaOption(
      nivelIdiomaOptionsById?.[selectedCandIdioma.habla] ??
        nivelIdiomaOptionsById.desconocido
    );
    setIsModalEditarCandIdiomaShown(true);
  }

  const prepareCargoPotEditAndShowModal = useCallback(
    (selectedExperiencia) => {
      setCargoOption(
        cargosOptions?.find(
          (e) => e.value == selectedExperiencia.cargoIdNumerico
        )
      );
      showModalEditarCargoPot();
    },
    [cargosOptions]
  );

  function agregarCandFormac() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      institutoIdNumerico: institutoOption?.value,
      institutoDescripcion: institutoOption?.label,
      institutoOtro: otroInstitutoInputShown ? institutoOtro : null,
      nivelFormacion: nivelFormacionOption?.value,
      titulacionIdNumerico: titulacionOption?.value,
      titulacionDescripcion: titulacionOption?.label,
      titulacionOtro: otraTitulacionInputShown ? titulacionOtro : null,
      inicioFecha: candFormacInicioFecha,
      finFecha: candFormacFinFecha,
      descripcion: candFormacDescripcion,
    };
    log({ 'agregarCandFormac.data': data });
    dispatch(crearCandidatoFormacionThunk(data));
  }

  function editarCandForm() {
    const data = {
      userToken: token.raw,
      idNumerico: selectedFormacion.idNumerico,
      candidatoIdNumerico,
      institutoIdNumerico: institutoOption?.value,
      institutoDescripcion: institutoOption?.label,
      institutoOtro: otroInstitutoInputShown ? institutoOtro : null,
      nivelFormacion: nivelFormacionOption?.value,
      titulacionIdNumerico: titulacionOption?.value,
      titulacionDescripcion: titulacionOption?.label,
      titulacionOtro: otraTitulacionInputShown ? titulacionOtro : null,
      inicioFecha: candFormacInicioFecha,
      finFecha: candFormacFinFecha,
      descripcion: candFormacDescripcion,
    };
    log({ 'editarCandForm.data': data });
    dispatch(editarCandFormThunk(data));
  }

  function editarCandIdioma() {
    const data = {
      userToken: token.raw,
      idNumerico: selectedCandIdioma.idNumerico,
      candidatoIdNumerico,
      idiomaIdNumerico: idiomaOption?.value,
      idiomaDescripcion: idiomaOption?.label,
      lee: leeOption?.value,
      escribe: escribeOption?.value,
      habla: hablaOption?.value,
    };
    log({ 'editarCandIdioma.data': data });
    dispatch(editarCandIdiomaThunk(data));
  }

  function agregarCandIdioma() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      idiomaIdNumerico: idiomaOption?.value,
      idiomaDescripcion: idiomaOption?.label,
      lee: leeOption?.value,
      escribe: escribeOption?.value,
      habla: hablaOption?.value,
    };
    log({ 'crearCandIdioma.data': data });
    dispatch(crearCandIdiomaThunk(data));
  }

  function eliminarCandIdioma() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoIdiomaIdNumerico: selectedCandIdioma.idNumerico,
    };
    log({ 'eliminarCandIdioma.data': data });
    dispatch(eliminarCandIdiomaThunk(data));
  }

  function eliminarArchivo() {
    log('eliminarArchivo.start');
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      archivoId: selectedArchivo._id,
    };
    log({ 'eliminarArchivo.data': data });
    dispatch(eliminarCandArchivoThunk(data));
  }

  function eliminarCandForm() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoFormacionIdNumerico: selectedFormacion.idNumerico,
    };
    log({ 'eliminarCandForm.data': data });
    dispatch(eliminarCandFormThunk(data));
  }

  function submitNota() {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    if (cargosPotenciales.length < 3) {
      setTopAlert({
        text: 'El candidato debe tener al menos 3 cargos potenciales para crear la nota',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    if (!titulo || !contenido) {
      setTopAlert({
        text: 'La nota debe incluir titulo y contenido',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    if (!notaSelected) {
      dispatch(
        crearNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    } else {
      dispatch(
        updateNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          idNumerico: notaSelected.idNumerico,
          creacionUsuario: notaSelected.creacionUsuario,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    }
  }

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearNota);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(crearNotaDuck.actions.clear());
    }

    if (data) {
      resetNotaInputs();
      dispatch(
        getCandidatoThunk({
          userToken: token.raw,
          idNumerico: candidatoIdNumerico,
        })
      );
      handlePageNotas(0);
      setTopAlert({
        text: 'Nota creada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearNotaDuck.actions.clear());
    }
  }, [modelCrearNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteNota);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(deleteNotaDuck.actions.clear());
    }
    if (data) {
      handlePageNotas(0);
      setTopAlert({
        text: 'Nota eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(deleteNotaDuck.actions.clear());
    }
  }, [modelDeleteNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUpdateNota);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(updateNotaDuck.actions.clear());
    }
    if (data) {
      resetNotaInputs();
      dispatch(
        getCandidatoThunk({
          userToken: token.raw,
          idNumerico: candidatoIdNumerico,
        })
      );
      handlePageNotas(0);
      setTopAlert({
        text: 'Nota actualizada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
  }, [modelUpdateNota]);

  function handlePageNotas(page) {
    setActivePageNotas(page);

    setNotas([]);

    const params = {
      page: page + 1,
      limit: pageSizeNotas,
      candidatoIdNumerico,
    };

    dispatch(
      getNotasThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  const hideModalNotas = () => {
    setNotaSelected(null);
    setIsModalNotasShown(false);
  };

  const showModalNotas = () => {
    setIsModalNotasShown(true);
  };

  function deleteNota(idNumerico, creacionUsuario) {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    dispatch(
      deleteNotaThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        idNumerico: idNumerico,
        creacionUsuario: creacionUsuario,
      })
    );
  }

  const resetNotaInputs = () => {
    setTitulo('');
    setContenido('');
    setCuentaFiltroChecked(true);
    setEntrevistaCortesiaChecked(false);
  };

  function setearNotaFavorita({ notaIdNumerico, candidatoIdNumerico, setear }) {
    dispatch(
      notaFavoritaSetThunk({
        userToken: token.raw,
        notaIdNumerico,
        candidatoIdNumerico,
        setear,
      })
    );
  }

  return (
    <EditarCandidatoView
      token={token}
      history={history}
      match={match}
      modelEditarCandidato={modelEditarCandidato}
      modelGetCandidato={modelGetCandidato}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      nombre={nombre}
      setNombre={setNombre}
      apellido={apellido}
      setApellido={setApellido}
      documento={documento}
      setDocumento={setDocumento}
      expectativaSalarial={expectativaSalarial}
      setExpectativaSalarial={setExpectativaSalarial}
      telefono1={telefono1}
      setTelefono1={setTelefono1}
      telefono2={telefono2}
      setTelefono2={setTelefono2}
      telefono3={telefono3}
      setTelefono3={setTelefono3}
      direccion={direccion}
      setDireccion={setDireccion}
      correo={correo}
      setCorreo={setCorreo}
      nacimientoFecha={nacimientoFecha}
      setNacimientoFecha={setNacimientoFecha}
      sexoOption={sexoOption}
      sexoOptions={sexoOptions}
      setSexoOption={setSexoOption}
      estadoCivilOption={estadoCivilOption}
      estadoCivilOptions={estadoCivilOptions}
      setEstadoCivilOption={setEstadoCivilOption}
      descripcionInterna={descripcionInterna}
      setDescripcionInterna={setDescripcionInterna}
      nacionalidadOption={nacionalidadOption}
      nacionalidadesOptions={nacionalidadesOptions}
      setNacionalidadOption={setNacionalidadOption}
      paisOption={paisOption}
      paisesOptions={paisesOptions}
      setPaisOption={setPaisOption}
      ciudadOption={ciudadOption}
      ciudadesOptions={ciudadesOptions}
      setCiudadOption={setCiudadOption}
      ciudadLibre={ciudadLibre}
      setCiudadLibre={setCiudadLibre}
      experiencias={experiencias}
      crearExperiencia={crearExperiencia}
      isModalAgregarExpShown={isModalAgregarExpShown}
      hideModalAgregarExp={hideModalAgregarExp}
      showModalAgregarExp={showModalAgregarExp}
      agregarExp={agregarExp}
      isAgregarExpButtonDisabled={false}
      isAgregarExpButtonLoading={modelCrearCandidatoExp.loading}
      empresaOption={empresaOption}
      empresasOptions={empresasOptions}
      setEmpresaOption={setEmpresaOption}
      cargoOption={cargoOption}
      cargosOptions={cargosOptions}
      setCargoOption={setCargoOption}
      rubroOption={rubroOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      observacion={observacion}
      setObservacion={setObservacion}
      tipoEmpleoOption={tipoEmpleoOption}
      setTipoEmpleoOption={setTipoEmpleoOption}
      salario={salario}
      setSalario={setSalario}
      condicionesActuales={condicionesActuales}
      setCondicionesActuales={setCondicionesActuales}
      vigente={vigente}
      setVigente={setVigente}
      inicioFecha={inicioFecha}
      setInicioFecha={setInicioFecha}
      finFecha={finFecha}
      setFinFecha={setFinFecha}
      salidaMotivoOption={salidaMotivoOption}
      salidaMotivoOptions={salidaMotivoOptions}
      setSalidaMotivoOption={setSalidaMotivoOption}
      experienciaSubmitAlert={experienciaSubmitAlert}
      eliminarCandidatoExp={eliminarCandidatoExp}
      modelGetCandidatoExperiencias={modelGetCandidatoExperiencias}
      isModalEliminarCandExpShown={isModalEliminarCandExpShown}
      hideModalEliminarCandExp={hideModalEliminarCandExp}
      showModalEliminarCandExp={showModalEliminarCandExp}
      modelEliminarCandidatoExp={modelEliminarCandidatoExp}
      setSelectedExperiencia={setSelectedExperiencia}
      isModalEditarExpShown={isModalEditarExpShown}
      hideModalEditarExp={hideModalEditarExp}
      showModalEditarExp={showModalEditarExp}
      isEditarExpButtonDisabled={false}
      isEditarExpButtonLoading={modelEditarCandidatoExp.loading}
      editarExperiencia={editarExperiencia}
      prepareExpEditAndShowModal={prepareExpEditAndShowModal}
      isModalAgregarCargoPotShown={isModalAgregarCargoPotShown}
      hideModalAgregarCargoPot={hideModalAgregarCargoPot}
      showModalAgregarCargoPot={showModalAgregarCargoPot}
      agregarCargoPot={agregarCargoPot}
      isAgregarCargoPotButtonDisabled={false}
      isAgregarCargoPotButtonLoading={modelCrearCargoPot.loading}
      isModalEditarCargoPotShown={isModalEditarCargoPotShown}
      hideModalEditarCargoPot={hideModalEditarCargoPot}
      showModalEditarCargoPot={showModalEditarCargoPot}
      editarCargoPot={editarCargoPot}
      isEditarCargoPotButtonDisabled={false}
      isEditarCargoPotButtonLoading={modelEditarCargoPot.loading}
      prepareCargoPotEditAndShowModal={prepareCargoPotEditAndShowModal}
      cargoPotSubmitAlert={cargoPotSubmitAlert}
      cargosPotenciales={cargosPotenciales}
      setSelectedCargoPot={setSelectedCargoPot}
      showModalEliminarCargoPot={showModalEliminarCargoPot}
      isModalEliminarCargoPotShown={isModalEliminarCargoPotShown}
      hideModalEliminarCargoPot={hideModalEliminarCargoPot}
      eliminarCargoPot={eliminarCargoPot}
      modelEliminarCargoPot={modelEliminarCargoPot}
      modelGetCargosPot={modelGetCargosPot}
      formacionSubmitAlert={formacionSubmitAlert}
      modelGetCandidatoFormaciones={modelGetCandidatoFormaciones}
      formaciones={formaciones}
      isModalAgregarFormacionShown={isModalAgregarFormacionShown}
      setIsModalAgregarFormacionShown={setIsModalAgregarFormacionShown}
      agregarCandFormac={agregarCandFormac}
      modelCrearCandidatoFormacion={modelCrearCandidatoFormacion}
      institutoOption={institutoOption}
      institutosOptions={institutosOptions}
      setInstitutoOption={setInstitutoOption}
      titulacionOption={titulacionOption}
      titulacionesOptions={titulacionesOptions}
      setTitulacionOption={setTitulacionOption}
      nivelFormacionOption={nivelFormacionOption}
      setNivelFormacionOption={setNivelFormacionOption}
      candFormacInicioFecha={candFormacInicioFecha}
      setCandFormacInicioFecha={setCandFormacInicioFecha}
      candFormacFinFecha={candFormacFinFecha}
      setCandFormacFinFecha={setCandFormacFinFecha}
      candFormacDescripcion={candFormacDescripcion}
      setCandFormacDescripcion={setCandFormacDescripcion}
      isModalEliminarCandFormShown={isModalEliminarCandFormShown}
      modelEliminarCandForm={modelEliminarCandForm}
      setIsModalEliminarCandFormShown={setIsModalEliminarCandFormShown}
      eliminarCandForm={eliminarCandForm}
      setSelectedFormacion={setSelectedFormacion}
      prepareCandFormEditAndShowModal={prepareCandFormEditAndShowModal}
      editarCandForm={editarCandForm}
      isModalEditarCandFormShown={isModalEditarCandFormShown}
      setIsModalEditarCandFormShown={setIsModalEditarCandFormShown}
      modelEditarCandForm={modelEditarCandForm}
      candidatoIdNumerico={candidatoIdNumerico}
      isModalGenericoShown={isModalGenericoShown}
      setIsModalGenericoShown={setIsModalGenericoShown}
      isModalNotasShown={isModalNotasShown}
      hideModalNotas={hideModalNotas}
      showModalNotas={showModalNotas}
      notas={notas}
      activePageNotas={activePageNotas}
      pageSizeNotas={pageSizeNotas}
      totalResultsNotas={totalResultsNotas}
      handlePageNotas={handlePageNotas}
      submitNota={submitNota}
      contenido={contenido}
      setContenido={setContenido}
      titulo={titulo}
      setTitulo={setTitulo}
      notaSelected={notaSelected}
      setNotaSelected={setNotaSelected}
      userIdNumerico={token.decoded.idNumerico}
      deleteNota={deleteNota}
      cuentaFiltroChecked={cuentaFiltroChecked}
      setCuentaFiltroChecked={setCuentaFiltroChecked}
      entrevistaCortesiaChecked={entrevistaCortesiaChecked}
      setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
      resetNotaInputs={resetNotaInputs}
      ultimaNota={notas?.[0]}
      isModalAgregarCandIdiomaShown={isModalAgregarCandIdiomaShown}
      setIsModalAgregarCandIdiomaShown={setIsModalAgregarCandIdiomaShown}
      idiomaSubmitAlert={idiomaSubmitAlert}
      modelGetCandIdiomas={modelGetCandIdiomas}
      candIdiomas={candIdiomas}
      setSelectedCandIdioma={setSelectedCandIdioma}
      setIsModalEliminarCandIdiomaShown={setIsModalEliminarCandIdiomaShown}
      prepareCandIdiomaFormEditAndShowModal={
        prepareCandIdiomaFormEditAndShowModal
      }
      editarCandIdioma={editarCandIdioma}
      leeOption={leeOption}
      setLeeOption={setLeeOption}
      hablaOption={hablaOption}
      setHablaOption={setHablaOption}
      escribeOption={escribeOption}
      setEscribeOption={setEscribeOption}
      agregarCandIdioma={agregarCandIdioma}
      modelCrearCandIdioma={modelCrearCandIdioma}
      idiomaOption={idiomaOption}
      idiomasOptions={idiomasOptions}
      setIdiomaOption={setIdiomaOption}
      isModalEditarCandIdiomaShown={isModalEditarCandIdiomaShown}
      setIsModalEditarCandIdiomaShown={setIsModalEditarCandIdiomaShown}
      modelEditarCandIdioma={modelEditarCandIdioma}
      isModalEliminarCandIdiomaShown={isModalEliminarCandIdiomaShown}
      idiomaById={idiomaById}
      eliminarCandIdioma={eliminarCandIdioma}
      archivoSubmitAlert={archivoSubmitAlert}
      modelGetCandArchivos={modelGetCandArchivos}
      candArchivos={candArchivos}
      isModalEliminarCandArchivoShown={isModalEliminarCandArchivoShown}
      selectedArchivo={selectedArchivo}
      setSelectedArchivo={setSelectedArchivo}
      setIsModalEliminarCandArchivoShown={setIsModalEliminarCandArchivoShown}
      eliminarArchivo={eliminarArchivo}
      modalRedirect={modalRedirect}
      setModalRedirect={setModalRedirect}
      modelEliminarCandArchivo={modelEliminarCandArchivo}
      rolCandidato={rol}
      cargoOtro={cargoOtro}
      setCargoOtro={setCargoOtro}
      empresaOtro={empresaOtro}
      setEmpresaOtro={setEmpresaOtro}
      otroCargoInputShown={otroCargoInputShown}
      setOtroCargoInputShown={setOtroCargoInputShown}
      otraEmpresaInputShown={otraEmpresaInputShown}
      setOtraEmpresaInputShown={setOtraEmpresaInputShown}
      otroInstitutoInputShown={otroInstitutoInputShown}
      setOtroInstitutoInputShown={setOtroInstitutoInputShown}
      institutoOtro={institutoOtro}
      setInstitutoOtro={setInstitutoOtro}
      otraTitulacionInputShown={otraTitulacionInputShown}
      setOtraTitulacionInputShown={setOtraTitulacionInputShown}
      titulacionOtro={titulacionOtro}
      setTitulacionOtro={setTitulacionOtro}
      modelCrearCargoPot={modelCrearCargoPot}
      notaFavoritaSet={notaFavoritaSet}
      setearNotaFavorita={setearNotaFavorita}
      yaIncluidoEnEmGeneral={yaIncluidoEnEmGeneral}
      token={token}
      isModalIncluirEmShown={isModalIncluirEmShown}
      setIsModalIncluirEmShown={setIsModalIncluirEmShown}
    />
  );
}

export { EditarCandidato };
