import createDuck, { createThunk } from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const estudioMercadoCreateDuck = createDuck('ESTUDIO_MERCADO_CREATE');

const estudioMercadoCreateThunk = (params) => {
  const url = `${baseUrl}/v1/estudio`;

  return createThunk(
    axios,
    'post',
    estudioMercadoCreateDuck.actions,
    url,
    params
  );
};

const emCandidatosGetDuck = createDuck('EM_CANDIDATOS_GET');

const emCandidatosGetThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/candidatos`;

  return createThunk(
    axios,
    'get',
    emCandidatosGetDuck.actions,
    url,
    null,
    params
  );
};

const emCandidatoEditDuck = createDuck('EM_CANDIDATO_EDIT');

const emCandidatoEditThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/candidato`;

  return createThunk(axios, 'patch', emCandidatoEditDuck.actions, url, params);
};

const emCandidatoDeleteDuck = createDuck('EM_CANDIDATO_DELETE');

const emCandidatoDeleteThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/candidato`;

  return createThunk(
    axios,
    'delete',
    emCandidatoDeleteDuck.actions,
    url,
    params
  );
};

const notaFavoritaGetDuck = createDuck('NOTA_FAVORITA_GET');
const notaFavoritaGetThunk = (params) => {
  const url = `${baseUrl}/v1/nota/favorita`;

  return createThunk(
    axios,
    'get',
    notaFavoritaGetDuck.actions,
    url,
    null,
    params
  );
};

const emCandidatoIncluirDuck = createDuck('EM_CANDIDATO_INCLUIR');
const emCandidatoIncluirThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/candidato`;

  return createThunk(
    axios,
    'post',
    emCandidatoIncluirDuck.actions,
    url,
    params
  );
};

const emForInclusionGetDuck = createDuck('EM_FOR_INCLUSION_GET');
const emForInclusionGetThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/lista/inclusion`;

  return createThunk(
    axios,
    'get',
    emForInclusionGetDuck.actions,
    url,
    null,
    params
  );
};

const emCandidatosConteoGetDuck = createDuck('EM_CANDIDATOS_CONTEO_GET');
const emCandidatosConteoGetThunk = (params) => {
  const url = `${baseUrl}/v1/estudio/candidatos/conteo`;

  return createThunk(
    axios,
    'get',
    emCandidatosConteoGetDuck.actions,
    url,
    null,
    params
  );
};

const estudiosMercadoGeneralesGetDuck = createDuck(
  'ESTUDIOS_MERCADO_GENERALES_GET'
);

const estudiosMercadoGeneralesGetThunk = (params) => {
  const url = `${baseUrl}/v1/estudios`;

  return createThunk(
    axios,
    'get',
    estudiosMercadoGeneralesGetDuck.actions,
    url,
    null,
    { ...params, tipo: 'general' }
  );
};

const estudiosMercadoEspecificosGetDuck = createDuck(
  'ESTUDIOS_MERCADO_ESPECIFICOS_GET'
);

const estudiosMercadoEspecificosGetThunk = (params) => {
  const url = `${baseUrl}/v1/estudios`;

  return createThunk(
    axios,
    'get',
    estudiosMercadoEspecificosGetDuck.actions,
    url,
    null,
    { ...params, tipo: 'especifico' }
  );
};
const estudioMercadoGeneralDeleteDuck = createDuck(
  'ESTUDIO_MERCADO_GENERAL_DELETE'
);

const estudioMercadoGeneralDeleteThunk = (params) => {
  const url = `${baseUrl}/v1/estudio`;

  return createThunk(
    axios,
    'delete',
    estudioMercadoGeneralDeleteDuck.actions,
    url,
    params
  );
};

const estudioMercadoEspecificoDeleteDuck = createDuck(
  'ESTUDIO_MERCADO_ESPECIFICO_DELETE'
);

const estudioMercadoEspecificoDeleteThunk = (params) => {
  const url = `${baseUrl}/v1/estudio`;

  return createThunk(
    axios,
    'delete',
    estudioMercadoEspecificoDeleteDuck.actions,
    url,
    params
  );
};

const estudioMercadoEditDuck = createDuck('ESTUDIO_MERCADO_EDIT');

const estudioMercadoEditThunk = (params) => {
  const url = `${baseUrl}/v1/estudio`;

  return createThunk(
    axios,
    'patch',
    estudioMercadoEditDuck.actions,
    url,
    params
  );
};

export {
  estudioMercadoCreateDuck,
  estudioMercadoCreateThunk,
  emCandidatosGetDuck,
  emCandidatosGetThunk,
  emCandidatoEditDuck,
  emCandidatoEditThunk,
  emCandidatoDeleteDuck,
  emCandidatoDeleteThunk,
  notaFavoritaGetDuck,
  notaFavoritaGetThunk,
  emCandidatoIncluirDuck,
  emCandidatoIncluirThunk,
  emForInclusionGetDuck,
  emForInclusionGetThunk,
  emCandidatosConteoGetDuck,
  emCandidatosConteoGetThunk,
  estudiosMercadoGeneralesGetDuck,
  estudiosMercadoGeneralesGetThunk,
  estudiosMercadoEspecificosGetDuck,
  estudiosMercadoEspecificosGetThunk,
  estudioMercadoGeneralDeleteDuck,
  estudioMercadoGeneralDeleteThunk,
  estudioMercadoEspecificoDeleteDuck,
  estudioMercadoEspecificoDeleteThunk,
  estudioMercadoEditDuck,
  estudioMercadoEditThunk,
};
