import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { TextArea } from '../utils/TextArea';
import { DateInput } from '../utils/DateInput';
import { TextInput } from '../utils/TextInput';
import { CheckBox } from '../ui/Checkbox';

function ModalAgregarCandFormac({
  origen,
  title,
  submitText,
  isModalAgregarFormacionShown,
  setIsModalAgregarFormacionShown,
  submit,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  institutoOption,
  institutosOptions,
  setInstitutoOption,
  titulacionOption,
  titulacionesOptions,
  setTitulacionOption,
  nivelFormacionOption,
  nivelFormacionesOptions,
  setNivelFormacionOption,
  candFormacInicioFecha,
  setCandFormacInicioFecha,
  candFormacFinFecha,
  setCandFormacFinFecha,
  candFormacDescripcion,
  setCandFormacDescripcion,
  otroInstitutoInputShown,
  setOtroInstitutoInputShown,
  institutoOtro,
  setInstitutoOtro,
  otraTitulacionInputShown,
  setOtraTitulacionInputShown,
  titulacionOtro,
  setTitulacionOtro,
}) {
  return (
    <Modal
      show={isModalAgregarFormacionShown}
      onHide={() => setIsModalAgregarFormacionShown(false)}
      onShow={() => setIsModalAgregarFormacionShown(true)}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <DateInput
            label="Fecha de inicio"
            selected={candFormacInicioFecha}
            onChange={setCandFormacInicioFecha}
            showYearDropdown
          />
          <DateInput
            label="Fecha de finalización o previsto"
            selected={candFormacFinFecha}
            onChange={setCandFormacFinFecha}
            showYearDropdown
          />

          <hr />

          {otroInstitutoInputShown ? (
            <TextInput
              label="Instituto"
              placeholder="Escriba aquí el instituto"
              initialValue={institutoOtro}
              setValue={setInstitutoOtro}
            />
          ) : (
            <SelectInput
              label="Instituto"
              value={institutoOption}
              options={institutosOptions}
              setValue={setInstitutoOption}
            />
          )}

          {origen === 'portal' && (
            <CheckBox
              checked={otroInstitutoInputShown}
              text="Otro instituto"
              onChange={setOtroInstitutoInputShown}
            />
          )}

          <hr />

          {otraTitulacionInputShown ? (
            <TextInput
              label="Titulación"
              placeholder="Escriba aquí la titulación"
              initialValue={titulacionOtro}
              setValue={setTitulacionOtro}
            />
          ) : (
            <SelectInput
              label="Titulación"
              value={titulacionOption}
              options={titulacionesOptions}
              setValue={setTitulacionOption}
            />
          )}

          {origen === 'portal' && (
            <CheckBox
              checked={otraTitulacionInputShown}
              text="Otra titulación"
              onChange={setOtraTitulacionInputShown}
            />
          )}

          <hr />

          <SelectInput
            label="Nivel de formación"
            value={nivelFormacionOption}
            options={nivelFormacionesOptions}
            setValue={setNivelFormacionOption}
            required
          />
          <TextArea
            label="Descripción"
            value={candFormacDescripcion}
            setValue={setCandFormacDescripcion}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text={submitText}
          submit={submit}
          disabled={isSubmitButtonDisabled}
          loading={isSubmitButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalAgregarCandFormac };
