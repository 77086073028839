import React from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

function Loader({
  style,
  loaderSize,
  loaderColor = "#3d4a51",
  children,
  isLoading,
}) {
  return isLoading ? (
    <div
      style={
        style
          ? style
          : {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
 
            }
      }
    >
      <Dots size={loaderSize} color={loaderColor} />
    </div>
  ) : (
    children
  );
}

export default Loader;
