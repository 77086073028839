import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { TextArea } from '../utils/TextArea';
import { MonedaInputV2 } from '../ui/moneda';
import { CheckBox } from '../ui/Checkbox';
import { DateInput } from '../utils/DateInput';
import { TextInput } from '../utils/TextInput';
import { responseSelector, toTitleCase } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, alertTypes, useAlerts } from '../ui/alert';
import { getEmpresasDuck, getEmpresasThunk } from '../oportunidades/duck';
import { Autocomplete } from '../ui/Autocomplete';
import {
  getEmpresasWithPaginationDuck,
  getEmpresasWithPaginationThunk,
} from '../empresas/duck';
import { EmpresaAutocomplete } from '../ui/EmpresaAutocomplete';

function ModalAgregarExperiencia({
  token,
  origen,
  title,
  submitText,
  isModalAgregarExpShown,
  hideModalAgregarExp,
  showModalAgregarExp,
  agregarExp,
  isAgregarExpButtonDisabled,
  isAgregarExpButtonLoading,
  empresaOption,
  empresasOptions,
  setEmpresaOption,
  cargoOption,
  cargosOptions,
  setCargoOption,
  rubroOption,
  rubrosOptions,
  setRubroOption,
  observacion,
  setObservacion,
  tipoEmpleoOption,
  tipoEmpleoOptions,
  setTipoEmpleoOption,
  salario,
  setSalario,
  condicionesActuales,
  setCondicionesActuales,
  vigente,
  setVigente,
  inicioFecha,
  setInicioFecha,
  finFecha,
  setFinFecha,
  salidaMotivoOption,
  salidaMotivoOptions,
  setSalidaMotivoOption,
  cargoOtro,
  setCargoOtro,
  empresaOtro,
  setEmpresaOtro,
  otroCargoInputShown,
  setOtroCargoInputShown,
  otraEmpresaInputShown,
  setOtraEmpresaInputShown,
}) {
  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  console.log('empresaOption', empresaOption);

  return (
    <Modal
      show={isModalAgregarExpShown}
      onHide={hideModalAgregarExp}
      onShow={showModalAgregarExp}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {topAlert.text && (
            <Alert
              title={topAlert.title}
              type={topAlert.type}
              text={topAlert.text}
              closable={topAlert.closable}
            />
          )}

          {otraEmpresaInputShown ? (
            <TextInput
              label="Empresa"
              placeholder="Escriba aquí la empresa"
              initialValue={empresaOtro}
              setValue={setEmpresaOtro}
            />
          ) : (
            <EmpresaAutocomplete
              label="Empresa"
              token={token}
              initialSelectedEmpresaLabel={
                !empresaOption || empresaOption?.value == null
                  ? 'Sin empresa seleccionada'
                  : toTitleCase(empresaOption?.label)
              }
              onSelectEmpresa={(empresa) => {
                // TODO: simplemente seleccionar el _id.
                // Ya no hace falta que sea un option (label y value).
                setEmpresaOption({
                  value: empresa.idNumerico,
                  label: empresa.nombre,
                });
              }}
            />
          )}

          {origen === 'portal' && (
            <CheckBox
              checked={otraEmpresaInputShown}
              text="Otra empresa"
              onChange={setOtraEmpresaInputShown}
            />
          )}

          <hr />

          {otroCargoInputShown ? (
            <TextInput
              label="Cargo"
              placeholder="Escriba aquí el cargo"
              initialValue={cargoOtro}
              setValue={setCargoOtro}
              required
            />
          ) : (
            <SelectInput
              label="Cargo"
              value={cargoOption}
              options={cargosOptions}
              setValue={setCargoOption}
              required
            />
          )}

          {origen === 'portal' && (
            <CheckBox
              checked={otroCargoInputShown}
              text="Otro cargo"
              onChange={setOtroCargoInputShown}
            />
          )}

          <hr />

          <SelectInput
            label="Rubro"
            value={rubroOption}
            options={rubrosOptions}
            setValue={setRubroOption}
          />

          <TextArea
            label="Descripción del cargo"
            value={observacion}
            setValue={setObservacion}
          />

          <SelectInput
            label="Tipo de empleo"
            value={tipoEmpleoOption}
            options={tipoEmpleoOptions}
            setValue={setTipoEmpleoOption}
            required
          />

          <MonedaInputV2
            label="Salario percibido"
            initialValue={salario}
            setValue={setSalario}
            selectorMonedaDisabled={true}
          />

          <TextArea
            label="Condiciones actuales en la empresa"
            value={condicionesActuales}
            setValue={setCondicionesActuales}
          />

          <CheckBox
            checked={vigente}
            text="¿Actualmente aquí?"
            onChange={setVigente}
          />

          <DateInput
            label="Fecha de inicio"
            selected={inicioFecha}
            onChange={setInicioFecha}
          />

          {!vigente && (
            <>
              <DateInput
                label="Fecha de fin"
                selected={finFecha}
                onChange={setFinFecha}
              />
              <SelectInput
                label="Motivo de salida"
                value={salidaMotivoOption}
                options={salidaMotivoOptions}
                setValue={setSalidaMotivoOption}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text={submitText}
          submit={agregarExp}
          disabled={isAgregarExpButtonDisabled}
          loading={isAgregarExpButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalAgregarExperiencia };
