import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getGeografiaDuck,
  getGeografiaThunk,
  createDatoGeograficoDuck,
  createDatoGeograficoThunk,
  editDatoGeograficoDuck,
  editDatoGeograficoThunk,
  deleteDatoGeograficoDuck,
  deleteDatoGeograficoThunk,
} from './duck';

import { getRegionesDuck, getRegionesThunk } from '../candidatos/duck';

import {
  constants,
  responseSelector,
  prepareRegionesOptions,
} from '../utils/utils';

import { ListaDatosGeografiaView } from './ListaDatosGeografiaView';
import { datosGeografiaOptions } from '../utils/constants';

const queryString = require('query-string');

function ListaDatosGeografia({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetDatosAuxiliares = useSelector((state) => state.getGeografia);
  const modelCreateDatoAuxiliar = useSelector(
    (state) => state.createDatoGeografico
  );
  const modelEditDatoAuxiliar = useSelector(
    (state) => state.editDatoGeografico
  );
  const modelDeleteDatoGeografico = useSelector(
    (state) => state.deleteDatoGeografico
  );

  const modelGetRegiones = useSelector((state) => state.getRegiones);

  const [concepto, setConcepto] = useState({ value: 'pais', label: 'Pais' });
  const [lista, setLista] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isModalCreateDatoAuxiliarShown, setIsModalCreateDatoAuxiliarShown] =
    useState(false);
  const [valor, setValor] = useState('');
  const [padreId, setPadreId] = useState('');
  const [formConcepto, setFormConcepto] = useState();
  const [regionesOptions, setRegionesOptions] = useState([]);
  const [selectedDatoAuxiliar, setSelectedDatoAuxiliar] = useState();
  const [isModalEditDatoAuxiliarShown, setIsModalEditDatoAuxiliarShown] =
    useState(false);

  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );
  const [etiqueta, setEtiqueta] = useState(parsedQueryParams?.etiqueta || null);
  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] = useState();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRegiones);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRegionesDuck.actions.clear());
    }

    if (data) {

      const mRegOptions = prepareRegionesOptions(data.items);

      setRegionesOptions(mRegOptions);

      dispatch(getRegionesDuck.actions.clear());
    }
  }, [modelGetRegiones]);

  useEffect(() => {
    dispatch(getRegionesThunk({ userToken: token.raw, padreId: null }));
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetDatosAuxiliares);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getGeografiaDuck.actions.clear());
    }

    if (data) {
      setTotalResults(data.total);
      setLista(data.items);

      dispatch(getGeografiaDuck.actions.clear());
    }
  }, [modelGetDatosAuxiliares]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCreateDatoAuxiliar);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalCreateDatoAuxiliarShown(false);
      dispatch(createDatoGeograficoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar creado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      setIsModalCreateDatoAuxiliarShown(false);
      handlePage(0);
      dispatch(createDatoGeograficoDuck.actions.clear());
    }
  }, [modelCreateDatoAuxiliar]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditDatoAuxiliar);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalCreateDatoAuxiliarShown(false);
      dispatch(editDatoGeograficoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar editado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      handlePage(0);
      setIsModalEditDatoAuxiliarShown(false);
      dispatch(editDatoGeograficoDuck.actions.clear());
    }
  }, [modelEditDatoAuxiliar]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteDatoGeografico);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsDeleteConfirmModalShown(false);
      dispatch(deleteDatoGeograficoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar eliminado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      handlePage(0);
      setIsDeleteConfirmModalShown(false);
      dispatch(deleteDatoGeograficoDuck.actions.clear());
    }
  }, [modelDeleteDatoGeografico]);

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    setLista([]);

    const params = {
      page: page + 1,
      limit: pageSize,
      busquedaRapida,
      etiqueta: etiqueta?.value,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getGeografiaThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function createDatoAuxiliar() {
    if (
      !formConcepto?.value ||
      !valor ||
      (formConcepto?.value === 'ciudad' && !padreId?.value)
    ) {
      setIsModalCreateDatoAuxiliarShown(false);
      setTopAlert({
        text: 'Debe completar todos los campos',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    const data = {
      userToken: token.raw,
      etiqueta: formConcepto?.value,
      descripcion: valor,
      padreId:
        formConcepto?.value === 'region'
          ? 9
          : formConcepto?.value === 'ciudad'
          ? padreId?.value
          : null,
    };
    dispatch(createDatoGeograficoThunk(data));
  }

  function editDatoAuxiliar() {
    if (!valor) {
      setIsModalEditDatoAuxiliarShown(false);
      setTopAlert({
        text: 'Debe completar todos los campos',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    dispatch(
      editDatoGeograficoThunk({
        userToken: token.raw,
        idNumerico: selectedDatoAuxiliar.idNumerico,
        etiqueta: selectedDatoAuxiliar.etiqueta,
        descripcion: valor,
        padreId: selectedDatoAuxiliar.padreId,
      })
    );
  }

  function prepareEditDatoAuxiliar(datoAuxiliar) {
    setValor(datoAuxiliar.descripcion);
    setFormConcepto(
      datosGeografiaOptions.find((opt) => opt.value === datoAuxiliar.etiqueta)
    );
    setSelectedDatoAuxiliar(datoAuxiliar);
    setIsModalEditDatoAuxiliarShown(true);
  }

  function deleteDatoGeografico() {
    dispatch(
      deleteDatoGeograficoThunk({
        userToken: token.raw,
        idNumerico: selectedDatoAuxiliar.idNumerico,
        etiqueta: selectedDatoAuxiliar.etiqueta,
      })
    );
  }

  return (
    <ListaDatosGeografiaView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      lista={lista}
      modelGetDatosAuxiliares={modelGetDatosAuxiliares}
      isModalCreateDatoAuxiliarShown={isModalCreateDatoAuxiliarShown}
      setIsModalCreateDatoAuxiliarShown={setIsModalCreateDatoAuxiliarShown}
      modelCreateDatoAuxiliar={modelCreateDatoAuxiliar}
      createDatoAuxiliar={createDatoAuxiliar}
      valor={valor}
      setValor={setValor}
      formConcepto={formConcepto}
      setFormConcepto={setFormConcepto}
      selectedDatoAuxiliar={selectedDatoAuxiliar}
      setSelectedDatoAuxiliar={setSelectedDatoAuxiliar}
      isModalEditDatoAuxiliarShown={isModalEditDatoAuxiliarShown}
      setIsModalEditDatoAuxiliarShown={setIsModalEditDatoAuxiliarShown}
      modelEditDatoAuxiliar={modelEditDatoAuxiliar}
      editDatoAuxiliar={editDatoAuxiliar}
      prepareEditDatoAuxiliar={prepareEditDatoAuxiliar}
      etiqueta={etiqueta}
      setEtiqueta={setEtiqueta}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      concepto={concepto}
      setConcepto={setConcepto}
      padreId={padreId}
      setPadreId={setPadreId}
      regionesOptions={regionesOptions}
      isDeleteConfirmModalShown={isDeleteConfirmModalShown}
      setIsDeleteConfirmModalShown={setIsDeleteConfirmModalShown}
      modelDeleteDatoGeografico={modelDeleteDatoGeografico}
      deleteDatoGeografico={deleteDatoGeografico}
    />
  );
}

export { ListaDatosGeografia };
