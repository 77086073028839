import React from 'react';
import Etiqueta from './etiquetaObligatorio/Etiqueta';

function TextArea({ label, value, setValue, disabled = false, style }) {
  return (
    <div className="form-group">
      <label>
        <Etiqueta texto={label} obligatorio={false} />
      </label>
      <textarea
        className="form-control"
        rows="3"
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        disabled={disabled}
        style={style}
      />
      {/* <p className="help-block">{`Puedes utilizar las siguientes etiquetas: ${getTagList()}. Al enviar el mensaje, se reemplazarán por los valores correspondientes a tu cliente y la factura.`}</p> */}
    </div>
  );
}

export { TextArea };
