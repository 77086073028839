import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  getNumberOrNull,
  prepareDatosAuxOptions,
  prepareEmpresasOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  createEmpresaDuck,
  createEmpresaThunk,
  searchEmpresasByNameDuck,
  searchEmpresasByNameThunk,
} from './duck';
import { CrearEmpresaView } from './CrearEmpresaView';
import {
  getEmpresasDuck,
  getEmpresasThunk,
  getRubrosDuck,
  getRubrosThunk,
} from '../oportunidades/duck';

function CrearEmpresa({ history, match, token }) {
  const dispatch = useDispatch();

  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelCreateEmpresa = useSelector((state) => state.createEmpresa);
  const modelGetEmpresas = useSelector((state) => state.getEmpresas);
  const modelSearchEmpresasByName = useSelector(
    (state) => state.searchEmpresasByName
  );

  const [grupoEmpresarialOptions, setGrupoEmpresarialOptions] = useState([]);
  const [grupoEmpresarialOption, setGrupoEmpresarialOption] = useState();

  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [documento, setDocumento] = useState('');
  const [gerenteGral, setGerenteGral] = useState('');
  const [gerenteRrhh, setGerenteRrhh] = useState('');
  const [esCliente, setEsCliente] = useState(false);
  const [esGrupo, setEsGrupo] = useState(false);
  const [rubrosOptions, setRubrosOptions] = useState();
  const [rubroOption, setRubroOption] = useState();
  const [contactoNombre, setContactoNombre] = useState('');
  const [contactoTelefono, setContactoTelefono] = useState('');
  const [foundEmpresas, setFoundEmpresas] = useState([]);

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const timer = useRef(null);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (nombre && nombre.length > 2) {
      timer.current = setTimeout(() => {
        dispatch(
          searchEmpresasByNameThunk({
            name: nombre,
            userToken: token.raw,
          })
        );
      }, 2000); // 2 seconds delay
    }
  }, [nombre]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelSearchEmpresasByName);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(searchEmpresasByNameDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(searchEmpresasByNameDuck.actions.clear());
      setFoundEmpresas(data);
    }
  }, [modelSearchEmpresasByName]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(getEmpresasDuck.actions.clear());

      setGrupoEmpresarialOptions([
        { value: null, label: 'Sin grupo' },
        ...prepareEmpresasOptions(data.items),
      ]);
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCreateEmpresa);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(createEmpresaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(createEmpresaDuck.actions.clear());

      setSubmitAlert({
        text: 'Empresa creada correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelCreateEmpresa]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetRubros.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setRubrosOptions([{ value: null, label: 'Ninguno' }, ...datosAuxOptions]);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    dispatch(
      getEmpresasThunk({
        esGrupo: 'SI',
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  function submit() {
    let data = {
      userToken: token.raw,
      nombre,
      direccion,
      telefono,
      correo,
      razonSocial,
      documento,
      gerenteGral,
      gerenteRrhh,
      esCliente,
      esGrupo,
      rubroIdNumerico: rubroOption?.value,
      contactoNombre,
      contactoTelefono,
      empresaPadreId: grupoEmpresarialOption?.value,
    };

    log({ 'submit.data': data });

    dispatch(createEmpresaThunk(data));
  }

  return (
    <CrearEmpresaView
      history={history}
      match={match}
      modelCreateEmpresa={modelCreateEmpresa}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      nombre={nombre}
      setNombre={setNombre}
      direccion={direccion}
      setDireccion={setDireccion}
      telefono={telefono}
      setTelefono={setTelefono}
      correo={correo}
      setCorreo={setCorreo}
      razonSocial={razonSocial}
      setRazonSocial={setRazonSocial}
      documento={documento}
      setDocumento={setDocumento}
      gerenteGral={gerenteGral}
      setGerenteGral={setGerenteGral}
      gerenteRrhh={gerenteRrhh}
      setGerenteRrhh={setGerenteRrhh}
      esCliente={esCliente}
      setEsCliente={setEsCliente}
      esGrupo={esGrupo}
      setEsGrupo={setEsGrupo}
      contactoNombre={contactoNombre}
      setContactoNombre={setContactoNombre}
      contactoTelefono={contactoTelefono}
      setContactoTelefono={setContactoTelefono}
      rubroOption={rubroOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      grupoEmpresarialOptions={grupoEmpresarialOptions}
      grupoEmpresarialOption={grupoEmpresarialOption}
      setGrupoEmpresarialOption={setGrupoEmpresarialOption}
      foundEmpresas={foundEmpresas}
      modelSearchEmpresasByName={modelSearchEmpresasByName}
    />
  );
}

export { CrearEmpresa };
