/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { TextInput } from '../utils/TextInput';
import { Alert } from '../ui/alert';
import { constants, toTitleCase } from '../utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useWindowSize } from '../utils/useWindowSize';
import { ModalCreateDatoGeografico } from './ModalCreateDatoGeografico';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { datosGeografiaOptions } from '../utils/constants';
import { ModalGenerico } from '../ui/ModalGenerico';

function ListaDatosGeografiaView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  lista,
  modelGetDatosAuxiliares,
  isModalCreateDatoAuxiliarShown,
  setIsModalCreateDatoAuxiliarShown,
  modelCreateDatoAuxiliar,
  createDatoAuxiliar,
  valor,
  setValor,
  formConcepto,
  setFormConcepto,
  selectedDatoAuxiliar,
  isModalEditDatoAuxiliarShown,
  setIsModalEditDatoAuxiliarShown,
  modelEditDatoAuxiliar,
  editDatoAuxiliar,
  prepareEditDatoAuxiliar,
  etiqueta,
  setEtiqueta,
  busquedaRapida,
  setBusquedaRapida,
  padreId,
  setPadreId,
  regionesOptions,
  isDeleteConfirmModalShown,
  setIsDeleteConfirmModalShown,
  modelDeleteDatoGeografico,
  deleteDatoGeografico,
  setSelectedDatoAuxiliar,
}) {
  const goToNextPage = (page) => {
    handlePage(page);
  };

  const { width, isMobile } = useWindowSize();

  const submitSearch = () => {
    handlePage(0);
  };

  const submitSearchOnEnterPress = (event) => {
    if (
      event.which === constants.ENTER_KEY_EVENT_CODE ||
      event.keyCode === constants.ENTER_KEY_EVENT_CODE
    ) {
      submitSearch();
    }
  };

  return (
    <Content
      title="Datos geográficos"
      subTitle=""
      browserTitle="Datos geográficos"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <ModalCreateDatoGeografico
        title="Crear dato geografico"
        isModalShown={isModalCreateDatoAuxiliarShown}
        hideModal={() => setIsModalCreateDatoAuxiliarShown(false)}
        buttonDisabled={!modelCreateDatoAuxiliar}
        buttonLoading={modelCreateDatoAuxiliar.loading}
        submit={createDatoAuxiliar}
        datosAuxiliaresOptions={datosGeografiaOptions}
        concepto={formConcepto}
        setConcepto={setFormConcepto}
        valor={valor}
        setValor={setValor}
        padreId={padreId}
        setPadreId={setPadreId}
        regionesOptions={regionesOptions}
      />

      {selectedDatoAuxiliar && (
        <ModalCreateDatoGeografico
          title="Editar dato auxiliar"
          isModalShown={isModalEditDatoAuxiliarShown}
          hideModal={() => setIsModalEditDatoAuxiliarShown(false)}
          buttonDisabled={!modelEditDatoAuxiliar}
          buttonLoading={modelEditDatoAuxiliar.loading}
          submit={editDatoAuxiliar}
          datosAuxiliaresOptions={datosGeografiaOptions}
          valor={valor}
          concepto={formConcepto}
          setConcepto={setFormConcepto}
          setValor={setValor}
          conceptoSelectDisabled={true}
          padreId={padreId}
          setPadreId={setPadreId}
          regionesOptions={regionesOptions}
        />
      )}

      {selectedDatoAuxiliar && (
        <ModalGenerico
          isModalShown={isDeleteConfirmModalShown}
          hideModal={() => setIsDeleteConfirmModalShown(false)}
          title="Eliminar"
          body="Se eliminará el dato geográfico. ¿Continuar?"
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={modelDeleteDatoGeografico.loading}
          buttonLoading={modelDeleteDatoGeografico.loading}
          submit={deleteDatoGeografico}
        />
      )}

      <Row>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <TextInput
              label="Búsqueda rápida"
              placeholder=""
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={submitSearchOnEnterPress}
            />

            <SelectInput
              label="Concepto"
              value={etiqueta}
              options={[
                { value: 'ciudad', label: 'Ciudad' },
                { value: 'pais', label: 'Pais' },
                { value: 'region', label: 'Region' },
              ]}
              setValue={setEtiqueta}
            />

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>

        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            customOptions={
              <div>
                <Button
                  size="sm"
                  type="default"
                  text="Agregar"
                  onClick={() => setIsModalCreateDatoAuxiliarShown(true)}
                />
              </div>
            }
          >
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              </div>
            </div>

            <Loader isLoading={modelGetDatosAuxiliares.loading}>
              {lista && lista.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={lista}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column
                        header="Acciones"
                        body={(datoAuxiliar) => (
                          <ButtonWithLoading
                            text="Editar"
                            size="sm"
                            type="default"
                            submit={() => prepareEditDatoAuxiliar(datoAuxiliar)}
                          />
                        )}
                      ></Column>

                      <Column
                        field="etiqueta"
                        header="Concepto"
                        body={(datoAuxiliar) =>
                          toTitleCase(datoAuxiliar.etiqueta)
                        }
                      ></Column>
                      <Column
                        field="descripcion"
                        header="Valor"
                        body={(datoAuxiliar) =>
                          toTitleCase(datoAuxiliar.descripcion)
                        }
                      ></Column>

                      <Column
                        header="Eliminar"
                        body={(datoAuxiliar) => (
                          <>
                            <ButtonWithLoading
                              text="Eliminar"
                              size="sm"
                              type="warning"
                              submit={() => {
                                setSelectedDatoAuxiliar(datoAuxiliar);
                                setIsDeleteConfirmModalShown(true);
                              }}
                            />
                          </>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado datos auxiliares</h4>
              )}
            </Loader>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={goToNextPage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div>
              <span>Total: {totalResults}</span>
            </div>

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ListaDatosGeografiaView };
