import React, { useMemo, useEffect } from "react";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import Spanish from "@uppy/locales/lib/es_ES";
import XHRUpload from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";

const UploaderWrapper = ({
  maxFileCount, // Cantidad de archivos a subir de una vez.
  maxFileSize, // Valor en MB.
  minWidth,
  minHeight,
  uploadUrl,
  uploadHeaders,
  onUploadError,
}) => {


  const onFileAdded = (file) => {
    // Setear width y height en metadatos
    // para que backend pueda rechazar imagen
    // si no cumplen con lo mínimo

    const data = file.data; // is a Blob instance
    const url = URL.createObjectURL(data);
    const image = new Image();
    image.src = url;
    image.onload = () => {
      uppy.setFileMeta(file.id, {
        width: image.width,
        height: image.height,
      });
      URL.revokeObjectURL(url);
    };
  };

  const onBeforeUpload = (files) => {

    // "esTemporal" se envía al backend
    // y se usa en el backend para diferenciar
    // imágenes temporales (originales) de las
    // ya editadas (recortadas)
    // uppy.setMeta({ esTemporal: true });

    const CHECK_SIZE_BEFORE_UPLOAD = false;    
    if (!CHECK_SIZE_BEFORE_UPLOAD) {
      return true;
    }

   

    // Recorrer archivos y validar dimensiones.

    let abort = false;
    Object.keys(files).forEach((fileID) => {
     
      if (files[fileID].meta.width < minWidth) {
        alert(
          `Ancho de ${files[fileID].name} es menor a lo permitido (${minWidth} px.)`
        );
        abort = true;
      }

      if (files[fileID].meta.height < minHeight) {
        alert(
          `Alto de ${files[fileID].name} es menor a lo permitido (${minHeight} px.)`
        );
        abort = true;
      }
    });

    if (abort) {
      const HIDE_MESSAGE_TIMEOUT = 5000; // En milisegundos.
      const UPPY_MESSAGE_TYPE = "error";
      const ERROR_MESSAGE =
        "Eliminá los archivos que tengan ancho o alto menor a lo permitido";
      uppy.info(ERROR_MESSAGE, UPPY_MESSAGE_TYPE, HIDE_MESSAGE_TIMEOUT);
      return false;
    }

    return files;
  };

  const onComplete = (result) => {
    return true;
  };

  const uppy = useMemo(() => {
    const maxFileSizeInBytes = maxFileSize * 10 ** 6;

    const restrictions = {
      maxNumberOfFiles: maxFileCount,
      allowedFileTypes: ["image/*"],
      maxFileSize: maxFileSizeInBytes,
    };

    const uppyConfig = {
      locale: Spanish,
      autoProceed: false,
      allowMultipleUploads: false,
      restrictions,
      onBeforeUpload,
    };

    const uppyUploadConfig = {
      endpoint: uploadUrl,
      headers: uploadHeaders,
      getResponseError: onUploadError,
    };

    return Uppy(uppyConfig)
      .use(XHRUpload, uppyUploadConfig)
      .on("file-added", onFileAdded)
      .on("complete", onComplete);
  }, []);

  useEffect(() => {
    return () => uppy.close();
  }, []);

  // Traducir strings del uploader.
  const localeConfig = {
    strings: {
      browse: "seleccionar",
    },
  };

  return (
    <Dashboard
      uppy={uppy}
      locale={localeConfig}
      proudlyDisplayPoweredByUppy={false}
    />
  );
};

export default UploaderWrapper;