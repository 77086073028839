/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';

import { Alert } from '../../utils/Alert';

import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toTitleCase } from '../../utils/utils';
import { SelectInput } from '../../utils/SelectInput';

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.cargosPotenciales === prevProps.cargosPotenciales &&
    nextProps.cargoPotSubmitAlert === prevProps.cargoPotSubmitAlert &&
    nextProps.modelGetCargosPot.loading ===
      prevProps.modelGetCargosPot.loading &&
    nextProps.modelCrearCargoPot.loading ===
      prevProps.modelCrearCargoPot.loading &&
    nextProps.cargoOption === prevProps.cargoOption &&
    nextProps.cargosOptions === prevProps.cargosOptions
  );
};

const CargosPotencialesNotas = memo(function Componente({
  cargoPotSubmitAlert,
  modelGetCargosPot,
  cargosPotenciales,
  cargoOption,
  cargosOptions,
  setCargoOption,
  agregarCargoPot,
  modelCrearCargoPot,
}) {
  // console.log('CargosPotencialesNotas.render');
  return (
    <>
      {cargoPotSubmitAlert.text && (
        <Alert
          title={cargoPotSubmitAlert.title}
          type={cargoPotSubmitAlert.type}
          text={cargoPotSubmitAlert.text}
          closable={cargoPotSubmitAlert.closable}
        />
      )}
      <SelectInput
        label="Nuevo cargo potencial"
        value={cargoOption}
        options={cargosOptions}
        setValue={setCargoOption}
        required
      />
      <ButtonWithLoading
        type="success"
        text="Agregar cargo potencial"
        submit={() => {
          agregarCargoPot();
        }}
        disabled={modelCrearCargoPot.loading}
        loading={modelCrearCargoPot.loading}
      />
      <Loader isLoading={modelGetCargosPot.loading}>
        {cargosPotenciales && cargosPotenciales.length > 0 ? (
          <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
            <div className="table-responsive">
              <DataTable
                lazy
                pt={{
                  table: {
                    className: 'table table-hover',
                  },
                }}
                value={cargosPotenciales}
                emptyMessage="No se encontraron cargos potenciales"
              >
                <Column
                  field="cargoDescripcion"
                  header="Cargo"
                  body={(cargoPotencial) =>
                    toTitleCase(cargoPotencial.cargoDescripcion)
                  }
                ></Column>
                <Column
                  field="actualizacionUsuarioNombre"
                  header="Usuario"
                  body={(cargoPotencial) =>
                    toTitleCase(cargoPotencial.actualizacionUsuarioNombre)
                  }
                ></Column>
              </DataTable>
            </div>
          </Box>
        ) : (
          <p>No se han encontrado cargos potenciales</p>
        )}
      </Loader>
    </>
  );
},
customComparator);

export { CargosPotencialesNotas };
