import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  prepareCiudadesOptions,
  prepareNacionalidadesOptions,
  preparePaisesOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  crearCandidatoDuck,
  crearCandidatoThunk,
  getNacionalidadesDuck,
  getNacionalidadesThunk,
  getPaisesDuck,
  getPaisesThunk,
  getRegionesDuck,
  getRegionesThunk,
  getCiudadesDuck,
  getCiudadesThunk,
  searchCandidatosByMobileDuck,
  searchCandidatosByMobileThunk,
} from './duck';
import { CrearCandidatoView } from './CrearCandidatoView';
import {
  asuncionOption,
  centralOption,
  nacionalidadPyOption,
  paraguayOption,
} from '../utils/constants';
import moment from 'moment';

function CrearCandidato({ history, match, token }) {
  const dispatch = useDispatch();

  const timer = useRef(null);

  const modelCrearCandidato = useSelector((state) => state.crearCandidato);
  const modelGetNacionalidades = useSelector(
    (state) => state.getNacionalidades
  );
  const modelGetPaises = useSelector((state) => state.getPaises);
  const modelGetRegiones = useSelector((state) => state.getRegiones);
  const modelGetCiudades = useSelector((state) => state.getCiudades);
  const modelSearchCandidatosByMobile = useSelector(
    (state) => state.searchCandidatosByMobile
  );

  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [documento, setDocumento] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono1, setTelefono1] = useState('');
  const [telefono2, setTelefono2] = useState('');
  const [telefono3, setTelefono3] = useState('');
  const [correo, setCorreo] = useState('');
  const [nacimientoFecha, setNacimientoFecha] = useState();
  const [sexoOption, setSexoOption] = useState();
  const [estadoCivilOption, setEstadoCivilOption] = useState();

  const [nacionalidadesOptions, setNacionalidadesOptions] = useState();
  const [nacionalidadOption, setNacionalidadOption] =
    useState(nacionalidadPyOption);

  const [paisesOptions, setPaisesOptions] = useState();
  const [paisOption, setPaisOption] = useState(paraguayOption);

  const [regionesOptions, setRegionesOptions] = useState();
  const [regionOption, setRegionOption] = useState(centralOption);
  const [regionLibre, setRegionLibre] = useState();

  const [ciudadesOptions, setCiudadesOptions] = useState();
  const [ciudadOption, setCiudadOption] = useState(asuncionOption);
  const [ciudadLibre, setCiudadLibre] = useState();

  const [expectativaSalarial, setExpectativaSalarial] = useState();
  const [descripcionInterna, setDescripcionInterna] = useState();

  const [foundCandidatos, setFoundCandidatos] = useState();

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const [creado, setCreado] = useState(false);

  const prepareRegionesOptions = (regiones) => {
    const options = regiones.map((region) => ({
      value: region.idNumerico,
      label: toTitleCase(region.descripcion),
    }));

    return options;
  };

  useEffect(() => {
    log('mount.useEffect');
    dispatch(getNacionalidadesThunk({ userToken: token.raw }));
    dispatch(getPaisesThunk({ userToken: token.raw }));
    dispatch(getCiudadesThunk({ userToken: token.raw, padreId: null }));
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetNacionalidades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNacionalidadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetNacionalidades.useEffect.data': data });

      const mNacOptions = prepareNacionalidadesOptions(data.items);
      // log({ mNacOptions });

      setNacionalidadesOptions(mNacOptions);

      dispatch(getNacionalidadesDuck.actions.clear());
    }
  }, [modelGetNacionalidades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetPaises);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getPaisesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetPaises.useEffect.data': data });

      const mPaisesOptions = preparePaisesOptions(data.items);
      // log({ mPaisesOptions });

      setPaisesOptions(mPaisesOptions);

      dispatch(getPaisesDuck.actions.clear());
    }
  }, [modelGetPaises]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRegiones);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRegionesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetRegiones.useEffect.data': data });

      const mRegOptions = prepareRegionesOptions(data.items);
      log({ mRegOptions });

      setRegionesOptions(mRegOptions);

      dispatch(getRegionesDuck.actions.clear());
    }
  }, [modelGetRegiones]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCiudades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCiudadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCiudades.useEffect.data': data });

      const mCiudadesOptions = prepareCiudadesOptions(data.items);
      // log({ mCiudadesOptions });

      setCiudadesOptions(mCiudadesOptions);

      dispatch(getCiudadesDuck.actions.clear());
    }
  }, [modelGetCiudades]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (telefono2 && telefono2.length > 8) {

      timer.current = setTimeout(() => {
        dispatch(
          searchCandidatosByMobileThunk({
            mobile: telefono2,
            userToken: token.raw,
          })
        );
      }, 2000); // 2 seconds delay
    }
  }, [telefono2]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelSearchCandidatosByMobile);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(searchCandidatosByMobileDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(searchCandidatosByMobileDuck.actions.clear());
      setFoundCandidatos(data);
    }
  }, [modelSearchCandidatosByMobile]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCandidato);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(crearCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setCreado(true);

      setSubmitAlert({
        text: 'Candidato creado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearCandidatoDuck.actions.clear());
    }
  }, [modelCrearCandidato]);

  function submit() {
    const data = {
      userToken: token.raw,
      nombre,
      apellido,
      documento,
      direccion,
      telefono1,
      telefono2,
      telefono3,
      correo,
      nacimientoFecha: nacimientoFecha
        ? moment(nacimientoFecha).format('YYYY-MM-DD')
        : null,
      sexo: sexoOption?.value,
      estadoCivil: estadoCivilOption?.value,
      nacionalidadIdNumerico: nacionalidadOption?.value,
      paisId: paisOption?.value,
      expectativaSalarial: expectativaSalarial
        ? expectativaSalarial.toString()
        : '0',
      descripcionInterna,
    };

    if (paisOption.value === paraguayOption.value) {
      data.ciudadId = ciudadOption?.value;
    } else {
      data.ciudadLibre = ciudadLibre;
    }

    log({ 'submit.data': data });

    dispatch(crearCandidatoThunk(data));
  }

  return (
    <CrearCandidatoView
      history={history}
      match={match}
      modelCrearCandidato={modelCrearCandidato}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      nombre={nombre}
      setNombre={setNombre}
      apellido={apellido}
      setApellido={setApellido}
      documento={documento}
      setDocumento={setDocumento}
      expectativaSalarial={expectativaSalarial}
      setExpectativaSalarial={setExpectativaSalarial}
      telefono1={telefono1}
      setTelefono1={setTelefono1}
      direccion={direccion}
      setDireccion={setDireccion}
      telefono2={telefono2}
      setTelefono2={setTelefono2}
      telefono3={telefono3}
      setTelefono3={setTelefono3}
      correo={correo}
      setCorreo={setCorreo}
      nacimientoFecha={nacimientoFecha}
      setNacimientoFecha={setNacimientoFecha}
      sexoOption={sexoOption}
      setSexoOption={setSexoOption}
      estadoCivilOption={estadoCivilOption}
      setEstadoCivilOption={setEstadoCivilOption}
      descripcionInterna={descripcionInterna}
      setDescripcionInterna={setDescripcionInterna}
      nacionalidadesOptions={nacionalidadesOptions}
      nacionalidadOption={nacionalidadOption}
      setNacionalidadOption={setNacionalidadOption}
      paisesOptions={paisesOptions}
      paisOption={paisOption}
      setPaisOption={setPaisOption}
      regionesOptions={regionesOptions}
      regionOption={regionOption}
      setRegionOption={setRegionOption}
      regionLibre={regionLibre}
      setRegionLibre={setRegionLibre}
      ciudadesOptions={ciudadesOptions}
      ciudadOption={ciudadOption}
      setCiudadOption={setCiudadOption}
      ciudadLibre={ciudadLibre}
      setCiudadLibre={setCiudadLibre}
      modelSearchCandidatosByMobile={modelSearchCandidatosByMobile}
      foundCandidatos={foundCandidatos}
      creado={creado}
    />
  );
}

export { CrearCandidato };
