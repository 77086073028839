import React from 'react';
import { Button } from 'adminlte-2-react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { TextArea } from '../../utils/TextArea';
import { SelectInput } from '../../utils/SelectInput';
import Loader from '../../utils/Loader';

function ModalEmCandidatoEdit({
  isModalShown,
  hideModal,
  title,
  emCandidatoEdit,
  editEmCandidato,
  condicionesActuales,
  setCondicionesActuales,
  condicionesContratacion,
  setCondicionesContratacion,
  resumen,
  setResumen,
  parametroOption,
  parametroOptions,
  setParametroOption,
  candidatoSelectedForEdit,
  setCandidatoSelectedForEdit,
  testMostrarModal,
  setTestMostrarModal,
  setCandidatoIdNumerico,
  setExpectativaSalarial,
  notaFavorita,
  notaFavoritaGet,
}) {
  return (
    <Modal show={isModalShown} style={{ opacity: 1 }} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectInput
          label="Parámetro"
          value={parametroOption}
          options={parametroOptions}
          setValue={setParametroOption}
          required
        />

        <TextArea
          label="Resumen"
          value={resumen}
          setValue={setResumen}
          style={{
            height: 200,
          }}
        />

        <TextArea
          label="Condiciones actuales"
          value={condicionesActuales}
          setValue={setCondicionesActuales}
          style={{
            height: 200,
          }}
        />

        <TextArea
          label="Condiciones de contratación"
          value={condicionesContratacion}
          setValue={setCondicionesContratacion}
          style={{
            height: 200,
          }}
        />

        <Loader isLoading={notaFavoritaGet.loading}>
          <TextArea
            label="Nota favorita"
            value={notaFavorita?.contenido ?? ''}
            disabled
            style={{
              height: 200,
            }}
          />
          <Button
            type="default"
            text="Ver más"
            size="sm"
            onClick={() => {
              setCandidatoIdNumerico(candidatoSelectedForEdit.idNumerico);
              setExpectativaSalarial(
                candidatoSelectedForEdit.expectativaSalarial
              );
            }}
          />
        </Loader>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonWithLoading
            type="warning"
            text="Volver"
            disabled={emCandidatoEdit.loading}
            loading={emCandidatoEdit.loading}
            submit={() => setCandidatoSelectedForEdit(null)}
          />

          <ButtonWithLoading
            type="primary"
            text="Guardar"
            disabled={emCandidatoEdit.loading}
            loading={emCandidatoEdit.loading}
            submit={editEmCandidato}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEmCandidatoEdit };
