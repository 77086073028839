import React from 'react';

function CustomDescriptionBlock({ header, text }) {
  return (
    <div className="description-block border-right">
      {/*
      <span className={`description-percentage text-${percentageColor}`}>
        <FontAwesomeIcon icon={['fas', `caret-${indication}`]} />
        {` ${percentage}%`}
      </span>
      */}
      <h5 className="description-header" style={{ textTransform: 'none' }}>
        {header}
      </h5>
      <span className="description-text" style={{ textTransform: 'none' }}>
        {text}
      </span>
    </div>
  );
}

export { CustomDescriptionBlock };
