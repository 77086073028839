const prepareEtapasOptions = (etapas) => {
  const mEtapasOptions = etapas.map((etapa) => ({
    value: etapa.idNumerico,
    label: etapa.descripcion,
  }));

  return mEtapasOptions;
};

module.exports = {
  oportunidadesUtils: {
    prepareEtapasOptions,
  },
};
