/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { Alert } from '../ui/alert';
import { constants, toTitleCase } from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { ModalGenerico } from '../ui/ModalGenerico';
import { esClienteOptions, esClienteOptionsByValue } from './shared';

function ListaEmpresasView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  lista,
  busquedaRapida,
  setBusquedaRapida,
  modelGetEmpresas,
  esCliente,
  setEsCliente,
  selectedEmpresa,
  setSelectedEmpresa,
  isDeleteConfirmModalShown,
  setIsDeleteConfirmModalShown,
  modelDeleteEmpresa,
  deleteEmpresa,
  seleccion,
  seleccionRetorno,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1200;

  const submitSearch = () => {
    handlePage(0);
  };

  const submitSearchOnEnterPress = (event) => {
    if (
      event.which === constants.ENTER_KEY_EVENT_CODE ||
      event.keyCode === constants.ENTER_KEY_EVENT_CODE
    ) {
      submitSearch();
    }
  };

  const goToNextPage = (page) => {
    handlePage(page);
  };

  return (
    <Content title="Empresas" subTitle="" browserTitle="Empresas" show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {selectedEmpresa && (
        <ModalGenerico
          isModalShown={isDeleteConfirmModalShown}
          hideModal={() => setIsDeleteConfirmModalShown(false)}
          title="Eliminar"
          body="Se eliminará la empresa. ¿Continuar?"
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={!selectedEmpresa}
          buttonLoading={modelDeleteEmpresa.loading}
          submit={deleteEmpresa}
        />
      )}

      <Row>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <TextInput
              label="Búsqueda rápida"
              placeholder="Nombre, ruc"
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={submitSearchOnEnterPress}
            />

            <SelectInput
              label="Es cliente"
              value={esCliente}
              options={esClienteOptions}
              setValue={setEsCliente}
            />

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>

        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            customOptions={
              <div>
                <Button
                  size="sm"
                  type="default"
                  text="Agregar"
                  onClick={() => history.push('/empresa/agregar')}
                />
              </div>
            }
          >
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              </div>
            </div>

            <Loader isLoading={modelGetEmpresas.loading}>
              {lista && lista.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={lista}
                      emptyMessage="No se encontraron registros"
                    >
                      {seleccion === 's' && (
                        <Column
                          header="Seleccionar"
                          body={(empresa) => (
                            <ButtonWithLoading
                              size="sm"
                              text="Seleccionar"
                              type="primary"
                              submit={() =>
                                history.push(
                                  `${seleccionRetorno}&empresaId=${empresa.idNumerico}&empresaDescripcion=${empresa.nombre}`
                                )
                              }
                            />
                          )}
                        ></Column>
                      )}

                      <Column
                        header="Acciones"
                        body={(empresa) => (
                          <ButtonWithLoading
                            text="Editar"
                            size="sm"
                            type="default"
                            submit={() =>
                              history.push(
                                `/empresa/editar/${empresa.idNumerico}`
                              )
                            }
                          />
                        )}
                      ></Column>

                      <Column
                        field="nombre"
                        header="Nombre"
                        body={(empresa) => toTitleCase(empresa.nombre)}
                      ></Column>
                      <Column field="documento" header="RUC"></Column>
                      <Column
                        header="Teléfono"
                        body={(empresa) => toTitleCase(empresa.telefono)}
                      ></Column>
                      <Column field="correo" header="Email"></Column>

                      <Column
                        field="eliminar"
                        header="Eliminar"
                        body={(empresa) => (
                          <div style={{}}>
                            <div style={{ display: 'inline-block' }}>
                              <ButtonWithLoading
                                type="warning"
                                size="sm"
                                text="Eliminar"
                                submit={() => {
                                  setSelectedEmpresa(empresa);
                                  setIsDeleteConfirmModalShown(true);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado empresas</h4>
              )}
            </Loader>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={goToNextPage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div>
              <span>Total: {totalResults}</span>
            </div>

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ListaEmpresasView };
