import axios from "axios";

import config from "./../config";
import { log } from '../utils/utils';
const { baseUrl, key } = config.api;

const client = axios.create({
  baseURL: baseUrl,
});

//Agregamos Headers comunes a todos los request
client.interceptors.request.use(
  (request) => {
    if (!request.url.startsWith(baseUrl)) {
      let req = request;
      delete req.baseUrl;
      return req;
    }
    //Solo agregamos los headers si corresponde a nuestra API
    request.headers["Access-Control-Allow-Headers"] = "x-access-token";
    request.headers["x-access-token"] = key;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Mapeamos la respuesta de la API para que se ajuste a como manejamos nosotros
client.interceptors.response.use(
  (response) => {
    // if (!response.config.url.startsWith(baseUrl)) {
    return response;
    // }

    // if (!response.data) {
    //   throw new Error('Error en response');
    // }

    // const newResponse = {
    //   data: response.data,
    //   // status: response.data.status,
    //   // message: response.data.mensaje,
    // };
    // return newResponse;
  },
  (error) => {
    const code = error.response?.status || null;
    log("## axios.res.error: ", JSON.stringify(error, null, 1));
    log("## axios.res.error (code): ", code);
    return Promise.reject(error);
  }
);

export default client;
