/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Col, Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../utils/Loader';
import {
  getDateOrEmpty,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { Alert } from '../ui/alert';
import { useWindowSize } from '../utils/useWindowSize';
import { ListaEmWithSearch } from './ListaEmWithSearch';
import { ModalGenerico } from '../ui/ModalGenerico';
import { ModalEstudioMercadoEdit } from './ModalEstudioMercadoEdit';

function ListaEstudiosMercadoView({
  history,
  topAlert,
  estudiosMercadoGeneralesGet,
  estudiosMercadoEspecificosGet,
  estudiosMercadoGenerales,
  estudiosMercadoEspecificos,
  selectedEmGeneral,
  setSelectedEmGeneral,
  selectedEmEspecifico,
  setSelectedEmEspecifico,
  busquedaRapidaEmG,
  setBusquedaRapidaEmG,
  handlePageEmG,
  pageSizeEmG,
  pageEmG,
  totalResultsEmG,
  busquedaRapidaEmE,
  setBusquedaRapidaEmE,
  handlePageEmE,
  pageSizeEmE,
  pageEmE,
  totalResultsEmE,
  estudioMercadoGeneralDelete,
  estudioMercadoEspecificoDelete,
  eliminarEstudioMercadoGeneral,
  eliminarEstudioMercadoEspecifico,
  selectedEmForEdit,
  setSelectedEmForEdit,
  token,
  setTopAlert,
  recargarEstudiosEspecificos,
  recargarEstudiosGenerales,
}) {
  return (
    <div className="content">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <h3 style={{ marginTop: 0 }}>Estudios de Mercado</h3>
        <Button
          type="success"
          text="Crear"
          onClick={() => history.push('/estudio/crear')}
        />
      </div>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {selectedEmGeneral && (
        <ModalGenerico
          isModalShown={!!selectedEmGeneral}
          hideModal={() => setSelectedEmGeneral(null)}
          title="Eliminar"
          body={<>Se eliminará el estudio de mercado general. ¿Continuar?</>}
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={estudioMercadoGeneralDelete.loading}
          buttonLoading={estudioMercadoGeneralDelete.loading}
          submit={eliminarEstudioMercadoGeneral}
        />
      )}

      {selectedEmEspecifico && (
        <ModalGenerico
          isModalShown={!!selectedEmEspecifico}
          hideModal={() => setSelectedEmEspecifico(null)}
          title="Eliminar"
          body={<>Se eliminará el estudio de mercado específico. ¿Continuar?</>}
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={estudioMercadoEspecificoDelete.loading}
          buttonLoading={estudioMercadoEspecificoDelete.loading}
          submit={eliminarEstudioMercadoEspecifico}
        />
      )}

      {selectedEmForEdit && (
        <ModalEstudioMercadoEdit
          isModalShown={!!selectedEmForEdit}
          hideModal={() => setSelectedEmForEdit(null)}
          setSelectedEmForEdit={setSelectedEmForEdit}
          selectedEmForEdit={selectedEmForEdit}
          token={token}
          setTopAlert={setTopAlert}
          recargarEstudiosEspecificos={recargarEstudiosEspecificos}
          recargarEstudiosGenerales={recargarEstudiosGenerales}
        />
      )}

      <ListaEmWithSearch
        titulo="Generales"
        history={history}
        topAlert={topAlert}
        estudiosMercadoGeneralesGet={estudiosMercadoGeneralesGet}
        busquedaRapida={busquedaRapidaEmG}
        setBusquedaRapida={setBusquedaRapidaEmG}
        handlePage={handlePageEmG}
        estudiosMercadoGet={estudiosMercadoGeneralesGet}
        estudiosMercado={estudiosMercadoGenerales}
        setSelectedEm={setSelectedEmGeneral}
        pageSize={pageSizeEmG}
        page={pageEmG}
        totalResults={totalResultsEmG}
        setSelectedEmForEdit={setSelectedEmForEdit}
      />

      <ListaEmWithSearch
        titulo="Específicos"
        history={history}
        topAlert={topAlert}
        estudiosMercadoGeneralesGet={estudiosMercadoGeneralesGet}
        busquedaRapida={busquedaRapidaEmE}
        setBusquedaRapida={setBusquedaRapidaEmE}
        handlePage={handlePageEmE}
        estudiosMercadoGet={estudiosMercadoEspecificosGet}
        estudiosMercado={estudiosMercadoEspecificos}
        setSelectedEm={setSelectedEmEspecifico}
        pageSize={pageSizeEmE}
        page={pageEmE}
        totalResults={totalResultsEmE}
        setSelectedEmForEdit={setSelectedEmForEdit}
      />
    </div>
  );
}

export { ListaEstudiosMercadoView };
