export const oportunidadEstadoIds = {
  b: 'b',
  a: 'a',
  p: 'p',
  c: 'c',
  f: 'f',
};

export const oportunidadEstadoDescById = {
  f: 'Finalizado',
  a: 'Abierto',
  c: 'Cancelado',
  p: 'Pausado',
};

export const oportunidadEstadoOptions = [
  { value: 'a', label: 'Abierto' },
  { value: 'p', label: 'Pausado' },
  { value: 'c', label: 'Cancelado' },
  { value: 'f', label: 'Finalizado' },
];

export const oportunidadOptionsByValue = {
  f: { value: 'f', label: 'Finalizado' },
  a: { value: 'a', label: 'Abierto' },
  c: { value: 'c', label: 'Cancelado' },
  p: { value: 'p', label: 'Pausado' },
};

export const oportunidadEstadoOptionsWithAll = [
  { value: 'todos', label: 'Todos' },
  ...oportunidadEstadoOptions,
];

export const oportunidadEstadoOptByValWithAll = {
  todos: { value: 'todos', label: 'Todos' },
  ...oportunidadOptionsByValue,
};

export const pagoFormaIds = {
  hour: 'hour',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
};

export const pagoFormaDescById = {
  hour: 'Por hora',
  day: 'Por día',
  week: 'Semanal',
  month: 'Mensual',
  year: 'Anual',
};

export const pagoFormaOptions = [
  { value: 'hour', label: 'Por hora' },
  { value: 'day', label: 'Por día' },
  { value: 'week', label: 'Semanal' },
  { value: 'month', label: 'Mensual' },
  { value: 'year', label: 'Anual' },
];

export const pagoFormaOptionsByValue = {
  hour: {
    value: 'hour',
    label: 'Por hora',
  },
  day: {
    value: 'day',
    label: 'Por día',
  },
  week: {
    value: 'week',
    label: 'Semanal',
  },
  month: {
    value: 'month',
    label: 'Mensual',
  },
  year: {
    value: 'year',
    label: 'Anual',
  },
};

export const empleoTipoIds = {
  tiempoCompleto: 'tiempoCompleto',
  medioTiempo: 'medioTiempo',
  contrato: 'contrato',
  temporal: 'temporal',
  pasantia: 'pasantia',
  voluntariado: 'voluntariado',
  porDia: 'porDia',
  otro: 'otro',
};

export const empleoTipoDescById = {
  tiempoCompleto: 'Tiempo Completo',
  medioTiempo: 'Medio Tiempo',
  contrato: 'Contrato',
  temporal: 'Temporal',
  pasantia: 'Pasantía',
  voluntariado: 'Voluntariado',
  porDia: 'Por día',
  otro: 'Otro',
};

export const empleoTipoOptions = [
  { value: 'tiempoCompleto', label: 'Tiempo Completo' },
  { value: 'medioTiempo', label: 'Medio Tiempo' },
  { value: 'contrato', label: 'Contrato' },
  { value: 'temporal', label: 'Temporal' },
  { value: 'pasantia', label: 'Pasantía' },
  { value: 'voluntariado', label: 'Voluntariado' },
  { value: 'porDia', label: 'Por día' },
  { value: 'otro', label: 'Otro' },
];

export const empleoTipoOptionsByValue = {
  tiempoCompleto: {
    value: 'tiempoCompleto',
    label: 'Tiempo Completo',
  },
  medioTiempo: {
    value: 'medioTiempo',
    label: 'Medio Tiempo',
  },
  contrato: {
    value: 'contrato',
    label: 'Contrato',
  },
  temporal: {
    value: 'temporal',
    label: 'Temporal',
  },
  pasantia: {
    value: 'pasantia',
    label: 'Pasantía',
  },
  voluntariado: {
    value: 'voluntariado',
    label: 'Voluntariado',
  },
  porDia: {
    value: 'porDia',
    label: 'Por día',
  },
  otro: {
    value: 'otro',
    label: 'Otro',
  },
};

export const empleoTipoOptionsForCandExp = [
  { value: 'tiempoCompleto', label: 'Tiempo Completo' },
  { value: 'medioTiempo', label: 'Medio Tiempo' },
];

export const sexoOptions = [
  { value: 'm', label: 'Masculino' },
  { value: 'f', label: 'Femenino' },
];

export const sexoOptionsById = {
  m: { value: 'm', label: 'Masculino' },
  f: { value: 'f', label: 'Femenino' },
};

export const estadoCivilIds = {
  soltero: 'soltero',
  casado: 'casado',
  concubino: 'concubino',
  divorciado: 'divorciado',
  viudo: 'viudo',
};

export const estadoCivilDescById = {
  soltero: 'Soltero/a',
  casado: 'Casado/a',
  concubino: 'Concubino/a',
  divorciado: 'Divorciado/a',
  viudo: 'Viudo/a',
};

export const estadoCivilOptions = [
  { value: 'soltero', label: 'Soltero/a' },
  { value: 'casado', label: 'Casado/a' },
  { value: 'concubino', label: 'Concubino/a' },
  { value: 'divorciado', label: 'Divorciado/a' },
  { value: 'viudo', label: 'Viudo/a' },
];

export const estadoCivilOptionsById = {
  soltero: { value: 'soltero', label: 'Soltero/a' },
  casado: { value: 'casado', label: 'Casado/a' },
  concubino: { value: 'concubino', label: 'Concubino/a' },
  divorciado: { value: 'divorciado', label: 'Divorciado/a' },
  viudo: { value: 'viudo', label: 'Viudo/a' },
};

export const paraguayOption = { value: '9', label: 'Paraguay' };
export const centralOption = { value: '875', label: 'Central' };
export const asuncionOption = { value: '1065', label: 'Asunción' };
export const nacionalidadPyOption = { value: '1166', label: 'Paraguayo' };

export const nivelFormacionDescById = {
  primario: 'Primario',
  secundario: 'Secundario',
  estudianteUniversitario: 'Estudiante Universitario',
  profesional: 'Profesional',
  postgrado: 'Postgrado',
  masterado: 'Masterado',
  tecnico: 'Tecnico',
  doctorado: 'Doctorado',
  diplomado: 'Diplomado',
  licenciatura: 'Licenciatura',
  terciario: 'Terciario',
};

export const nivelFormacionOptions = [
  { value: 'primario', label: 'Primario' },
  { value: 'secundario', label: 'Secundario' },
  { value: 'estudianteUniversitario', label: 'Estudiante Universitario' },
  { value: 'profesional', label: 'Profesional' },
  { value: 'postgrado', label: 'Postgrado' },
  { value: 'masterado', label: 'Masterado' },
  { value: 'tecnico', label: 'Tecnico' },
  { value: 'doctorado', label: 'Doctorado' },
  { value: 'diplomado', label: 'Diplomado' },
  { value: 'licenciatura', label: 'Licenciatura' },
  { value: 'terciario', label: 'Terciario' },
];

export const idiomaNivelDescById = {
  nativo: 'Nativo',
  basico: 'Básico',
  bueno: 'Bueno',
  muyBueno: 'Muy Bueno',
  excelente: 'Excelente',
};

export const nivelIdiomaOptions = [
  { value: 'nativo', label: 'Nativo' },
  { value: 'basico', label: 'Básico' },
  { value: 'bueno', label: 'Bueno' },
  { value: 'muyBueno', label: 'Muy Bueno' },
  { value: 'excelente', label: 'Excelente' },
];

export const nivelIdiomaOptionsWithAll = [
  { value: 'desconocido', label: 'Desconocido' },
  { value: 'nativo', label: 'Nativo' },
  { value: 'basico', label: 'Básico' },
  { value: 'bueno', label: 'Bueno' },
  { value: 'muyBueno', label: 'Muy Bueno' },
  { value: 'excelente', label: 'Excelente' },
];

export const nivelIdiomaOptionsById = {
  desconocido: { value: 'desconocido', label: 'Desconocido' },
  nativo: { value: 'nativo', label: 'Nativo' },
  basico: { value: 'basico', label: 'Básico' },
  bueno: { value: 'bueno', label: 'Bueno' },
  muyBueno: { value: 'muyBueno', label: 'Muy Bueno' },
  excelente: { value: 'excelente', label: 'Excelente' },
};

export const datosAuxiliaresOptions = [
  { value: 'cargo', label: 'Cargo' },
  { value: 'rubro', label: 'Rubro' },
  { value: 'motivoSalida', label: 'Motivo de salida' },
  { value: 'nacionalidad', label: 'Nacionalidad' },
  { value: 'titulacion', label: 'Titulación' },
  { value: 'idioma', label: 'Idioma' },
];

export const datosGeografiaOptions = [
  { value: 'pais', label: 'Pais' },
  { value: 'region', label: 'Region' },
  { value: 'ciudad', label: 'Ciudad' },
];

export const etapasIdByName = {
  reclutamiento: '200',
  primerFiltro: '201',
  entrevistaSelector: '202',
  entrevistaLider: '203',
  cierre: '206',
};

/*
Filtro Telefónico	2
Entrevista por Videollamada	3
Recepcionados	1
Entrevistas	11
Evaluaciones	13
Primer Filtro	201
Cierre	206
Entrevista Presencial	4
Cierre	14
Filtros	10
Reclutamiento	200
Entrevista Empresa	6
Entrevista Presencial Directora	5
Seguimiento Ingreso	9
Procesados	12
Confirmación Puesto	7
Entrevista Selector	202
Entrevista Lider	203
Entrevista Director	204
Retorno a Candidato	8
Entrevista Cliente	205
*/

export const allEtapasOptions = [
  { value: '200', label: 'Reclutamiento' },
  { value: '201', label: 'Primer Filtro' },
  { value: '202', label: 'Entrevista Selector' },
  { value: '203', label: 'Entrevista Lider' },
  { value: '204', label: 'Entrevista Director' },
  { value: '205', label: 'Entrevista Cliente' },
  { value: '206', label: 'Cierre' },
  { value: '1', label: 'Recepcionados' },
  { value: '2', label: 'Filtro Telefónico' },
  { value: '3', label: 'Entrevista por Videollamada' },
  { value: '4', label: 'Entrevista Presencial' },
  { value: '5', label: 'Entrevista Presencial Directora' },
  { value: '6', label: 'Entrevista Empresa' },
  { value: '7', label: 'Confirmación Puesto' },
  { value: '8', label: 'Retorno a Candidato' },
  { value: '9', label: 'Seguimiento Ingreso' },
  { value: '10', label: 'Filtros' },
  { value: '11', label: 'Entrevistas' },
  { value: '12', label: 'Procesados' },
  { value: '13', label: 'Evaluaciones' },
  { value: '14', label: 'Cierre' },
];

export const fuentesOptions = [
  { value: 'baseDatos', label: 'Base de Datos' },
  { value: 'contactoLinkedin', label: 'Contacto de LinkedIn' },
  { value: 'referidoEquipo', label: 'Referido Equipo' },
  { value: 'recomendado', label: 'Recomendado' },
  { value: 'cliente', label: 'Cliente' },
  { value: 'portal', label: 'Portal' },
  { value: 'envioCVLinkedin', label: 'Envió CV - LinkedIn' },
  { value: 'envioCVFacebook', label: 'Envió CV - Facebook' },
  { value: 'envioCVGrupoWhatsapp', label: 'Envió CV – Grupo de Whatsapp' },
];

export const estudioTipoOptions = [
  { value: 'especifico', label: 'Específico' },
  { value: 'general', label: 'General' },
];

export const estudioOrigenOptions = [
  { value: 'cargoRubro', label: 'Desde candidatos' },
  { value: 'oportunidades', label: 'Desde oportunidad' },
  { value: 'manual', label: 'Manual' },
];

// conExperiencia, conExperienciaVigente, conCargoPotencial
export const cargoRubroFiltroOptions = [
  { value: 'conExperiencia', label: 'Con experiencia' },
  { value: 'conExperienciaVigente', label: 'Con experiencia vigente' },
  { value: 'conCargoPotencial', label: 'Con cargo potencial' },
];

export const emParametrosByName = {
  noDisponibleNoInteresado: 'noDisponibleNoInteresado',
  expectativaSuperior: 'expectativaSuperior',
  perfil: 'perfil',
  potable: 'potable',
  enEntrevista: 'enEntrevista',
  conExperienciaPrevia: 'conExperienciaPrevia',
};

export const emParametrosDescByName = {
  noDisponibleNoInteresado: 'No disponible / no interesado',
  expectativaSuperior: 'Expectativa salarial superior',
  perfil:
    'No cuenta con perfil requerido (académ., trayectoria, solidez, person., otros)',
  potable: 'Perfil potable para la posición',
  enEntrevista: 'Perfil en proceso de entrevista',
  conExperienciaPrevia: 'Perfil con experiencia previa en la empresa',
};

export const emParametrosOptions = [
  { value: 'noDisponibleNoInteresado', label: 'No disponible / no interesado' },
  { value: 'expectativaSuperior', label: 'Expectativa salarial superior' },
  {
    value: 'perfil',
    label:
      'No cuenta con perfil requerido (académ., trayectoria, solidez, person., otros)',
  },
  { value: 'potable', label: 'Perfil potable para la posición' },
  { value: 'enEntrevista', label: 'Perfil en proceso de entrevista' },
  {
    value: 'conExperienciaPrevia',
    label: 'Perfil con experiencia previa en la empresa',
  },
];

export const colorsByEmParametros = {
  noDisponibleNoInteresado: 'BFBFBF',
  expectativaSuperior: 'FF8A93',
  perfil: 'FFFF7A',
  potable: '92D14F',
  enEntrevista: '9BC1E6',
  conExperienciaPrevia: 'B685DB',
};
