import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import _ from 'lodash';
import controller from './duck';

import { useSelector, useDispatch } from 'react-redux';
import {
  clearLocalStorage,
  constants,
  errorMessageSelector,
  errorSelector,
  responseSelector,
  validateEmail,
} from './../utils/utils';
import { Alert, alertTypes } from './../utils/Alert';
import Loader from '../utils/Loader';

const { Text } = Inputs;

function Login({ history, firebaseToken }) {
  const dispatch = useDispatch();
  const modelStandard = useSelector((state) => state.loginStandard);
  const modelSocial = useSelector((state) => state.loginSocial);
  const modelRegistro = useSelector((state) => state.registro);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const getNameFromFbResponse = (response) => {
    if (response.name) {
      return response.name;
    } else {
      let spaceAndLastName = '';

      if (response.last_name && response.last_name !== '') {
        spaceAndLastName = ' ' + response.last_name;
      }

      return response.first_name + spaceAndLastName;
    }
  };

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelStandard);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(controller.duck.actions.clear());
    }

    if (data) {
      localStorage.setItem('token', data.token);
      dispatch(controller.duck.actions.clear());

      // Usando window.location.href
      // porque si se llama a history.push
      // el sidebar no se refresca.

      const redirectTo = localStorage.getItem('redirectTo');

      if (redirectTo) {
        window.location.assign(redirectTo);
        localStorage.removeItem('redirectTo');
        return;
      }
      window.location.href = '/landing';
    }
  }, [modelStandard]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelSocial);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(controller.duckSocial.actions.clear());
    }

    if (data) {
      localStorage.setItem('token', data.data.token);
      dispatch(controller.duckSocial.actions.clear());
      // window.location.href = '/landing';
    }
  }, [modelSocial]);

  function login() {
    if (!validateEmail(username)) {
      setTopAlert({
        title: 'Error',
        text: 'Por favor proporcione un email con el formato correcto',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    let data = {
      username,
      password,
      type: 'standard',
      firebaseToken: firebaseToken.token,
    };

    clearLocalStorage();

    dispatch(controller.loginStandardThunk(data));
  }

  const submitOnEnter = (event) => {
    if (
      event.which === constants.ENTER_KEY_EVENT_CODE ||
      event.keyCode === constants.ENTER_KEY_EVENT_CODE
    ) {
      login();
    }
  };

  return (
    <Content title="Login" browserTitle="Login" show={true}>
      <Row>
        <Col md="4" mdOffset="4">
          <Box title="INICIAR SESIÓN">
            {topAlert.text && (
              <Alert
                title={topAlert.title}
                type={topAlert.type}
                text={topAlert.text}
                closable={topAlert.closable}
              />
            )}

            <Text
              type="email"
              labelPosition="above"
              size="sm"
              label="Email"
              onChange={(evt) => setUsername(evt.target.value)}
              onKeyDown={submitOnEnter}
            />
            <Text
              inputType="password"
              labelPosition="above"
              size="sm"
              label="Contraseña"
              onChange={(evt) => setPassword(evt.target.value)}
              onKeyDown={submitOnEnter}
            />

            <a
              style={{
                display: 'inline-block',
                marginBottom: 10,
                float: 'right',
              }}
              href="/recuperarPassword"
            >
              ¿Olvidaste tu contraseña?
            </a>

            <Button
              type="primary"
              size="block"
              disabled={modelStandard.loading || modelSocial.loading}
              text={
                <Loader
                  loaderColor="#ffffff"
                  isLoading={modelStandard.loading || modelSocial.loading}
                >
                  Iniciar sesión
                </Loader>
              }
              onClick={login}
            />

            <Button
              type="default"
              size="block"
              disabled={false}
              text={
                <Loader loaderColor="#ffffff" isLoading={false}>
                  Registrarme
                </Loader>
              }
              onClick={() => history.push('/registro')}
            />
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export default Login;
