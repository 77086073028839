/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
// import { NumericInput } from "../../utils/NumericInput";
import { monedasSoportadas, prefixByMoneda } from './index';
import { Inputs } from 'adminlte-2-react';
import CurrencyInput from 'react-currency-input-field';

import Etiqueta from '../../utils/etiquetaObligatorio/Etiqueta';

const { Text } = Inputs;

function MonedaInputV2({
  setValue,
  label,
  initialValue = null,
  textInputDisabled = false,
  required = false,
  moneda = monedasSoportadas.PYG,
  setMoneda,
  selectorMonedaDisabled = false,
  onKeyDown,
}) {
  // console.log('MonedaInputV2.render');

  if (!monedasSoportadas[moneda]) {
    throw new Error('Moneda no soportada');
  }

  function handleMonedaChange(newMoneda) {
    setMoneda(newMoneda);
    setValue('');
  }

  let allowDecimals = true;
  let decimalsLimit = 2;

  if (moneda === 'PYG') {
    allowDecimals = false;
    decimalsLimit = 0;
  }

  return (
    <div className="form-group">
      {label && (
        <label>
          <Etiqueta texto={label} obligatorio={required} />
        </label>
      )}
      <div className="input-group">
        <div className="input-group-btn">
          <button
            type="button"
            className={
              'btn btn-default dropdown-toggle' +
              (selectorMonedaDisabled ? ' disabled' : '')
            }
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {prefixByMoneda[moneda]} <span className="caret"></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  handleMonedaChange('PYG');
                }}
              >
                Guaraníes
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  handleMonedaChange('USD');
                }}
              >
                Dólares
              </a>
            </li>
          </ul>
        </div>
        <CurrencyInput
          id="input-example"
          name="input-name"
          className="form-control"
          placeholder=""
          initialValue={initialValue}
          value={initialValue}
          allowDecimals={allowDecimals}
          decimalsLimit={decimalsLimit}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => {
            if (value > Number.MAX_SAFE_INTEGER) {
              console.log('Bigger than safer integer!');
            }
            setValue(value);
          }}
          maxLength={15}
          disabled={textInputDisabled}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
}

export { MonedaInputV2 };
