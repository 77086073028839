import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { nivelIdiomaOptions } from '../utils/constants';

function ModalEditarCandIdioma({
  title = 'Agregar idioma',
  isModalShown,
  setIsModalShown,
  leeOption,
  setLeeOption,
  hablaOption,
  setHablaOption,
  escribeOption,
  setEscribeOption,
  submit,
  isSubmitLoading,
  isSubmitDisabled,
  idiomaOption,
  idiomasOptions,
  setIdiomaOption,
  idiomaSelectDisabled = false,
}) {
  return (
    <Modal
      show={isModalShown}
      onHide={() => setIsModalShown(false)}
      onShow={() => setIsModalShown(true)}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectInput
            label="Idioma"
            value={idiomaOption}
            options={idiomasOptions}
            setValue={setIdiomaOption}
            disabled={idiomaSelectDisabled}
          />
          <SelectInput
            label="Lee"
            value={leeOption}
            options={nivelIdiomaOptions}
            setValue={setLeeOption}
          />
          <SelectInput
            label="Escribe"
            value={escribeOption}
            options={nivelIdiomaOptions}
            setValue={setEscribeOption}
          />
          <SelectInput
            label="Habla"
            value={hablaOption}
            options={nivelIdiomaOptions}
            setValue={setHablaOption}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Guardar cambios"
          submit={submit}
          disabled={isSubmitDisabled}
          loading={isSubmitLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEditarCandIdioma };
