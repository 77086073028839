/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Content,
  Row,
  Col,
  Box,
  Button,
  Pagination,
  Inputs,
} from 'adminlte-2-react';

import Loader from '../utils/Loader';
import { Alert } from '../ui/alert';
import {
  constants,
  toTitleCase,
  openWhatsapp,
  procesarNota,
  getDateWithFormat,
} from '../utils/utils';
import { DateRangeInput } from '../ui/DateRangeInput';
import { TextInput } from '../utils/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { ModalNotas } from './ModalNotas';
import { CheckBox } from '../ui/Checkbox';

function Experiencia({ datos }) {
  return (
    <div style={{ marginBottom: 5 }}>
      <span style={{ display: 'block' }}>
        <strong>
          {datos.empresaDescripcion ? (
            toTitleCase(datos.empresaDescripcion)
          ) : (
            // <span className="text-muted">(Sin especificar)</span>
            <span className="text-muted">N/A</span>
          )}
        </strong>
      </span>
      <span style={{ display: 'block' }}>
        {toTitleCase(datos.cargoDescripcion)}
      </span>
    </div>
  );
}

function ContactoCandidatosView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  candidatos,
  modelCandidatos,
  isModalNotasShown,
  hideModalNotas,
  showModalNotas,
  notas,
  setCandidatoIdNumerico,
  handlePageNotas,
  totalResultsNotas,
  activePageNotas,
  pageSizeNotas,
  setTitulo,
  setContenido,
  submitNota,
  titulo,
  contenido,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,
  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,
  getCV,
  inicio,
  fin,
  setInicio,
  setFin,
  notaFavoritaSet,
  setearNotaFavorita,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1200;

  const submitSearch = () => {
    handlePage(0);
  };

  const goToNextPage = (page) => {
    handlePage(page);
  };

  return (
    <Content
      title="Contacto a candidatos"
      subTitle=""
      browserTitle="Contacto a candidatos"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <ModalNotas
        isModalShown={isModalNotasShown}
        hideModal={hideModalNotas}
        showModal={showModalNotas}
        notas={notas}
        history={history}
        activePage={activePageNotas}
        pageSize={pageSizeNotas}
        totalResults={totalResultsNotas}
        handlePage={handlePageNotas}
        submitNota={submitNota}
        contenido={contenido}
        setContenido={setContenido}
        titulo={titulo}
        setTitulo={setTitulo}
        notaSelected={notaSelected}
        setNotaSelected={setNotaSelected}
        userIdNumerico={userIdNumerico}
        deleteNota={deleteNota}
        cuentaFiltroChecked={cuentaFiltroChecked}
        setCuentaFiltroChecked={setCuentaFiltroChecked}
        entrevistaCortesiaChecked={entrevistaCortesiaChecked}
        setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
        resetNotaInputs={resetNotaInputs}
        notaFavoritaSet={notaFavoritaSet}
        setearNotaFavorita={setearNotaFavorita}
      />

      <Row>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <div className="form-group">
              <label>Fecha de ultimo contacto</label>

              <DateRangeInput
                showLabels
                labelStyle={{ fontWeight: 'normal' }}
                inicio={inicio}
                setInicio={setInicio}
                fin={fin}
                setFin={setFin}
                xs={12}
                md={12}
              />
            </div>

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>

        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            customOptions={
              <div>
                <Button
                  size="sm"
                  type="default"
                  text="Agregar"
                  onClick={() => history.push('/crearCandidato')}
                />
              </div>
            }
          >
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              </div>
            </div>

            <Loader isLoading={modelCandidatos.loading}>
              {candidatos && candidatos.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={candidatos}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column
                        field="nombreCompleto"
                        header="Nombre"
                        body={(candidato) =>
                          toTitleCase(candidato.nombreCompleto)
                        }
                      ></Column>
                      <Column
                        field="curriculum"
                        header="Curriculum"
                        body={(candidato) => {
                          return (
                            <Button
                              type="default"
                              text="Ver CV"
                              size="sm"
                              onClick={() => getCV(candidato.idNumerico)}
                            />
                          );
                        }}
                      ></Column>
                      <Column
                        header="Acciones"
                        body={(candidato) => (
                          <ButtonWithLoading
                            text="Editar"
                            size="sm"
                            type="default"
                            submit={() =>
                              history.push(
                                `/editarCandidato/${candidato.idNumerico}`
                              )
                            }
                          />
                        )}
                      ></Column>

                      <Column
                        field="telefono2"
                        header="Telefono"
                        body={(candidato) => {
                          return (
                            <a
                              href="javascript:;"
                              onClick={() =>
                                openWhatsapp({
                                  telefono: candidato.telefono2,
                                  mensaje: `Hola ${toTitleCase(
                                    candidato.nombreCompleto
                                  )}`,
                                })
                              }
                            >
                              {candidato.telefono2}
                            </a>
                          );
                        }}
                      ></Column>
                      <Column
                        field="experiencias"
                        header="Experiencia"
                        body={(candidato) =>
                          candidato?.experiencias?.length > 0
                            ? candidato.experiencias
                                .slice(0, 2)
                                .map((exp, index) => {
                                  return <Experiencia datos={exp} />;
                                })
                            : 'N/A'
                        }
                        style={{ minWidth: 90 }}
                      ></Column>
                      <Column
                        field="ultimoContacto"
                        header="Ultimo contacto"
                        body={(candidato) =>
                          candidato.ultimoContacto
                            ? getDateWithFormat(candidato.ultimoContacto)
                            : 'N/A'
                        }
                      ></Column>
                    </DataTable>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado candidatos</h4>
              )}
            </Loader>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={goToNextPage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div>
              <span>Total: {totalResults}</span>
            </div>

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ContactoCandidatosView };
