import React from 'react';
import { Content, Row, Col, Box } from 'adminlte-2-react';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert } from '../utils/Alert';
import { log } from '../utils/logs';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import Loader from '../utils/Loader';
const { TextInput } = require('../utils/TextInput');

function CrearInstitutoView({
  mode,
  history,
  match,
  modelCreateInstituto,
  submit,
  submitAlert,
  topAlert,
  nombre,
  setNombre,
  direccion,
  setDireccion,
  telefono,
  setTelefono,
  contactoNombre,
  setContactoNombre,
  contactoTelefono,
  setContactoTelefono,
  modelGetInstituto,
}) {
  const title = mode === 'edit' ? 'Editar institución' : 'Crear institución';

  return (
    <Content title={title} browserTitle={title} show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {/* nombre
      direccion
      telefono
      contactoNombre
      contactoTelefono */}

      <Loader isLoading={mode === 'edit' && modelGetInstituto.loading}>
        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Datos">
              <ReferenciaObligatorios />
              <TextInput
                label="Nombre"
                initialValue={nombre}
                setValue={setNombre}
                required
              />
              <TextInput
                label="Dirección"
                initialValue={direccion}
                setValue={setDireccion}
              />
              <TextInput
                label="Teléfono"
                initialValue={telefono}
                setValue={setTelefono}
              />
              <TextInput
                label="Nombre de contacto"
                initialValue={contactoNombre}
                setValue={setContactoNombre}
              />
              <TextInput
                label="Teléfono de contacto"
                initialValue={contactoTelefono}
                setValue={setContactoTelefono}
              />
              <ButtonWithLoading
                text="Guardar"
                submit={submit}
                pullRight
                loading={modelCreateInstituto.loading}
              />
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { CrearInstitutoView };
