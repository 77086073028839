import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getDatosAuxiliaresDuck,
  getDatosAuxiliaresThunk,
  createDatoAuxiliarDuck,
  createDatoAuxiliarThunk,
  editDatoAuxiliarDuck,
  editDatoAuxiliarThunk,
  deleteDatoAuxiliarThunk,
  deleteDatoAuxiliarDuck,
} from './duck';

import { constants, responseSelector } from '../utils/utils';

import { ListaDatosAuxiliaresView } from './ListaDatosAuxiliaresView';
import { datosAuxiliaresOptions } from '../utils/constants';

const queryString = require('query-string');

function ListaDatosAuxiliares({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetDatosAuxiliares = useSelector(
    (state) => state.getDatosAuxiliares
  );
  const modelCreateDatoAuxiliar = useSelector(
    (state) => state.createDatoAuxiliar
  );
  const modelEditDatoAuxiliar = useSelector((state) => state.editDatoAuxiliar);
  const modelDeleteDatoAuxiliar = useSelector(
    (state) => state.deleteDatoAuxiliar
  );

  const [lista, setLista] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [concepto, setConcepto] = useState({ value: 'cargo', label: 'Cargo' });
  const [isModalCreateDatoAuxiliarShown, setIsModalCreateDatoAuxiliarShown] =
    useState(false);
  const [valor, setValor] = useState('');
  const [formConcepto, setFormConcepto] = useState();
  const [selectedDatoAuxiliar, setSelectedDatoAuxiliar] = useState();
  const [isModalEditDatoAuxiliarShown, setIsModalEditDatoAuxiliarShown] =
    useState(false);
  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] =
    useState(false);

  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );
  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetDatosAuxiliares);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getDatosAuxiliaresDuck.actions.clear());
    }

    if (data) {
      setTotalResults(data.total);
      setLista(data.items);

      dispatch(getDatosAuxiliaresDuck.actions.clear());
    }
  }, [modelGetDatosAuxiliares]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCreateDatoAuxiliar);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(createDatoAuxiliarDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar creado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      setIsModalCreateDatoAuxiliarShown(false);
      setSelectedDatoAuxiliar(null);
      dispatch(createDatoAuxiliarDuck.actions.clear());
    }
  }, [modelCreateDatoAuxiliar]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteDatoAuxiliar);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(deleteDatoAuxiliarDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar borrado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      handlePage(0);
      setIsDeleteConfirmModalShown(false);
      setSelectedDatoAuxiliar(null);
      dispatch(deleteDatoAuxiliarDuck.actions.clear());
    }
  }, [modelDeleteDatoAuxiliar]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditDatoAuxiliar);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editDatoAuxiliarDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        text: 'Dato auxiliar editado exitosamente',
        type: alertTypes.success,
        closable: false,
      });
      handlePage(0);
      setIsModalEditDatoAuxiliarShown(false);
      dispatch(editDatoAuxiliarDuck.actions.clear());
    }
  }, [modelEditDatoAuxiliar]);

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    setLista([]);

    const params = {
      page: page + 1,
      limit: pageSize,
      etiqueta: concepto?.value,
      busquedaRapida,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getDatosAuxiliaresThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function createDatoAuxiliar() {
    dispatch(
      createDatoAuxiliarThunk({
        userToken: token.raw,
        etiqueta: formConcepto?.value,
        descripcion: valor,
      })
    );
  }

  function editDatoAuxiliar() {
    dispatch(
      editDatoAuxiliarThunk({
        userToken: token.raw,
        idNumerico: selectedDatoAuxiliar.idNumerico,
        etiqueta: formConcepto?.value,
        descripcion: valor,
      })
    );
  }

  function prepareEditDatoAuxiliar(datoAuxiliar) {
    setValor(datoAuxiliar.descripcion);
    setFormConcepto(
      datosAuxiliaresOptions.find((opt) => opt.value === datoAuxiliar.etiqueta)
    );
    setSelectedDatoAuxiliar(datoAuxiliar);
    setIsModalEditDatoAuxiliarShown(true);
  }

  function deleteDatoAuxiliar() {
    dispatch(
      deleteDatoAuxiliarThunk({
        userToken: token.raw,
        idNumerico: selectedDatoAuxiliar.idNumerico,
        etiqueta: selectedDatoAuxiliar.etiqueta,
      })
    );
  }

  return (
    <ListaDatosAuxiliaresView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      lista={lista}
      concepto={concepto}
      setConcepto={setConcepto}
      modelGetDatosAuxiliares={modelGetDatosAuxiliares}
      isModalCreateDatoAuxiliarShown={isModalCreateDatoAuxiliarShown}
      setIsModalCreateDatoAuxiliarShown={setIsModalCreateDatoAuxiliarShown}
      modelCreateDatoAuxiliar={modelCreateDatoAuxiliar}
      createDatoAuxiliar={createDatoAuxiliar}
      valor={valor}
      setValor={setValor}
      formConcepto={formConcepto}
      setFormConcepto={setFormConcepto}
      selectedDatoAuxiliar={selectedDatoAuxiliar}
      setSelectedDatoAuxiliar={setSelectedDatoAuxiliar}
      isModalEditDatoAuxiliarShown={isModalEditDatoAuxiliarShown}
      setIsModalEditDatoAuxiliarShown={setIsModalEditDatoAuxiliarShown}
      modelEditDatoAuxiliar={modelEditDatoAuxiliar}
      editDatoAuxiliar={editDatoAuxiliar}
      prepareEditDatoAuxiliar={prepareEditDatoAuxiliar}
      isDeleteConfirmModalShown={isDeleteConfirmModalShown}
      setIsDeleteConfirmModalShown={setIsDeleteConfirmModalShown}
      modelDeleteDatoAuxiliar={modelDeleteDatoAuxiliar}
      deleteDatoAuxiliar={deleteDatoAuxiliar}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
    />
  );
}

export { ListaDatosAuxiliares };
