import React from 'react';
import { Modal } from 'react-bootstrap';
import { SelectInput } from '../utils/SelectInput';
import { oportunidadEstadoDescById } from '../utils/constants';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { toTitleCase } from '../utils/utils';
import Loader from '../utils/Loader';

function ModalAgregarAOportunidad({
  isModalAgregarShown,
  hideModalAgregar,
  showModalAgregar,
  incluirEnOportunidad,
  isIncluirButtonDisabled,
  isIncluirButtonLoading,
  etapaOption,
  etapasOptions,
  setEtapaOption,
  oportunidad,
  selectEtapaDisabled,
}) {
  return (
    <Modal
      show={isModalAgregarShown}
      style={{ opacity: 1, paddingTop: 200 }}
      onHide={hideModalAgregar}
      onShow={showModalAgregar}
    >
      <Modal.Header>
        <Modal.Title>Incluir en oportunidad</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {oportunidad ? (
          <>
            <div style={{ marginBottom: 10 }}>
              Título: {toTitleCase(oportunidad.titulo)}
              <br />
              Empresa: {toTitleCase(oportunidad.empresaDescripcion)}
              <br />
              Estado: {oportunidadEstadoDescById?.[oportunidad.estado] ?? 'N/A'}
              <br />
              Cargo: {toTitleCase(oportunidad.cargoDescripcion)}
            </div>
            <div>
              <SelectInput
                label="Etapa"
                value={etapaOption}
                options={etapasOptions}
                setValue={setEtapaOption}
                disabled={selectEtapaDisabled}
              />
            </div>
          </>
        ) : (
          <Loader isLoading={true} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Incluir"
          submit={incluirEnOportunidad}
          disabled={isIncluirButtonDisabled}
          loading={isIncluirButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalAgregarAOportunidad };
