/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { Row, Col, Box, Button } from "adminlte-2-react";
import Loader from "../../utils/Loader";

import { Alert } from "../../utils/Alert";

import { ButtonWithLoading } from "../../utils/ButtonWithLoading";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getDateOrEmpty, toTitleCase } from "../../utils/utils";


const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.candidatoIdNumerico === prevProps.candidatoIdNumerico &&
    nextProps.archivoSubmitAlert === prevProps.archivoSubmitAlert &&
        nextProps.modelGetCandArchivos.loading ===
      prevProps.modelGetCandArchivos.loading &&
      nextProps.candArchivos === prevProps.candArchivos
  );
};

const Archivos = memo(function Componente({
  setModalRedirect,
  candidatoIdNumerico,
  archivoSubmitAlert,
  modelGetCandArchivos,
  candArchivos,
  setSelectedArchivo,
  setIsModalEliminarCandArchivoShown,
}) {

  return (
         <Row>
          <Col md="10" mdOffset="1">
            <Box
              title="Archivos"
              customOptions={
                <div>
                  <Button
                    size="sm"
                    type="default"
                    text="Ir a agregar"
                    onClick={() =>
                      setModalRedirect(
                        `/candidato/archivo/${candidatoIdNumerico}`
                      )
                    }
                  />
                </div>
              }
            >
              {archivoSubmitAlert.text && (
                <Alert
                  title={archivoSubmitAlert.title}
                  type={archivoSubmitAlert.type}
                  text={archivoSubmitAlert.text}
                  closable={archivoSubmitAlert.closable}
                />
              )}

              <Loader isLoading={modelGetCandArchivos.loading}>
                <div style={{ display: 'block' }}>
                  <span>Total: {candArchivos?.length}</span>
                </div>

                {candArchivos && candArchivos.length > 0 ? (
                  <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                    <div className="table-responsive">
                      <DataTable
                        lazy
                        pt={{
                          table: {
                            className: 'table table-hover',
                          },
                        }}
                        value={candArchivos}
                        emptyMessage="No se encontró archivos"
                      >
                        <Column
                          field="acciones"
                          header="Acciones"
                          body={(archivo) => (
                            <div style={{ width: 130 }}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginRight: 5,
                                }}
                              >
                                <ButtonWithLoading
                                  size="sm"
                                  type="warning"
                                  text="Eliminar"
                                  submit={() => {
                                    setSelectedArchivo(archivo);
                                    setIsModalEliminarCandArchivoShown(true);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        ></Column>
                        <Column
                          field="descripcion"
                          header="Descripción"
                          body={(archivo) => toTitleCase(archivo.descripcion)}
                        ></Column>
                        <Column
                          field="fechaSubida"
                          header="Fecha"
                          body={(archivo) =>
                            getDateOrEmpty(archivo.fechaSubida)
                          }
                        ></Column>
                        <Column
                          field="url"
                          header="Archivo"
                          body={(archivo) => (
                            <a
                              href={archivo.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {archivo.key.split('/').pop()}
                            </a>
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  </Box>
                ) : (
                  <h4>No se han encontrado archivos</h4>
                )}
              </Loader>
            </Box>
          </Col>
        </Row> 
  );
},
customComparator);

export { Archivos };
