import React from "react";
import { Button, Content, Box, Row, Col } from "adminlte-2-react";
import queryString from "query-string";

import { logger } from "../utils/utils";
import UploaderWrapper from "./UploaderWrapper";

export default function SubirImagenes(props) {
  const params = queryString.parse(props.history.location.search);

  logger.debug("SubirImagenes.parsedQueryString", params);

  const {
    maxFileCount,
    maxFileSize,
    minWidth,
    minHeight,
    uploadUrl,
    rawUploadHeaders,
    nextStepUrl,
    pageTitle,
    pageSubtitle,
    nextButtonText,
    saveInConceptUrl,
    cropConfig,
    concepto,
    conceptoId,
    lastStepUrl,
    cropUploadUrl,
    relatedImageId,
    cancelUrl,
  } = params;

  logger.debug("SubirImagenes.params");
  logger.debug({ params });

  const uploadHeaders = JSON.parse(rawUploadHeaders);

  const navigateToNextStep = () => {
    const nextUrlParams = queryString.stringify({
      saveInConceptUrl,
      conceptoId,
      concepto,
      cropConfig,
      lastStepUrl,
      rawUploadHeaders,
      cropUploadUrl,
      relatedImageId,
      cancelUrl,
    });

    logger.debug("SubirImagenes.nextUrlParams");
    logger.debug(nextUrlParams);

    props.history.push(`${nextStepUrl}?${nextUrlParams}`);
  };

  const onUploadError = (responseText, response) => {
    let parsedResponse = JSON.parse(responseText);
    logger.debug("onUploadError.parsedResponse", parsedResponse);
    if (parsedResponse && parsedResponse.message) {
      alert(parsedResponse.message);
    }
  };

  const messageStyle = { backgroundColor: "orange", padding: 10, fontSize: 18 };

  return (
    <Content title={pageTitle} browserTitle={pageTitle} show={false}>
      <Row>
        <Col
          sm="12"
          md="8"
          mdOffset="2"
          style={{ maxWidth: 750, float: "none", margin: "0 auto" }}
        >
          <Box
            title={pageSubtitle}
            footer={
              <>
            
                  <Button
                    type="default"
                    text="Cancelar"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  />
            
                <Button
                  type="default"
                  text={nextButtonText}
                  onClick={navigateToNextStep}
                  pullRight={true}
                />
              </>
            }
          >
            <div className="SubidaImagen">
              <div style={messageStyle}>
                <p>Tamaño máximo de imagen: {maxFileSize} MB</p>
                <p>Ancho mínimo: {minWidth} px</p>
                <p>Alto mínimo: {minHeight} px</p>
              </div>
              <UploaderWrapper
                maxFileCount={maxFileCount}
                maxFileSize={maxFileSize}
                minWidth={minWidth}
                minHeight={minHeight}
                uploadUrl={uploadUrl}
                uploadHeaders={uploadHeaders}
                onUploadError={onUploadError}
              />
            </div>
          </Box>
        </Col>
      </Row>
    </Content>
  );
}
