import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseVapidKey =
  'BFNxnC0DG-GIyBs1GGRau0hm3DU9W-zVYo8Acw9NFkVYDnUtYyg_Ob4cgao5CFNAV-8uu4xyXlhorzBU8D3T8Us';

const firebaseConfig = {
  apiKey: 'AIzaSyDqzaQhJRdHtZ1F1fROLRPEyfnHZPiVJcc',
  authDomain: 'tom-facturacion-ee87d.firebaseapp.com',
  projectId: 'tom-facturacion-ee87d',
  storageBucket: 'tom-facturacion-ee87d.appspot.com',
  messagingSenderId: '87088653731',
  appId: '1:87088653731:web:ffcd261dab368510006616',
  measurementId: 'G-8DN7D922V7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { app, messaging, getToken, onMessage, firebaseVapidKey };
