import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function ModalGenerico({
  isModalShown,
  hideModal,
  title,
  body,
  buttonText,
  buttonType = 'primary',
  buttonDisabled = false,
  buttonLoading,
  submit,
}) {
  return (
    <Modal show={isModalShown} style={{ opacity: 1 }} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type={buttonType}
          text={buttonText}
          disabled={buttonDisabled}
          loading={buttonLoading}
          submit={submit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalGenerico };
