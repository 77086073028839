import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import axios from 'axios';
import config from '../../config';
import { Alert, alertTypes } from '../../utils/Alert';
import { useAlerts } from '../../ui/alert';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

const baseUrl = config.api.baseUrl;
const key = config.api.key;

function SubirOportRelevamiento({ history, match, token }) {
  const oportunidadId = match.params.oportunidadId;

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const [selectedFile, setSelectedFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelection = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const processUpload = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('files[]', selectedFile);
      formData.append('userToken', token.raw);
      formData.append('oportunidadIdNumerico', oportunidadId);
      const uploadHeaders = {
        'x-access-token': key,
      };
      const config = { headers: uploadHeaders };
      const url = `${baseUrl}/v1/oportunidad/relevamiento`;
      const result = await axios.post(url, formData, config);
      if (result.status === 200) {
        setTopAlert({
          text: 'Formulario de relevamiento subido correctamente',
          type: alertTypes.success,
          closable: false,
        });
      }
    } catch (e) {
      setTopAlert({
        text: 'Error al subir archivo',
        type: alertTypes.danger,
        closable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Content title="Relevamiento" browserTitle="Relevamiento" show={false}>
      <Col md="4" mdOffset="4">
        <Box
          title="Formulario de relevamiento a subir"
          footer={
            <ButtonWithLoading
              type="primary"
              text="Subir"
              pullRight={true}
              disabled={!selectedFile}
              submit={processUpload}
              loading={isLoading}
            />
          }
        >
          {topAlert.text && (
            <Alert
              title={topAlert.title}
              type={topAlert.type}
              text={topAlert.text}
              closable={topAlert.closable}
            />
          )}

          <Col xs="12" md="12">
            <div>
              <input type="file" name="file" onChange={handleSelection} />

              {selectedFile ? (
                <div>
                  {/* <p>Nombre: {selectedFile.name}</p> */}
                  {/* <p>Tipo: {selectedFile.type}</p> */}
                  {/* <p>Tamaño en bytes: {selectedFile.size}</p> */}
                </div>
              ) : (
                <p className="text-muted">Seleccione un archivo</p>
              )}
            </div>
          </Col>
        </Box>
      </Col>
    </Content>
  );
}

export { SubirOportRelevamiento };
