import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const searchEmpresasByNameDuck = createDuck(
  'SEARCH_EMPRESAS_BY_NAME',
  newActions,
  newReducers
);

const searchEmpresasByNameThunk = (params) => {
  const url = `${baseUrl}/v1/empresas/searchByName`;

  return createThunk(
    axios,
    'get',
    searchEmpresasByNameDuck.actions,
    url,
    null,
    params
  );
};

const getEmpresasWithPaginationDuck = createDuck(
  'GET_EMPRESAS_WITH_PAGINATION',
  newActions,
  newReducers
);

const getEmpresasWithPaginationThunk = (params) => {
  const url = `${baseUrl}/v1/empresasWithPagination`;

  return createThunk(
    axios,
    'get',
    getEmpresasWithPaginationDuck.actions,
    url,
    null,
    params
  );
};

const createEmpresaDuck = createDuck('CREATE_EMPRESA', newActions, newReducers);

const createEmpresaThunk = (params) => {
  const url = `${baseUrl}/v1/empresa`;

  return createThunk(axios, 'post', createEmpresaDuck.actions, url, params);
};

const editEmpresaDuck = createDuck('EDIT_EMPRESA', newActions, newReducers);

const editEmpresaThunk = (params) => {
  const url = `${baseUrl}/v1/empresa`;

  return createThunk(axios, 'patch', editEmpresaDuck.actions, url, params);
};

const deleteEmpresaDuck = createDuck('DELETE_EMPRESA', newActions, newReducers);

const deleteEmpresaThunk = (params) => {
  const url = `${baseUrl}/v1/empresa`;

  return createThunk(axios, 'delete', deleteEmpresaDuck.actions, url, params);
};

export {
  getEmpresasWithPaginationDuck,
  getEmpresasWithPaginationThunk,
  createEmpresaDuck,
  createEmpresaThunk,
  editEmpresaDuck,
  editEmpresaThunk,
  deleteEmpresaDuck,
  deleteEmpresaThunk,
  searchEmpresasByNameDuck,
  searchEmpresasByNameThunk,
};
