import React, { PropTypes } from 'react';
import Etiqueta from './etiquetaObligatorio/Etiqueta';
import './../index.css';

import Select, { createFilter, components } from 'react-select';

const CustomOption = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

function SelectInput({
  placeholder = 'Seleccione...',
  value,
  setValue, // funcion que recibe el {value: '...', label: '...'} seleccionado
  label,
  options,
  disabled = false,
  required = false,
  isLoading = false,
  hint = null,
  isMulti = false,
  maxMultiSelection,
}) {
  // console.log('SelectInput.render');

  return (
    <div className="form-group">
      {label && (
        <label>
          <Etiqueta texto={label} obligatorio={required} />
          {hint && (
            <>
              <br />
              <span
                style={{ fontSize: 12, fontWeight: 'normal' }}
                className="text-muted"
              >
                {hint}
              </span>
            </>
          )}
        </label>
      )}
      <Select
        placeholder={placeholder}
        styles={{
          // Without this, the dropdown list renders below other components
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        onChange={setValue}
        value={value}
        options={options}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isOptionDisabled={() =>
          Boolean(
            isMulti &&
              maxMultiSelection !== undefined &&
              maxMultiSelection !== null &&
              value?.length >= maxMultiSelection
          )
        }
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ Option: CustomOption }}
        classNamePrefix="custom-select"
      />
    </div>
  );
}

export { SelectInput };
