import React, { useEffect, useState } from 'react';
import AdminLTE, {
  Sidebar,
  Content,
  Row,
  Col,
  Box,
  Button,
  Inputs,
  Label,
} from 'adminlte-2-react';
import _ from 'lodash';
import { confirmarRegistroDuck, confirmarRegistroThunk } from './duck';
import { useSelector, useDispatch } from 'react-redux';
import { parseJwt, responseSelector } from './../utils/utils';
import { Alert, alertTypes } from '../utils/Alert';

const { Select2, Date, DateTime, Text } = Inputs;

function Confirmacion({ history, match }) {
  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const submitAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [submitAlert, setSubmitAlert] = useState(submitAlertDefaultValue);

  const dispatch = useDispatch();
  const modelRegistro = useSelector((state) => state.confirmarRegistro);
  const [invitacionCodigo, setInvitacionCodigo] = useState(null);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelRegistro);

    if (error) {
      setSubmitAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(confirmarRegistroDuck.actions.clear());
    }
    if (data) {
      setTopAlert({
        title: 'Éxito',
        text: 'Confirmación exitosa, ahora podés ingresar',
        type: alertTypes.success,
        closable: false,
      });

      history.push('/login');
      dispatch(confirmarRegistroDuck.actions.clear());
    }
  }, [modelRegistro]);

  const registrar = () => {
    if (!invitacionCodigo) {
      setSubmitAlert({
        title: 'Error',
        text: 'Por favor, escribí el código que te enviamos (también podés usar el enlace que te enviamos)',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    let data = {
      invitacionId: match?.params?.invitacionId,
      invitacionCodigo,
    };

    dispatch(confirmarRegistroThunk(data));
  };

  return (
    <Content title="Confirmación" browserTitle="Confirmación" show={false}>
      <Row>
        <Col md="4" mdOffset="4">
          <Box>
            <Row style={{ marginBottom: 20 }}>
              <Col md="12">
                Te enviamos un mensaje para confirmar tu registro. Podés
                escribir aquí el código que te enviamos.
              </Col>
            </Row>
            {topAlert.text && (
              <Alert
                title={topAlert.title}
                type={topAlert.type}
                text={topAlert.text}
                closable={topAlert.closable}
              />
            )}
            <Text
              labelPosition="above"
              size="sm"
              label="Código"
              onChange={(evt) => setInvitacionCodigo(evt.target.value)}
            />

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}

            <Button
              type="primary"
              text="Continuar"
              pullRight={true}
              onClick={() => registrar()}
            />
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export default Confirmacion;
