/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';
import { Alert } from '../../utils/Alert';
import { log } from '../../utils/logs';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDateOrEmpty, toTitleCase } from '../../utils/utils';
import { nivelFormacionDescById } from '../../utils/constants';
import { ModalEditarCandForm } from './ModalEditarCandForm';
import { ModalGenerico } from '../../ui/ModalGenerico';
import { ModalEditarExperiencia } from './ModalEditarExperiencia';

const queryString = require('query-string');

const redireccionarAInstitutos = ({
  candFormIdNumerico,
  institutoOtro,
  candidatoId,
  history,
}) => {
  const mQueryParams = queryString.stringify({
    seleccion: 's',
    seleccionMensaje: `Seleccionando instituto correspondiente a "${institutoOtro}"`,
    seleccionRetorno: `/actualizarCandidato/${candidatoId}?candFormIdNumerico=${candFormIdNumerico}`,
  });
  history.push(`/institutos/?${mQueryParams}`);
};

const redireccionarAEmpresas = ({
  empresaOtro,
  candExpIdNumerico,
  candidatoId,
  history,
}) => {
  const mQueryParams = queryString.stringify({
    seleccion: 's',
    seleccionMensaje: `Seleccionando empresa correspondiente a "${empresaOtro}"`,
    seleccionRetorno: `/actualizarCandidato/${candidatoId}?candExpIdNumerico=${candExpIdNumerico}`,
  });
  history.push(`/empresas/?${mQueryParams}`);
};

const getInstituto = ({ formacion, history, candidatoId }) => {
  return (
    <div style={{ color: 'red' }}>{toTitleCase(formacion.institutoOtro)}</div>
  );
};

function NormalizarCandidatoView({
  history,
  match,
  topAlert,
  setIsModalAgregarFormacionShown,
  formacionTopAlert,
  modelGetCandidatoFormaciones,
  formaciones,
  isModalActualizarShown,
  setIsModalActualizarShown,
  candidatoId,
  isModalEditarCandFormShown,
  setIsModalEditarCandFormShown,
  institutoDescripcion,
  titulacionOption,
  titulacionesOptions,
  setTitulacionOption,
  editarCandForm,
  modelEditarCandForm,
  prepareEditModalAndShow,
  institutoOtro,
  titulacionOtro,
  candFormIdNumerico,
  selectedFormacion,
  setSelectedFormacion,
  isDiscardCandFormModalShown,
  setIsDiscardCandFormModalShown,
  descartarCambiosCandForm,
  experiencias,
  selectedExperiencia,
  modelGetCandidatoExperiencias,
  experienciaTopAlert,
  setSelectedExperiencia,
  prepareExpEditAndShowModal,
  isModalEditarExpShown,
  setIsEditarExpModalShown,
  editarExperiencia,
  modelEditarCandidatoExp,
  empresaOtro,
  empresaDescripcion,
  candExpIdNumerico,
  cargoOption,
  cargosOptions,
  setCargoOption,
  cargoOtro,
  descartarCambiosCandExp,
  isDiscardCandExpModalShown,
  setIsDiscardCandExpModalShown,
}) {
  return (
    <Content
      title="Actualizar candidato"
      browserTitle="Actualizar candidato"
      show={false}
    >
      <ModalEditarCandForm
        isModalEditarCandFormShown={
          !!(isModalEditarCandFormShown && candFormIdNumerico)
        }
        setIsModalEditarCandFormShown={setIsModalEditarCandFormShown}
        institutoDescripcion={institutoDescripcion}
        titulacionOption={titulacionOption}
        titulacionesOptions={titulacionesOptions}
        setTitulacionOption={setTitulacionOption}
        editarCandForm={editarCandForm}
        isEditarCandFormButtonLoading={modelEditarCandForm.loading}
        institutoOtro={institutoOtro}
        titulacionOtro={titulacionOtro}
        irASeleccionar={() =>
          redireccionarAInstitutos({
            institutoOtro: institutoOtro,
            candidatoId,
            history,
            candFormIdNumerico,
          })
        }
      />

      <ModalEditarExperiencia
        isModalEditarExpShown={!!(isModalEditarExpShown && selectedExperiencia)}
        hideModalEditarExp={() => setIsEditarExpModalShown(false)}
        showModalEditarExp={() => setIsEditarExpModalShown(true)}
        editarExperiencia={editarExperiencia}
        isEditarExpButtonDisabled={modelEditarCandidatoExp.loading}
        isEditarExpButtonLoading={modelEditarCandidatoExp.loading}
        empresaOtro={empresaOtro}
        empresaDescripcion={empresaDescripcion}
        irASeleccionar={() =>
          redireccionarAEmpresas({
            empresaOtro,
            candidatoId,
            history,
            candExpIdNumerico,
          })
        }
        cargoOption={cargoOption}
        cargosOptions={cargosOptions}
        setCargoOption={setCargoOption}
        cargoOtro={cargoOtro}
      />

      {selectedFormacion && (
        <ModalGenerico
          isModalShown={isDiscardCandFormModalShown}
          hideModal={() => setIsDiscardCandFormModalShown(false)}
          title="Descartar cambios"
          body="Se descartarán los cambios de formación"
          buttonText="Descartar"
          buttonLoading={modelEditarCandForm.loading}
          buttonType="danger"
          buttonDisabled={modelEditarCandForm.loading}
          submit={() => {
            descartarCambiosCandForm();
          }}
        />
      )}

      {selectedExperiencia && (
        <ModalGenerico
          isModalShown={isDiscardCandExpModalShown}
          hideModal={() => setIsDiscardCandExpModalShown(false)}
          title="Descartar cambios"
          body="Se descartarán los cambios de formación"
          buttonText="Descartar"
          buttonLoading={modelEditarCandidatoExp.loading}
          buttonType="danger"
          buttonDisabled={modelEditarCandidatoExp.loading}
          submit={() => {
            descartarCambiosCandExp();
          }}
        />
      )}

      <Loader isLoading={modelGetCandidatoFormaciones.loading}>
        {topAlert.text && (
          <Alert
            title={topAlert.title}
            type={topAlert.type}
            text={topAlert.text}
            closable={topAlert.closable}
          />
        )}

        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Formación">
              {formacionTopAlert.text && (
                <Alert
                  title={formacionTopAlert.title}
                  type={formacionTopAlert.type}
                  text={formacionTopAlert.text}
                  closable={formacionTopAlert.closable}
                />
              )}

              <Loader isLoading={modelGetCandidatoFormaciones.loading}>
                <div style={{ display: 'block' }}>
                  <span>Total: {formaciones?.length}</span>
                </div>

                {formaciones && formaciones.length > 0 ? (
                  <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                    <div className="table-responsive">
                      <DataTable
                        lazy
                        pt={{
                          table: {
                            className: 'table table-hover',
                          },
                        }}
                        value={formaciones}
                        emptyMessage="No se encontró registros de formación"
                      >
                        <Column
                          field="acciones"
                          header="Acciones"
                          body={(formacion) => (
                            <div style={{ width: 200 }}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginRight: 5,
                                }}
                              >
                                <ButtonWithLoading
                                  size="sm"
                                  type="default"
                                  text="Actualizar"
                                  submit={() =>
                                    prepareEditModalAndShow(formacion)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                <ButtonWithLoading
                                  size="sm"
                                  type="warning"
                                  text="Descartar cambios"
                                  submit={() => {
                                    setSelectedFormacion(formacion);
                                    setIsDiscardCandFormModalShown(true);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        ></Column>

                        <Column
                          header="Institución ingres. por candidato"
                          body={(formacion) =>
                            getInstituto({
                              formacion,
                              history,
                              candidatoId,
                            })
                          }
                        ></Column>

                        <Column
                          header="Institución"
                          body={(formacion) =>
                            toTitleCase(formacion.institutoDescripcion)
                          }
                        ></Column>

                        <Column
                          field="titulacionOtro"
                          header="Titulación ingres. por candidato"
                          body={(formacion) => (
                            <div style={{ color: 'red' }}>
                              {toTitleCase(formacion.titulacionOtro)}
                            </div>
                          )}
                        ></Column>

                        <Column
                          field="titulacionDescripcion"
                          header="Titulación"
                          body={(formacion) =>
                            toTitleCase(formacion.titulacionDescripcion)
                          }
                        ></Column>
                      </DataTable>
                    </div>
                  </Box>
                ) : (
                  <h4>No hay actualizaciones pendientes</h4>
                )}
              </Loader>
            </Box>
          </Col>
        </Row>
      </Loader>

      <Loader isLoading={modelGetCandidatoExperiencias.loading}>
        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Experiencias">
              {experienciaTopAlert.text && (
                <Alert
                  title={experienciaTopAlert.title}
                  type={experienciaTopAlert.type}
                  text={experienciaTopAlert.text}
                  closable={experienciaTopAlert.closable}
                />
              )}

              <Loader isLoading={modelGetCandidatoExperiencias.loading}>
                <div style={{ display: 'block' }}>
                  <span>Total: {experiencias.length}</span>
                </div>

                {experiencias && experiencias.length > 0 ? (
                  <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                    <div className="table-responsive">
                      <DataTable
                        lazy
                        pt={{
                          table: {
                            className: 'table table-hover',
                          },
                        }}
                        value={experiencias}
                        emptyMessage="No hay actualizaciones pendientes"
                      >
                        <Column field="idNumerico" header="ID"></Column>
                        <Column
                          field="acciones"
                          header="Acciones"
                          body={(experiencia) => (
                            <div style={{ width: 200 }}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginRight: 5,
                                }}
                              >
                                <ButtonWithLoading
                                  size="sm"
                                  type="default"
                                  text="Actualizar"
                                  submit={() => {
                                    prepareExpEditAndShowModal(experiencia);
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                <ButtonWithLoading
                                  size="sm"
                                  type="warning"
                                  text="Descartar cambios"
                                  submit={() => {
                                    setSelectedExperiencia(experiencia);
                                    setIsDiscardCandExpModalShown(true);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        ></Column>
                        <Column
                          header="Empresa ingres. por candidato"
                          body={(experiencia) => (
                            <div style={{ color: 'red' }}>
                              {toTitleCase(experiencia.empresaOtro)}
                            </div>
                          )}
                        ></Column>
                        <Column
                          header="Empresa"
                          body={(experiencia) =>
                            toTitleCase(experiencia.empresaDescripcion)
                          }
                        ></Column>
                        <Column
                          header="Cargo ingres. por candidato"
                          body={(experiencia) => (
                            <div style={{ color: 'red' }}>
                              {toTitleCase(experiencia.cargoOtro)}
                            </div>
                          )}
                        ></Column>
                        <Column
                          field="cargoDescripcion"
                          header="Cargo"
                          body={(experiencia) =>
                            toTitleCase(experiencia.cargoDescripcion)
                          }
                        ></Column>
                      </DataTable>
                    </div>
                  </Box>
                ) : (
                  <h4>No hay actualizaciones pendientes</h4>
                )}
              </Loader>
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { NormalizarCandidatoView };
