/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { SelectInput } from '../../utils/SelectInput';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { MonedaInputV2 } from '../../ui/moneda';
import { DateInput } from '../../utils/DateInput';
import {
  estadoCivilOptions,
  paraguayOption,
  sexoOptions,
} from '../../utils/constants';
import { TextArea } from '../../utils/TextArea';
import { PhoneInput } from 'react-international-phone';
import '../../../node_modules/react-international-phone/build/index.css';
import '../../ui/PhoneInput/customCss.css';
import { translatedCountries } from '../../ui/PhoneInput/translatedCountries';
import Loader from '../../utils/Loader';
import { Alert, alertTypes } from '../../ui/alert';
import { toTitleCase } from '../../utils/utils';
import Etiqueta from '../../utils/etiquetaObligatorio/Etiqueta';
import { PhoneInputV3 } from '../../ui/PhoneInput/PhoneInputV3';

const { TextInput, knownRegexes } = require('../../utils/TextInput');

const getFoundCandidatosText = ({ history, foundCandidatos }) => {
  const items = foundCandidatos.searchResultsByTerm.map((item, index) => {
    if (!item?.items?.length) {
      return null;
    }

    return (
      <li key={index} style={{ marginBottom: 10 }}>
        {toTitleCase(item.term)} ({item.count} candidatos)
        <ul>
          {item.items.map((candidato) => (
            <li key={candidato._id}>{toTitleCase(candidato.nombreCompleto)}</li>
          ))}
        </ul>
        <a
          href="javascript:;"
          onClick={() =>
            history.push(`/candidatos/?busquedaRapida=${item.term}`)
          }
        >
          Ver más
        </a>
      </li>
    );
  });
  return (
    <>
      <ul>{items}</ul>
    </>
  );
};

function CamposCandidato({
  mode,
  history,
  nombre,
  setNombre,
  apellido,
  setApellido,
  documento,
  setDocumento,
  expectativaSalarial,
  setExpectativaSalarial,
  telefono2,
  setTelefono2,
  telefono3,
  setTelefono3,
  telefono1,
  setTelefono1,
  direccion,
  setDireccion,
  correo,
  setCorreo,
  nacimientoFecha,
  setNacimientoFecha,
  sexoOption,
  sexoOptions,
  setSexoOption,
  estadoCivilOption,
  estadoCivilOptions,
  setEstadoCivilOption,
  descripcionInterna,
  setDescripcionInterna,
  nacionalidadOption,
  nacionalidadesOptions,
  setNacionalidadOption,
  paisOption,
  paisesOptions,
  setPaisOption,
  ciudadOption,
  ciudadesOptions,
  setCiudadOption,
  ciudadLibre,
  setCiudadLibre,
  modelSearchCandidatosByMobile,
  foundCandidatos,
}) {
  return (
    <>
      <PhoneInputV3
        required={true}
        label="Celular 1"
        value={telefono2}
        setValue={setTelefono2}
      />

      {mode === 'create' && (
        <Loader isLoading={modelSearchCandidatosByMobile.loading}>
          {foundCandidatos?.total > 0 && (
            <Alert
              title={'Se encontraron teléfonos similares'}
              type={alertTypes.warning}
              text={getFoundCandidatosText({ foundCandidatos, history })}
              closable={false}
            />
          )}
        </Loader>
      )}

      <TextInput
        label="Nombre"
        initialValue={nombre}
        setValue={setNombre}
        required
      />
      <TextInput
        label="Apellido"
        initialValue={apellido}
        setValue={setApellido}
        required
      />
      <PhoneInputV3
        label="Celular 2"
        value={telefono3}
        setValue={setTelefono3}
      />
      <TextInput
        label="Teléfono"
        initialValue={telefono1}
        setValue={setTelefono1}
        customRegex={knownRegexes.NUMERIC}
      />
      <TextInput
        label="Documento"
        initialValue={documento}
        setValue={setDocumento}
      />
      <MonedaInputV2
        label="Expectativa salarial"
        initialValue={expectativaSalarial}
        setValue={setExpectativaSalarial}
        selectorMonedaDisabled={true}
      />

      <TextInput
        label="Dirección"
        initialValue={direccion}
        setValue={setDireccion}
      />
      <TextInput label="Correo" initialValue={correo} setValue={setCorreo} />
      <DateInput
        label="Fecha de nacimiento"
        selected={nacimientoFecha}
        onChange={setNacimientoFecha}
        showYearDropdown
        scrollableYearDropdown={false}
      />
      <SelectInput
        label="Sexo"
        value={sexoOption}
        options={sexoOptions}
        setValue={setSexoOption}
      />
      <SelectInput
        label="Estado civil"
        value={estadoCivilOption}
        options={estadoCivilOptions}
        setValue={setEstadoCivilOption}
      />
      <TextArea
        label="Descripción"
        value={descripcionInterna}
        setValue={setDescripcionInterna}
      />
      <SelectInput
        label="Nacionalidad"
        value={nacionalidadOption}
        options={nacionalidadesOptions}
        setValue={setNacionalidadOption}
      />
      <SelectInput
        label="País"
        value={paisOption}
        options={paisesOptions}
        setValue={setPaisOption}
      />
      {!!paisOption?.value && paisOption.value === paraguayOption.value && (
        <>
          <SelectInput
            label="Ciudad"
            value={ciudadOption}
            options={ciudadesOptions}
            setValue={setCiudadOption}
          />
        </>
      )}
      {!!paisOption?.value && paisOption.value !== paraguayOption.value && (
        <>
          <TextInput
            label="Ciudad"
            initialValue={ciudadLibre}
            setValue={setCiudadLibre}
          />
        </>
      )}
    </>
  );
}
export { CamposCandidato };
