import { MonedaInput } from "./MonedaInput";
import { MonedaInputV2 } from "./MonedaInputV2";
import { formatValue } from "react-currency-input-field";

const monedasSoportadas = {
  USD: "USD",
  PYG: "PYG",
};

const prefixByMoneda = {
  [monedasSoportadas.USD]: "USD",
  [monedasSoportadas.PYG]: "Gs.",
};

const monedaFormat = ({ valor, moneda = "PYG" }) => {

  const valorAFormatear = valor ? String(valor) : "0";

  return formatValue({
    value: valorAFormatear,
    groupSeparator: ".",
    decimalSeparator: ",",
    prefix: `${prefixByMoneda[moneda]} `,
  });
};

export { monedasSoportadas, prefixByMoneda, MonedaInputV2, monedaFormat };
