import React from 'react';
import { Content, Row, Col, Box, Button } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { DateRangeInput } from '../ui/DateRangeInput';
import { Alert } from '../ui/alert';
import { etapasIdByName } from '../utils/constants';

import './ReporteProduccionSelector.css';

function ReporteProduccionSupervisorView({
  history,
  topAlert,
  modelGetReporteProduccionSupervisor,
  usuariosLoadStatus,
  usuarioOption,
  usuariosOptions,
  setUsuarioOption,
  inicio,
  setInicio,
  fin,
  setFin,
  submit,
  reporteProduccion,
}) {
  return (
    <Content
      title="Reporte de producción"
      subTitle="Supervisor"
      browserTitle="Reporte de producción: supervisor"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Loader isLoading={usuariosLoadStatus !== 'LOADED'}>
            <Box title="Filtro">
              <SelectInput
                label="Supervisor"
                hint="Busca oportunidades a cargo del supervisor"
                value={usuarioOption}
                options={usuariosOptions}
                setValue={setUsuarioOption}
                disabled={usuariosLoadStatus !== 'LOADED'}
                isLoading={usuariosLoadStatus !== 'LOADED'}
              />

              <div className="form-group">
                <label>Fecha</label>

                <DateRangeInput
                  showLabels
                  labelStyle={{ fontWeight: 'normal' }}
                  inicio={inicio}
                  setInicio={setInicio}
                  fin={fin}
                  setFin={setFin}
                  xs={12}
                  md={12}
                />
              </div>

              <div style={{ float: 'right' }}>
                <div style={{ display: 'inline-block', marginRight: 10 }}>
                  <Button
                    type="primary"
                    text="Filtrar"
                    onClick={() => submit()}
                    disabled={!usuarioOption?.value || !inicio || !fin}
                  />
                </div>
              </div>
            </Box>
          </Loader>
        </Col>
      </Row>

      {reporteProduccion?.idsOportunidades?.length > 0 && (
        <Row>
          <Col xs="12" md="10" mdOffset="1">
            <h3>Producción por oportunidad</h3>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Loader isLoading={modelGetReporteProduccionSupervisor.loading}>
            {reporteProduccion?.idsOportunidades?.length > 0 &&
              reporteProduccion.idsOportunidades.map((oportunidadId, index) => {
                const datosAvancesPorEtapa =
                  reporteProduccion?.cantAvancesPorSupervisorYEtapa?.[
                    oportunidadId
                  ];

                const contAvancesPorEtapa =
                  datosAvancesPorEtapa?.conteoPorEtapa;

                const cantidadCierreSupervisor =
                  contAvancesPorEtapa?.[etapasIdByName.cierre] || 0;

                const cantidadEntrevista =
                  reporteProduccion?.cantEntrevistasLiderPorOport?.[
                    oportunidadId
                  ] || 0;

                const efectividadEntrevista =
                  reporteProduccion?.porcEfectEntrPorOport?.[oportunidadId] ||
                  0;

                return (
                  <Box
                    key={index}
                    title={`${reporteProduccion?.oportunidadesById?.[oportunidadId]?.titulo} - ${reporteProduccion?.oportunidadesById?.[oportunidadId]?.empresaDescripcion}`}
                  >
                    <div style={{ marginLeft: 50, marginRight: 50 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="contenedor">
                          <div className="cuadro">
                            <div className="titulo">Entrevista líder</div>
                            <div className="numero">{cantidadEntrevista}</div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Procesados</div>
                            <div className="numero">
                              {cantidadCierreSupervisor}
                            </div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Efectividad</div>
                            <div className="numero">
                              {efectividadEntrevista}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                );
              })}
          </Loader>
        </Col>
      </Row>
    </Content>
  );
}

export { ReporteProduccionSupervisorView };
