import React from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import Loader from '../utils/Loader';
import { Alert, alertTypes } from '../utils/Alert';
import ImagenesLista from '../imageUploadV3/ImagenesLista';

function EditarFotoCandidatoView({
  history,
  match,
  modelGetCandidato,
  submit,
  submitAlert,
  topAlert,
  navigateToSubirImagenes,
  imagenes,
  imagenesListaLoading,
  doEliminarImagen,
}) {
  return (
    <Content
      title="Editar foto de candidato"
      browserTitle="Editar foto de candidato"
      show={false}
    >
      <Loader isLoading={modelGetCandidato.loading}>
        {topAlert.text && (
          <Alert
            title={topAlert.title}
            type={topAlert.type}
            text={topAlert.text}
            closable={topAlert.closable}
          />
        )}

        <Row>
          <Col md="10" mdOffset="1">
            <Button
              type="primary"
              text="Subir imágenes"
              pullRight={true}
              disabled={false}
              onClick={navigateToSubirImagenes}
            />
            <div style={{ display: 'inline-block' }}>
              <h4 style={{ display: 'inline-block' }}>Foto del candidato</h4>
            </div>

            <ImagenesLista
              imagenes={imagenes}
              doEliminarImagen={doEliminarImagen}
              loading={imagenesListaLoading}
              // navigateToSubirThumbnail={navigateToSubirThumbnail}
            />
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { EditarFotoCandidatoView };
