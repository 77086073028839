import React, { useEffect, useState } from 'react';
import { Inputs } from 'adminlte-2-react';

import Etiqueta from './etiquetaObligatorio/Etiqueta';

const { Text } = Inputs;

const knownRegexes = {
  NUMERIC: '[^0-9]+',
  NUMERIC_RUC: '[^0-9-]+', // 1234567-8,
  SPANISH_ALPHABET:
    '[^aábcdeéfghijklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIJKLMNÑOÓPQRSTUÚÜVWXYZ]+',
  SPANISH_ALPHABET_WITH_SPACES:
    '[^aábcdeéfghijklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIJKLMNÑOÓPQRSTUÚÜVWXYZ ]+',
};

const removeNonValid = (value, regex) => {
  if (!Boolean(value)) {
    return '';
  }

  if (!Boolean(regex)) {
    return value;
  }

  const stringValue = value.toString();

  if (!Boolean(stringValue)) {
    return '';
  }

  const regExp = new RegExp(regex, 'gi');

  const newValue = stringValue.replace(regExp, '');

  if (!Boolean(newValue)) {
    return '';
  }

  return newValue;
};

function TextInput({
  setValue,
  label,
  labelPosition = 'above',
  size = 'sm',
  initialValue = null,
  disabled = false,
  customRegex = null,
  maxLength = null,
  required = false,
  inputType = 'text',
  rows = null,
  min = null,
  max = null,
  placeholder = null,
  help = null,
  onKeyDown = null,
  style,
}) {
  const [valorLocal, setValorLocal] = useState('');

  useEffect(() => {
    const value = removeNonValid(initialValue, customRegex);
    setValorLocal(value);
    setValue?.(value);
  }, [initialValue]);

  const handleChange = (event) => {
    if (maxLength !== null && event.target.value.length > maxLength) {
      return;
    }
    if (min && event.target.value < min) {
      return;
    }

    if (max && event.target.value > max) {
      return;
    }
    const value = removeNonValid(event.target.value, customRegex);
    setValorLocal(value);
    setValue?.(value);
  };

  return (
    <Text
      disabled={disabled}
      labelPosition={labelPosition}
      size={size}
      label={<Etiqueta texto={label} obligatorio={required} />}
      value={valorLocal}
      onChange={handleChange}
      inputType={inputType}
      rows={rows} // in case inputType = 'textarea'
      placeholder={placeholder}
      help={help}
      onKeyDown={onKeyDown}
      style={style}
    />
  );
}

export { TextInput, knownRegexes };
