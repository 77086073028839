const moment = require('moment');
const axios = require('axios');
export const { log, logger } = require('./logs');

export const getDate = (date) => {
  const dateToReturn = date
    ? moment(date).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');
  return dateToReturn;
};

export const getDateWithHour = (date) =>
  moment(date).format('YYYY-MM-DD HH:mm:ss');

export const getDateUtc = (fecha) => {
  const day = fecha
    ? moment.utc(fecha).format('YYYY-MM-DD')
    : moment.utc().format('YYYY-MM-DD');
  return day;
};

export const getDateWithFormat = (fecha, format = 'YYYY-MM-DD HH:mm:ss') => {
  const day = fecha ? moment(fecha).format(format) : moment().format(format);
  return day;
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

export const thousandSeparator = (value) => {
  return addCommas(removeNonNumeric(value));
};

export const checkAuthorization = (rolesPermitidos) => {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('Acceso no autorizado');
    return (window.location.href = '/logout');
  }
  const decodedToken = parseJwt(token);
  const isPermitido = rolesPermitidos.filter(
    (item) => item === decodedToken.rol
  );
  if (isPermitido.length === 0) {
    alert('Acceso no autorizado');
    return (window.location.href = '/logout');
  }
};

export const cajaOptions = () => [
  { value: 'Automatico', label: 'Automatico' },
  { value: 'Mecanico', label: 'Mecanico' },
];

export const colorOptions = () => [
  { value: 'Amarillo', label: 'Amarillo' },
  { value: 'Azul', label: 'Azul' },
  { value: 'Beige', label: 'Beige' },
  { value: 'Blanco', label: 'Blanco' },
  { value: 'Bordo', label: 'Bordo' },
  { value: 'Cafe', label: 'Café' },
  { value: 'Celeste', label: 'Celeste' },
  { value: 'Dorado', label: 'Dorado' },
  { value: 'Gris claro', label: 'Gris claro' },
  { value: 'Gris oscuro', label: 'Gris oscuro' },
  { value: 'Lila', label: 'Lila' },
  { value: 'Marron', label: 'Marron' },
  { value: 'Mostaza', label: 'Mostaza' },
  { value: 'Naranja', label: 'Naranja' },
  { value: 'Negro', label: 'Negro' },
  { value: 'Ocre', label: 'Ocre' },
  { value: 'Plata', label: 'Plata' },
  { value: 'Rojo', label: 'Rojo' },
  { value: 'Rosa', label: 'Rosa' },
  { value: 'Verde', label: 'Verde' },
];

export const combustibleOptions = () => [
  {
    value: 'Diesel',
    label: 'Diesel',
  },
  {
    value: 'Naftero',
    label: 'Naftero',
  },
  {
    value: 'Gas',
    label: 'Gas',
  },
  {
    value: 'Flex',
    label: 'Flex',
  },
  {
    value: 'Hibrido',
    label: 'Hibrido',
  },
  {
    value: 'Semi Hibrido',
    label: 'Semi Hibrido',
  },
  {
    value: 'Electrico',
    label: 'Electrico',
  },
];

export const traccionOptions = () => [
  { value: '4x2', label: '4x2' },
  { value: '4x4', label: '4x4' },
];

export const cuerpoOptions = () => [
  { value: 'Camioneta', label: 'Camioneta' },
  { value: 'Camion', label: 'Camion' },
  { value: 'VAN', label: 'VAN' },
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Pickup', label: 'Pick Up' },
  // { value: "Notchback", label: "Notchback" },
  // { value: "Fastback", label: "Fastback" },
  // { value: "Hard Top", label: "Hard Top" },
  // { value: "Targa", label: "Targa" },
  // { value: "Shooting Brake", label: "Shooting Brake" },
  // { value: "Station Wagon", label: "Station Wagon" },
  // { value: "Coupe", label: "Coupe" },
  // { value: "Roadster", label: "Roadster" },
  // { value: "Monovolumen", label: "Monovolumen" },
  // { value: "Familiar", label: "Familiar" },
];
export const duenhosOptions = () => [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3 o más' },
];

export const estadoSubmodeloOptions = () => [
  { value: 'VALIDANDO', label: 'VALIDANDO' },
  { value: 'ACTIVO', label: 'ACTIVO' },
  { value: 'INACTIVO', label: 'INACTIVO' },
];

export const modelosOptions = (modelos) => {
  return modelos
    ? modelos.map((item) => ({
        value: item.name,
        label: item.name,
      }))
    : [];
};

export const marcaOptions = (marcas) =>
  marcas
    ? marcas.map((item) => ({
        value: item.make,
        label: item.make,
      }))
    : [];

export const inputAlphaNumeric = (e, callback) => {
  // numbers and spanish alphabet
  // [^0-9aábcdeéfghijklmnñoópqrstuúüvwxyzAÁBCDEÉFGHIJKLMNÑOÓPQRSTUÚÜVWXYZ]

  const value = e.target.value
    ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, '')
    : '';

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === 'function') {
    return callback(value);
  }
};

export const getDateAtMidnight = (date = null) => {
  let baseDate = null;

  // Avoid new Date(null) because it
  // will set it to 1969
  // or new Date(undefined) that
  // will get 'invalid date'

  if (date) {
    baseDate = new Date(date);
  } else {
    baseDate = new Date();
  }

  return new Date(baseDate.setHours(0, 0, 0, 0));
};

export const validateRuc = (ruc) => {
  return /^[0-9]{5,8}-[0-9]{1}$/.test(ruc);
};

export const constants = {
  HTTP_SUCCESS: 200,
  HTTP_INVALID_DATA: 400,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,
  HTTP_GENERAL_ERROR: 500,
  ENTER_KEY_EVENT_CODE: 13,
};

export const naturalezaOpciones = [
  { value: '1', label: 'Contribuyente' },
  { value: '2', label: 'No contribuyente' },
];

export const tipoDocumentoOpciones = [
  { value: '1', label: 'Cédula paraguaya' },
  { value: '2', label: 'Pasaporte' },
  { value: '3', label: 'Cédula extranjera' },
  { value: '4', label: 'Carnet de residencia' },
  { value: '5', label: 'Innominado' },
  { value: '6', label: 'Tarjeta Diplomática de exoneración fiscal' },
  { value: '9', label: 'Otro' },
];

export const tasaIvaOpciones = [
  { value: '0', label: '0%' },
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
];

export const precioVariableOpciones = [
  { value: 'SI', label: 'SI' },
  { value: 'NO', label: 'NO' },
];

export const zeroPad = (num, places) => String(num).padStart(places, '0');
// Ejemplo de respuesta v2 de API
// {
//   httpStatus: constants.HTTP_SUCCESS, // el campo se llama httpStatus pero es un campo cualquiera dentro de los datos
//   mensaje: "PDF de factura generado",
//   datos:{
//     url: result.archivo.url,
//   }
// }

// Retorna el estado HTTP real del request
export const responseHttpStatusSelector = (axiosResult) => {
  return axiosResult?.data?.status;
};

export const errorHttpStatusSelector = (axiosResult) => {
  return axiosResult?.error?.response?.status;
};

export const responseDataSelector = (axiosResult) =>
  axiosResult?.data?.data?.data || axiosResult?.data?.data?.datos;

export const responseCodeSelector = (axiosResult) =>
  axiosResult?.data?.data?.code ||
  axiosResult?.data?.data?.codigo ||
  axiosResult?.data?.data?.status ||
  axiosResult?.data?.data?.httpStatus; // el campo se llama httpStatus pero es un campo cualquiera dentro de los datos

export const responseMessageSelector = (axiosResult) =>
  axiosResult?.data?.data?.message || axiosResult?.data?.data?.mensaje;

export const errorSelector = (axiosResult) => axiosResult?.error;

export const errorMessageSelector = (
  axiosResult,
  defaultMessage = 'Ocurrió un error al realizar la operación. Error: FE1'
) => {
  const msg =
    axiosResult?.error?.response?.data?.error?.message ||
    axiosResult?.error?.response?.data?.error?.mensaje ||
    axiosResult?.error?.response?.data?.message ||
    axiosResult?.error?.response?.data?.mensaje;

  if (msg) {
    return msg;
  }

  return defaultMessage;
};

export const errorCodeSelector = (axiosResult) => {
  const code =
    axiosResult?.error?.response?.data?.error?.code ||
    axiosResult?.error?.response?.data?.error?.codigo ||
    axiosResult?.error?.response?.data?.error?.status ||
    axiosResult?.error?.response?.data?.error?.httpStatus ||
    axiosResult?.error?.response?.data?.code ||
    axiosResult?.error?.response?.data?.codigo ||
    axiosResult?.error?.response?.data?.status ||
    axiosResult?.error?.response?.data?.httpStatus;

  return code;
};

export const responseSelector = (axiosResult, defaultErrorMessage) => ({
  httpStatus: responseHttpStatusSelector(axiosResult),
  data: responseDataSelector(axiosResult),
  code: responseCodeSelector(axiosResult),
  message: responseMessageSelector(axiosResult),
  error: errorSelector(axiosResult),
  errorMessage: errorMessageSelector(axiosResult, defaultErrorMessage),
  errorHttpStatus: errorHttpStatusSelector(axiosResult),
  errorCode: errorCodeSelector(axiosResult),
});

export const getAndRemoveFromStorage = (item) => {
  const storage = localStorage.getItem(item);
  localStorage.removeItem(item);
  return storage;
};

export const sumarDias = (diaInicial, suma) => {
  const dia = moment(diaInicial, 'YYYY-MM-DD')
    .add(suma, 'days')
    .format('YYYY-MM-DD');
  return dia;
};

export const clearLocalStorage = () => {
  const redirect = localStorage.getItem('redirectTo');
  localStorage.clear();
  if (redirect) {
    localStorage.setItem('redirectTo', redirect);
  }
};

export const sumarMeses = (diaInicial, suma) => {
  const dia = moment(diaInicial, 'YYYY-MM-DD')
    .add(suma, 'month')
    .format('YYYY-MM-DD');
  return dia;
};

export const monthByNumber = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};

export const getMomentAtMidnight = (momentObject) => {
  return momentObject
    .clone()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const getNextOccurrenceOfDayNumberOfMonth = ({
  startDate,
  dayNumberOfMonth,
}) => {
  /*
    Get the next occurrence of a day of month.

    It returns a moment object.

    Example, startDate is the 12/May, we need to get the next 21th day of month,
    so it will return 21/May.
    If we need the next 9th day of the month, it will return 9/June.
    
    It gets first date of month,
    adds 1 month if now is already past that day,
    or zero if now is before that day.
    Then it adds the desired amount of days which
    will result in the next occurrence of the day the month.
    Didn't check its behaviour with day > 28.
  */

  if (dayNumberOfMonth > 28) {
    // TODO check behaviour with > 28
    throw new Error('Day number not valid');
  }

  const mtStartDate = getMomentAtMidnight(moment.utc(startDate));

  const mtFirstDateOfMonth = mtStartDate.clone().date(1);

  return mtFirstDateOfMonth
    .add(mtStartDate.date() > dayNumberOfMonth ? 1 : 0, 'months')
    .add(dayNumberOfMonth - 1, 'days');
};

export function openUrl(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

function addPyCode(telefono) {
  if (!telefono || typeof telefono !== 'string') {
    return telefono;
  }
  if (telefono.trim().startsWith('0') && telefono.trim().length === 10) {
    // El telefono probablemente es de Paraguay, agregarle 595 en vez del cero
    return `595${telefono.trim().slice(1)}`;
  }
  if (telefono.trim().startsWith('+')) {
    return telefono.trim().slice(1);
  }
}

export function openWhatsapp({ telefono, mensaje }) {
  const telefonoToUse = addPyCode(telefono);
  const url = `//api.whatsapp.com/send?phone=${telefonoToUse}&text=${mensaje}`;
  openUrl(url);
}

export const enviarLinkFacturaPorWhatsapp = async (data, token) => {
  openWhatsapp({
    telefono: data.client.telefono,
    mensaje: `Hola ${data.client.nombre}, esta es tu factura. ${data.archivo.url}`,
  });
  const url = `${process.env.REACT_APP_API_URL}/v1/admin/addAccionNotificacionWhatsApp?userToken=${token.raw}`;
  await axios.post(
    url,
    {
      facturaId: data._id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export function enviarFactura({ history, facturaId, clienteId }) {
  let clienteIdParam = '';
  if (clienteId) {
    clienteIdParam = `/${clienteId}`;
  }
  history.push(`/enviarFactura/${facturaId}${clienteIdParam}`);
}

export const motivoNotaOptions = [
  { value: 1, label: 'Devolución y Ajuste de precios' },
  { value: 2, label: 'Devolución' },
  { value: 3, label: 'Descuento' },
  { value: 4, label: 'Bonificación' },
  { value: 5, label: 'Crédito incobrable' },
  { value: 6, label: 'Recupero de costo' },
  { value: 7, label: 'Recupero de gasto' },
  { value: 8, label: 'Ajuste de precio' },
];

export const facturaCondicionByName = { contado: 1, credito: 2 };

export const facturaCondicionById = {
  [facturaCondicionByName.contado]: 'Contado',
  [facturaCondicionByName.credito]: 'Crédito',
};

export const totalProducto = (cantidad, precio) => cantidad * precio;

export const totalIvaProducto = (cantidad, precio, iva) => {
  const divisor = iva === 10 ? 1.1 : 1.05;
  return (
    totalProducto(cantidad, precio) - totalProducto(cantidad, precio) / divisor
  );
};

export const condicionOpciones = [
  { value: '1', label: 'Contado' },
  { value: '2', label: 'Crédito' },
];

export const tarjetaOpciones = [
  { value: '1', label: 'Visa' },
  { value: '2', label: 'Mastercard' },
  { value: '3', label: 'American Express' },
  { value: '4', label: 'Maestro' },
  { value: '5', label: 'Panal' },
  { value: '6', label: 'Cabal' },
];

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const toTitleCase = (string) => {
  if (!string) {
    return string;
  }
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const etapasByName = {
  reclutamiento: '200',
  primerFiltro: '201',
  entrevistaSelector: '202',
  entrevistaLider: '203',
  entrevistaDirector: '204',
  entrevistaCliente: '205',
  cierre: '205',
};

export const etapas = {
  [etapasByName.reclutamiento]: 'Reclutamiento',
  [etapasByName.primerFiltro]: 'Primer Filtro',
  [etapasByName.entrevistaSelector]: 'Entrevista Selector',
  [etapasByName.entrevistaLider]: 'Entrevista Lider',
  [etapasByName.entrevistaDirector]: 'Entrevista Director',
  [etapasByName.entrevistaCliente]: 'Entrevista Cliente',
  [etapasByName.cierre]: 'Cierre',
};

export const tipoDEIdByName = {
  facturaElectronica: 1,
  facturaElectronicaDeExportacion: 2,
  facturaElectronicaDeImportacion: 3,
  autofacturaElectronica: 4,
  notaDeCreditoElectronica: 5,
  notaDeDebitoElectronica: 6,
  notaDeRemisionElectronica: 7,
  comprobanteDeRetencionElectronico: 8,
};

export const tipoDE = {
  [tipoDEIdByName.facturaElectronica]: 'Factura electrónica',
  [tipoDEIdByName.facturaElectronicaDeExportacion]:
    'Factura electrónica de exportación',
  [tipoDEIdByName.facturaElectronicaDeImportacion]:
    'Factura electrónica de importación',
  [tipoDEIdByName.autofacturaElectronica]: 'Autofactura electrónica',
  [tipoDEIdByName.notaDeCreditoElectronica]: 'Nota de crédito electrónica',
  [tipoDEIdByName.notaDeDebitoElectronica]: 'Nota de débito electrónica',
  [tipoDEIdByName.notaDeRemisionElectronica]: 'Nota de remisión electrónica',
  [tipoDEIdByName.comprobanteDeRetencionElectronico]:
    'Comprobante de retención electrónico',
};

export const tipoTransaccionOpciones = [
  { value: '1', label: 'Venta de mercadería' },
  { value: '2', label: 'Prestación de servicios' },
  { value: '3', label: 'Mixto (Venta de mercadería y servicios)' },
];

export const tipoPagoOpciones = [
  { value: '1', label: 'Efectivo' },
  { value: '2', label: 'Cheque' },
  { value: '3', label: 'Tarjeta de crédito' },
  { value: '4', label: 'Tarjeta de débito' },
  { value: '5', label: 'Transferencia' },
  { value: '6', label: 'Giro' },
  { value: '7', label: 'Billetera electrónica' },
  { value: '8', label: 'Tarjeta empresarial' },
  { value: '9', label: 'Vale' },
  { value: '10', label: 'Retención' },
  { value: '11', label: 'Pago por anticipo' },
  { value: '12', label: 'Valor fiscal' },
  { value: '13', label: ' Valor comercia' },
  { value: '14', label: 'Compensación' },
  { value: '15', label: 'Permuta' },
  { value: '16', label: 'Pago bancario' },
  { value: '17', label: 'Pago Móvil' },
  { value: '18', label: 'Donación' },
  { value: '19', label: 'Promoción' },
  { value: '20', label: 'Consumo Interno' },
  { value: '21', label: 'Pago Electrónico' },
  { value: '99', label: 'Otro' },
];

export const facturaEstadoNombreByValue = {
  VERIFICANDO: 'Verificando SET',
  GENERADO: 'Generado',
  CANCELADO: 'Anulado',
  RECHAZADO: 'Rechazado',
};

export const contribuyenteTipoIdByName = {
  fisica: 1,
  juridica: 2,
};

export const contribuyenteTipoDescById = {
  [contribuyenteTipoIdByName.fisica]: 'Persona física',
  [contribuyenteTipoIdByName.juridica]: 'Persona jurídica',
};

export const contribuyenteTipoOpciones = [
  {
    value: contribuyenteTipoIdByName.fisica,
    label: contribuyenteTipoDescById[contribuyenteTipoIdByName.fisica],
  },
  {
    value: contribuyenteTipoIdByName.juridica,
    label: contribuyenteTipoDescById[contribuyenteTipoIdByName.juridica],
  },
];

export const regimenTipoIdByName = {
  turismo: 1,
  importador: 2,
  exportador: 3,
  maquila: 4,
  ley6090: 5,
  productorPequenho: 6,
  productorMediano: 7,
  contable: 8,
};

export const regimenTipoDescById = {
  [regimenTipoIdByName.turismo]: 'Régimen de Turismo',
  [regimenTipoIdByName.importador]: 'Importador',
  [regimenTipoIdByName.exportador]: 'Exportador',
  [regimenTipoIdByName.maquila]: 'Maquila',
  [regimenTipoIdByName.ley6090]: 'Ley N° 60/90',
  [regimenTipoIdByName.productorPequenho]: 'Régimen del Pequeño Productor',
  [regimenTipoIdByName.productorMediano]: 'Régimen del Mediano Productor',
  [regimenTipoIdByName.contable]: 'Régimen Contable',
};

export const regimenTipoOpciones = [
  { value: null, label: 'Sin régimen' },
  { value: regimenTipoIdByName.turismo, label: 'Régimen de Turismo' },
  { value: regimenTipoIdByName.importador, label: 'Importador' },
  { value: regimenTipoIdByName.exportador, label: 'Exportador' },
  { value: regimenTipoIdByName.maquila, label: 'Maquila' },
  { value: regimenTipoIdByName.ley6090, label: 'Ley N° 60/90' },
  {
    value: regimenTipoIdByName.productorPequenho,
    label: 'Régimen del Pequeño Productor',
  },
  {
    value: regimenTipoIdByName.productorMediano,
    label: 'Régimen del Mediano Productor',
  },
  { value: regimenTipoIdByName.contable, label: 'Régimen Contable' },
];

export const getLinkPago = (token) => {
  return `${process.env.REACT_APP_PAGO_LINK_BASE_URL}?linkToken=${token}`;
};

export const getMsjLinkPago = (token) =>
  `Hola! Tenés un pago pendiente en TOM. Podés pagarlo aquí: ${getLinkPago(
    token
  )}`;

export const copiarAPortapapeles = (texto) => {
  log('copiarAPortapapeles.start');
  navigator.clipboard.writeText(texto);
};

export const submitSearchOnEnterPress = (event, submitSearch) => {
  if (
    event.which === constants.ENTER_KEY_EVENT_CODE ||
    event.keyCode === constants.ENTER_KEY_EVENT_CODE
  ) {
    submitSearch();
  }
};

export const getDateOrEmpty = (date, valueToReturnWhenEmpty = null) => {
  if (date) {
    return getDateUtc(date);
  }
  return valueToReturnWhenEmpty;
};

export const codigosBancard = {
  '00': 'Transacción aprobada',
  '05': 'Tarjeta inhabilitada',
  12: 'Transacción inválida',
  15: 'Tarjeta inválida',
  51: 'Fondos insuficientes',
};

export const insertToArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const preparePaisesOptions = (paises) => {
  const options = paises.map((pais) => ({
    value: pais.idNumerico,
    label: toTitleCase(pais.descripcion),
  }));

  return options;
};

export const prepareNacionalidadesOptions = (nacionalidades) => {
  const options = nacionalidades.map((nacionalidad) => ({
    value: nacionalidad.idNumerico,
    label: toTitleCase(nacionalidad.descripcion),
  }));

  return options;
};

export const prepareDatosAuxOptions = (datos) => {
  const options = datos.map((dato) => ({
    value: dato.idNumerico,
    label: toTitleCase(dato.descripcion),
  }));

  return options;
};

export const TINYMCE_SCRIPT_SRC = `/tinymce/tinymce.min.js`;

export const TINYMCE_CONFIG = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar:
    'undo redo | formatselect | ' +
    'bold italic | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  content_style:
    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  branding: false,
};

export const prepareCiudadesOptions = (ciudades) => {
  const options = ciudades.map((ciudad) => ({
    value: ciudad.idNumerico,
    label: toTitleCase(ciudad.descripcion),
  }));

  return options;
};

export const getNumberOrNull = (n) => {
  if (n || n === 0) {
    return n;
  } else {
    return null;
  }
};

export const procesarNota = (nota) => {
  const result =
    nota.contenido && nota.contenido.length > 383
      ? `${nota.contenido.slice(0, 380)}...`
      : nota.contenido;
  return result;
};

export const prepareEmpresasOptions = (empresas) => {
  const options = empresas.map((empresa) => ({
    value: empresa.idNumerico,
    label: toTitleCase(empresa.nombre),
  }));

  return options;
};

export const prepareRegionesOptions = (regiones) => {
  const options = regiones.map((region) => ({
    value: region.idNumerico,
    label: toTitleCase(region.descripcion),
  }));

  return options;
};
