import React, { useState } from 'react';
import { Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Modal } from 'react-bootstrap';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { getDateWithFormat, toTitleCase, openWhatsapp } from '../utils/utils';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';
import { CheckBox } from '../ui/Checkbox';
import { MonedaInputV2 } from '../ui/moneda';
import { Alert, alertTypes } from '../ui/alert';
import { CargosPotencialesNotas } from './editar/CargosPotencialesNotas';
const { TextInput } = require('../utils/TextInput');

function ModalNotas({
  isModalShown,
  hideModal,
  showModal,
  notas,
  history,
  activePage,
  pageSize,
  totalResults,
  handlePage,
  submitNota,
  contenido,
  setContenido,
  titulo,
  setTitulo,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,
  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,
  expectativaSalarial,
  setExpectativaSalarial,
  cargoPotSubmitAlert,
  cargosPotenciales,
  modelGetCargosPot,
  modelCrearCargoPot,
  cargosOptions,
  agregarCargoPot,
  cargoOption,
  setCargoOption,
  candidatoIdNumerico,
  notaAlert,
  notaFavoritaSet,
  setearNotaFavorita,
}) {
  // console.log('ModalNotas.render');

  const [showInputs, setShowInputs] = useState(null);
  const [showAlertBorrar, setShowAlertBorrar] = useState(null);
  const [selected, setSelected] = useState(null);
  const title = showAlertBorrar ? 'Alerta' : 'Notas del candidato';

  return (
    <Modal
      show={isModalShown}
      style={{ opacity: 1 }}
      onHide={() => {
        hideModal();
        setShowAlertBorrar(false);
      }}
      onShow={showModal}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notaAlert?.text && (
          <Alert
            title={notaAlert.title}
            type={notaAlert.type}
            text={notaAlert.text}
            closable={notaAlert.closable}
          />
        )}

        {showAlertBorrar ? (
          'Esta seguro que desea borrar la nota?'
        ) : (
          <div style={{ marginBottom: 10 }}>
            {!showInputs && (
              <Button
                pullRight
                type="primary"
                text="Agregar nota"
                size="sm"
                onClick={() => {
                  setTitulo('');
                  setContenido('');
                  setNotaSelected('');
                  setShowInputs(true);
                }}
              />
            )}
            <br />
            {showInputs && (
              <>
                <div className="form-group">
                  {cargosPotenciales.length < 3 && (
                    <Alert
                      title="Observacion"
                      type={alertTypes.info}
                      text="El candidato debe tener al menos 3 cargos potenciales para crear una nota"
                      closable={false}
                    />
                  )}

                  <h4>Cargos potenciales</h4>

                  <h5>
                    Para editar o eliminar cargo potenciales acceder a la
                    pantalla de{' '}
                    <a
                      href={`/editarCandidato/${candidatoIdNumerico}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      edicion del usuario
                    </a>
                  </h5>

                  <CargosPotencialesNotas
                    agregarCargoPot={agregarCargoPot}
                    cargoOption={cargoOption}
                    setCargoOption={setCargoOption}
                    cargosOptions={cargosOptions}
                    modelCrearCargoPot={modelCrearCargoPot}
                    cargoPotSubmitAlert={cargoPotSubmitAlert}
                    modelGetCargosPot={modelGetCargosPot}
                    cargosPotenciales={cargosPotenciales}
                  />

                  <h4>Expectativa salarial</h4>

                  <MonedaInputV2
                    initialValue={expectativaSalarial}
                    setValue={setExpectativaSalarial}
                    selectorMonedaDisabled={true}
                  />

                  <h4>Nota</h4>

                  <TextInput
                    label="Título"
                    initialValue={titulo}
                    setValue={setTitulo}
                  />

                  <label>
                    <Etiqueta texto="Observación" obligatorio={false} />
                  </label>

                  <textarea
                    className="form-control"
                    rows="5"
                    value={contenido}
                    onChange={(evt) => setContenido(evt.target.value)}
                  />

                  <br />

                  <CheckBox
                    text="Cuenta como filtro"
                    checked={cuentaFiltroChecked}
                    onChange={setCuentaFiltroChecked}
                  />

                  <Button
                    pullRight
                    type="primary"
                    text="Guardar"
                    disabled={
                      (notaSelected &&
                        !notaSelected.destacado &&
                        cargosPotenciales.length < 3) ||
                      (!notaSelected && cargosPotenciales.length < 3)
                    }
                    margin
                    size="sm"
                    onClick={() => {
                      setShowInputs(false);
                      submitNota();
                    }}
                  />

                  <Button
                    pullRight
                    type="danger"
                    text="Cancelar"
                    size="sm"
                    margin
                    onClick={() => {
                      setNotaSelected(null);
                      resetNotaInputs();
                      setShowInputs(false);
                    }}
                  />

                  <br />
                </div>
              </>
            )}
            {!showInputs && (
              <div style={{ marginTop: 10 }}>
                {notas && notas.length > 0 ? (
                  <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                    <div className="table-responsive">
                      <DataTable
                        lazy
                        pt={{
                          table: {
                            className: 'table table-hover',
                          },
                        }}
                        value={notas}
                        emptyMessage="No se encontraron registros"
                      >
                        <Column
                          field="contenido"
                          header="Notas"
                          body={(nota) => {
                            return (
                              <>
                                <strong>
                                  Fecha: {getDateWithFormat(nota.creacionTs)}
                                </strong>{' '}
                                -
                                <strong>
                                  De: {nota.creacionUsuarioNombre}
                                </strong>
                                {nota.creacionUsuario === userIdNumerico && (
                                  <Button
                                    pullRight
                                    type="danger"
                                    icon="fa-trash"
                                    size="sm"
                                    margin
                                    onClick={() => {
                                      setSelected({
                                        idNumerico: nota.idNumerico,
                                        creacionUsuario: nota.creacionUsuario,
                                      });
                                      setShowAlertBorrar(true);
                                    }}
                                  />
                                )}
                                {(nota.creacionUsuario === userIdNumerico ||
                                  nota.destacado) && (
                                  <Button
                                    pullRight
                                    type="default"
                                    icon="fa-edit"
                                    margin
                                    size="sm"
                                    onClick={() => {
                                      setNotaSelected(nota);
                                      setShowInputs(true);
                                    }}
                                  />
                                )}
                                <br />
                                <strong>{nota.titulo}</strong>
                                <br />
                                <p>{nota.contenido}</p>
                              </>
                            );
                          }}
                        ></Column>

                        <Column
                          header="Favorita"
                          body={(nota) => (
                            <ButtonWithLoading
                              pullRight
                              type={nota.destacado ? 'success' : 'default'}
                              icon="fa-star"
                              margin
                              size="sm"
                              submit={() => {
                                setearNotaFavorita({
                                  notaIdNumerico: nota.idNumerico,
                                  candidatoIdNumerico: nota.candidatoIdNumerico,
                                  setear: !nota.destacado,
                                });
                              }}
                              loading={notaFavoritaSet.loading}
                            />
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  </Box>
                ) : (
                  <p className="text-muted">No se han encontrado notas</p>
                )}
              </div>
            )}

            {!showInputs && notas && notas.length > 0 && (
              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
            )}
          </div>
        )}
      </Modal.Body>

      {showAlertBorrar && (
        <Modal.Footer>
          <ButtonWithLoading
            type="danger"
            text={'Eliminar'}
            submit={() => {
              deleteNota(selected.idNumerico, selected.creacionUsuario);
              setShowAlertBorrar(false);
            }}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}

export { ModalNotas };
