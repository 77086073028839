import { useState } from 'react';
import { alertTypes } from './Alert';

const alertDefaultValue = {
  text: null,
  type: null,
  title: null,
  closable: false,
};

function useAlerts() {
  const [topAlert, setTopAlert] = useState(alertDefaultValue);
  const [submitAlert, setSubmitAlert] = useState(alertDefaultValue);

  const setErrorTopAlert = (message, title) => {
    setTopAlert({
      title: title,
      text: message,
      type: alertTypes.danger,
      closable: false,
    });
  };

  const setWarningSubmitAlert = (message, title) => {
    setSubmitAlert({
      title: title,
      text: message,
      type: alertTypes.warning,
      closable: false,
    });
  };

  return {
    topAlert,
    setTopAlert,
    submitAlert,
    setSubmitAlert,
    setWarningSubmitAlert,
    setErrorTopAlert,
  };
}

export { useAlerts, alertDefaultValue };
