import * as queryString from "query-string";
import { useState, useEffect } from "react";

const useQueryParams = (history) => {
  const [queryParams, setQueryParams] = useState();
  const [queryParamsloaded, setQueryParamsLoaded] = useState(false);

  useEffect(() => {
    const queryParams = history?.location?.search;
    const parsedQueryParams = queryString.parse(queryParams);
    setQueryParams(parsedQueryParams);
    setQueryParamsLoaded(true);
  }, [history?.location?.search]);

  return {
    queryParams,
    queryParamsloaded,
  };
};

export { useQueryParams };
