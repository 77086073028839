/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';
import { ReferenciaObligatorios } from '../../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert } from '../../utils/Alert';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import {
  empleoTipoOptionsForCandExp,
  nivelFormacionOptions,
} from '../../utils/constants';
import { ModalAgregarExperiencia } from '../ModalAgregarExperiencia';
import { ModalEliminarCandExp } from '../ModalConfirmarEliminarExp';
import { ModalEditarExperiencia } from '../ModalEditarExperiencia';
import { ModalAgregarCargoPot } from '../ModalAgregarCargoPot';
import { ModalEditarCargoPot } from '../ModalEditarCargoPot';
import { ModalConfirmarEliminarCargoPot } from '../ModalConfirmarEliminarCargoPot';
import { ModalAgregarCandFormac } from '../ModalAgregarCandFormac';
import { ModalEliminarCandForm } from '../ModalEliminarCandForm';
import { ModalEditarCandForm } from '../ModalEditarCandForm';
import { ModalGenerico } from '../../ui/ModalGenerico';
import { ModalNotas } from '../ModalNotas';
import { UltimaNota } from './Notas';
import { CamposCandidato } from '../componentes/CamposCandidato';
import { ModalEditarCandIdioma } from '../ModalEditarCandIdioma';
import { Experiencias } from './Experiencias';
import { CargosPotenciales } from './CargosPotenciales';
import { Formacion } from './Formacion';
import { Idiomas } from './Idiomas';
import { Archivos } from './Archivos';
import { ModalIncluirEm } from './ModalIncluirEm';

function EditarCandidatoView({
  token,
  origen = 'admin',
  history,
  match,
  modelEditarCandidato,
  modelGetCandidato,
  submit,
  submitAlert,
  topAlert,
  nombre,
  setNombre,
  apellido,
  setApellido,
  documento,
  setDocumento,
  expectativaSalarial,
  setExpectativaSalarial,
  telefono1,
  setTelefono1,
  experiencias,
  isModalAgregarExpShown,
  hideModalAgregarExp,
  showModalAgregarExp,
  agregarExp,
  isAgregarExpButtonDisabled,
  isAgregarExpButtonLoading,
  empresaOption,
  empresasOptions,
  setEmpresaOption,
  cargoOption,
  cargosOptions,
  setCargoOption,
  rubroOption,
  rubrosOptions,
  setRubroOption,
  observacion,
  setObservacion,
  tipoEmpleoOption,
  setTipoEmpleoOption,
  salario,
  setSalario,
  condicionesActuales,
  setCondicionesActuales,
  vigente,
  setVigente,
  inicioFecha,
  setInicioFecha,
  finFecha,
  setFinFecha,
  salidaMotivoOption,
  salidaMotivoOptions,
  setSalidaMotivoOption,
  experienciaSubmitAlert,
  eliminarCandidatoExp,
  modelGetCandidatoExperiencias,
  isModalEliminarCandExpShown,
  hideModalEliminarCandExp,
  showModalEliminarCandExp,
  modelEliminarCandidatoExp,
  setSelectedExperiencia,
  isModalEditarExpShown,
  hideModalEditarExp,
  showModalEditarExp,
  isEditarExpButtonDisabled,
  isEditarExpButtonLoading,
  editarExperiencia,
  prepareExpEditAndShowModal,
  isModalAgregarCargoPotShown,
  hideModalAgregarCargoPot,
  showModalAgregarCargoPot,
  agregarCargoPot,
  isAgregarCargoPotButtonDisabled,
  isAgregarCargoPotButtonLoading,
  isModalEditarCargoPotShown,
  hideModalEditarCargoPot,
  showModalEditarCargoPot,
  editarCargoPot,
  isEditarCargoPotButtonDisabled,
  isEditarCargoPotButtonLoading,
  prepareCargoPotEditAndShowModal,
  cargoPotSubmitAlert,
  cargosPotenciales,
  setSelectedCargoPot,
  showModalEliminarCargoPot,
  isModalEliminarCargoPotShown,
  hideModalEliminarCargoPot,
  eliminarCargoPot,
  modelEliminarCargoPot,
  modelGetCargosPot,
  formacionSubmitAlert,
  modelGetCandidatoFormaciones,
  formaciones,
  isModalAgregarFormacionShown,
  setIsModalAgregarFormacionShown,
  agregarCandFormac,
  modelCrearCandidatoFormacion,
  institutoOption,
  institutosOptions,
  setInstitutoOption,
  titulacionOption,
  titulacionesOptions,
  setTitulacionOption,
  nivelFormacionOption,
  setNivelFormacionOption,
  candFormacInicioFecha,
  setCandFormacInicioFecha,
  candFormacFinFecha,
  setCandFormacFinFecha,
  candFormacDescripcion,
  setCandFormacDescripcion,
  isModalEliminarCandFormShown,
  setIsModalEliminarCandFormShown,
  eliminarCandForm,
  modelEliminarCandForm,
  setSelectedFormacion,
  prepareCandFormEditAndShowModal,
  isModalEditarCandFormShown,
  setIsModalEditarCandFormShown,
  editarCandForm,
  modelEditarCandForm,
  candidatoIdNumerico,
  isModalGenericoShown,
  setIsModalGenericoShown,
  isModalNotasShown,
  hideModalNotas,
  showModalNotas,
  notas,
  activePageNotas,
  pageSizeNotas,
  totalResultsNotas,
  handlePageNotas,
  submitNota,
  contenido,
  setContenido,
  titulo,
  setTitulo,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,
  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,
  ultimaNota,
  telefono2,
  setTelefono2,
  telefono3,
  setTelefono3,
  direccion,
  setDireccion,
  correo,
  setCorreo,
  nacimientoFecha,
  setNacimientoFecha,
  sexoOption,
  sexoOptions,
  setSexoOption,
  estadoCivilOption,
  estadoCivilOptions,
  setEstadoCivilOption,
  descripcionInterna,
  setDescripcionInterna,
  nacionalidadOption,
  nacionalidadesOptions,
  setNacionalidadOption,
  paisOption,
  paisesOptions,
  setPaisOption,
  ciudadOption,
  ciudadesOptions,
  setCiudadOption,
  ciudadLibre,
  setCiudadLibre,
  setIsModalAgregarCandIdiomaShown,
  idiomaSubmitAlert,
  modelGetCandIdiomas,
  candIdiomas,
  setSelectedCandIdioma,
  setIsModalEliminarCandIdiomaShown,
  prepareCandIdiomaFormEditAndShowModal,
  isModalAgregarCandIdiomaShown,
  leeOption,
  setLeeOption,
  hablaOption,
  setHablaOption,
  escribeOption,
  setEscribeOption,
  agregarCandIdioma,
  modelCrearCandIdioma,
  idiomaOption,
  idiomasOptions,
  setIdiomaOption,
  isModalEliminarCandIdiomaShown,
  eliminarCandIdioma,
  isModalEditarCandIdiomaShown,
  setIsModalEditarCandIdiomaShown,
  editarCandIdioma,
  modelEditarCandIdioma,
  idiomaById,
  archivoSubmitAlert,
  modelGetCandArchivos,
  candArchivos,
  setSelectedArchivo,
  isModalEliminarCandArchivoShown,
  setIsModalEliminarCandArchivoShown,
  selectedArchivo,
  eliminarArchivo,
  modalRedirect,
  setModalRedirect,
  modelEliminarCandArchivo,
  rolCandidato,
  cargoOtro,
  setCargoOtro,
  empresaOtro,
  setEmpresaOtro,
  otroCargoInputShown,
  setOtroCargoInputShown,
  otraEmpresaInputShown,
  setOtraEmpresaInputShown,
  otroInstitutoInputShown,
  setOtroInstitutoInputShown,
  institutoOtro,
  setInstitutoOtro,
  otraTitulacionInputShown,
  setOtraTitulacionInputShown,
  titulacionOtro,
  setTitulacionOtro,
  modelCrearCargoPot,
  notaFavoritaSet,
  setearNotaFavorita,
  isModalIncluirEmShown,
  setIsModalIncluirEmShown,
  yaIncluidoEnEmGeneral,
}) {
  return (
    <Content
      title="Editar candidato"
      browserTitle="Editar candidato"
      show={false}
    >
      <ModalNotas
        candidatoIdNumerico={candidatoIdNumerico}
        isModalShown={isModalNotasShown}
        hideModal={hideModalNotas}
        showModal={showModalNotas}
        notas={notas}
        history={history}
        activePage={activePageNotas}
        pageSize={pageSizeNotas}
        totalResults={totalResultsNotas}
        handlePage={handlePageNotas}
        submitNota={submitNota}
        contenido={contenido}
        setContenido={setContenido}
        titulo={titulo}
        setTitulo={setTitulo}
        notaSelected={notaSelected}
        setNotaSelected={setNotaSelected}
        userIdNumerico={userIdNumerico}
        deleteNota={deleteNota}
        cuentaFiltroChecked={cuentaFiltroChecked}
        setCuentaFiltroChecked={setCuentaFiltroChecked}
        entrevistaCortesiaChecked={entrevistaCortesiaChecked}
        setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
        resetNotaInputs={resetNotaInputs}
        expectativaSalarial={expectativaSalarial}
        setExpectativaSalarial={setExpectativaSalarial}
        cargoPotSubmitAlert={cargoPotSubmitAlert}
        cargosPotenciales={cargosPotenciales}
        modelGetCargosPot={modelGetCargosPot}
        modelCrearCargoPot={modelCrearCargoPot}
        agregarCargoPot={agregarCargoPot}
        cargosOptions={cargosOptions}
        cargoOption={cargoOption}
        setCargoOption={setCargoOption}
        notaFavoritaSet={notaFavoritaSet}
        setearNotaFavorita={setearNotaFavorita}
      />

      <ModalAgregarExperiencia
        token={token}
        title="Agregar experiencia"
        submitText="Agregar experiencia"
        origen={rolCandidato === 'candidato' ? 'portal' : 'admin'}
        isModalAgregarExpShown={isModalAgregarExpShown}
        hideModalAgregarExp={hideModalAgregarExp}
        showModalAgregarExp={showModalAgregarExp}
        agregarExp={agregarExp}
        isAgregarExpButtonDisabled={isAgregarExpButtonDisabled}
        isAgregarExpButtonLoading={isAgregarExpButtonLoading}
        empresaOption={empresaOption}
        empresasOptions={empresasOptions}
        setEmpresaOption={setEmpresaOption}
        cargoOption={cargoOption}
        cargosOptions={cargosOptions}
        setCargoOption={setCargoOption}
        rubroOption={rubroOption}
        rubrosOptions={rubrosOptions}
        setRubroOption={setRubroOption}
        observacion={observacion}
        setObservacion={setObservacion}
        tipoEmpleoOption={tipoEmpleoOption}
        tipoEmpleoOptions={empleoTipoOptionsForCandExp}
        setTipoEmpleoOption={setTipoEmpleoOption}
        salario={salario}
        setSalario={setSalario}
        condicionesActuales={condicionesActuales}
        setCondicionesActuales={setCondicionesActuales}
        vigente={vigente}
        setVigente={setVigente}
        inicioFecha={inicioFecha}
        setInicioFecha={setInicioFecha}
        finFecha={finFecha}
        setFinFecha={setFinFecha}
        salidaMotivoOption={salidaMotivoOption}
        salidaMotivoOptions={salidaMotivoOptions}
        setSalidaMotivoOption={setSalidaMotivoOption}
        cargoOtro={cargoOtro}
        setCargoOtro={setCargoOtro}
        empresaOtro={empresaOtro}
        setEmpresaOtro={setEmpresaOtro}
        otroCargoInputShown={otroCargoInputShown}
        setOtroCargoInputShown={setOtroCargoInputShown}
        otraEmpresaInputShown={otraEmpresaInputShown}
        setOtraEmpresaInputShown={setOtraEmpresaInputShown}
      />

      <ModalAgregarExperiencia
        token={token}
        title="Editar experiencia"
        text="Guardar cambios"
        origen={rolCandidato === 'candidato' ? 'portal' : 'admin'}
        isModalAgregarExpShown={isModalEditarExpShown}
        hideModalAgregarExp={hideModalEditarExp}
        showModalAgregarExp={showModalEditarExp}
        agregarExp={editarExperiencia}
        isAgregarExpButtonDisabled={isEditarExpButtonDisabled}
        isAgregarExpButtonLoading={isEditarExpButtonLoading}
        empresaOption={empresaOption}
        empresasOptions={empresasOptions}
        setEmpresaOption={setEmpresaOption}
        cargoOption={cargoOption}
        cargosOptions={cargosOptions}
        setCargoOption={setCargoOption}
        rubroOption={rubroOption}
        rubrosOptions={rubrosOptions}
        setRubroOption={setRubroOption}
        observacion={observacion}
        setObservacion={setObservacion}
        tipoEmpleoOption={tipoEmpleoOption}
        tipoEmpleoOptions={empleoTipoOptionsForCandExp}
        setTipoEmpleoOption={setTipoEmpleoOption}
        salario={salario}
        setSalario={setSalario}
        condicionesActuales={condicionesActuales}
        setCondicionesActuales={setCondicionesActuales}
        vigente={vigente}
        setVigente={setVigente}
        inicioFecha={inicioFecha}
        setInicioFecha={setInicioFecha}
        finFecha={finFecha}
        setFinFecha={setFinFecha}
        salidaMotivoOption={salidaMotivoOption}
        salidaMotivoOptions={salidaMotivoOptions}
        setSalidaMotivoOption={setSalidaMotivoOption}
        cargoOtro={cargoOtro}
        setCargoOtro={setCargoOtro}
        empresaOtro={empresaOtro}
        setEmpresaOtro={setEmpresaOtro}
        otroCargoInputShown={otroCargoInputShown}
        setOtroCargoInputShown={setOtroCargoInputShown}
        otraEmpresaInputShown={otraEmpresaInputShown}
        setOtraEmpresaInputShown={setOtraEmpresaInputShown}
      />

      <ModalEliminarCandExp
        isModalEliminarCandExpShown={isModalEliminarCandExpShown}
        hideModalEliminarCandExp={hideModalEliminarCandExp}
        showModalEliminarCandExp={showModalEliminarCandExp}
        eliminarCandidatoExp={eliminarCandidatoExp}
        isEliminarButtonLoading={modelEliminarCandidatoExp.loading}
      />

      <ModalAgregarCandFormac
        origen={rolCandidato === 'candidato' ? 'portal' : 'admin'}
        title="Agregar formación"
        submitText="Agregar formación"
        isModalAgregarFormacionShown={isModalAgregarFormacionShown}
        setIsModalAgregarFormacionShown={setIsModalAgregarFormacionShown}
        submit={agregarCandFormac}
        isSubmitButtonDisabled={modelCrearCandidatoFormacion.loading}
        isSubmitButtonLoading={modelCrearCandidatoFormacion.loading}
        institutoOption={institutoOption}
        institutosOptions={institutosOptions}
        setInstitutoOption={setInstitutoOption}
        titulacionOption={titulacionOption}
        titulacionesOptions={titulacionesOptions}
        setTitulacionOption={setTitulacionOption}
        nivelFormacionOption={nivelFormacionOption}
        nivelFormacionesOptions={nivelFormacionOptions}
        setNivelFormacionOption={setNivelFormacionOption}
        candFormacInicioFecha={candFormacInicioFecha}
        setCandFormacInicioFecha={setCandFormacInicioFecha}
        candFormacFinFecha={candFormacFinFecha}
        setCandFormacFinFecha={setCandFormacFinFecha}
        candFormacDescripcion={candFormacDescripcion}
        setCandFormacDescripcion={setCandFormacDescripcion}
        otroInstitutoInputShown={otroInstitutoInputShown}
        setOtroInstitutoInputShown={setOtroInstitutoInputShown}
        institutoOtro={institutoOtro}
        setInstitutoOtro={setInstitutoOtro}
        otraTitulacionInputShown={otraTitulacionInputShown}
        setOtraTitulacionInputShown={setOtraTitulacionInputShown}
        titulacionOtro={titulacionOtro}
        setTitulacionOtro={setTitulacionOtro}
      />

      <ModalAgregarCandFormac
        origen={rolCandidato === 'candidato' ? 'portal' : 'admin'}
        title="Editar formación"
        submitText="Guardar cambios"
        isModalAgregarFormacionShown={isModalEditarCandFormShown}
        setIsModalAgregarFormacionShown={setIsModalEditarCandFormShown}
        submit={editarCandForm}
        isSubmitButtonLoading={modelEditarCandForm.loading}
        isSubmitButtonDisabled={modelEditarCandForm.loading}
        institutoOption={institutoOption}
        institutosOptions={institutosOptions}
        setInstitutoOption={setInstitutoOption}
        titulacionOption={titulacionOption}
        titulacionesOptions={titulacionesOptions}
        setTitulacionOption={setTitulacionOption}
        candFormacInicioFecha={candFormacInicioFecha}
        setCandFormacInicioFecha={setCandFormacInicioFecha}
        candFormacFinFecha={candFormacFinFecha}
        setCandFormacFinFecha={setCandFormacFinFecha}
        nivelFormacionOption={nivelFormacionOption}
        nivelFormacionesOptions={nivelFormacionOptions}
        setNivelFormacionOption={setNivelFormacionOption}
        candFormacDescripcion={candFormacDescripcion}
        setCandFormacDescripcion={setCandFormacDescripcion}
        otroInstitutoInputShown={otroInstitutoInputShown}
        setOtroInstitutoInputShown={setOtroInstitutoInputShown}
        institutoOtro={institutoOtro}
        setInstitutoOtro={setInstitutoOtro}
        otraTitulacionInputShown={otraTitulacionInputShown}
        setOtraTitulacionInputShown={setOtraTitulacionInputShown}
        titulacionOtro={titulacionOtro}
        setTitulacionOtro={setTitulacionOtro}
      />

      <ModalEliminarCandForm
        isModalEliminarCandFormShown={isModalEliminarCandFormShown}
        setIsModalEliminarCandFormShown={setIsModalEliminarCandFormShown}
        eliminarCandForm={eliminarCandForm}
        isSubmitButtonLoading={modelEliminarCandForm.loading}
      />

      <ModalAgregarCargoPot
        isModalAgregarCargoPotShown={isModalAgregarCargoPotShown}
        hideModalAgregarCargoPot={hideModalAgregarCargoPot}
        showModalAgregarCargoPot={showModalAgregarCargoPot}
        agregarCargoPot={agregarCargoPot}
        isAgregarCargoPotButtonDisabled={isAgregarCargoPotButtonDisabled}
        isAgregarCargoPotButtonLoading={isAgregarCargoPotButtonLoading}
        cargoOption={cargoOption}
        cargosOptions={cargosOptions}
        setCargoOption={setCargoOption}
      />

      <ModalEditarCargoPot
        isModalEditarCargoPotShown={isModalEditarCargoPotShown}
        hideModalEditarCargoPot={hideModalEditarCargoPot}
        showModalEditarCargoPot={showModalEditarCargoPot}
        editarCargoPot={editarCargoPot}
        isEditarCargoPotButtonDisabled={isEditarCargoPotButtonDisabled}
        isEditarCargoPotButtonLoading={isEditarCargoPotButtonLoading}
        cargoOption={cargoOption}
        cargosOptions={cargosOptions}
        setCargoOption={setCargoOption}
      />

      <ModalConfirmarEliminarCargoPot
        isModalEliminarCargoPotShown={isModalEliminarCargoPotShown}
        hideModalEliminarCargoPot={hideModalEliminarCargoPot}
        showModalEliminarCargoPot={showModalEliminarCargoPot}
        eliminarCargoPot={eliminarCargoPot}
        isEliminarCargoPotButtonLoading={modelEliminarCargoPot.loading}
      />

      <ModalEditarCandIdioma
        title="Agregar idioma"
        isModalShown={isModalAgregarCandIdiomaShown}
        setIsModalShown={setIsModalAgregarCandIdiomaShown}
        leeOption={leeOption}
        setLeeOption={setLeeOption}
        hablaOption={hablaOption}
        setHablaOption={setHablaOption}
        escribeOption={escribeOption}
        setEscribeOption={setEscribeOption}
        submit={agregarCandIdioma}
        isSubmitLoading={modelCrearCandIdioma.loading}
        isSubmitDisabled={!leeOption || !escribeOption || !hablaOption}
        idiomaOption={idiomaOption}
        idiomasOptions={idiomasOptions}
        setIdiomaOption={setIdiomaOption}
        idiomaSelectDisabled={false}
      />

      <ModalEditarCandIdioma
        title="Editar idioma"
        isModalShown={isModalEditarCandIdiomaShown}
        setIsModalShown={setIsModalEditarCandIdiomaShown}
        leeOption={leeOption}
        setLeeOption={setLeeOption}
        hablaOption={hablaOption}
        setHablaOption={setHablaOption}
        escribeOption={escribeOption}
        setEscribeOption={setEscribeOption}
        submit={editarCandIdioma}
        isSubmitLoading={modelEditarCandIdioma.loading}
        isSubmitDisabled={!leeOption || !escribeOption || !hablaOption}
        idiomaOption={idiomaOption}
        idiomasOptions={idiomasOptions}
        setIdiomaOption={setIdiomaOption}
        idiomaSelectDisabled={false}
      />

      {candidatoIdNumerico && (
        <ModalGenerico
          isModalShown={isModalGenericoShown}
          hideModal={() => setIsModalGenericoShown(false)}
          title="Alerta"
          body="Si no guardaste los cambios hechos en el formulario, se perderán. ¿Continuar?"
          buttonText="Continuar"
          submit={() => {
            history.push(
              `/oportunidades/?candidatoAIncluirId=${candidatoIdNumerico}`
            );
          }}
        />
      )}

      {modalRedirect && (
        <ModalGenerico
          isModalShown={!!modalRedirect}
          hideModal={() => setModalRedirect(null)}
          title="Alerta"
          body="Si no guardaste los cambios hechos en el formulario, se perderán. ¿Continuar?"
          buttonText="Continuar"
          submit={() => {
            history.push(modalRedirect);
          }}
        />
      )}

      {selectedArchivo && (
        <ModalGenerico
          isModalShown={isModalEliminarCandArchivoShown}
          hideModal={() => setIsModalEliminarCandArchivoShown(false)}
          title="Eliminar permanentemente"
          body={
            <>
              Se eliminará <b>permanentemente</b> el archivo y no se podrá
              recuperar. ¿Continuar?
            </>
          }
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={!selectedArchivo}
          buttonLoading={modelEliminarCandArchivo.loading}
          submit={eliminarArchivo}
        />
      )}

      <ModalIncluirEm
        isModalIncluirEmShown={isModalIncluirEmShown}
        hideModal={() => setIsModalIncluirEmShown(false)}
        yaIncluidoEnEmGeneral={yaIncluidoEnEmGeneral}
        token={token}
        candidatoIdNumerico={candidatoIdNumerico}
        history={history}
      />

      <ModalGenerico
        isModalShown={isModalEliminarCandIdiomaShown}
        hideModal={() => setIsModalEliminarCandIdiomaShown(false)}
        title="Eliminar idioma"
        body="Se eliminará el idioma del candidato. ¿Continuar?"
        buttonText="Eliminar"
        submit={eliminarCandIdioma}
      />

      <Loader isLoading={modelGetCandidato.loading}>
        {topAlert.text && (
          <Alert
            title={topAlert.title}
            type={topAlert.type}
            text={topAlert.text}
            closable={topAlert.closable}
          />
        )}

        <Row>
          <Col md="10" mdOffset="1">
            <Box
              title="Datos principales"
              customOptions={
                candidatoIdNumerico &&
                rolCandidato !== 'candidato' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ marginLeft: 10 }}>
                      <Button
                        size="sm"
                        type="default"
                        text="Incluir en oportunidad"
                        onClick={() => setIsModalGenericoShown(true)}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <Button
                        size="sm"
                        type="default"
                        text="Incluir en EM"
                        onClick={() => setIsModalIncluirEmShown(true)}
                      />
                    </div>
                  </div>
                )
              }
            >
              <ReferenciaObligatorios />
              <div style={{ marginBottom: 10 }}>
                Foto de perfil:{' '}
                <ButtonWithLoading
                  text="Ver"
                  type="default"
                  size="sm"
                  loading={false}
                  submit={() =>
                    setModalRedirect(
                      `/editarFotoCandidato/${candidatoIdNumerico}`
                    )
                  }
                />
              </div>
              <CamposCandidato
                mode="edit"
                nombre={nombre}
                setNombre={setNombre}
                apellido={apellido}
                setApellido={setApellido}
                documento={documento}
                setDocumento={setDocumento}
                expectativaSalarial={expectativaSalarial}
                setExpectativaSalarial={setExpectativaSalarial}
                telefono2={telefono2}
                setTelefono2={setTelefono2}
                telefono3={telefono3}
                setTelefono3={setTelefono3}
                telefono1={telefono1}
                setTelefono1={setTelefono1}
                direccion={direccion}
                setDireccion={setDireccion}
                correo={correo}
                setCorreo={setCorreo}
                nacimientoFecha={nacimientoFecha}
                setNacimientoFecha={setNacimientoFecha}
                sexoOption={sexoOption}
                sexoOptions={sexoOptions}
                setSexoOption={setSexoOption}
                estadoCivilOption={estadoCivilOption}
                estadoCivilOptions={estadoCivilOptions}
                setEstadoCivilOption={setEstadoCivilOption}
                descripcionInterna={descripcionInterna}
                setDescripcionInterna={setDescripcionInterna}
                nacionalidadOption={nacionalidadOption}
                nacionalidadesOptions={nacionalidadesOptions}
                setNacionalidadOption={setNacionalidadOption}
                paisOption={paisOption}
                paisesOptions={paisesOptions}
                setPaisOption={setPaisOption}
                ciudadOption={ciudadOption}
                ciudadesOptions={ciudadesOptions}
                setCiudadOption={setCiudadOption}
                ciudadLibre={ciudadLibre}
                setCiudadLibre={setCiudadLibre}
              />
              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}
              <ButtonWithLoading
                text="Guardar"
                type="success"
                pullRight
                loading={modelEditarCandidato.loading}
                submit={submit}
              />
            </Box>
          </Col>
        </Row>
        {rolCandidato !== 'candidato' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box title="Notas">
                <UltimaNota ultimaNota={ultimaNota} />
                <a href="javascript:;" onClick={() => showModalNotas()}>
                  Ver más
                </a>
              </Box>
            </Col>
          </Row>
        )}
        <Experiencias
          experiencias={experiencias}
          showModalAgregarExp={showModalAgregarExp}
          experienciaSubmitAlert={experienciaSubmitAlert}
          modelGetCandidatoExperiencias={modelGetCandidatoExperiencias}
          showModalEliminarCandExp={showModalEliminarCandExp}
          setSelectedExperiencia={setSelectedExperiencia}
          prepareExpEditAndShowModal={prepareExpEditAndShowModal}
        />

        {rolCandidato !== 'candidato' && (
          <CargosPotenciales
            showModalAgregarCargoPot={showModalAgregarCargoPot}
            cargoPotSubmitAlert={cargoPotSubmitAlert}
            modelGetCargosPot={modelGetCargosPot}
            cargosPotenciales={cargosPotenciales}
            setSelectedCargoPot={setSelectedCargoPot}
            showModalEliminarCargoPot={showModalEliminarCargoPot}
            prepareCargoPotEditAndShowModal={prepareCargoPotEditAndShowModal}
          />
        )}

        <Formacion
          setIsModalAgregarFormacionShown={setIsModalAgregarFormacionShown}
          formacionSubmitAlert={formacionSubmitAlert}
          modelGetCandidatoFormaciones={modelGetCandidatoFormaciones}
          formaciones={formaciones}
          setSelectedFormacion={setSelectedFormacion}
          setIsModalEliminarCandFormShown={setIsModalEliminarCandFormShown}
          prepareCandFormEditAndShowModal={prepareCandFormEditAndShowModal}
          institutosOptions={institutosOptions}
        />

        <Idiomas
          setIsModalAgregarCandIdiomaShown={setIsModalAgregarCandIdiomaShown}
          idiomaSubmitAlert={idiomaSubmitAlert}
          modelGetCandIdiomas={modelGetCandIdiomas}
          candIdiomas={candIdiomas}
          idiomaById={idiomaById}
          setSelectedCandIdioma={setSelectedCandIdioma}
          setIsModalEliminarCandIdiomaShown={setIsModalEliminarCandIdiomaShown}
          prepareCandIdiomaFormEditAndShowModal={
            prepareCandIdiomaFormEditAndShowModal
          }
        />

        <Archivos
          setModalRedirect={setModalRedirect}
          candidatoIdNumerico={candidatoIdNumerico}
          archivoSubmitAlert={archivoSubmitAlert}
          modelGetCandArchivos={modelGetCandArchivos}
          candArchivos={candArchivos}
          setIsModalEliminarCandArchivoShown={
            setIsModalEliminarCandArchivoShown
          }
          setSelectedArchivo={setSelectedArchivo}
        />
      </Loader>
    </Content>
  );
}

export { EditarCandidatoView };
