import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { CheckBox } from '../ui/Checkbox';

function ModalConfirmarReposicion({
  isModalConfirmarReposicionShown,
  hideModalConfirmarReposicion,
  showModalConfirmarReposicion,
  confirmarReposicion,
  isConfirmarReposicionButtonLoading,
  setIncluirCandidatos,
  incluirCandidatos,
}) {
  return (
    <Modal
      show={isModalConfirmarReposicionShown}
      style={{ opacity: 1 }}
      onHide={hideModalConfirmarReposicion}
      onShow={showModalConfirmarReposicion}
    >
      <Modal.Header>
        <Modal.Title>Reposición</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se creará una nueva oportunidad con los mismos datos que la actual.
        <br />
        <br />
        <CheckBox
          text="Incluir candidatos de la oportunidad actual"
          onChange={setIncluirCandidatos}
          checked={incluirCandidatos}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Confirmar reposición"
          submit={confirmarReposicion}
          loading={isConfirmarReposicionButtonLoading}
          disabled={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalConfirmarReposicion };
