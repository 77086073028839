import React, { useEffect, useState } from "react";
// import { NumericInput } from "../../utils/NumericInput";
import { monedasSoportadas, prefixByMoneda } from "./index";
import { Inputs } from "adminlte-2-react";

import Etiqueta from "../../utils/etiquetaObligatorio/Etiqueta";

const { Text } = Inputs;

const addGroupSeparator = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9,]/g, "");

function NumericInput({
  setValue,
  label,
  size = "sm",
  initialValue = "",
  disabled = false,
  addonLeft = null,
  required = false,
}) {
  const [shownValue, setShownValue] = useState("");

  function removeLastComma(event) {
    const shownValue = event.target.value;
    const lastRealValue = initialValue;

    if (lastRealValue.charAt(lastRealValue.length - 1) === ",") {
      const lastRealValueWithoutLastComma = lastRealValue.slice(0, -1);

      const formattedValue = getFormattedValue(lastRealValueWithoutLastComma);

      setShownValue(formattedValue);

      const realValue = removeNonNumeric(lastRealValueWithoutLastComma);
      setValue(realValue);
    }
  }

  function getFormattedValue(value) {
    const cleanValue = removeNonNumeric(value);
    const parts = cleanValue.split(".");

    const integerPart = parts[0];
    const decimalPart = parts?.[1];

    const integerPartWithGroupSeparator = addGroupSeparator(integerPart);

    if (!decimalPart) {
      return integerPartWithGroupSeparator;
    }

    const formattedValue = `${integerPartWithGroupSeparator},${decimalPart}`;


    return formattedValue;
  }

  useEffect(() => {
    if (initialValue) {
      const formattedValue = getFormattedValue(initialValue);
      setShownValue(formattedValue);
    }
  }, [initialValue]);

  const handleChange = (event) => {
    const typedValue = event.target.value;

    // Prevent user from typing a comma in the first character

    if (typedValue.charAt(0) === ",") {
      return;
    }

    const parts = typedValue.split(",");

    // Prevent user from typing more than 1 comma

    if (parts.length > 2) {
      return;
    }

    const decimalPart = parts?.[1];

    // Prevent user from typing more than 2 decimals

    if (!decimalPart || decimalPart.length <= 2) {
      const formattedValue = getFormattedValue(typedValue);
      setShownValue(formattedValue);
      const realValue = removeNonNumeric(typedValue);
      setValue(realValue);
      return;
    }
  };

  return (
    <Text
      disabled={disabled}
      labelPosition="above"
      size={size}
      label={<Etiqueta texto={label} obligatorio={required} />}
      value={shownValue}
      onChange={handleChange}
      addonLeft={addonLeft ? addonLeft : null}
      onBlur={removeLastComma}
    />
  );
}

function MonedaInput({
  setValue,
  label,
  size = "sm",
  initialValue = "",
  disabled = false,
  required = false,
  moneda = monedasSoportadas.PYG,
}) {
  // throw new Error('No funciona');

  if (!monedasSoportadas[moneda]) {
    throw new Error("Moneda no soportada");
  }

  return (
    <NumericInput
      setValue={setValue}
      label={label}
      size={size}
      initialValue={initialValue}
      disabled={disabled}
      addonLeft={prefixByMoneda[moneda]}
      required={required}
    />
  );
}

export { MonedaInput };
