/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';

import { Alert } from '../../utils/Alert';

import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDateOrEmpty, toTitleCase } from '../../utils/utils';
import { nivelFormacionDescById } from '../../utils/constants';

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.formaciones === prevProps.formaciones &&
    nextProps.formacionSubmitAlert === prevProps.formacionSubmitAlert &&
    nextProps.modelGetCandidatoFormaciones.loading ===
      prevProps.modelGetCandidatoFormaciones.loading &&
    nextProps.prepareCandFormEditAndShowModal ===
      prevProps.prepareCandFormEditAndShowModal
  );
};

const Formacion = memo(function Componente({
  setIsModalAgregarFormacionShown,
  formacionSubmitAlert,
  modelGetCandidatoFormaciones,
  formaciones,
  setSelectedFormacion,
  setIsModalEliminarCandFormShown,
  prepareCandFormEditAndShowModal,
}) {
  return (
    <Row>
      <Col md="10" mdOffset="1">
        <Box
          title="Formación"
          customOptions={
            <div>
              <Button
                size="sm"
                type="default"
                text="Agregar"
                onClick={() => setIsModalAgregarFormacionShown(true)}
              />
            </div>
          }
        >
          {formacionSubmitAlert.text && (
            <Alert
              title={formacionSubmitAlert.title}
              type={formacionSubmitAlert.type}
              text={formacionSubmitAlert.text}
              closable={formacionSubmitAlert.closable}
            />
          )}

          <Loader isLoading={modelGetCandidatoFormaciones.loading}>
            <div style={{ display: 'block' }}>
              <span>Total: {formaciones?.length}</span>
            </div>

            {formaciones && formaciones.length > 0 ? (
              <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                <div className="table-responsive">
                  <DataTable
                    lazy
                    pt={{
                      table: {
                        className: 'table table-hover',
                      },
                    }}
                    value={formaciones}
                    emptyMessage="No se encontró registros de formación"
                  >
                    <Column
                      field="acciones"
                      header="Acciones"
                      body={(formacion) => (
                        <div style={{ width: 130 }}>
                          <div
                            style={{
                              display: 'inline-block',
                              marginRight: 5,
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="warning"
                              text="Eliminar"
                              submit={() => {
                                setSelectedFormacion(formacion);
                                setIsModalEliminarCandFormShown(true);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="default"
                              text="Editar"
                              submit={() => {
                                setSelectedFormacion(formacion);
                                prepareCandFormEditAndShowModal(formacion);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="institutoDescripcion"
                      header="Institución educativa"
                      body={(formacion) =>
                        toTitleCase(formacion.institutoDescripcion)
                      }
                    ></Column>
                    <Column
                      field="titulacionDescripcion"
                      header="Titulación"
                      body={(formacion) =>
                        toTitleCase(formacion.titulacionDescripcion)
                      }
                    ></Column>
                    <Column
                      field="nivelFormacion"
                      header="Nivel de formación"
                      body={(formacion) => {
                        return (
                          nivelFormacionDescById[formacion.nivelFormacion] ??
                          'N/A'
                        );
                      }}
                    ></Column>
                    <Column
                      field="inicioFecha"
                      header="Fecha de inicio"
                      style={{ minWidth: 90 }}
                      body={(formacion) =>
                        getDateOrEmpty(formacion.inicioFecha)
                      }
                    ></Column>
                    <Column
                      field="finFecha"
                      header="Fecha de finalización o previsto"
                      style={{ minWidth: 90 }}
                      body={(formacion) => getDateOrEmpty(formacion.finFecha)}
                    ></Column>
                    <Column field="descripcion" header="Descripción"></Column>
                  </DataTable>
                </div>
              </Box>
            ) : (
              <h4>No se han encontrado registros de formación</h4>
            )}
          </Loader>
        </Box>
      </Col>
    </Row>
  );
},
customComparator);

export { Formacion };
