import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  getNumberOrNull,
  prepareCiudadesOptions,
  prepareDatosAuxOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  crearOportunidadDuck,
  crearOportunidadThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  getCargosDuck,
  getCargosThunk,
  getRubrosDuck,
  getRubrosThunk,
  getEtapasDuck,
  getEtapasThunk,
  getUsuariosForSelectDuck,
  getUsuariosForSelectThunk,
} from './duck';
import { CrearOportunidadView } from './CrearOportunidadView';
import {
  asuncionOption,
  empleoTipoOptions,
  empleoTipoOptionsByValue,
  oportunidadEstadoOptions,
  pagoFormaOptions,
  pagoFormaOptionsByValue,
} from '../utils/constants';
import { getCiudadesDuck, getCiudadesThunk } from '../candidatos/duck';

function CrearOportunidad({ history, match, token }) {
  const dispatch = useDispatch();

  const proposRef = React.useRef(null);
  const responRef = React.useRef(null);
  const conEspRef = React.useRef(null);
  const conDesRef = React.useRef(null);
  const expReqRef = React.useRef(null);
  const aptitudesRef = React.useRef(null);
  const reqAdRef = React.useRef(null);
  const condContRef = React.useRef(null);

  const modelCrearOportunidad = useSelector((state) => state.crearOportunidad);
  const modelGetEmpresas = useSelector((state) => state.getEmpresas);
  const modelGetCargos = useSelector((state) => state.getCargos);
  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelUsuarios = useSelector((state) => state.getUsuariosForSelect);
  const modelGetCiudades = useSelector((state) => state.getCiudades);

  const [empresaOption, setEmpresaOption] = useState();
  const [empresasOptions, setEmpresasOptions] = useState();
  const [titulo, setTitulo] = useState('');
  const [tituloPublicacion, setTituloPublicacion] = useState('');
  const [cargosOptions, setCargosOptions] = useState([]);
  const [cargoOption, setCargoOption] = useState();
  const [rubrosOptions, setRubrosOptions] = useState([]);
  const [rubroOption, setRubroOption] = useState();
  const [rubroPublicacion, setRubroPublicacion] = useState();
  const [esConfidencial, setEsConfidencial] = useState(true);
  const [publicarWeb, setPublicarWeb] = useState(false);
  const [solicitudFecha, setSolicitudFecha] = useState(new Date());
  const estadosOptions = oportunidadEstadoOptions;
  const [estadoOption, setEstadoOption] = useState();
  const [cierreEsperadoFecha, setCierreEsperadoFecha] = useState(new Date());
  const [observacion, setObservacion] = useState('');
  const [etapas, setEtapas] = useState([]);
  const [etapasMarcadas, setEtapasMarcadas] = useState([]);
  const [salarioMinimo, setSalarioMinimo] = useState('');
  const [salarioMaximo, setSalarioMaximo] = useState('');
  const [usuarioOptions, setUsuarioOptions] = useState([]);
  const [aCargo, setACargo] = useState({});
  const [usuarioSelected, setUsuarioSelected] = useState('');
  const [rolSelected, setRolSelected] = useState('');
  const [ciudadesOptions, setCiudadesOptions] = useState();
  const [ciudadSelected, setCiudadSelected] = useState(asuncionOption);
  const [monedaSelected, setMonedaSelected] = useState();
  const [pagoFormaOption, setPagoFormaOption] = useState(
    pagoFormaOptionsByValue.month
  );
  const [empleoTipoOption, setEmpleoTipoOption] = useState(
    empleoTipoOptionsByValue.tiempoCompleto
  );
  const [muestraPosicion, setMuestraPosicion] = useState(true);
  const [muestraResponsabilidad, setMuestraResponsabilidad] = useState(true);
  const [muestraConocimientoEspecifico, setMuestraConocimientoEspecifico] =
    useState(true);
  const [muestraConocimientoDeseable, setMuestraConocimientoDeseable] =
    useState(true);
  const [muestraExperienciaRequerida, setMuestraExperienciaRequerida] =
    useState(true);
  const [muestraAptitud, setMuestraAptitud] = useState(true);
  const [muestraRequisitoAdicional, setMuestraRequisitoAdicional] =
    useState(false);
  const [muestraCondicionContratacion, setMuestraCondicionContratacion] =
    useState(false);

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const prepareEmpresasOptions = (empresas) => {
    const options = empresas.map((empresa) => ({
      value: empresa.idNumerico,
      label: toTitleCase(empresa.nombre),
    }));

    return options;
  };

  const [creado, setCreado] = useState(false);

  useEffect(() => {
    dispatch(
      getEmpresasThunk({
        esCliente: true,
        userToken: token.raw,
      })
    );
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getUsuariosForSelectThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getEtapasThunk({
        userToken: token.raw,
      })
    );
    dispatch(getCiudadesThunk({ userToken: token.raw, padreId: 'todos' }));
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCiudades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCiudadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCiudades.useEffect.data': data });

      const mCiudadesOptions = prepareCiudadesOptions(data.items);
      // log({ mCiudadesOptions });

      setCiudadesOptions(mCiudadesOptions);

      dispatch(getCiudadesDuck.actions.clear());
    }
  }, [modelGetCiudades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasDuck.actions.clear());
    }

    if (data) {
      const mEmpresasOptions = prepareEmpresasOptions(data.items);

      setEmpresasOptions(mEmpresasOptions);

      dispatch(getEmpresasDuck.actions.clear());
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUsuarios);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getUsuariosForSelectDuck.actions.clear());
    }

    if (data) {
      setUsuarioOptions(data?.data);
      dispatch(getUsuariosForSelectDuck.actions.clear());
    }
  }, [modelUsuarios]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setRubrosOptions(datosAuxOptions);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearOportunidad);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(crearOportunidadDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setCreado(true);

      dispatch(crearOportunidadDuck.actions.clear());

      setSubmitAlert({
        text: 'Oportunidad creada correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelCrearOportunidad]);

  const handleACargo = () => {
    const aux = { ...aCargo };
    aux[usuarioSelected?.value] = {
      rol: rolSelected?.value,
      rolLabel: rolSelected?.label,
      name: usuarioSelected?.label,
    };
    setACargo(aux);
    setRolSelected('');
    setUsuarioSelected('');
  };

  const deleteACargo = (usuario) => {
    const objAux = { ...aCargo };
    delete objAux[usuario];
    setACargo(objAux);
  };

  function submit() {
    log({
      proposRef: proposRef.current.getContent(),
      responRef: responRef.current.getContent(),
      conEspRef: conEspRef.current.getContent(),
      conDesRef: conDesRef.current.getContent(),
      expReqRef: expReqRef.current.getContent(),
      reqAdRef: reqAdRef.current.getContent(),
      condContRef: condContRef.current.getContent(),
    });

    const aCargoDeAux = Object.keys(aCargo);

    if (aCargoDeAux.length === 0) {
      setSubmitAlert({
        text: 'Debe seleccionar al menos un supervisor o selector',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    const aCargoDe = aCargoDeAux.map((key) => {
      return {
        usuarioId: key,
        tipo: aCargo[key]?.rol,
        porcentaje: null,
      };
    });

    let data = {
      userToken: token.raw,
      empresaIdNumerico: empresaOption?.value,
      titulo,
      estado: estadoOption?.value,
      cargoIdNumerico: cargoOption?.value,
      salarioMinimo: getNumberOrNull(salarioMinimo),
      salarioMaximo: getNumberOrNull(salarioMaximo),
      observacion,
      solicitudFecha: solicitudFecha,
      cierreEsperadoFecha: cierreEsperadoFecha,
      rubroIdNumerico: rubroOption?.value,
      proposito: proposRef.current.getContent(),
      responsabilidad: responRef.current.getContent(),
      conocimientoEspecifico: conEspRef.current.getContent(),
      conocimientoDeseable: conDesRef.current.getContent(),
      experienciaRequerida: expReqRef.current.getContent(),
      aptitud: null,
      requisitoAdicional: reqAdRef.current.getContent(),
      condicionContratacion: condContRef.current.getContent(),
      esConfidencial: esConfidencial,
      publicarWeb: publicarWeb,
      // enviarCorreo: Boolean,
      muestraPosicion,
      muestraResponsabilidad,
      muestraConocimientoEspecifico,
      muestraConocimientoDeseable,
      muestraExperienciaRequerida,
      muestraAptitud,
      muestraRequisitoAdicional,
      muestraCondicionContratacion,
      // experienciaCargo: Boolean,
      // experienciaRubro: Boolean,
      // deseoCargo: Boolean,
      // deseoRubro: Boolean,
      tituloPublicacion,
      rubroPublicacion,
      ciudadIdNumerico: ciudadSelected?.value,
      ciudadDescripcion: ciudadSelected?.label,
      empleoTipo: empleoTipoOption?.value,
      pagoForma: pagoFormaOption?.value,
      // titulaciones: [String], // array de ids de titulacion,
      aCargoDe,
    };

    log({ 'submit.data': data });

    dispatch(crearOportunidadThunk(data));
  }

  function handleSelection(id, value) {
    if (value === true) {
      setEtapasMarcadas((arrayMarcados) => [...arrayMarcados, id]);
    } else {
      setEtapasMarcadas((arrayMarcados) =>
        arrayMarcados.filter((elem) => elem !== id)
      );
    }
  }

  return (
    <CrearOportunidadView
      history={history}
      match={match}
      modelCrearOportunidad={modelCrearOportunidad}
      empresaOption={empresaOption}
      titulo={titulo}
      tituloPublicacion={tituloPublicacion}
      cargoOption={cargoOption}
      rubroOption={rubroOption}
      rubroPublicacion={rubroPublicacion}
      esConfidencial={esConfidencial}
      publicarWeb={publicarWeb}
      solicitudFecha={solicitudFecha}
      estadoOption={estadoOption}
      cierreEsperadoFecha={cierreEsperadoFecha}
      observacion={observacion}
      empresasOptions={empresasOptions}
      setEmpresaOption={setEmpresaOption}
      setTitulo={setTitulo}
      setTituloPublicacion={setTituloPublicacion}
      cargosOptions={cargosOptions}
      setCargoOption={setCargoOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      setRubroPublicacion={setRubroPublicacion}
      setEsConfidencial={setEsConfidencial}
      setSolicitudFecha={setSolicitudFecha}
      estadosOptions={estadosOptions}
      setEstadoOption={setEstadoOption}
      setCierreEsperadoFecha={setCierreEsperadoFecha}
      setObservacion={setObservacion}
      setPublicarWeb={setPublicarWeb}
      etapas={etapas}
      handleSelection={handleSelection}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      salarioMinimo={salarioMinimo}
      setSalarioMinimo={setSalarioMinimo}
      salarioMaximo={salarioMaximo}
      setSalarioMaximo={setSalarioMaximo}
      pagoFormaOption={pagoFormaOption}
      setPagoFormaOption={setPagoFormaOption}
      pagoFormaOptions={pagoFormaOptions}
      empleoTipoOption={empleoTipoOption}
      setEmpleoTipoOption={setEmpleoTipoOption}
      empleoTipoOptions={empleoTipoOptions}
      aCargo={aCargo}
      setACargo={setACargo}
      usuarioOptions={usuarioOptions}
      usuarioSelected={usuarioSelected}
      setUsuarioSelected={setUsuarioSelected}
      rolSelected={rolSelected}
      setRolSelected={setRolSelected}
      handleACargo={handleACargo}
      deleteACargo={deleteACargo}
      ciudadSelected={ciudadSelected}
      setCiudadSelected={setCiudadSelected}
      monedaSelected={monedaSelected}
      setMonedaSelected={setMonedaSelected}
      proposRef={proposRef}
      responRef={responRef}
      conEspRef={conEspRef}
      conDesRef={conDesRef}
      expReqRef={expReqRef}
      aptitudesRef={aptitudesRef}
      reqAdRef={reqAdRef}
      condContRef={condContRef}
      muestraPosicion={muestraPosicion}
      setMuestraPosicion={setMuestraPosicion}
      muestraResponsabilidad={muestraResponsabilidad}
      setMuestraResponsabilidad={setMuestraResponsabilidad}
      muestraConocimientoEspecifico={muestraConocimientoEspecifico}
      setMuestraConocimientoEspecifico={setMuestraConocimientoEspecifico}
      muestraConocimientoDeseable={muestraConocimientoDeseable}
      setMuestraConocimientoDeseable={setMuestraConocimientoDeseable}
      muestraExperienciaRequerida={muestraExperienciaRequerida}
      setMuestraExperienciaRequerida={setMuestraExperienciaRequerida}
      muestraAptitud={muestraAptitud}
      setMuestraAptitud={setMuestraAptitud}
      muestraRequisitoAdicional={muestraRequisitoAdicional}
      setMuestraRequisitoAdicional={setMuestraRequisitoAdicional}
      muestraCondicionContratacion={muestraCondicionContratacion}
      setMuestraCondicionContratacion={setMuestraCondicionContratacion}
      ciudadesOptions={ciudadesOptions}
      creado={creado}
    />
  );
}

export { CrearOportunidad };
