/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Content,
  Row,
  Col,
  Box,
  Button,
  Pagination,
  Inputs,
} from 'adminlte-2-react';
import Loader from '../utils/Loader';
import { Alert, alertTypes } from '../ui/alert';
import {
  constants,
  getDate,
  getDateOrEmpty,
  getDateUtc,
  log,
  openWhatsapp,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { ModalCambioEtapa } from './ModalCambioEtapa';
import { ModalConfirmarDesactivar } from './ModalConfirmarDesactivar';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { SelectInput } from '../utils/SelectInput';
import { oportunidadEstadoIds } from '../utils/constants';
import { ModalConfirmarReposicion } from './ModalConfirmarReposicion';
import { CustomDescriptionBlock } from '../ui/layout/CustomDescriptionBlock';
import { ModalNotas } from '../candidatos/ModalNotas';
import { TextInput } from '../utils/TextInput';
import { todosOption } from './EditarOportunidadShared';
import { CandidatosEnOportunidad } from './CandidatosEnOportunidad';

function EnviarMailInicio({ oportunidad, enviarMailInicio, correoEnviado }) {
  if (oportunidad.correoInicioEnviadoTs) {
    return `Correo enviado el ${getDateOrEmpty(
      oportunidad.correoInicioEnviadoTs
    )}`;
  }

  if (correoEnviado) {
    return `Correo enviado el ${getDate()}`;
  }

  return (
    <ButtonWithLoading
      type="default"
      size="sm"
      text="Enviar mail"
      submit={() => enviarMailInicio(oportunidad)}
    />
  );
}



function getEtapas({ candidato, etapasById }) {
  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>
        {etapasById?.[candidato?.etapasIds?.[0]]?.descripcion || 'N/A'}
      </div>
      {candidato.etapasIds.length > 1
        ? candidato.etapasIds.slice(1).map((etapaId) => {
            return (
              <div className="text-muted">
                {etapasById[etapaId].descripcion}
              </div>
            );
          })
        : null}
    </div>
  );
}

function EditarOportunidadView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  candidatos,
  modelGetCandidatosEnOport,
  oportunidadId,
  hideModalCambioEtapa,
  showModalCambioEtapa,
  etapaOption,
  etapasOptions,
  setEtapaOption,
  oportunidad,
  etapasById,
  oportunidadLoadStatus,
  selectedCandidato,
  setSelectedCandidato,
  isModalCambioEtapaShown,
  avanzarAEtapa,
  modelAvanzarAEtapa,
  isModalConfirmarDesactivarShown,
  hideModalConfirmarDesactivar,
  showModalConfirmarDesactivar,
  desactivarCandidato,
  guardarEdicion,
  estadosOptions,
  estadoOption,
  setEstadoOption,
  modelGuardarEdicionOport,
  modelDesactivarCandidato,
  showModalConfirmarReposicion,
  hideModalConfirmarReposicion,
  isModalConfirmarReposicionShown,
  confirmarReposicion,
  modelGenerarReposicion,
  incluirCandidatos,
  setIncluirCandidatos,
  etapasEnOportunidad,
  conteoPorEtapa,
  isModalDescarteShown,
  hideModalDescarte,
  showModalDescarte,
  descartarCandidato,
  motivoOption,
  motivosOptions,
  setMotivoOption,
  modelDescartarCandidato,
  descarteAlert,
  modelGetResumenEtapas,
  modelGetOportunidad,

  isModalNotasShown,
  hideModalNotas,
  showModalNotas,
  notas,
  totalResultsNotas,
  activePageNotas,
  pageSizeNotas,
  candidatoIdNumerico,
  setCandidatoIdNumerico,
  handlePageNotas,
  setTitulo,
  titulo,
  setContenido,
  contenido,
  submitNota,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,

  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,

  enviarMailInicio,
  modelEnviarMailInicio,

  correoTopAlert,
  correoEnviado,
  agregarCargoPot,
  cargoOption,
  setCargoOption,
  cargosOptions,
  modelCrearCargoPot,
  cargoPotSubmitAlert,
  modelGetCargosPot,
  cargosPotenciales,
  expectativaSalarial,
  setExpectativaSalarial,
  notaAlert,
  setNotaAlert,

  filtroNombre,
  setFiltroNombre,

  filtroEtapaOption,
  setFiltroEtapaOption,
  filtroEtapaOptions,

  filtroMotivoDescarteOption,
  setFiltroMotivoDescarteOption,
  filtroMotivoDescarteOptions,

  filtroOrigenPostulacionOption,
  filtroOrigenPostulacionOptions,
  setFiltroOrigenPostulacionOption,

  accion,
  setAccion,

  notaFavoritaSet,
  setearNotaFavorita,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1200;

  return (
    <Content
      title="Editar oportunidad"
      subTitle=""
      browserTitle="Editar oportunidad"
      show={false}
    >
      {oportunidad && selectedCandidato && accion && (
        <ModalCambioEtapa
          isModalCambioEtapaShown={isModalCambioEtapaShown}
          hideModalCambioEtapa={hideModalCambioEtapa}
          showModalCambioEtapa={showModalCambioEtapa}
          avanzarAEtapa={avanzarAEtapa}
          descartarCandidato={descartarCandidato}
          isAvanzarButtonDisabled={accion === 'avance' && !etapaOption?.value}
          isAvanzarButtonLoading={
            modelAvanzarAEtapa.loading || modelDescartarCandidato.loading
          }
          etapaOption={etapaOption}
          etapasOptions={etapasOptions}
          setEtapaOption={setEtapaOption}
          oportunidad={oportunidad}
          candidato={selectedCandidato}
          etapasById={etapasById}
          titulo={titulo}
          setTitulo={setTitulo}
          contenido={contenido}
          setContenido={setContenido}
          candidatoIdNumerico={candidatoIdNumerico}
          agregarCargoPot={agregarCargoPot}
          cargoOption={cargoOption}
          setCargoOption={setCargoOption}
          cargosOptions={cargosOptions}
          modelCrearCargoPot={modelCrearCargoPot}
          cargoPotSubmitAlert={cargoPotSubmitAlert}
          modelGetCargosPot={modelGetCargosPot}
          cargosPotenciales={cargosPotenciales}
          expectativaSalarial={expectativaSalarial}
          setExpectativaSalarial={setExpectativaSalarial}
          accion={accion}
          motivoOption={motivoOption}
          motivosOptions={motivosOptions}
          setMotivoOption={setMotivoOption}
        />
      )}

      {selectedCandidato && (
        <ModalConfirmarDesactivar
          isModalConfirmarDesactivarShown={isModalConfirmarDesactivarShown}
          hideModalConfirmarDesactivar={hideModalConfirmarDesactivar}
          showModalConfirmarDesactivar={showModalConfirmarDesactivar}
          selectedCandidato={selectedCandidato}
          etapasById={etapasById}
          desactivarCandidato={desactivarCandidato}
          isDesactivarButtonLoading={modelDesactivarCandidato.loading}
        />
      )}

      <ModalNotas
        candidatoIdNumerico={candidatoIdNumerico}
        isModalShown={isModalNotasShown}
        hideModal={hideModalNotas}
        showModal={showModalNotas}
        notas={notas}
        history={history}
        activePage={activePageNotas}
        pageSize={pageSizeNotas}
        totalResults={totalResultsNotas}
        handlePage={handlePageNotas}
        submitNota={submitNota}
        contenido={contenido}
        setContenido={setContenido}
        titulo={titulo}
        setTitulo={setTitulo}
        notaSelected={notaSelected}
        setNotaSelected={setNotaSelected}
        userIdNumerico={userIdNumerico}
        deleteNota={deleteNota}
        cuentaFiltroChecked={cuentaFiltroChecked}
        setCuentaFiltroChecked={setCuentaFiltroChecked}
        entrevistaCortesiaChecked={entrevistaCortesiaChecked}
        setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
        resetNotaInputs={resetNotaInputs}
        agregarCargoPot={agregarCargoPot}
        cargoOption={cargoOption}
        setCargoOption={setCargoOption}
        cargosOptions={cargosOptions}
        modelCrearCargoPot={modelCrearCargoPot}
        cargoPotSubmitAlert={cargoPotSubmitAlert}
        modelGetCargosPot={modelGetCargosPot}
        cargosPotenciales={cargosPotenciales}
        expectativaSalarial={expectativaSalarial}
        setExpectativaSalarial={setExpectativaSalarial}
        notaAlert={notaAlert}
        notaFavoritaSet={notaFavoritaSet}
        setearNotaFavorita={setearNotaFavorita}
      />

      <ModalConfirmarReposicion
        isModalConfirmarReposicionShown={isModalConfirmarReposicionShown}
        hideModalConfirmarReposicion={hideModalConfirmarReposicion}
        showModalConfirmarReposicion={showModalConfirmarReposicion}
        confirmarReposicion={confirmarReposicion}
        isConfirmarReposicionButtonLoading={modelGenerarReposicion.loading}
        incluirCandidatos={incluirCandidatos}
        setIncluirCandidatos={setIncluirCandidatos}
      />

      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Loader isLoading={oportunidadId && !oportunidad}>
        <Row>
          <Col md="10" mdOffset="1">
            <Box
              title="Datos"
              customOptions={
                <div>
                  {oportunidad?.estado === oportunidadEstadoIds.f && (
                    <Button
                      size="sm"
                      margin
                      type="default"
                      text="Reposición"
                      onClick={showModalConfirmarReposicion}
                    />
                  )}
                  <Button
                    size="sm"
                    type="default"
                    margin
                    text="Editar detalles"
                    onClick={() =>
                      history.push(`/editarDetalleOportunidad/${oportunidadId}`)
                    }
                  />
                </div>
              }
            >
              <ReferenciaObligatorios />
              <SelectInput
                label="Estado"
                value={estadoOption}
                options={estadosOptions}
                setValue={setEstadoOption}
                required
              />

              <ButtonWithLoading
                text="Guardar"
                submit={guardarEdicion}
                isLoading={modelGuardarEdicionOport.loading}
              />
            </Box>
          </Col>
        </Row>

        <Loader
          isLoading={
            modelGetResumenEtapas.loading || modelGetOportunidad.loading
          }
        >
          {!!etapasEnOportunidad && etapasById && (
            <Row>
              <Col md="10" mdOffset="1" sm="12" xs="12">
                <Box title="Candidatos por etapa">
                  <Row>
                    {etapasEnOportunidad.map((etapaIdNumerico) => {
                      return (
                        <Col sm={4} xs={4}>
                          <CustomDescriptionBlock
                            text={etapasById[etapaIdNumerico]?.descripcion}
                            header={conteoPorEtapa[etapaIdNumerico]}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Box>
              </Col>
            </Row>
          )}
        </Loader>

        <Row>
          <Col xs="12" md="10" mdOffset="1">
            <Box title="Correo de inicio de oportunidad">
              {correoTopAlert.text && (
                <Alert
                  title={correoTopAlert.title}
                  type={correoTopAlert.type}
                  text={correoTopAlert.text}
                  closable={correoTopAlert.closable}
                />
              )}

              <Loader isLoading={!oportunidad || modelEnviarMailInicio.loading}>
                <EnviarMailInicio
                  oportunidad={oportunidad}
                  modelEnviarMailInicio={modelEnviarMailInicio}
                  enviarMailInicio={enviarMailInicio}
                  correoEnviado={correoEnviado}
                />
              </Loader>
            </Box>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="10" mdOffset="1">
            <Box title="Candidatos incluidos">
              {descarteAlert.text && (
                <Alert
                  title={descarteAlert.title}
                  type={descarteAlert.type}
                  text={descarteAlert.text}
                  closable={descarteAlert.closable}
                />
              )}

              <div style={{ marginBottom: 20 }}>
                <h4>Filtros</h4>

                <TextInput
                  label="Nombre"
                  placeholder="Nombre"
                  initialValue={filtroNombre}
                  setValue={setFiltroNombre}
                  onKeyDown={(event) =>
                    submitSearchOnEnterPress(event, () => handlePage(0))
                  }
                />

                <SelectInput
                  label="Etapa"
                  value={filtroEtapaOption}
                  options={filtroEtapaOptions}
                  setValue={setFiltroEtapaOption}
                />

                <SelectInput
                  label="Motivo de descarte"
                  value={filtroMotivoDescarteOption}
                  options={filtroMotivoDescarteOptions}
                  setValue={setFiltroMotivoDescarteOption}
                />

                <SelectInput
                  label="Origen de postulación"
                  value={filtroOrigenPostulacionOption}
                  options={filtroOrigenPostulacionOptions}
                  setValue={setFiltroOrigenPostulacionOption}
                />

                <ButtonWithLoading
                  text="Buscar"
                  submit={() => handlePage(0)}
                  isLoading={modelGetCandidatosEnOport.loading}
                />
              </div>

              <h4>Lista</h4>

              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Pagination
                    activePage={activePage}
                    pageSize={pageSize}
                    totalElements={totalResults}
                    onChange={handlePage}
                    labels={{
                      first: 'Primera',
                      last: 'Última',
                      previous: 'Anterior',
                      next: 'Siguiente',
                    }}
                  />
                </div>
              </div>

              <CandidatosEnOportunidad
                modelGetCandidatosEnOport={modelGetCandidatosEnOport}
                candidatos={candidatos}
                getEtapas={getEtapas}
                etapasById={etapasById}
                oportunidadId={oportunidadId}
                setCandidatoIdNumerico={setCandidatoIdNumerico}
                setNotaAlert={setNotaAlert}
                setSelectedCandidato={setSelectedCandidato}
                setAccion={setAccion}
                showModalCambioEtapa={showModalCambioEtapa}
                showModalConfirmarDesactivar={showModalConfirmarDesactivar}
                oportunidadLoadStatus={oportunidadLoadStatus}
              />

              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
              <div>
                <span>Total: {totalResults}</span>
              </div>

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { EditarOportunidadView };
