import createDuck, { createAction, createThunk } from './../utils/actions';
import axios from '../net/axios';
import config from './../config';

const baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duck = createDuck('LOGIN_STANDARD', newActions, newReducers);

const loginStandardThunk = (data) => {
  const url = `${baseUrl}/login`;
  return createThunk(axios, 'post', duck.actions, url, data);
};

const duckSocial = createDuck('LOGIN_SOCIAL', newActions, newReducers);

const loginSocialThunk = (data) => {
  const url = `${baseUrl}/login`;
  return createThunk(axios, 'post', duckSocial.actions, url, data);
};

const editContrasenaDuck = createDuck(
  'CONTRASENA_EDIT',
  newActions,
  newReducers,
);

const editContrasenaThunk = (data) => {
  const url = `${baseUrl}/credencial/password?userToken=${data.userToken}`;
  return createThunk(axios, 'post', editContrasenaDuck.actions, url, data);
};

const mExports = {
  duck,
  loginStandardThunk,
  duckSocial,
  loginSocialThunk,
  editContrasenaDuck,
  editContrasenaThunk,
};

export default mExports;
