import React, { useEffect, useState } from "react";
import { clearLocalStorage } from "../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "tom.com.py";

function Logout({ history }) {
  useEffect(() => {
    // document.cookie =
    //   "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
    //   COOKIE_DOMAIN;

    clearLocalStorage();

    const url = "/login";
    window.location.assign(url);
  }, []);

  return <div />;
}

export default Logout;
