import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button } from 'adminlte-2-react';
import { duck, registroThunk } from './duck';
import { useSelector, useDispatch } from 'react-redux';
import {
  constants,
  getDate,
  responseSelector,
  validateEmail,
} from '../utils/utils';
import Loader from '../utils/Loader';
import { TextInput } from '../utils/TextInput';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert, alertTypes } from '../utils/Alert';
import { PhoneV2 } from '../utils/PhoneV2';
import { SelectInput } from '../utils/SelectInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';
import { PhoneInputV3 } from '../ui/PhoneInput/PhoneInputV3';
import { DateInput } from '../utils/DateInput';

const queryString = require('query-string');

function RegistroSuper({ history, match }) {
  const parsedQueryParams = queryString.parse(history?.location?.search);

  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const submitAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [submitAlert, setSubmitAlert] = useState(submitAlertDefaultValue);
  const dispatch = useDispatch();
  const modelRegistro = useSelector((state) => state.registro);
  const [username, setUsername] = useState(parsedQueryParams.email ?? '');
  const [password, setPassword] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const [direccion, setDireccion] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [tel, setTel] = useState('');
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState({
    value: 'CI',
    label: 'Cedula de identidad',
  });
  const [fechaNacimiento, setFechaNacimiento] = useState('');

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelRegistro);

    if (error) {
      setSubmitAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(duck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setSubmitAlert({
        title: 'Éxito',
        text: 'Registro exitoso. Te enviamos un correo para confirmar tu cuenta.',
        type: alertTypes.success,
        closable: false,
      });
      dispatch(duck.actions.clear());
    }
  }, [dispatch, modelRegistro]);

  function registrar() {
    if (!validateEmail(username)) {
      setSubmitAlert({
        title: 'Error',
        text: 'Por favor proporcione un email con el formato correcto',
        type: alertTypes.danger,
        closable: false,
      });

      return;
    }

    if (
      !username ||
      !password ||
      !nombre ||
      !apellido ||
      !documentType ||
      !document
    ) {
      setSubmitAlert({
        title: 'Error',
        text: 'Por favor complete todos los campos',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    let data = {
      username,
      password,
      nombre: nombre,
      apellido,
      document,
      documentType: documentType?.value,
      fechaNacimiento: getDate(fechaNacimiento),
      phone: tel === '+595' ? null : tel,
      address: {
        direccion,
        lat,
        lng,
      },
    };
    dispatch(registroThunk(data));
  }
  return (
    <Content title="Registro" browserTitle="Registro" show={false}>
      <Row>
        <Col md="4" mdOffset="4">
          <Box
            title="Nuevo usuario"
            footer={
              <Button
                type="primary"
                disabled={modelRegistro.loading}
                text={
                  <Loader
                    loaderColor="#ffffff"
                    isLoading={modelRegistro.loading}
                  >
                    Registrarse
                  </Loader>
                }
                pullRight={true}
                onClick={registrar}
              />
            }
          >
            <ReferenciaObligatorios />

            {topAlert.text && (
              <Alert
                title={topAlert.title}
                type={topAlert.type}
                text={topAlert.text}
                closable={topAlert.closable}
              />
            )}

            <TextInput
              label="Nombre"
              initialValue={nombre}
              setValue={setNombre}
              required={true}
            />

            <TextInput
              label="Apellido"
              initialValue={apellido}
              setValue={setApellido}
              required={true}
            />

            <SelectInput
              label="Tipo de documento"
              value={documentType}
              options={[
                { value: 'CI', label: 'Cedula de identidad' },
                { value: 'DNI', label: 'DNI' },
                { value: 'PASSPORT', label: 'Pasaporte' },
              ]}
              setValue={setDocumentType}
              required={true}
            />

            <TextInput
              label={'Nro de documento'}
              setValue={setDocument}
              required={true}
            />

            <TextInput
              label="Email"
              initialValue={username}
              setValue={setUsername}
              required={true}
            />

            <TextInput
              inputType="password"
              label="Password"
              initialValue={password}
              setValue={setPassword}
              required={true}
            />

            <DateInput
              label="Fecha de nacimiento"
              selected={fechaNacimiento}
              onChange={setFechaNacimiento}
              showYearDropdown
              scrollableYearDropdown={false}
            />

            <PhoneInputV3
              required={true}
              label="Teléfono"
              value={tel}
              setValue={setTel}
            />

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export default RegistroSuper;
