import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import CortarImagen from './CortarImagen';
import { logger } from '../utils/utils';

import { Button, Content, Box, Row, Col } from 'adminlte-2-react';

export default function CortarImagenes({
  imagenesTemporales,
  imgTmpRespuestaRecibida,
  uploadUrl,
  uploadHeaders,
  eliminacionLoading,
  onClickEliminar, // Recibe id de imagen.
  onAllDone,
  minCroppedWidth,
  minCroppedHeight,
  maxCroppedWidth,
  maxCroppedHeight,
  aspectRatioNumerator,
  aspectRatioDenominator,
  conceptoId,
  saveInConceptUrl,
  relatedImageId,
  history,
  cancelUrl,
}) {
  const [currentImg, setCurrentImg] = useState(0);
  const [urlImagen, setUrlImagen] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({});
  const [cropperLoading, setCropperLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [allFinished, setAllFinished] = useState(false);

  // const cropperRef = useRef(null);
  const cropperInstance = useRef(null);

  useEffect(() => {
    if (!imagenesTemporales || !imagenesTemporales?.length) {
      return;
    }

    setCurrentImg(0);
    setUrlImagen(imagenesTemporales[0].originalUrl);
    setUploadStatus(prepareUploadStatus(imagenesTemporales));
  }, [imagenesTemporales]);

  const prepareUploadStatus = (data) => {
    const newUploadStatus = {};
    data.forEach((element, index) => {
      newUploadStatus[index] = {};
      newUploadStatus[index]['status'] = false;
    });

    return newUploadStatus;
  };

  const getFilenameWithoutExtension = (str) => {
    if (typeof str !== 'string') return;
    let frags = str.split('.');

    if (frags.length == 1) {
      return str;
    }
    return frags.splice(0, frags.length - 1).join('.');
  };

  const getCanvasBlob = (canvas) => {
    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const fileUpload = async () => {
    setUploading(true);
    const croppedCanvas = cropperInstance.current.getCroppedCanvas();
    const blob = await getCanvasBlob(croppedCanvas);
    await uploadBlobAndGoNext(blob);
  };

  const uploadBlobAndGoNext = async (blob) => {
    const url = uploadUrl;
    const formData = new FormData();
    const filename = getFilenameWithoutExtension(urlImagen);
    formData.append('files[]', blob, filename + '.png');
    formData.append('imagenID', imagenesTemporales[currentImg]._id);

    const config = { headers: uploadHeaders };

    try {
      const uploadResult = await axios.post(url, formData, config);

      logger.debug('fileUpload.uploadResult');
      logger.debug(uploadResult);

      const saveInConceptParams = {
        url: uploadResult.data.data.url,
        key: uploadResult.data.data.key,
        bucket: uploadResult.data.data.bucket,
        imagenID: imagenesTemporales[currentImg]._id,
        conceptoId: conceptoId,
        relatedImageId,
      };

      logger.debug({ saveInConceptUrl });
      logger.debug({ saveInConceptParams });

      await axios.post(saveInConceptUrl, saveInConceptParams, config);

      setUploadStatus((prevState) => ({
        ...prevState,
        [currentImg]: { status: true },
      }));
      setUploading(false);
      siguienteImagen();
    } catch (e) {
      alert('Ocurrió un error al subir la imagen');
      setUploading(false);
    }
  };

  const siguienteImagen = () => {
    setCropperLoading(true);

    const cantidad = Array.isArray(imagenesTemporales)
      ? imagenesTemporales.length
      : 0;

    const next = currentImg + 1;

    if (next > cantidad - 1) {
      setCropperLoading(false);
      setAllFinished(true);
      return;
    }

    setCurrentImg(next);

    setUrlImagen(imagenesTemporales[next].originalUrl);
  };

  const onCropperReady = () => {
    setCropperLoading(false);
  };

  const showLoaderAndDoEliminar = () => {
    setCropperLoading(true);
    onClickEliminar(imagenesTemporales[currentImg]._id);
  };

  // Check if all done.

  if (
    allFinished ||
    (imgTmpRespuestaRecibida === true && !imagenesTemporales?.length)
  ) {
    onAllDone();
  }

  const messageStyle = {
    backgroundColor: 'orange',
    padding: 10,
    fontSize: 18,
    display: cropperLoading ? 'none' : 'block',
  };

  const buttonContainerStyle = {
    display: cropperLoading ? 'none' : 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  const showUploadButton =
    uploadStatus[currentImg] &&
    uploadStatus[currentImg]['status'] &&
    eliminacionLoading === true &&
    cropperLoading === true
      ? false
      : true;

  const mostrarBotonSiguiente = !!uploadStatus[currentImg]?.['status'];

  // Render.

  return (
    <Box
      title="Paso 2 : Recorte"
      footer={
        <>
          {uploading && (
            <div
              style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}
            >
              <img src="/loading.gif" alt="Subiendo..." style={{ width: 45 }} />
              <p>Subiendo, por favor espere...</p>
            </div>
          )}

          {!uploading && (
            <div style={buttonContainerStyle}>
              {cancelUrl && (
                <Button
                  type="default"
                  text="Cancelar"
                  onClick={() => {
                    history.push(cancelUrl);
                  }}
                />
              )}

              <Button
                type="warning"
                onClick={showLoaderAndDoEliminar}
                text="Eliminar"
              />

              {showUploadButton && (
                <Button type="primary" onClick={fileUpload} text="Subir" />
              )}

              {mostrarBotonSiguiente && (
                <Button
                  type="default"
                  onClick={siguienteImagen}
                  text="Siguiente"
                />
              )}
            </div>
          )}
        </>
      }
    >
      <div>
        {cropperLoading && (
          <div style={{ textAlign: 'center' }}>
            <img src="/loading.gif" alt="Cargando..." style={{ width: 45 }} />
            <p>Cargando, por favor espere...</p>
          </div>
        )}

        <div style={messageStyle}>
          Para guardar las imágenes, tenés que recortarlas.
          <br />
          Las imágenes que no recortes serán eliminadas.
          <br />
          Las dimensiones deben ser:
          <ul>
            <li>
              Ancho: entre {minCroppedWidth} px.
              {maxCroppedWidth ? `y ${maxCroppedWidth} px.` : null}
            </li>
            <li>
              Alto: entre {minCroppedHeight} px.
              {maxCroppedHeight ? `y ${maxCroppedHeight} px.` : null}
            </li>
          </ul>
        </div>

        <div>
          <CortarImagen
            // cropperRef={cropperRef}
            cropperInstance={cropperInstance}
            urlImagen={urlImagen}
            onCropperReady={onCropperReady}
            minCroppedWidth={minCroppedWidth}
            minCroppedHeight={minCroppedHeight}
            maxCroppedWidth={maxCroppedWidth}
            maxCroppedHeight={maxCroppedHeight}
            aspectRatioNumerator={aspectRatioNumerator}
            aspectRatioDenominator={aspectRatioDenominator}
          />
        </div>
      </div>
    </Box>
  );
}
