import React, { useState, useEffect } from 'react';
import { parseJwt, sleep } from '../utils/utils';
import Loader from './Loader';
import { isExpired } from 'react-jwt';

// import { useLocation } from "react-router-dom";

const REDIRECT_ONBOARDING_ENABLED = true;

const SHOW_DEBUG_INFO = false;

// NOTA: si algún día se necesita usar
// useHistory.push en vez de window.location.replace
// en el return
// token ? <Children/> : <Loader/>;
// para que no monte los componentes hijos.

/**
 * allowedRoles = null para permitir cualquier rol, array de roles (string) para controlar
 * allowWithoutOnboarding = si esta ruta se puede acceder sin onboarding, setear true
 * additionalProps = props para pasarle al children
 * @param {*} param0
 * @returns
 */
function WithAuthV2({
  history,
  match,
  children,
  allowedRoles = null,
  datosUsuario,
  allowWithoutOnboarding = false,
  additionalProps,
}) {
  // const location = useLocation();
  // const location = window.location;

  const [token, setToken] = useState(() => {
    const rawToken = localStorage.getItem('token');
    if (!rawToken || (rawToken && isExpired(rawToken))) {
      localStorage.setItem('redirectTo', window.location.href);
      window.location.replace('/logout');
      return null;
    }

    let decodedToken = parseJwt(rawToken);
    localStorage.setItem('token', rawToken);

    const result = {
      raw: rawToken,
      decoded: decodedToken,
    };

    return result;
  });

  useEffect(() => {
    if (
      !token ||
      (allowedRoles?.length && !allowedRoles.includes(token.decoded.rol))
    ) {
      localStorage.setItem('redirectTo', window.location.href);
      window.location.replace('/logout');
      return;
    }
  }, [token, allowedRoles]);

  const Children = children;

  if (!token) {
    return <Loader>{SHOW_DEBUG_INFO ? 'Esperando token...' : null}</Loader>;
  }

  return (
    <Children
      token={token}
      history={history}
      match={match}
      datosUsuario={datosUsuario}
      {...additionalProps}
    />
  );
}

export default WithAuthV2;
