import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  constants,
  prepareDatosAuxOptions,
  responseSelector,
  toTitleCase,
} from '../../utils/utils';
import { useAlerts } from '../../ui/alert';
import { alertTypes } from '../../utils/Alert';
import { log } from '../../utils/logs';
import {
  editarCandFormDuck,
  editarCandFormThunk,
  editarCandidatoExpDuck,
  editarCandidatoExpThunk,
  getCandidatoExperienciasDuck,
  getCandidatoExperienciasThunk,
  getCandidatoFormacionesDuck,
  getCandidatoFormacionesThunk,
  getTitulacionesDuck,
  getTitulacionesThunk,
} from '../duck';
import { NormalizarCandidatoView } from './NormalizarCandidatoView';
import { getCargosDuck, getCargosThunk } from '../../oportunidades/duck';
const queryString = require('query-string');

function NormalizarCandidato({ history, match, token }) {
  const dispatch = useDispatch();
  const candidatoIdNumerico = match.params.candidatoId;

  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const modelGetCandidatoExperiencias = useSelector(
    (state) => state.getCandidatoExperiencias
  );
  const modelEditarCandForm = useSelector((state) => state.editarCandForm);
  const modelEditarCandidatoExp = useSelector(
    (state) => state.editarCandidatoExp
  );

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const [formacionTopAlert, setFormacionTopAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const modelGetCandidatoFormaciones = useSelector(
    (state) => state.getCandidatoFormaciones
  );
  const modelGetTitulaciones = useSelector((state) => state.getTitulaciones);
  const modelGetCargos = useSelector((state) => state.getCargos);

  const [formaciones, setFormaciones] = useState();
  const [isModalActualizarShown, setIsModalActualizarShown] = useState(false);
  const [isModalEditarCandFormShown, setIsModalEditarCandFormShown] =
    useState();
  const [titulacionOption, setTitulacionOption] = useState();
  const [titulacionesOptions, setTitulacionesOptions] = useState();
  const [titulacionOtro, setTitulacionOtro] = useState();
  const [institutoOtro, setInstitutoOtro] = useState();
  const [candFormIdNumerico, setCandFormIdNumerico] = useState();
  const [institutoIdNumerico, setInstitutoIdNumerico] = useState();
  const [institutoDescripcion, setInstitutoDescripcion] = useState();
  const [selectedFormacion, setSelectedFormacion] = useState();
  const [isDiscardCandFormModalShown, setIsDiscardCandFormModalShown] =
    useState(false);
  const [experienciaTopAlert, setExperienciaTopAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });
  const [experiencias, setExperiencias] = useState([]);
  const [selectedExperiencia, setSelectedExperiencia] = useState();
  const [isModalEditarExpShown, setIsEditarExpModalShown] = useState();
  const [empresaOtro, setEmpresaOtro] = useState();
  const [empresaDescripcion, setEmpresaDescripcion] = useState();
  const [candExpIdNumerico, setCandExpIdNumerico] = useState();
  const [cargoOption, setCargoOption] = useState();
  const [cargosOptions, setCargosOptions] = useState();
  const [cargoOtro, setCargoOtro] = useState();
  const [empresaIdNumerico, setEmpresaIdNumerico] = useState();
  const [isDiscardCandExpModalShown, setIsDiscardCandExpModalShown] =
    useState(false);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCargos.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetTitulaciones);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getTitulacionesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetInstitutos.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setTitulacionesOptions(datosAuxOptions);

      dispatch(getTitulacionesDuck.actions.clear());
    }
  }, [modelGetTitulaciones]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidatoFormaciones);

    if (error) {
      setFormacionTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatoFormacionesDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandidatoFormaciones.data': data });

      dispatch(getCandidatoFormacionesDuck.actions.clear());

      const allFormac = data.formaciones;

      setFormaciones(
        allFormac.filter((formacion) => formacion.normalizado === false)
      );

      if (parsedQueryParams.candFormIdNumerico) {
        prepareEditModalAndShow(
          allFormac.find(
            (formacion) =>
              formacion.idNumerico === parsedQueryParams.candFormIdNumerico
          )
        );
      }
    }
  }, [modelGetCandidatoFormaciones]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidatoExperiencias);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatoExperienciasDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCandidatoExperiencias.data': data });

      dispatch(getCandidatoExperienciasDuck.actions.clear());

      const allExp = data.experiencias;

      setExperiencias(allExp.filter((exp) => exp.normalizado === false));

      if (parsedQueryParams.candExpIdNumerico) {
        prepareExpEditAndShowModal(
          allExp.find(
            (exp) => exp.idNumerico === parsedQueryParams.candExpIdNumerico
          )
        );
      }
    }
  }, [modelGetCandidatoExperiencias]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandForm);

    if (error) {
      setFormacionTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editarCandFormDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandFormDuck.actions.clear());

      dispatch(
        getCandidatoFormacionesThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      history.replace(`/actualizarCandidato/${candidatoIdNumerico}`);

      setIsModalEditarCandFormShown(false);
      setIsDiscardCandFormModalShown(false);

      setFormacionTopAlert({
        text: 'Formación editada correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelEditarCandForm]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarCandidatoExp);

    if (error) {
      setExperienciaTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsEditarExpModalShown(false);

      dispatch(editarCandidatoExpDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarCandidatoExpDuck.actions.clear());

      dispatch(
        getCandidatoExperienciasThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      history.replace(`/actualizarCandidato/${candidatoIdNumerico}`);

      setExperienciaTopAlert({
        text: 'Experiencia editada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      setIsEditarExpModalShown(false);
      setIsDiscardCandExpModalShown(false);
    }
  }, [modelEditarCandidatoExp]);

  function editarCandForm() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      idNumerico: candFormIdNumerico,
      institutoIdNumerico: institutoIdNumerico,
      institutoDescripcion,
      institutoOtro: null,
      normalizado: 's',
      titulacionIdNumerico: titulacionOption?.value,
      titulacionDescripcion: titulacionOption?.label,
      titulacionOtro: null,
    };
    log({ 'editarCandForm.data': data });
    dispatch(editarCandFormThunk(data));
  }

  function editarExperiencia() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoExpIdNumerico: selectedExperiencia.idNumerico,
      empresaIdNumerico,
      cargoIdNumerico: cargoOption?.value,
      empresaOtro: null,
      cargoOtro: null,
      normalizado: 's',
    };
    log({ 'editarExperiencia.data': data });
    dispatch(editarCandidatoExpThunk(data));
  }

  function descartarCambiosCandForm() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      idNumerico: selectedFormacion.idNumerico,
      institutoOtro: null,
      titulacionOtro: null,
      normalizado: 's',
    };
    log({ 'editarCandForm.data': data });
    dispatch(editarCandFormThunk(data));
  }

  function descartarCambiosCandExp() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      candidatoExpIdNumerico: selectedExperiencia.idNumerico,
      empresaOtro: null,
      cargoOtro: null,
      normalizado: 's',
    };
    log({ 'editarExperiencia.data': data });
    dispatch(editarCandidatoExpThunk(data));
  }

  useEffect(() => {
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getTitulacionesThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getCandidatoExperienciasThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
    dispatch(
      getCandidatoFormacionesThunk({
        userToken: token.raw,
        candidatoIdNumerico,
      })
    );
  }, []);

  function prepareEditModalAndShow(formacion) {
    setCandFormIdNumerico(formacion.idNumerico);

    setInstitutoOtro(formacion.institutoOtro);

    setTitulacionOtro(formacion.titulacionOtro);

    if (parsedQueryParams?.institutoId) {
      setInstitutoIdNumerico(parsedQueryParams?.institutoId);
      setInstitutoDescripcion(parsedQueryParams?.institutoDescripcion);
    } else {
      setInstitutoIdNumerico(formacion.institutoIdNumerico);
      setInstitutoDescripcion(formacion.institutoDescripcion);
    }

    setTitulacionOption({
      value: formacion.titulacionIdNumerico,
      label: formacion.titulacionDescripcion,
    });

    setIsModalEditarCandFormShown(true);
  }

  function prepareExpEditAndShowModal(experiencia) {
    setSelectedExperiencia(experiencia);

    setCandExpIdNumerico(experiencia.idNumerico);

    setEmpresaOtro(experiencia.empresaOtro);

    setCargoOtro(experiencia.cargoOtro);

    if (parsedQueryParams?.empresaId) {
      setEmpresaIdNumerico(parsedQueryParams?.empresaId);
      setEmpresaDescripcion(parsedQueryParams?.empresaDescripcion);
    } else {
      setEmpresaIdNumerico(experiencia.empresaIdNumerico);
      setEmpresaDescripcion(experiencia.empresaDescripcion);
    }

    setCargoOption({
      value: experiencia.cargoIdNumerico,
      label: experiencia.cargoDescripcion,
    });

    setIsEditarExpModalShown(true);
  }

  return (
    <NormalizarCandidatoView
      history={history}
      match={match}
      topAlert={topAlert}
      formacionTopAlert={formacionTopAlert}
      modelGetCandidatoFormaciones={modelGetCandidatoFormaciones}
      formaciones={formaciones}
      isModalActualizarShown={isModalActualizarShown}
      setIsModalActualizarShown={setIsModalActualizarShown}
      candidatoId={candidatoIdNumerico}
      isModalEditarCandFormShown={isModalEditarCandFormShown}
      setIsModalEditarCandFormShown={setIsModalEditarCandFormShown}
      institutoDescripcion={institutoDescripcion}
      titulacionOption={titulacionOption}
      titulacionesOptions={titulacionesOptions}
      setTitulacionOption={setTitulacionOption}
      editarCandForm={editarCandForm}
      modelEditarCandForm={modelEditarCandForm}
      titulacionOtro={titulacionOtro}
      setTitulacionOtro={setTitulacionOtro}
      institutoOtro={institutoOtro}
      setInstitutoOtro={setInstitutoOtro}
      prepareEditModalAndShow={prepareEditModalAndShow}
      candFormIdNumerico={candFormIdNumerico}
      selectedFormacion={selectedFormacion}
      setSelectedFormacion={setSelectedFormacion}
      isDiscardCandFormModalShown={isDiscardCandFormModalShown}
      setIsDiscardCandFormModalShown={setIsDiscardCandFormModalShown}
      descartarCambiosCandForm={descartarCambiosCandForm}
      experienciaTopAlert={experienciaTopAlert}
      experiencias={experiencias}
      modelGetCandidatoExperiencias={modelGetCandidatoExperiencias}
      selectedExperiencia={selectedExperiencia}
      setSelectedExperiencia={setSelectedExperiencia}
      prepareExpEditAndShowModal={prepareExpEditAndShowModal}
      isModalEditarExpShown={isModalEditarExpShown}
      setIsEditarExpModalShown={setIsEditarExpModalShown}
      editarExperiencia={editarExperiencia}
      modelEditarCandidatoExp={modelEditarCandidatoExp}
      empresaOtro={empresaOtro}
      empresaDescripcion={empresaDescripcion}
      candExpIdNumerico={candExpIdNumerico}
      cargoOption={cargoOption}
      cargosOptions={cargosOptions}
      setCargoOption={setCargoOption}
      cargoOtro={cargoOtro}
      descartarCambiosCandExp={descartarCambiosCandExp}
      isDiscardCandExpModalShown={isDiscardCandExpModalShown}
      setIsDiscardCandExpModalShown={setIsDiscardCandExpModalShown}
    />
  );
}

export { NormalizarCandidato };
