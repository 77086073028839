import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getEmpresasWithPaginationDuck,
  getEmpresasWithPaginationThunk,
  deleteEmpresaDuck,
  deleteEmpresaThunk,
} from './duck';

import { constants, responseSelector } from '../utils/utils';

import { ListaEmpresasView } from './ListaEmpresasView';
import { esClienteOptionsByValue } from './shared';

const queryString = require('query-string');

function ListaEmpresas({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetEmpresas = useSelector(
    (state) => state.getEmpresasWithPagination
  );
  const modelDeleteEmpresa = useSelector((state) => state.deleteEmpresa);

  const [seleccion] = useState(parsedQueryParams?.seleccion);
  const [seleccionRetorno] = useState(parsedQueryParams?.seleccionRetorno);
  const [lista, setLista] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedEmpresa, setSelectedEmpresa] = useState();
  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] =
    useState(false);

  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );
  const [esCliente, setEsCliente] = useState(
    parsedQueryParams?.esCliente
      ? esClienteOptionsByValue[parsedQueryParams.esCliente]
      : esClienteOptionsByValue.SI
  );

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasWithPaginationDuck.actions.clear());
    }

    if (data) {
      setTotalResults(data.count);
      setLista(data.items);

      dispatch(getEmpresasWithPaginationDuck.actions.clear());
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteEmpresa);


    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setIsDeleteConfirmModalShown(false);

      dispatch(deleteEmpresaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {

      handlePage(0);

      setTopAlert({
        text: 'Empresa eliminada',
        type: alertTypes.success,
        closable: false,
      });

      setIsDeleteConfirmModalShown(false);
      dispatch(deleteEmpresaDuck.actions.clear());
    }
  }, [modelDeleteEmpresa]);

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    setLista([]);

    const params = {
      page: page + 1,
      limit: pageSize,
      busquedaRapida,
      esCliente: esCliente.value,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify({
      ...parsedQueryParams,
      ...params,
    })}`;

    history.replace(newUrl);

    dispatch(
      getEmpresasWithPaginationThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function deleteEmpresa() {
    dispatch(
      deleteEmpresaThunk({
        userToken: token.raw,
        empresaId: selectedEmpresa?.idNumerico,
      })
    );
  }

  return (
    <ListaEmpresasView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      lista={lista}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      modelGetEmpresas={modelGetEmpresas}
      esCliente={esCliente}
      setEsCliente={setEsCliente}
      deleteEmpresa={deleteEmpresa}
      modelDeleteEmpresa={modelDeleteEmpresa}
      selectedEmpresa={selectedEmpresa}
      setSelectedEmpresa={setSelectedEmpresa}
      isDeleteConfirmModalShown={isDeleteConfirmModalShown}
      setIsDeleteConfirmModalShown={setIsDeleteConfirmModalShown}
      seleccion={seleccion}
      seleccionRetorno={seleccionRetorno}
    />
  );
}

export { ListaEmpresas };
