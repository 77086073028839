import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  getNumberOrNull,
  prepareCiudadesOptions,
  prepareDatosAuxOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  editarDetallesOportunidadDuck,
  editarDetallesOportunidadThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  getCargosDuck,
  getCargosThunk,
  getRubrosDuck,
  getRubrosThunk,
  getEtapasDuck,
  getEtapasThunk,
  getUsuariosForSelectDuck,
  getUsuariosForSelectThunk,
  getOportunidadDuck,
  getOportunidadThunk,
  getOportFlyerDuck,
  getOportFlyerThunk,
  eliminarOportFlyerDuck,
  eliminarOportFlyerThunk,
  eliminarOportRelevamientoDuck,
  eliminarOportRelevamientoThunk,
  getOportRelevamientoDuck,
  getOportRelevamientoThunk,
} from './duck';
import { CrearOportunidadView } from './CrearOportunidadView';
import {
  asuncionOption,
  empleoTipoOptions,
  empleoTipoOptionsByValue,
  fuentesOptions,
  oportunidadEstadoOptions,
  oportunidadOptionsByValue,
  pagoFormaOptions,
  pagoFormaOptionsByValue,
} from '../utils/constants';
import { getCiudadesDuck, getCiudadesThunk } from '../candidatos/duck';

function EditarDetallesOportunidad({ history, match, token }) {
  const oportunidadId = match?.params?.oportunidadId;

  const dispatch = useDispatch();

  const proposRef = React.useRef(null);
  const responRef = React.useRef(null);
  const conEspRef = React.useRef(null);
  const conDesRef = React.useRef(null);
  const expReqRef = React.useRef(null);
  const aptitudesRef = React.useRef(null);
  const reqAdRef = React.useRef(null);
  const condContRef = React.useRef(null);

  const modelEditarDetalleOportunidad = useSelector(
    (state) => state.editarDetallesOportunidad
  );
  const modelGetOportunidad = useSelector((state) => state.getOportunidad);
  const modelGetOportFlyer = useSelector((state) => state.getOportFlyer);
  const modelGetOportRelevamiento = useSelector(
    (state) => state.getOportRelevamiento
  );

  const modelGetEmpresas = useSelector((state) => state.getEmpresas);
  const modelGetCargos = useSelector((state) => state.getCargos);
  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelUsuarios = useSelector((state) => state.getUsuariosForSelect);
  const modelGetCiudades = useSelector((state) => state.getCiudades);

  const modelEliminarOportFlyer = useSelector(
    (state) => state.eliminarOportFlyer
  );

  const modelEliminarOportRelevamiento = useSelector(
    (state) => state.eliminarOportRelevamiento
  );

  // Campos de texto enriquecido
  // son solamente valores iniciales,
  // no son inputs controlados.

  const [proposito, setProposito] = useState('');
  const [responsabilidad, setResponsabilidad] = useState('');
  const [conocimientoEspecifico, setConocimientoEspecifico] = useState('');
  const [conocimientoDeseable, setConocimientoDeseable] = useState('');
  const [experienciaRequerida, setExperienciaRequerida] = useState('');
  const [aptitud, setAptitud] = useState('');
  const [requisitoAdicional, setRequisitoAdicional] = useState('');
  const [condicionContratacion, setCondicionContratacion] = useState('');
  const [loadOportunidadStatus, setLoadOportunidadStatus] = useState('IDLE');
  const [empresaOption, setEmpresaOption] = useState();
  const [empresasOptions, setEmpresasOptions] = useState();
  const [titulo, setTitulo] = useState('');
  const [tituloPublicacion, setTituloPublicacion] = useState('');
  const [cargosOptions, setCargosOptions] = useState([]);
  const [cargoOption, setCargoOption] = useState();
  const [rubrosOptions, setRubrosOptions] = useState([]);
  const [rubroOption, setRubroOption] = useState();
  const [rubroPublicacion, setRubroPublicacion] = useState();
  const [esConfidencial, setEsConfidencial] = useState(true);
  const [publicarWeb, setPublicarWeb] = useState(false);
  const [solicitudFecha, setSolicitudFecha] = useState(new Date());
  const estadosOptions = oportunidadEstadoOptions;
  const [estadoOption, setEstadoOption] = useState();
  const [cierreEsperadoFecha, setCierreEsperadoFecha] = useState(new Date());
  const [observacion, setObservacion] = useState('');
  const [etapas, setEtapas] = useState([]);
  const [etapasMarcadas, setEtapasMarcadas] = useState([]);
  const [salarioMinimo, setSalarioMinimo] = useState('');
  const [salarioMaximo, setSalarioMaximo] = useState('');
  const [usuarioOptions, setUsuarioOptions] = useState([]);
  const [aCargo, setACargo] = useState({});
  const [usuarioSelected, setUsuarioSelected] = useState('');
  const [rolSelected, setRolSelected] = useState('');
  const [ciudadesOptions, setCiudadesOptions] = useState();
  const [ciudadSelected, setCiudadSelected] = useState(asuncionOption);
  const [monedaSelected, setMonedaSelected] = useState();
  const [pagoFormaOption, setPagoFormaOption] = useState(
    pagoFormaOptionsByValue.month
  );
  const [empleoTipoOption, setEmpleoTipoOption] = useState(
    empleoTipoOptionsByValue.tiempoCompleto
  );
  const [muestraPosicion, setMuestraPosicion] = useState();
  const [muestraResponsabilidad, setMuestraResponsabilidad] = useState();
  const [muestraConocimientoEspecifico, setMuestraConocimientoEspecifico] =
    useState();
  const [muestraConocimientoDeseable, setMuestraConocimientoDeseable] =
    useState();
  const [muestraExperienciaRequerida, setMuestraExperienciaRequerida] =
    useState();
  const [muestraAptitud, setMuestraAptitud] = useState();
  const [muestraRequisitoAdicional, setMuestraRequisitoAdicional] =
    useState(false);
  const [muestraCondicionContratacion, setMuestraCondicionContratacion] =
    useState();
  const [fuenteOption, setFuenteOption] = useState();

  const [modalRedirect, setModalRedirect] = useState(null);

  const [flyerSubmitAlert, setFlyerSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [relevamientoSubmitAlert, setRelevamientoSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [isModalEliminarFlyerShown, setIsModalEliminarFlyerShown] =
    useState(false);
  const [flyer, setFlyer] = useState();

  const [
    isModalEliminarRelevamientoShown,
    setIsModalEliminarRelevamientoShown,
  ] = useState(false);
  const [relevamiento, setRelevamiento] = useState();

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const prepareEmpresasOptions = (empresas) => {
    const options = empresas.map((empresa) => ({
      value: empresa.idNumerico,
      label: toTitleCase(empresa.nombre),
    }));

    return options;
  };

  useEffect(() => {
    dispatch(
      getEmpresasThunk({
        esCliente: true,
        userToken: token.raw,
      })
    );
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getUsuariosForSelectThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getEtapasThunk({
        userToken: token.raw,
      })
    );
    dispatch(getCiudadesThunk({ userToken: token.raw, padreId: 'todos' }));
    dispatch(
      getOportFlyerThunk({
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
      })
    );
    dispatch(
      getOportRelevamientoThunk({
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
      })
    );
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCiudades);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCiudadesDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetCiudades.useEffect.data': data });

      const mCiudadesOptions = prepareCiudadesOptions(data.items);
      // log({ mCiudadesOptions });

      setCiudadesOptions(mCiudadesOptions);

      dispatch(getCiudadesDuck.actions.clear());
    }
  }, [modelGetCiudades]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasDuck.actions.clear());
    }

    if (data) {
      const mEmpresasOptions = prepareEmpresasOptions(data.items);

      setEmpresasOptions(mEmpresasOptions);

      dispatch(getEmpresasDuck.actions.clear());
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUsuarios);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getUsuariosForSelectDuck.actions.clear());
    }

    if (data) {
      setUsuarioOptions(data?.data);
      dispatch(getUsuariosForSelectDuck.actions.clear());
    }
  }, [modelUsuarios]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setRubrosOptions(datosAuxOptions);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditarDetalleOportunidad);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editarDetallesOportunidadDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editarDetallesOportunidadDuck.actions.clear());
      setSubmitAlert({
        text: 'Oportunidad editada correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelEditarDetalleOportunidad]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarOportFlyer);

    if (error) {
      setFlyerSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEliminarFlyerShown(false);

      dispatch(eliminarOportFlyerDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarOportFlyerDuck.actions.clear());
      dispatch(
        getOportFlyerThunk({
          userToken: token.raw,
          oportunidadIdNumerico: oportunidadId,
        })
      );

      setFlyerSubmitAlert({
        text: 'Flyer eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });
      setIsModalEliminarFlyerShown(false);
    }
  }, [modelEliminarOportFlyer]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEliminarOportRelevamiento);

    if (error) {
      setRelevamientoSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setIsModalEliminarRelevamientoShown(false);

      dispatch(eliminarOportRelevamientoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(eliminarOportRelevamientoDuck.actions.clear());
      dispatch(
        getOportRelevamientoThunk({
          userToken: token.raw,
          oportunidadIdNumerico: oportunidadId,
        })
      );

      setRelevamientoSubmitAlert({
        text: 'Relevamiento eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });
      setIsModalEliminarRelevamientoShown(false);
    }
  }, [modelEliminarOportRelevamiento]);

  const handleACargo = () => {
    const aux = { ...aCargo };
    aux[usuarioSelected?.value] = {
      rol: rolSelected?.value,
      rolLabel: rolSelected?.label,
      name: usuarioSelected?.label,
    };
    setACargo(aux);
    setRolSelected('');
    setUsuarioSelected('');
  };

  const deleteACargo = (usuario) => {
    const objAux = { ...aCargo };
    delete objAux[usuario];
    setACargo(objAux);
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportunidad);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadDuck.actions.clear());
    }

    if (data) {
      setTitulo(data?.oportunidad?.titulo);
      setTituloPublicacion(data?.oportunidad?.tituloPublicacion);

      setEmpresaOption({
        value: data?.oportunidad?.empresaIdNumerico,
        label: data?.oportunidad?.empresaDescripcion,
      });

      setRubroPublicacion(data?.oportunidad?.rubroPublicacion);
      setEsConfidencial(data?.oportunidad?.esConfidencial);
      setPublicarWeb(data?.oportunidad?.publicarWeb);

      setObservacion(data?.oportunidad?.observacion);
      setSalarioMinimo(data?.oportunidad?.salarioMinimo);
      setSalarioMaximo(data?.oportunidad?.salarioMaximo);

      setSolicitudFecha(
        data?.oportunidad?.solicitudFecha
          ? new Date(data?.oportunidad?.solicitudFecha)
          : null
      );
      setCierreEsperadoFecha(
        data?.oportunidad?.cierreEsperadoFecha
          ? new Date(data?.oportunidad?.cierreEsperadoFecha)
          : null
      );

      setProposito(data.oportunidad?.proposito);
      setResponsabilidad(data.oportunidad?.responsabilidad);
      setConocimientoEspecifico(data.oportunidad?.conocimientoEspecifico);
      setConocimientoDeseable(data.oportunidad?.conocimientoDeseable);
      setExperienciaRequerida(data.oportunidad?.experienciaRequerida);
      setAptitud(data.oportunidad?.aptitud);
      setRequisitoAdicional(data.oportunidad?.requisitoAdicional);
      setCondicionContratacion(data.oportunidad?.condicionContratacion);

      setMuestraPosicion(data.oportunidad?.muestraPosicion);
      setMuestraResponsabilidad(data.oportunidad?.muestraResponsabilidad);
      setMuestraConocimientoEspecifico(
        data.oportunidad?.muestraConocimientoEspecifico
      );
      setMuestraConocimientoDeseable(
        data.oportunidad?.muestraConocimientoDeseable
      );
      setMuestraExperienciaRequerida(
        data.oportunidad?.muestraExperienciaRequerida
      );
      setMuestraAptitud(data.oportunidad?.muestraAptitud);
      setMuestraRequisitoAdicional(data.oportunidad?.muestraRequisitoAdicional);
      setMuestraCondicionContratacion(
        data.oportunidad?.muestraCondicionContratacion
      );

      if (
        data?.oportunidad?.ciudadIdNumerico &&
        data?.oportunidad?.ciudadDescripcion
      ) {
        const ciudadSelectedAux = {
          value: data?.oportunidad?.ciudadIdNumerico,
          label: data?.oportunidad?.ciudadDescripcion,
        };
        setCiudadSelected(ciudadSelectedAux);
      }

      if (data?.oportunidad?.pagoForma) {
        const pagoFormaAux =
          pagoFormaOptionsByValue[data?.oportunidad?.pagoForma];
        setPagoFormaOption(pagoFormaAux);
      }

      if (data?.oportunidad?.pagoForma) {
        const empleoTipoAux =
          empleoTipoOptionsByValue[data?.oportunidad?.empleoTipo];
        setEmpleoTipoOption(empleoTipoAux);
      }

      if (
        data?.oportunidad?.cargoDescripcion &&
        data?.oportunidad?.cargoIdNumerico
      ) {
        const cargoAux = {
          value: data?.oportunidad?.cargoIdNumerico,
          label: toTitleCase(data?.oportunidad?.cargoDescripcion),
        };
        setCargoOption(cargoAux);
      }

      if (
        data?.oportunidad?.rubroDescripcion &&
        data?.oportunidad?.rubroIdNumerico
      ) {
        const rubroAux = {
          value: data?.oportunidad?.rubroIdNumerico,
          label: toTitleCase(data?.oportunidad?.rubroDescripcion),
        };
        setRubroOption(rubroAux);
      }

      if (data?.oportunidad?.estado) {
        const estadoAux = oportunidadOptionsByValue[data?.oportunidad?.estado];
        setEstadoOption(estadoAux);
      }

      if (data?.oportunidad?.aCargoDe) {
        let aCargoAux = {};
        data?.oportunidad?.aCargoDe.forEach((row) => {
          const usuarioAux = usuarioOptions.filter(
            (elem) => elem.value === row.usuarioId
          );

          aCargoAux[row.usuarioId] = {
            rol: row?.tipo,
            rolLabel: toTitleCase(row?.tipo),
            name: usuarioAux?.[0]?.label,
          };
        });
        setACargo(aCargoAux);
      }

      if (data.oportunidad.fuente) {
        setFuenteOption(
          fuentesOptions.find(
            (fuenteOption) => fuenteOption.value === data.oportunidad.fuente
          )
        );
      } else {
        setFuenteOption({ value: null, label: 'Seleccionar' });
      }

      setLoadOportunidadStatus('LOADED');

      dispatch(getOportunidadDuck.actions.clear());
    }
  }, [modelGetOportunidad]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportFlyer);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportFlyerDuck.actions.clear());
    }

    if (data) {
      setFlyer(data?.flyer);

      dispatch(getOportFlyerDuck.actions.clear());
    }
  }, [modelGetOportFlyer]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportRelevamiento);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportRelevamientoDuck.actions.clear());
    }

    if (data) {
      setRelevamiento(data?.relevamiento);

      dispatch(getOportRelevamientoDuck.actions.clear());
    }
  }, [modelGetOportRelevamiento]);

  useEffect(() => {
    log('oportunidadId.useEffect');

    if (oportunidadId && usuarioOptions) {
      log('oportunidadId.useEffect.insideIf');
      setLoadOportunidadStatus('LOADING');
      dispatch(
        getOportunidadThunk({
          idNumerico: oportunidadId,
          userToken: token.raw,
        })
      );
    }
  }, [oportunidadId, usuarioOptions]);

  function submit() {
    const aCargoDeAux = Object.keys(aCargo);
    if (aCargoDeAux.length === 0) {
      setSubmitAlert({
        text: 'Debe seleccionar al menos un supervisor o selector',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    const aCargoDe = aCargoDeAux.map((key) => {
      return {
        usuarioId: key,
        tipo: aCargo[key]?.rol,
        porcentaje: null,
      };
    });

    let data = {
      userToken: token.raw,
      titulo,
      estado: estadoOption?.value,
      cargoIdNumerico: cargoOption?.value,
      salarioMinimo: getNumberOrNull(salarioMinimo),
      salarioMaximo: getNumberOrNull(salarioMaximo),
      observacion,
      solicitudFecha: solicitudFecha,
      cierreEsperadoFecha: cierreEsperadoFecha,
      rubroIdNumerico: rubroOption?.value,
      proposito: proposRef.current.getContent(),
      responsabilidad: responRef.current.getContent(),
      conocimientoEspecifico: conEspRef.current.getContent(),
      conocimientoDeseable: conDesRef.current.getContent(),
      experienciaRequerida: expReqRef.current.getContent(),
      aptitud: null,
      requisitoAdicional: reqAdRef.current.getContent(),
      condicionContratacion: condContRef.current.getContent(),
      esConfidencial,
      publicarWeb,
      // enviarCorreo: Boolean,
      muestraPosicion,
      muestraResponsabilidad,
      muestraConocimientoEspecifico,
      muestraConocimientoDeseable,
      muestraExperienciaRequerida,
      muestraAptitud,
      muestraRequisitoAdicional,
      muestraCondicionContratacion,
      // experienciaCargo: Boolean,
      // experienciaRubro: Boolean,
      // deseoCargo: Boolean,
      // deseoRubro: Boolean,
      tituloPublicacion,
      rubroPublicacion,
      ciudadIdNumerico: ciudadSelected?.value,
      ciudadDescripcion: ciudadSelected?.label,
      empleoTipo: empleoTipoOption?.value,
      pagoForma: pagoFormaOption?.value,
      // titulaciones: [String], // array de ids de titulacion,
      aCargoDe,
      oportunidadId,
      fuente: fuenteOption?.value,
    };

    log({ 'submit.data': data });

    dispatch(editarDetallesOportunidadThunk(data));
  }

  function handleSelection(id, value) {
    if (value === true) {
      setEtapasMarcadas((arrayMarcados) => [...arrayMarcados, id]);
    } else {
      setEtapasMarcadas((arrayMarcados) =>
        arrayMarcados.filter((elem) => elem !== id)
      );
    }
  }

  function eliminarFlyer() {
    log('eliminarFlyer.start');
    const data = {
      userToken: token.raw,
      oportunidadIdNumerico: oportunidadId,
    };
    log({ 'eliminarFlyer.data': data });
    dispatch(eliminarOportFlyerThunk(data));
  }

  function eliminarRelevamiento() {
    log('eliminarRelevamiento.start');
    const data = {
      userToken: token.raw,
      oportunidadIdNumerico: oportunidadId,
    };
    log({ 'eliminarRelevamiento.data': data });
    dispatch(eliminarOportRelevamientoThunk(data));
  }

  return (
    <CrearOportunidadView
      mode="edit"
      history={history}
      match={match}
      modelCrearOportunidad={modelEditarDetalleOportunidad}
      empresaOption={empresaOption}
      titulo={titulo}
      tituloPublicacion={tituloPublicacion}
      cargoOption={cargoOption}
      rubroOption={rubroOption}
      rubroPublicacion={rubroPublicacion}
      esConfidencial={esConfidencial}
      publicarWeb={publicarWeb}
      solicitudFecha={solicitudFecha}
      estadoOption={estadoOption}
      cierreEsperadoFecha={cierreEsperadoFecha}
      observacion={observacion}
      empresasOptions={empresasOptions}
      setEmpresaOption={setEmpresaOption}
      setTitulo={setTitulo}
      setTituloPublicacion={setTituloPublicacion}
      cargosOptions={cargosOptions}
      setCargoOption={setCargoOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      setRubroPublicacion={setRubroPublicacion}
      setEsConfidencial={setEsConfidencial}
      setSolicitudFecha={setSolicitudFecha}
      estadosOptions={estadosOptions}
      setEstadoOption={setEstadoOption}
      setCierreEsperadoFecha={setCierreEsperadoFecha}
      setObservacion={setObservacion}
      setPublicarWeb={setPublicarWeb}
      etapas={etapas}
      handleSelection={handleSelection}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      salarioMinimo={salarioMinimo}
      setSalarioMinimo={setSalarioMinimo}
      salarioMaximo={salarioMaximo}
      setSalarioMaximo={setSalarioMaximo}
      pagoFormaOption={pagoFormaOption}
      setPagoFormaOption={setPagoFormaOption}
      pagoFormaOptions={pagoFormaOptions}
      empleoTipoOption={empleoTipoOption}
      setEmpleoTipoOption={setEmpleoTipoOption}
      empleoTipoOptions={empleoTipoOptions}
      aCargo={aCargo}
      setACargo={setACargo}
      usuarioOptions={usuarioOptions}
      usuarioSelected={usuarioSelected}
      setUsuarioSelected={setUsuarioSelected}
      rolSelected={rolSelected}
      setRolSelected={setRolSelected}
      handleACargo={handleACargo}
      deleteACargo={deleteACargo}
      ciudadSelected={ciudadSelected}
      setCiudadSelected={setCiudadSelected}
      monedaSelected={monedaSelected}
      setMonedaSelected={setMonedaSelected}
      proposRef={proposRef}
      responRef={responRef}
      conEspRef={conEspRef}
      conDesRef={conDesRef}
      expReqRef={expReqRef}
      aptitudesRef={aptitudesRef}
      reqAdRef={reqAdRef}
      condContRef={condContRef}
      muestraPosicion={muestraPosicion}
      setMuestraPosicion={setMuestraPosicion}
      muestraResponsabilidad={muestraResponsabilidad}
      setMuestraResponsabilidad={setMuestraResponsabilidad}
      muestraConocimientoEspecifico={muestraConocimientoEspecifico}
      setMuestraConocimientoEspecifico={setMuestraConocimientoEspecifico}
      muestraConocimientoDeseable={muestraConocimientoDeseable}
      setMuestraConocimientoDeseable={setMuestraConocimientoDeseable}
      muestraExperienciaRequerida={muestraExperienciaRequerida}
      setMuestraExperienciaRequerida={setMuestraExperienciaRequerida}
      muestraAptitud={muestraAptitud}
      setMuestraAptitud={setMuestraAptitud}
      muestraRequisitoAdicional={muestraRequisitoAdicional}
      setMuestraRequisitoAdicional={setMuestraRequisitoAdicional}
      muestraCondicionContratacion={muestraCondicionContratacion}
      setMuestraCondicionContratacion={setMuestraCondicionContratacion}
      ciudadesOptions={ciudadesOptions}
      proposito={proposito}
      responsabilidad={responsabilidad}
      conocimientoEspecifico={conocimientoEspecifico}
      conocimientoDeseable={conocimientoDeseable}
      experienciaRequerida={experienciaRequerida}
      aptitud={aptitud}
      requisitoAdicional={requisitoAdicional}
      condicionContratacion={condicionContratacion}
      loadOportunidadStatus={loadOportunidadStatus}
      flyerSubmitAlert={flyerSubmitAlert}
      flyer={flyer}
      oportunidadIdNumerico={oportunidadId}
      eliminarFlyer={eliminarFlyer}
      modalRedirect={modalRedirect}
      setModalRedirect={setModalRedirect}
      isModalEliminarFlyerShown={isModalEliminarFlyerShown}
      setIsModalEliminarFlyerShown={setIsModalEliminarFlyerShown}
      oportunidadId={oportunidadId}
      modelEliminarOportFlyer={modelEliminarOportFlyer}
      modelGetOportFlyer={modelGetOportFlyer}
      fuenteOption={fuenteOption}
      setFuenteOption={setFuenteOption}
      isModalEliminarRelevamientoShown={isModalEliminarRelevamientoShown}
      setIsModalEliminarRelevamientoShown={setIsModalEliminarRelevamientoShown}
      modelEliminarOportRelevamiento={modelEliminarOportRelevamiento}
      eliminarRelevamiento={eliminarRelevamiento}
      modelGetOportRelevamiento={modelGetOportRelevamiento}
      relevamientoSubmitAlert={relevamientoSubmitAlert}
      relevamiento={relevamiento}
    />
  );
}

export { EditarDetallesOportunidad };
