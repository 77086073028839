import React, { useEffect, useState } from "react";
import controller from "./duck";
import { useSelector, useDispatch } from "react-redux";
import { constants, responseSelector } from "../utils/utils";
import { EditContrasenaView } from "./EditContrasenaView";
import { Alert, alertTypes } from "../utils/Alert";
import {log } from '../utils/utils';

const { editContrasenaDuck, editContrasenaThunk } = controller;

function EditContrasena({ history, match, token }) {
  const dispatch = useDispatch();

  const modelEditContrasena = useSelector((state) => state.editContrasena);

  const [passActual, setPassActual] = useState("");
  const [passNuevo, setPassNuevo] = useState("");
  const [passNuevo2, setPassNuevo2] = useState("");

  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const submitAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [submitAlert, setSubmitAlert] = useState(submitAlertDefaultValue);

  useEffect(() => {

    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditContrasena);

    if (error) {
      setTopAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editContrasenaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setTopAlert({
        title: "Éxito",
        text: "Contraseña actualizada",
        type: alertTypes.success,
        closable: false,
      });
      dispatch(editContrasenaDuck.actions.clear());
    }
  }, [dispatch, modelEditContrasena]);

  function guardarEdicion() {
    if (!passNuevo || !passNuevo2 || !passActual) {
      setSubmitAlert({
        title: "Error",
        text: "Por favor complete todos los campos",
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    if (passNuevo !== passNuevo2) {
      setSubmitAlert({
        title: "Error",
        text: "Las contraseñas nuevas no coinciden",
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    let data = {
      newPassword: passNuevo,
      oldPassword: passActual,
      userToken: token.raw,
    };
    dispatch(editContrasenaThunk(data));
  }

  return (
    <EditContrasenaView
      modelEditContrasena={modelEditContrasena}
      guardarEdicion={guardarEdicion}
      passActual={passActual}
      setPassActual={setPassActual}
      passNuevo={passNuevo}
      setPassNuevo={setPassNuevo}
      passNuevo2={passNuevo2}
      setPassNuevo2={setPassNuevo2}
      topAlert={topAlert}
      submitAlert={submitAlert}
    />
  );
}

export { EditContrasena };
