const translatedCountries = [
  ['Afganistán', 'af', '93'],
  ['Albania', 'al', '355'],
  ['Alemania', 'de', '49', '.... ........'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Antigua y Barbuda', 'ag', '1268'],
  ['Arabia Saudita', 'sa', '966'],
  ['Argelia', 'dz', '213'],
  ['Argentina', 'ar', '54', '(..) ........', 0],
  ['Armenia', 'am', '374', '.. ......'],
  ['Aruba', 'aw', '297'],
  [
    'Australia',
    'au',
    '61',
    {
      default: '. .... ....',
      '/^4/': '... ... ...',
      '/^5(?!50)/': '... ... ...',
      '/^1(3|8)00/': '.... ... ...',
      '/^13/': '.. .. ..',
      '/^180/': '... ....',
    },
    0,
    [],
  ],
  ['Austria', 'at', '43'],
  ['Azerbaiyán', 'az', '994', '(..) ... .. ..'],
  ['Bahamas', 'bs', '1242'],
  ['Bangladesh', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Baréin', 'bh', '973'],
  ['Bélgica', 'be', '32', '... .. .. ..'],
  ['Belice', 'bz', '501'],
  ['Benín', 'bj', '229'],
  ['Bielorrusia', 'by', '375', '(..) ... .. ..'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia y Herzegovina', 'ba', '387'],
  ['Botsuana', 'bw', '267'],
  ['Brasil', 'br', '55', '(..) .........'],
  ['Brunéi', 'bn', '673'],
  ['Bulgaria', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi', 'bi', '257'],
  ['Bután', 'bt', '975'],
  ['Cabo Verde', 'cv', '238'],
  ['Camboya', 'kh', '855'],
  ['Camerún', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    '(...) ...-....',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Caribe Neerlandés', 'bq', '599', '', 1],
  ['Chad', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China', 'cn', '86', '... .... ....'],
  ['Chipre', 'cy', '357', '.. ......'],
  ['Colombia', 'co', '57', '... ... ....'],
  ['Comoras', 'km', '269'],
  ['Congo', 'cd', '243'],
  ['Congo', 'cg', '242'],
  ['Corea del Norte', 'kp', '850'],
  ['Corea del Sur', 'kr', '82', '... .... ....'],
  ['Costa de Marfil', 'ci', '225', '.. .. .. .. ..'],
  ['Costa Rica', 'cr', '506', '....-....'],
  ['Croacia', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curazao', 'cw', '599', '', 0],
  ['Dinamarca', 'dk', '45', '.. .. .. ..'],
  ['Ecuador', 'ec', '593'],
  ['Egipto', 'eg', '20'],
  ['El Salvador', 'sv', '503', '....-....'],
  ['Emiratos Árabes Unidos', 'ae', '971'],
  ['Eslovaquia', 'sk', '421'],
  ['Eslovenia', 'si', '386'],
  ['España', 'es', '34', '... ... ...'],
  ['Estados Unidos', 'us', '1', '(...) ...-....', 0],
  ['Estonia', 'ee', '372', '.... ......'],
  ['Etiopía', 'et', '251'],
  ['Fiyi', 'fj', '679'],
  ['Filipinas', 'ph', '63', '.... .......'],
  ['Finlandia', 'fi', '358', '.. ... .. ..'],
  ['Francia', 'fr', '33', '. .. .. .. ..'],
  ['Gabón', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia', 'ge', '995'],
  ['Ghana', 'gh', '233'],
  ['Granada', 'gd', '1473'],
  ['Grecia', 'gr', '30'],
  ['Guadalupe', 'gp', '590', '', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502', '....-....'],
  ['Guayana Francesa', 'gf', '594'],
  ['Guinea', 'gn', '224'],
  ['Guinea-Bissau', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haití', 'ht', '509', '....-....'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong', 'hk', '852', '.... ....'],
  ['Hungría', 'hu', '36'],
  ['India', 'in', '91', '.....-.....'],
  ['Indonesia', 'id', '62'],
  ['Irak', 'iq', '964'],
  ['Irán', 'ir', '98', '... ... ....'],
  ['Irlanda', 'ie', '353', '.. .......'],
  ['Islandia', 'is', '354', '... ....'],
  ['Islas Marshall', 'mh', '692'],
  ['Islas Salomón', 'sb', '677'],
  ['Islas Vírgenes Británicas', 'vg', '1284'],
  ['Islas Vírgenes de los Estados Unidos', 'vi', '1', '', 4, ['340']],
  ['Israel', 'il', '972', '... ... ....'],
  ['Italia', 'it', '39', '... .......', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japón', 'jp', '81', '.. .... ....'],
  ['Jordania', 'jo', '962'],
  ['Kazajistán', 'kz', '7', '... ...-..-..', 0],
  ['Kenia', 'ke', '254'],
  ['Kirguistán', 'kg', '996', '... ... ...'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait', 'kw', '965'],
  ['Laos', 'la', '856'],
  ['Lesoto', 'ls', '266'],
  ['Letonia', 'lv', '371', '.. ... ...'],
  ['Líbano', 'lb', '961'],
  ['Liberia', 'lr', '231'],
  ['Libia', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lituania', 'lt', '370'],
  ['Luxemburgo', 'lu', '352'],
  ['Macao', 'mo', '853'],
  ['Macedonia', 'mk', '389'],
  ['Madagascar', 'mg', '261'],
  ['Malasia', 'my', '60', '..-....-....'],
  ['Maldivas', 'mv', '960'],
  ['Malí', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marruecos', 'ma', '212'],
  ['Martinica', 'mq', '596'],
  ['Mauricio', 'mu', '230'],
  ['Mauritania', 'mr', '222'],
  ['México', 'mx', '52', '... ... ....', 0],
  ['Micronesia', 'fm', '691'],
  ['Moldavia', 'md', '373', '(..) ..-..-..'],
  ['Mónaco', 'mc', '377'],
  ['Mongolia', 'mn', '976'],
  ['Montenegro', 'me', '382'],
  ['Mozambique', 'mz', '258'],
  ['Myanmar', 'mm', '95'],
  ['Namibia', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal', 'np', '977'],
  ['Nicaragua', 'ni', '505'],
  ['Níger', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Noruega', 'no', '47', '... .. ...'],
  ['Nueva Caledonia', 'nc', '687'],
  ['Nueva Zelanda', 'nz', '64', '...-...-....'],
  ['Omán', 'om', '968'],
  ['Países Bajos', 'nl', '31', '.. ........'],
  ['Pakistán', 'pk', '92', '...-.......'],
  ['Palau', 'pw', '680'],
  ['Palestina', 'ps', '970'],
  ['Panamá', 'pa', '507'],
  ['Papúa Nueva Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Perú', 'pe', '51'],
  ['Polinesia Francesa', 'pf', '689'],
  ['Polonia', 'pl', '48', '...-...-...'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', '', 3, ['787', '939']],
  ['Qatar', 'qa', '974'],
  ['Reino Unido', 'gb', '44', '.... ......'],
  ['República Centroafricana', 'cf', '236'],
  ['República Checa', 'cz', '420', '... ... ...'],
  ['República Dominicana', 'do', '1', '', 2],
  ['Reunión', 're', '262'],
  ['Ruanda', 'rw', '250'],
  ['Rumania', 'ro', '40'],
  ['Rusia', 'ru', '7', '(...) ...-..-..', 1],
  ['Samoa', 'ws', '685'],
  ['San Cristóbal y Nieves', 'kn', '1869'],
  ['San Marino', 'sm', '378'],
  ['San Vicente y las Granadinas', 'vc', '1784'],
  ['Santa Lucía', 'lc', '1758'],
  ['Santo Tomé y Príncipe', 'st', '239'],
  ['Senegal', 'sn', '221'],
  ['Serbia', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leona', 'sl', '232'],
  ['Singapur', 'sg', '65', '....-....'],
  ['Siria', 'sy', '963'],
  ['Somalia', 'so', '252'],
  ['Sri Lanka', 'lk', '94'],
  ['Sudáfrica', 'za', '27'],
  ['Sudán', 'sd', '249'],
  ['Sudán del Sur', 'ss', '211'],
  ['Suecia', 'se', '46', '... ... ...'],
  ['Suiza', 'ch', '41', '.. ... .. ..'],
  ['Surinam', 'sr', '597'],
  ['Swazilandia', 'sz', '268'],
  ['Tayikistán', 'tj', '992'],
  ['Timor Oriental', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tonga', 'to', '676'],
  ['Trinidad y Tobago', 'tt', '1868'],
  ['Turkmenistán', 'tm', '993'],
  ['Turquía', 'tr', '90', '... ... .. ..'],
  ['Tuvalu', 'tv', '688'],
  ['Ucrania', 'ua', '380', '(..) ... .. ..'],
  ['Uganda', 'ug', '256'],
  ['Uruguay', 'uy', '598'],
  ['Uzbekistán', 'uz', '998', '.. ... .. ..'],
  ['Vanuatu', 'vu', '678'],
  ['Vaticano', 'va', '39', '.. .... ....', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam', 'vn', '84'],
  ['Yemen', 'ye', '967'],
  ['Yibuti', 'dj', '253'],
  ['Zambia', 'zm', '260'],
  ['Zimbabue', 'zw', '263'],
];

export { translatedCountries };
