export const esClienteOptions = [
  { value: 'SI', label: 'Sí' },
  { value: 'NO', label: 'No' },
  { value: 'TODOS', label: 'Todos' },
];

export const esClienteOptionsByValue = {
  SI: { value: 'SI', label: 'Sí' },
  NO: { value: 'NO', label: 'No' },
  TODOS: { value: 'TODOS', label: 'Todos' },
};
