import createDuck, { createAction, createThunk } from "../utils/actions";
import axios from "../net/axios";
import config from "../config";
let baseUrl = config.api.baseUrl;
const newActions = {
  test: (name) => createAction("NAME", name),
};

const newReducers = {
  "NAME": (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duck = createDuck("INVITACION_CREAR", newActions, newReducers);

const crearInvitacionThunk = (data) => {
  const url = `${baseUrl}/invitacion/`;
  return createThunk(axios, "post", duck.actions, url, data);
};

const duckGetOrganizaciones = createDuck("ORGANIZACION_GET_ALL", newActions, newReducers);

const getOrganizacionesThunk = () => {
  const url = `${baseUrl}/organizacion/`;
  return createThunk(axios, "get", duckGetOrganizaciones.actions, url);
};

const duckGetInvitaciones = createDuck("GET_INVITACIONES", newActions, newReducers);

const getInvitacionesThunk = (token, page, limit) => {
  const url = `${baseUrl}/getInvitaciones/${token}?page=${page}&limit=${limit}`;
  return createThunk(axios, "get", duckGetInvitaciones.actions, url);
};

export default {
  duck,
  crearInvitacionThunk,
  duckGetOrganizaciones,
  getOrganizacionesThunk,
  duckGetInvitaciones,
  getInvitacionesThunk
};
