import React from 'react';
import { Content, Col, Box, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../utils/Loader';
import { getDateOrEmpty, toTitleCase } from '../utils/utils';
import { oportunidadEstadoDescById } from '../utils/constants';
import { Alert } from '../ui/alert';

function CandidatoListaOportunidadesView({
  topAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  oportunidades,
  modelOportunidades,
  usuariosLoadStatus,
}) {
  return (
    <Content
      title="Oportunidades"
      subTitle=""
      browserTitle="Oportunidades"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Col md="8" mdOffset="2">
        <Box title="Lista" border={false}>
          <Loader isLoading={modelOportunidades.loading}>
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <DataTable
              lazy
              pt={{
                table: {
                  className: 'table table-hover',
                },
              }}
              value={oportunidades}
              emptyMessage="No se encontraron registros"
            >
              <Column field="titulo" header="Título"></Column>
              <Column
                field="cargoDescripcion"
                header="Cargo"
                body={(oportunidad) => {
                  return toTitleCase(oportunidad.cargoDescripcion);
                }}
              ></Column>
              <Column
                field="rubroDescripcion"
                header="Rubro"
                body={(oportunidad) => {
                  return toTitleCase(oportunidad.rubroDescripcion);
                }}
              ></Column>
              <Column
                field="conocimientoDeseable"
                header="Formación académica"
                body={(oportunidad) => {
                  return (
                    <div
                      style={{ minWidth: 200, maxHeight: 150, overflow: 'auto' }}
                      dangerouslySetInnerHTML={{
                        __html: toTitleCase(oportunidad.conocimientoDeseable),
                      }}
                    />
                  );
                }}
              ></Column>
            </DataTable>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={handlePage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>
          </Loader>
        </Box>
      </Col>
    </Content>
  );
}

export { CandidatoListaOportunidadesView };
