import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getContactoMensajesDuck = createDuck(
  'GET_CONTACTO_MENSAJES',
  newActions,
  newReducers
);

const getContactoMensajesThunk = (params) => {
  const url = `${baseUrl}/v1/mensajes`;

  return createThunk(
    axios,
    'get',
    getContactoMensajesDuck.actions,
    url,
    null,
    params
  );
};

export { getContactoMensajesDuck, getContactoMensajesThunk };
