import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../../ui/alert';

import {
  emCandidatosGetDuck,
  emCandidatosGetThunk,
  emCandidatoDeleteDuck,
  emCandidatoDeleteThunk,
  emCandidatoEditDuck,
  emCandidatoEditThunk,
  notaFavoritaGetDuck,
  notaFavoritaGetThunk,
} from '../duck';

import {
  constants,
  responseSelector,
  log,
  parseJwt,
  prepareDatosAuxOptions,
} from '../../utils/utils';

import { ListaEmCandidatosView } from './ListaEmCandidatosView';
import { useQueryParams } from '../../utils/useQueryParams';
import { emParametrosOptions } from '../../utils/constants';
import {
  crearCargoPotDuck,
  crearCargoPotThunk,
  crearNotaDuck,
  crearNotaThunk,
  deleteNotaDuck,
  deleteNotaThunk,
  getCargosPotDuck,
  getCargosPotThunk,
  getNotasDuck,
  getNotasThunk,
  notaFavoritaSetDuck,
  notaFavoritaSetThunk,
  updateNotaDuck,
  updateNotaThunk,
} from '../../candidatos/duck';
import { getCargosDuck, getCargosThunk } from '../../oportunidades/duck';

const parametroOptionsToUse = [
  { value: null, label: 'Sin especificar' },
  ...emParametrosOptions,
];

const queryString = require('query-string');

function ListaEmCandidatos({ history, match, test, token }) {
  const cargosSelected = null;

  const getSelectedCargosOptions = () => {
    if (!cargosSelected) {
      return [];
    }

    if (typeof cargosSelected === 'string' && cargosSelected !== 'todos') {
      return [{ value: cargosSelected, label: '...' }];
    } else if (Array.isArray(cargosSelected)) {
      return cargosSelected.map((cargo) => ({ value: cargo, label: '...' }));
    } else {
      log('Cargos de URL no validos');
      return [];
    }
  };

  const estudioMercadoIdNumerico = match.params.estudioMercadoIdNumerico;

  const { queryParams, queryParamsloaded } = useQueryParams(history);

  const dispatch = useDispatch();

  const emCandidatosGet = useSelector((state) => state.emCandidatosGet);
  const emCandidatoDelete = useSelector((state) => state.emCandidatoDelete);
  const emCandidatoEdit = useSelector((state) => state.emCandidatoEdit);
  const modelGetCargosPot = useSelector((state) => state.getCargosPot);
  const modelCrearCargoPot = useSelector((state) => state.crearCargoPot);
  const modelGetCargos = useSelector((state) => state.getCargos);
  const notaFavoritaSet = useSelector((state) => state.notaFavoritaSet);
  const notaFavoritaGet = useSelector((state) => state.notaFavoritaGet);
  const modelNotas = useSelector((state) => state.getNotas);

  const [filters, setFilters] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [busquedaRapida, setBusquedaRapida] = useState();
  const [groupedCandidates, setGroupedCandidates] = useState([]);
  const [selectedCandidato, setSelectedCandidato] = useState(null);
  const [candidatoSelectedForEdit, setCandidatoSelectedForEdit] =
    useState(null);
  const [condicionesActuales, setCondicionesActuales] = useState('');
  const [condicionesContratacion, setCondicionesContratacion] = useState('');
  const [resumen, setResumen] = useState('');
  const [parametroOption, setParametroOption] = useState(null);

  const [isModalNotasShown, setIsModalNotasShown] = useState();
  const [notas, setNotas] = useState([]);
  const [candidatoIdNumerico, setCandidatoIdNumerico] = useState();
  const [totalResultsNotas, setTotalResultsNotas] = useState(0);
  const [activePageNotas, setActivePageNotas] = useState(0);
  const [pageSizeNotas, setPageSizeNotas] = useState(5);
  const [notaSelected, setNotaSelected] = useState('');
  const [expectativaSalarial, setExpectativaSalarial] = useState('0');
  const [cargosOptions, setCargosOptions] = useState([]);
  const [cargosPotenciales, setCargosPotenciales] = useState([]);
  const [cargoOption, setCargoOption] = useState(getSelectedCargosOptions());
  const [cargoOptionNota, setCargoOptionNota] = useState();
  const [titulo, setTitulo] = useState();
  const [contenido, setContenido] = useState();
  const [cuentaFiltroChecked, setCuentaFiltroChecked] = useState(true);
  const [entrevistaCortesiaChecked, setEntrevistaCortesiaChecked] =
    useState(false);
  const [userIdNumerico, setUserIdNumerico] = useState();
  const [cargoPotSubmitAlert, setCargoPotSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });
  const [notaFavorita, setNotaFavorita] = useState(null);
  const modelCrearNota = useSelector((state) => state.crearNota);
  const modelUpdateNota = useSelector((state) => state.updateNota);
  const modelDeleteNota = useSelector((state) => state.deleteNota);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const _getFiltersFromQueryParams = (queryParams) => {
    const page = parseInt(queryParams?.page ?? 1, 10);

    const busquedaRapida = queryParams?.busquedaRapida ?? '';

    return {
      busquedaRapida,
      page,
    };
  };

  useEffect(() => {
    if (!queryParamsloaded) return;
    // Si no tenemos queryParams _getFiltersFromQueryParams
    // trae los filtros por defecto

    const filters = _getFiltersFromQueryParams(queryParams);

    setBusquedaRapida(filters.busquedaRapida);
    setPage(filters.page);

    setFilters(filters);
    getEmCandidatos(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(emCandidatosGet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(emCandidatosGetDuck.actions.clear());
    }

    if (data) {
      setGroupedCandidates(data.groupedItems);
      setTotalResults(data.total);

      dispatch(emCandidatosGetDuck.actions.clear());
    }
  }, [emCandidatosGet]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(emCandidatoDelete);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(emCandidatoDeleteDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setSelectedCandidato(null);
      getEmCandidatos(filters);
      dispatch(emCandidatoDeleteDuck.actions.clear());
    }
  }, [emCandidatoDelete]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(emCandidatoEdit);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(emCandidatoEditDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // setCandidatoSelectedForEdit(null);
      getEmCandidatos(filters);
      dispatch(emCandidatoEditDuck.actions.clear());
    }
  }, [emCandidatoEdit]);

  function getEmCandidatos(filters) {
    if (!filters) {
      throw new Error('No filters received');
    }

    dispatch(
      emCandidatosGetThunk({
        userToken: token.raw,
        limit: pageSize,
        estudioMercadoIdNumerico,
        page: filters.page,
        busquedaRapida: filters.busquedaRapida,
      })
    );
  }

  function handlePage(page) {
    const strNewParams = queryString.stringify({
      estudioMercadoIdNumerico,
      limit: pageSize,
      page,
      busquedaRapida,
    });

    history.replace(`${history.location.pathname}?${strNewParams}`);
  }

  function deleteEmCandidato(candidatoIdNumerico) {
    dispatch(
      emCandidatoDeleteThunk({
        userToken: token.raw,
        estudioMercadoIdNumerico,
        candidatoIdNumerico,
      })
    );
  }

  function editEmCandidato() {
    const data = {
      userToken: token.raw,
      estudioMercadoIdNumerico,
      candidatoIdNumerico: candidatoSelectedForEdit.idNumerico,
      condicionesActuales,
      condicionesContratacion,
      resumen,
      parametro: parametroOption?.value,
    };

    dispatch(emCandidatoEditThunk(data));
  }

  useEffect(() => {
    if (candidatoSelectedForEdit) {
      setCondicionesActuales(candidatoSelectedForEdit.condicionesActuales);
      setCondicionesContratacion(
        candidatoSelectedForEdit.condicionesContratacion
      );
      setResumen(candidatoSelectedForEdit.resumen);
      if (candidatoSelectedForEdit.parametro) {
        setParametroOption(
          parametroOptionsToUse.find(
            (option) => option.value === candidatoSelectedForEdit.parametro
          )
        );
      } else {
        setParametroOption({ value: null, label: 'Sin especificar' });
      }
    }
  }, [candidatoSelectedForEdit]);

  function handlePageNotas(page) {
    setActivePageNotas(page);

    setNotas([]);

    const params = {
      page: page + 1,
      limit: pageSizeNotas,
      candidatoIdNumerico,
    };

    dispatch(
      getNotasThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }
  const hideModalNotas = () => {
    setCandidatoIdNumerico(null);
    setNotaSelected(null);
    setIsModalNotasShown(false);
  };
  const showModalNotas = () => {
    setIsModalNotasShown(true);
  };
  function submitNota() {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    if (!titulo || !contenido) {
      setTopAlert({
        text: 'La nota debe incluir titulo y contenido',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    setNotas([]);
    if (!notaSelected) {
      dispatch(
        crearNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    } else {
      if (!notaSelected.destacado && cargosPotenciales.length < 3) {
        setTopAlert({
          text: 'El candidato debe tener al menos 3 cargos potenciales para crear la nota',
          type: alertTypes.danger,
          closable: false,
        });
        return;
      }

      dispatch(
        updateNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          idNumerico: notaSelected.idNumerico,
          creacionUsuario: notaSelected.creacionUsuario,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    }
  }

  function deleteNota(idNumerico, creacionUsuario) {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    dispatch(
      deleteNotaThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        idNumerico: idNumerico,
        creacionUsuario: creacionUsuario,
      })
    );
  }

  function setearNotaFavorita({ notaIdNumerico, candidatoIdNumerico, setear }) {
    dispatch(
      notaFavoritaSetThunk({
        userToken: token.raw,
        notaIdNumerico,
        candidatoIdNumerico,
        setear,
      })
    );
  }

  const resetNotaInputs = () => {
    setTitulo('');
    setContenido('');
    setCuentaFiltroChecked(true);
    setEntrevistaCortesiaChecked(false);
  };

  function agregarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      cargoIdNumerico: cargoOptionNota?.value,
    };
    dispatch(crearCargoPotThunk(data));
  }

  useEffect(() => {
    if (token?.raw) {
      const decodedToken = parseJwt(token.raw);
      setUserIdNumerico(decodedToken?.idNumerico);
    }
  }, [token]);

  useEffect(() => {
    if (candidatoIdNumerico) {
      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    if (candidatoIdNumerico) {
      handlePageNotas(0);
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  const showSelectedCargosNames = (datosAuxOptions) => {
    if (typeof cargosSelected === 'string' && cargosSelected !== 'todos') {
      const opcionCargo = datosAuxOptions.filter(
        (elem) => elem.value === cargosSelected
      );

      if (opcionCargo[0]) {
        setCargoOption([opcionCargo[0]]);
      }
    } else if (Array.isArray(cargosSelected)) {
      setCargoOption(
        cargosSelected.map((cargoIdNumerico) => {
          const mCargoOption = datosAuxOptions.find(
            (da) => da.value == cargoIdNumerico
          );
          if (mCargoOption) {
            return mCargoOption;
          }
          return { value: cargoIdNumerico, label: 'Desconocido' };
        })
      );
    } else {
      log('invalidSelectedCargos');
    }
  };

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const cargos = data.items;

      const datosAuxOptions = prepareDatosAuxOptions(cargos);

      setCargosOptions(datosAuxOptions);

      showSelectedCargosNames(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargosPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCargosPot.data': data });

      dispatch(getCargosPotDuck.actions.clear());

      setCargosPotenciales(data.cargosPotenciales);
    }
  }, [modelGetCargosPot]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelNotas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasDuck.actions.clear());
    }

    if (data) {
      setNotas(data.items);
      setTotalResultsNotas(data.total);
      showModalNotas();

      dispatch(getNotasDuck.actions.clear());
    }
  }, [modelNotas]);

  useEffect(() => {
    const { httpStatus, data, error, errorMessage } =
      responseSelector(notaFavoritaGet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(notaFavoritaGetDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setNotaFavorita(data);

      dispatch(notaFavoritaGetDuck.actions.clear());
    }
  }, [notaFavoritaGet]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelUpdateNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      getNotaFavorita();
      getEmCandidatos(filters);

      setTopAlert({
        text: 'Nota actualizada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
  }, [modelUpdateNota]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(notaFavoritaSet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(notaFavoritaSetDuck.actions.clear());
    }

    if (httpStatus) {
      handlePageNotas(activePageNotas);
      getNotaFavorita();
      getEmCandidatos(filters);

      dispatch(notaFavoritaSetDuck.actions.clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notaFavoritaSet]);

  useEffect(() => {
    if (notaSelected) {
      setTitulo(notaSelected.titulo);
      setContenido(notaSelected.contenido);
      setCandidatoIdNumerico(notaSelected.candidatoIdNumerico);
      setCuentaFiltroChecked(notaSelected.cuentaFiltro);
      setEntrevistaCortesiaChecked(notaSelected.entrevistaCortesia);
    }
  }, [notaSelected]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(modelCrearCargoPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(crearCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial agregado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelCrearCargoPot]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelCrearNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(crearNotaDuck.actions.clear());
    }

    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      setTopAlert({
        text: 'Nota creada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearNotaDuck.actions.clear());
    }
  }, [modelCrearNota]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelDeleteNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(deleteNotaDuck.actions.clear());
    }
    if (data) {
      handlePageNotas(0);

      getNotaFavorita();

      getEmCandidatos(filters);

      setTopAlert({
        text: 'Nota eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(deleteNotaDuck.actions.clear());
    }
  }, [modelDeleteNota]);

  function getNotaFavorita() {
    dispatch(
      notaFavoritaGetThunk({
        userToken: token.raw,
        candidatoIdNumerico: candidatoSelectedForEdit.idNumerico,
      })
    );
  }

  useEffect(() => {
    if (candidatoSelectedForEdit) {
      getNotaFavorita();
    }
  }, [candidatoSelectedForEdit]);

  return (
    <ListaEmCandidatosView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      page={page}
      pageSize={pageSize}
      handlePage={handlePage}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      emCandidatosGet={emCandidatosGet}
      groupedCandidates={groupedCandidates}
      emCandidatoDelete={emCandidatoDelete}
      deleteEmCandidato={deleteEmCandidato}
      selectedCandidato={selectedCandidato}
      setSelectedCandidato={setSelectedCandidato}
      candidatoSelectedForEdit={candidatoSelectedForEdit}
      setCandidatoSelectedForEdit={setCandidatoSelectedForEdit}
      emCandidatoEdit={emCandidatoEdit}
      editEmCandidato={editEmCandidato}
      parametroOption={parametroOption}
      parametroOptions={parametroOptionsToUse}
      setParametroOption={setParametroOption}
      condicionesActuales={condicionesActuales}
      setCondicionesActuales={setCondicionesActuales}
      condicionesContratacion={condicionesContratacion}
      setCondicionesContratacion={setCondicionesContratacion}
      resumen={resumen}
      setResumen={setResumen}
      candidatoIdNumerico={candidatoIdNumerico}
      isModalNotasShown={isModalNotasShown}
      hideModalNotas={hideModalNotas}
      showModalNotas={showModalNotas}
      notas={notas}
      activePageNotas={activePageNotas}
      pageSizeNotas={pageSizeNotas}
      totalResultsNotas={totalResultsNotas}
      handlePageNotas={handlePageNotas}
      submitNota={submitNota}
      contenido={contenido}
      setContenido={setContenido}
      titulo={titulo}
      setTitulo={setTitulo}
      notaSelected={notaSelected}
      setNotaSelected={setNotaSelected}
      userIdNumerico={userIdNumerico}
      deleteNota={deleteNota}
      cuentaFiltroChecked={cuentaFiltroChecked}
      setCuentaFiltroChecked={setCuentaFiltroChecked}
      entrevistaCortesiaChecked={entrevistaCortesiaChecked}
      setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
      resetNotaInputs={resetNotaInputs}
      expectativaSalarial={expectativaSalarial}
      setExpectativaSalarial={setExpectativaSalarial}
      cargoPotSubmitAlert={cargoPotSubmitAlert}
      cargosPotenciales={cargosPotenciales}
      modelGetCargosPot={modelGetCargosPot}
      modelCrearCargoPot={modelCrearCargoPot}
      agregarCargoPot={agregarCargoPot}
      cargosOptions={cargosOptions}
      cargoOptionNota={cargoOptionNota}
      setCargoOptionNota={setCargoOptionNota}
      notaFavoritaSet={notaFavoritaSet}
      setearNotaFavorita={setearNotaFavorita}
      setCandidatoIdNumerico={setCandidatoIdNumerico}
      notaFavorita={notaFavorita}
      notaFavoritaGet={notaFavoritaGet}
      token={token}
      estudioMercadoIdNumerico={estudioMercadoIdNumerico}
      queryParams={queryParams}
    />
  );
}

export { ListaEmCandidatos };
