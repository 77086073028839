import createDuck, { createAction, createThunk } from '../utils/actions';
import axios from '../net/axios';
import config from '../config';


let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duck = createDuck('REGISTRO', newActions, newReducers);

const registroThunk = (data) => {
  const url = `${baseUrl}/crearAutoInvitacion`;
  return createThunk(axios, 'post', duck.actions, url, data);
};

const confirmarRegistroDuck = createDuck('REGISTRO_CONFIRMAR', newActions, newReducers);

const confirmarRegistroThunk = (data) => {
  const url = `${baseUrl}/signup`;
  return createThunk(axios, 'post', confirmarRegistroDuck.actions, url, data);
};

const duckUsuario = createDuck('USUARIO', newActions, newReducers);

const usuarioThunk = (credencial) => {
  const url = `${baseUrl}/usuario/credencial/${credencial}`;
  return createThunk(axios, 'get', duckUsuario.actions, url, null);
};

const duckOrganizacion = createDuck(
  'ORGANIZACIONBYID',
  newActions,
  newReducers,
);

const organizacionByIdThunk = (id) => {
  const url = `${baseUrl}/organizacion/${id}`;
  return createThunk(axios, 'get', duckOrganizacion.actions, url, null);
};

const duckInvitacion = createDuck('INVITACION', newActions, newReducers);

const getInvitacionBytokenthunk = (token) => {
  const url = `${baseUrl}/invitacionByToken/${token}`;
  return createThunk(axios, 'get', duckInvitacion.actions, url, null);
};

const updateFirebaseTokenDuck = createDuck(
  'UPDATE_FIREBASE_TOKEN',
  newActions,
  newReducers,
);

const updateFirebaseTokenThunk = (data) => {
  const url = `${baseUrl}/updateFirebaseToken`;
  return createThunk(axios, 'post', updateFirebaseTokenDuck.actions, url, data);
};

export {
  duck,
  registroThunk,
  duckUsuario,
  usuarioThunk,
  duckOrganizacion,
  organizacionByIdThunk,
  duckInvitacion,
  getInvitacionBytokenthunk,
  updateFirebaseTokenThunk,
  updateFirebaseTokenDuck,
  confirmarRegistroDuck,
  confirmarRegistroThunk
};
