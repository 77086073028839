import React from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert, alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { MonedaInputV2 } from '../ui/moneda';
import { DateInput } from '../utils/DateInput';
import {
  estadoCivilOptions,
  paraguayOption,
  sexoOptions,
} from '../utils/constants';
import { TextArea } from '../utils/TextArea';
import { CamposCandidato } from './componentes/CamposCandidato';
const { TextInput, knownRegexes } = require('../utils/TextInput');

function CrearCandidatoView({
  history,
  match,
  title = 'Crear candidato',
  modelCrearCandidato,
  submit,
  submitAlert,
  topAlert,
  nombre,
  setNombre,
  apellido,
  setApellido,
  documento,
  setDocumento,
  expectativaSalarial,
  setExpectativaSalarial,
  telefono1,
  setTelefono1,
  direccion,
  setDireccion,
  telefono2,
  setTelefono2,
  telefono3,
  setTelefono3,
  correo,
  setCorreo,
  nacimientoFecha,
  setNacimientoFecha,
  sexoOption,
  setSexoOption,
  estadoCivilOption,
  setEstadoCivilOption,
  descripcionInterna,
  setDescripcionInterna,
  nacionalidadesOptions,
  nacionalidadOption,
  setNacionalidadOption,
  paisesOptions,
  paisOption,
  setPaisOption,
  ciudadesOptions,
  ciudadOption,
  setCiudadOption,
  ciudadLibre,
  setCiudadLibre,
  modelSearchCandidatosByMobile,
  foundCandidatos,
  creado,
}) {
  return (
    <Content title={title} browserTitle={title} show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Row>
        <Col md="10" mdOffset="1">
          <Box title="Datos principales">
            <ReferenciaObligatorios />
            <CamposCandidato
              mode="create"
              modelSearchCandidatosByMobile={modelSearchCandidatosByMobile}
              foundCandidatos={foundCandidatos}
              history={history}
              nombre={nombre}
              setNombre={setNombre}
              apellido={apellido}
              setApellido={setApellido}
              documento={documento}
              setDocumento={setDocumento}
              expectativaSalarial={expectativaSalarial}
              setExpectativaSalarial={setExpectativaSalarial}
              telefono2={telefono2}
              setTelefono2={setTelefono2}
              telefono3={telefono3}
              setTelefono3={setTelefono3}
              telefono1={telefono1}
              setTelefono1={setTelefono1}
              direccion={direccion}
              setDireccion={setDireccion}
              correo={correo}
              setCorreo={setCorreo}
              nacimientoFecha={nacimientoFecha}
              setNacimientoFecha={setNacimientoFecha}
              sexoOption={sexoOption}
              sexoOptions={sexoOptions}
              setSexoOption={setSexoOption}
              estadoCivilOption={estadoCivilOption}
              estadoCivilOptions={estadoCivilOptions}
              setEstadoCivilOption={setEstadoCivilOption}
              descripcionInterna={descripcionInterna}
              setDescripcionInterna={setDescripcionInterna}
              nacionalidadOption={nacionalidadOption}
              nacionalidadesOptions={nacionalidadesOptions}
              setNacionalidadOption={setNacionalidadOption}
              paisOption={paisOption}
              paisesOptions={paisesOptions}
              setPaisOption={setPaisOption}
              ciudadOption={ciudadOption}
              ciudadesOptions={ciudadesOptions}
              setCiudadOption={setCiudadOption}
              ciudadLibre={ciudadLibre}
              setCiudadLibre={setCiudadLibre}
            />

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
            <ButtonWithLoading
              text="Guardar"
              type="success"
              submit={submit}
              pullRight
              loading={modelCrearCandidato.loading}
              disabled={modelCrearCandidato.loading || creado}
            />
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { CrearCandidatoView };
