import React, { useEffect, useState } from 'react';
import { Autocomplete } from './Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { responseSelector, toTitleCase } from '../utils/utils';
import {
  getEmpresasWithPaginationDuck,
  getEmpresasWithPaginationThunk,
} from '../empresas/duck';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';

function EmpresaAutocomplete({
  token,
  onSelectEmpresa,
  label,
  required,
  initialSelectedEmpresaLabel = '',
}) {
  const dispatch = useDispatch();

  const modelGetEmpresas = useSelector(
    (state) => state.getEmpresasWithPagination
  );

  const [empresaLabel, setEmpresaLabel] = useState(initialSelectedEmpresaLabel);
  const [empresas, setEmpresas] = useState([]);
  const [empresaNombre, setEmpresaNombre] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      dispatch(getEmpresasWithPaginationDuck.actions.clear());
      setError('Ocurrio un error al consultar los clientes');
    }

    if (data) {
      if (!data?.items?.length) {
        setError('No se encontraron clientes');
        return;
      }
      setError('');
      setEmpresas(data.items);
      dispatch(getEmpresasWithPaginationDuck.actions.clear());
    }
  }, [modelGetEmpresas]);

  const dispatchGetEmpresas = () => {
    dispatch(
      getEmpresasWithPaginationThunk({
        userToken: token.raw,
        page: 1,
        limit: 100,
        busquedaRapida: empresaNombre,
        contar: 'n',
        esGrupo: 'TODOS',
        esCliente: 'TODOS',
      })
    );
  };

  const seleccionarEmpresa = (empresa) => {
    setEmpresaLabel(empresa.nombre);
    setEmpresaNombre('');
    setError('');
    setEmpresas([]);
    onSelectEmpresa(empresa);
  };

  return (
    <>
      <div style={{ marginBottom: 5 }}>
        <b>
          <Etiqueta texto={label} obligatorio={required} />
        </b>
      </div>

      <Autocomplete
        placeholder="Buscar empresa..."
        dispatchGetDataList={dispatchGetEmpresas}
        dataList={empresas}
        error={error}
        setError={setError}
        value={empresaNombre}
        setValue={setEmpresaNombre}
        setDataList={setEmpresas}
        onItemClick={seleccionarEmpresa}
        onLastItemClick={() => console.log('last item clicked')}
        renderItem={(empresa) => (
          <div style={{ minWidth: 150 }}>
            <p>{toTitleCase(empresa.nombre)}</p>
          </div>
        )}
      />

      {/* <br /> */}

      {empresaLabel && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <i>Empresa seleccionada:</i> <br />
          {toTitleCase(empresaLabel)}
        </div>
      )}
    </>
  );
}

export { EmpresaAutocomplete };
