import React from 'react';
import { Modal } from 'react-bootstrap';
import { SelectInput } from '../utils/SelectInput';
import { oportunidadEstadoDescById } from '../utils/constants';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { getDateOrEmpty } from '../utils/utils';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';
import { MonedaInputV2 } from '../ui/moneda';
import { Alert, alertTypes } from '../ui/alert';
import { CargosPotencialesNotas } from '../candidatos/editar/CargosPotencialesNotas';
import Loader from '../utils/Loader';
const { TextInput } = require('../utils/TextInput');

function ModalCambioEtapa({
  isModalCambioEtapaShown,
  hideModalCambioEtapa,
  showModalCambioEtapa,
  avanzarAEtapa,
  descartarCandidato,
  etapaOption,
  etapasOptions,
  setEtapaOption,
  oportunidad,
  candidato,
  etapasById,
  isAvanzarButtonDisabled,
  isAvanzarButtonLoading,
  titulo,
  setTitulo,
  contenido,
  setContenido,
  candidatoIdNumerico,
  agregarCargoPot,
  cargoOption,
  setCargoOption,
  cargosOptions,
  modelCrearCargoPot,
  cargoPotSubmitAlert,
  modelGetCargosPot,
  cargosPotenciales,
  expectativaSalarial,
  setExpectativaSalarial,
  accion,
  motivoOption,
  motivosOptions,
  setMotivoOption,
}) {
  const accionEsValida = ['avance', 'descarte'].includes(accion);

  return (
    <Modal
      show={isModalCambioEtapaShown}
      style={{ opacity: 1 }}
      onHide={hideModalCambioEtapa}
      onShow={showModalCambioEtapa}
    >
      {!accionEsValida && <Loader />}
      {accionEsValida && (
        <>
          <Modal.Header>
            <Modal.Title>
              {accion === 'avance' ? 'Avance de etapa' : 'Descartar candidato'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <Alert
                title="Observacion"
                type={alertTypes.info}
                text="El candidato debe tener al menos 3 cargos potenciales para crear una nota"
                closable={false}
              />
              <h4>Cargos potenciales</h4>
              <h5>
                Para editar o eliminar cargo potenciales acceder a la pantalla
                de{' '}
                <a
                  href={`/editarCandidato/${candidatoIdNumerico}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  edicion del usuario
                </a>
              </h5>

              <CargosPotencialesNotas
                agregarCargoPot={agregarCargoPot}
                cargoOption={cargoOption}
                setCargoOption={setCargoOption}
                cargosOptions={cargosOptions}
                modelCrearCargoPot={modelCrearCargoPot}
                cargoPotSubmitAlert={cargoPotSubmitAlert}
                modelGetCargosPot={modelGetCargosPot}
                cargosPotenciales={cargosPotenciales}
              />

              <h4>Expectativa salarial</h4>

              <MonedaInputV2
                initialValue={expectativaSalarial}
                setValue={setExpectativaSalarial}
                selectorMonedaDisabled={true}
              />

              <div style={{ marginBottom: 10 }}>
                <b>Oportunidad</b>
                <br />
                Título: {oportunidad.titulo}
                <br />
                Empresa: {oportunidad.empresaDescripcion}
                <br />
                Estado:{' '}
                {oportunidadEstadoDescById?.[oportunidad.estado] ?? 'N/A'}
                <br />
                Cargo: {oportunidad.cargoDescripcion}
              </div>

              <div style={{ marginBottom: 10 }}>
                <b>Candidato</b>
                <br />
                Nombre: {candidato.nombreCompleto}
                <br />
                Etapa actual:{' '}
                {etapasById?.[candidato.etapaInfo.etapaIdNumerico]
                  ?.descripcion || 'N/A'}
                <br />
                Fecha de avance a etapa:{' '}
                {getDateOrEmpty(candidato.etapaInfo.fecha)}
              </div>

              {accion === 'descarte' ? (
                <div>
                  <SelectInput
                    label="Motivo"
                    value={motivoOption}
                    options={motivosOptions}
                    setValue={setMotivoOption}
                  />
                </div>
              ) : null}

              {accion === 'avance' ? (
                <SelectInput
                  label="Nueva etapa para candidato"
                  value={etapaOption}
                  options={etapasOptions}
                  setValue={setEtapaOption}
                />
              ) : null}

              <div>
                <h4>Nota</h4>
                <TextInput
                  label="Título"
                  initialValue={titulo}
                  setValue={setTitulo}
                />
                <label>
                  <Etiqueta texto="Observación" obligatorio={false} />
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  value={contenido}
                  onChange={(evt) => setContenido(evt.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonWithLoading
              type={accion === 'avance' ? 'success' : 'danger'}
              text={
                accion === 'avance'
                  ? 'Avanzar a etapa seleccionada'
                  : 'Descartar candidato'
              }
              submit={
                accion === 'avance'
                  ? () => avanzarAEtapa()
                  : () => descartarCandidato()
              }
              disabled={
                (accion === 'descarte' && !motivoOption) ||
                isAvanzarButtonDisabled
              }
              loading={isAvanzarButtonLoading}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export { ModalCambioEtapa };
