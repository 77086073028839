export const createAction = (type, payload = undefined) => {
  return payload === undefined ? { type } : { type, payload };
};

const defaultState = {
  loading: false,
  error: null,
  data: null,
};

export const createReducer = (handlers, initialState = defaultState) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export const createTypes = (scope, prefix = "app") => {
  return {
    LOADING: `${prefix}/${scope}_LOADING`,
    SUCCESS: `${prefix}/${scope}_SUCCESS`,
    ERROR: `${prefix}/${scope}_ERROR`,
    CLEAR: `${prefix}/${scope}_CLEAR`,
  };
};

export const defaultReducers = (types) => {
  return {
    [types.SUCCESS]: (state, action) => {
      const nState = { data: action.payload, loading: false, error: null };
      return { ...state, ...nState };
    },
    [types.LOADING]: (state, action) => {
      const nState = { data: null, loading: true, error: null };
      return { ...state, ...nState };
    },
    [types.ERROR]: (state, action) => {
      const nState = { data: null, loading: false, error: action.payload };
      return { ...state, ...nState };
    },
  };
};

export const defaultActions = (types) => {
  return {
    success: (res) => createAction(types.SUCCESS, res),
    clear: () => createAction(types.SUCCESS, null),
    error: (err) => createAction(types.ERROR, err),
    loading: () => createAction(types.LOADING),
  };
};

const createDuck = (scope, duckActions = null, duckReducers = null) => {
  const mTypes = createTypes(scope);
  const mActions = defaultActions(mTypes);
  const mReducers = defaultReducers(mTypes);

  let pActions = {};
  if (duckActions) {
    pActions = { ...mActions, ...duckActions };
  } else {
    pActions = mActions;
  }

  let pReducers = {};
  if (duckReducers) {
    pReducers = { ...mReducers, ...duckReducers };
  } else {
    pReducers = mReducers;
  }

  return {
    types: { ...mTypes },
    actions: { ...pActions },
    reducer: createReducer(pReducers),
  };
};

export const createThunk = (axios, method, actions, url, data, params) => {
  return async (dispatch) => {
    dispatch(actions.loading());
    try {
      const result = await axios({
        method: method,
        url: url,
        data: data,
        params: params,
      });
      dispatch(actions.success(result));
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const createAsyncDbThunk = (operation, actions, data) => {
  return async (dispatch) => {
    dispatch(actions.loading());
    try {
      const result = await operation(data);
      dispatch(actions.success(result));
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export default createDuck;
