import React, { useEffect, useState } from "react";
import AdminLTE, {
  Sidebar,
  Content,
  Row,
  Col,
  Box,
  Button,
  Inputs,
  Label,
} from "adminlte-2-react";
import _ from "lodash";
import controller from "./duck";
import { useSelector, useDispatch } from "react-redux";
import { Alert, alertTypes } from "../utils/Alert";
import "react-phone-input-2/lib/style.css";
import { constants, responseSelector } from "../utils/utils";

const { Select2, Date, DateTime, Text } = Inputs;

function ChangePassByToken({ history, match }) {
  const dispatch = useDispatch();
  const modelChangePass = useSelector((state) => state.changePassByToken);
  const modelCheckToken = useSelector((state) => state.checkResetToken);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);

  const topAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [topAlert, setTopAlert] = useState(topAlertDefaultValue);

  const submitAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [submitAlert, setSubmitAlert] = useState(submitAlertDefaultValue);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelChangePass);

    if (error) {
      setSubmitAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(controller.duckChangePassByToken.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setSubmitAlert({
        title: "Éxito",
        text: "Contraseña actualizada",
        type: alertTypes.success,
        closable: false,
      });
      dispatch(controller.duckChangePassByToken.actions.clear());
    }
  }, [modelChangePass]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCheckToken);

    if (error) {
      setTopAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      history.push("/login");
      dispatch(controller.duckCheckResetToken.actions.clear());
    }
    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(controller.duckCheckResetToken.actions.clear());
    }
  }, [modelCheckToken]);

  useEffect(() => {
    const resetToken = match.params.resetToken;
    dispatch(controller.checkResetTokenThunk(resetToken));
  }, []);

  function submit() {
    if (!password || !password2) {
      setSubmitAlert({
        title: "Error",
        text: "Por favor complete todos los campos",
        type: alertTypes.error,
        closable: false,
      });
      return;
    }
    if (password !== password2) {
      setSubmitAlert({
        title: "Error",
        text: "Las contraseeñas no coinciden",
        type: alertTypes.error,
        closable: false,
      });
      return;
    }
    let data = {
      password,
      resetToken: match.params.resetToken,
    };
    dispatch(controller.changePassByTokenThunk(data));
  }
  return (
    <Content
      title="Olvide mi contraseña"
      browserTitle="Olvide mi contraseña"
      show={false}
    >
      <Row>
        <Col md="4" mdOffset="4">
          <Box title="GENERAR NUEVA CONTRASEÑA">
            {topAlert.text && (
              <Alert
                title={topAlert.title}
                type={topAlert.type}
                text={topAlert.text}
                closable={topAlert.closable}
              />
            )}

            <Text
              inputType="password"
              labelPosition="above"
              size="sm"
              label="Password"
              onChange={(evt) => setPassword(evt.target.value)}
            />
            <Text
              inputType="password"
              labelPosition="above"
              size="sm"
              label="Password"
              onChange={(evt) => setPassword2(evt.target.value)}
            />

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}

            <Button
              type="primary"
              text="Enviar"
              pullRight={true}
              onClick={() => submit()}
            />
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export default ChangePassByToken;
