import createDuck, { createAction, createThunk } from '../utils/actions';
import axios from '../net/axios';
import config from '../config';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duckGetImagenesTemporalesV3 = createDuck("GET_IMG_TMP");

const getImagenesTemporalesV3Thunk = (data) => {
  const url = `${baseUrl}/getImagenesv3/${data.concepto}/${data.conceptoId}`;
  return createThunk(
    axios,
    "get",
    duckGetImagenesTemporalesV3.actions,
    url,
    null
  );
};

const duckEliminarImgTmpV3 = createDuck("ELIMINAR_IMG_TMP");

const eliminarImgTmpV3Thunk = (data) => {
  const url = `${baseUrl}/eliminarImgv3`;
  return createThunk(
    axios,
    "post",
    duckEliminarImgTmpV3.actions,
    url,
    data
  );
};

export {
  duckGetImagenesTemporalesV3,
  getImagenesTemporalesV3Thunk,
  duckEliminarImgTmpV3,
  eliminarImgTmpV3Thunk

};
