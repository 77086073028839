import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function ModalEliminarCandForm({
  isModalEliminarCandFormShown,
  setIsModalEliminarCandFormShown,
  eliminarCandForm,
  isSubmitButtonLoading,
}) {
  return (
    <Modal
      show={isModalEliminarCandFormShown}
      onHide={() => setIsModalEliminarCandFormShown(false)}
      onShow={() => setIsModalEliminarCandFormShown(true)}
    >
      <Modal.Header>
        <Modal.Title>Eliminar formación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se <b>eliminará</b> la formación del candidato
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="danger"
          text="Eliminar formación"
          submit={eliminarCandForm}
          loading={isSubmitButtonLoading}
          disabled={isSubmitButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEliminarCandForm };
