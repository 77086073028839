import React, { useEffect, useState } from 'react';
import { Button } from 'adminlte-2-react';
import Loader from './Loader';

function ButtonWithLoading({
  submit,
  type = 'primary',
  text = '',
  loading = false,
  pullRight = false,
  className = '',
  disabled = false,
  size = 'md',
  icon = null,
  margin = null,
}) {
  return (
    <Button
      margin={margin}
      icon={icon}
      className={className}
      type={type}
      disabled={loading || disabled}
      text={
        text && (
          <Loader loaderColor="#ffffff" isLoading={loading}>
            {text}
          </Loader>
        )
      }
      pullRight={pullRight}
      onClick={submit}
      size={size}
    />
  );
}

export { ButtonWithLoading };
