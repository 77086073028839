import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../../utils/SelectInput';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { TextInput } from '../../utils/TextInput';

function ModalEditarExperiencia({
  isModalEditarExpShown,
  hideModalEditarExp,
  showModalEditarExp,
  editarExperiencia,
  isEditarExpButtonDisabled,
  isEditarExpButtonLoading,
  empresaOtro,
  empresaDescripcion,
  irASeleccionar,
  cargoOption,
  cargosOptions,
  setCargoOption,
  cargoOtro,
}) {
  return (
    <Modal
      show={isModalEditarExpShown}
      onHide={hideModalEditarExp}
      onShow={showModalEditarExp}
    >
      <Modal.Header>
        <Modal.Title>Editar experiencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {empresaOtro && (
            <>
              Empresa ingresada por candidato: <span>{empresaOtro}</span>
              <br />
              <br />
            </>
          )}
          <TextInput
            label="Empresa seleccionada"
            initialValue={empresaDescripcion ?? null}
            disabled
          />

          <ButtonWithLoading
            text="Ir a seleccionar"
            size="sm"
            type="default"
            submit={irASeleccionar}
          />

          <br />
          <br />
          {cargoOtro && (
            <>
              Cargo ingresado por candidato: <span>{cargoOtro}</span>
              <br />
              <br />
            </>
          )}
          <SelectInput
            label="Cargo seleccionado"
            value={cargoOption}
            options={cargosOptions}
            setValue={setCargoOption}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Guardar cambios"
          submit={editarExperiencia}
          disabled={isEditarExpButtonDisabled}
          loading={isEditarExpButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEditarExperiencia };
