import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { SelectInput } from '../utils/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { responseSelector } from '../utils/utils';
import { alertTypes } from '../ui/alert';
import { estudioMercadoEditDuck, estudioMercadoEditThunk } from './duck';

const tipoOptions = [
  { label: 'General', value: 'general' },
  { label: 'Específico', value: 'especifico' },
];

function ModalEstudioMercadoEdit({
  token,
  isModalShown,
  hideModal,
  setTopAlert,
  setSelectedEmForEdit,
  selectedEmForEdit,
  recargarEstudiosEspecificos,
  recargarEstudiosGenerales,
}) {
  const dispatch = useDispatch();

  const estudioMercadoEdit = useSelector((state) => state.estudioMercadoEdit);

  const [tipoOption, setTipoOption] = useState(() =>
    tipoOptions.find((option) => option.value === selectedEmForEdit.tipo)
  );

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(estudioMercadoEdit);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudioMercadoEditDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setTopAlert({
        text: 'Estudio de mercado editado correctamente',
        type: alertTypes.success,
        closable: false,
      });
      recargarEstudiosEspecificos();
      recargarEstudiosGenerales();
      dispatch(estudioMercadoEditDuck.actions.clear());
      setSelectedEmForEdit(null);
    }
  }, [estudioMercadoEdit]);

  function editarEstudioMercado() {
    dispatch(
      estudioMercadoEditThunk({
        userToken: token.raw,
        estudioMercadoIdNumerico: selectedEmForEdit.idNumerico,
        tipo: tipoOption.value,
      })
    );
  }

  return (
    <Modal show={isModalShown} style={{ opacity: 1 }} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Modificar estudio de mercado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectInput
          label="Tipo"
          value={tipoOption}
          options={tipoOptions}
          setValue={setTipoOption}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonWithLoading
            type="warning"
            text="Volver"
            disabled={estudioMercadoEdit.loading}
            loading={estudioMercadoEdit.loading}
            submit={() => setSelectedEmForEdit(null)}
          />

          <ButtonWithLoading
            type="primary"
            text="Guardar"
            disabled={estudioMercadoEdit.loading}
            loading={estudioMercadoEdit.loading}
            submit={editarEstudioMercado}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEstudioMercadoEdit };
