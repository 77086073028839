import React from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { DateRangeInput } from '../ui/DateRangeInput';
import { Alert } from '../ui/alert';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { etapasIdByName } from '../utils/constants';
import {
  toTitleCase,
  etapas,
  procesarNota,
  getDate,
  getDateOrEmpty,
} from '../utils/utils';

import './ReporteProduccionSelector.css';

function ReporteProduccionDetalleView({
  history,
  topAlert,
  modelGetReporteProduccion,
  usuariosLoadStatus,
  usuarioOption,
  usuariosOptions,
  setUsuarioOption,
  inicio,
  setInicio,
  fin,
  setFin,
  submit,
  tipo,
  setTipo,
  tipoOptions,
  reporteProduccion,
  handlePage,
  activePage,
  pageSize,
  totalResults,
}) {
  return (
    <Content
      title="Reporte de producción"
      subTitle="Detalle"
      browserTitle="Reporte de producción: detalle"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Loader isLoading={usuariosLoadStatus !== 'LOADED'}>
            <Box title="Filtro">
              <SelectInput
                label="Tipo de reporte"
                hint="Selector (entrevista selector), Supervisor (entrevista lider)"
                value={tipo}
                options={tipoOptions}
                setValue={setTipo}
              />
              <SelectInput
                label="Colaborador"
                hint="Busca notas que cuentan como filtro a cargo del selector o supervisor"
                value={usuarioOption}
                options={usuariosOptions}
                setValue={setUsuarioOption}
                disabled={usuariosLoadStatus !== 'LOADED'}
                isLoading={usuariosLoadStatus !== 'LOADED'}
              />

              <div className="form-group">
                <label>Fecha</label>

                <DateRangeInput
                  showLabels
                  labelStyle={{ fontWeight: 'normal' }}
                  inicio={inicio}
                  setInicio={setInicio}
                  fin={fin}
                  setFin={setFin}
                  xs={12}
                  md={12}
                />
              </div>

              <div style={{ float: 'right' }}>
                <div style={{ display: 'inline-block', marginRight: 10 }}>
                  <Button
                    type="primary"
                    text="Filtrar"
                    onClick={() => submit()}
                  />
                </div>
              </div>
            </Box>
          </Loader>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Box title="Lista">
            <Loader isLoading={modelGetReporteProduccion.loading}>
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={handlePage}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
              {reporteProduccion && reporteProduccion.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={reporteProduccion}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column
                        field="candidato"
                        header="Candidato"
                        body={(nota) => (
                          <a
                            href={`/editarCandidato/${nota.candidatoIdNumerico}`}
                          >
                            {toTitleCase(nota.candidatoNombre)}
                          </a>
                        )}
                      ></Column>

                      <Column
                        field="oportunidad"
                        header="Oportunidad"
                        body={(nota) => (
                          <a href={`/editarOportunidad/${nota.oportunidadId}`}>
                            {nota.oportunidadNombre}
                          </a>
                        )}
                      ></Column>
                      <Column
                        field="etapa"
                        header="Etapa"
                        body={(nota) => etapas[nota.etapaId]}
                      ></Column>
                      <Column
                        header="Fecha"
                        body={(nota) => getDateOrEmpty(nota.creacionTs)}
                      ></Column>
                      <Column
                        field="titulo"
                        header="Titulo"
                        body={(nota) => toTitleCase(nota.titulo)}
                      ></Column>
                      <Column
                        field="contenido"
                        header="Contenido"
                        body={(nota) => procesarNota(nota)}
                      ></Column>
                    </DataTable>
                    <Pagination
                      activePage={activePage}
                      pageSize={pageSize}
                      totalElements={totalResults}
                      onChange={handlePage}
                      labels={{
                        first: 'Primera',
                        last: 'Última',
                        previous: 'Anterior',
                        next: 'Siguiente',
                      }}
                    />
                    <div style={{ display: 'block' }}>
                      <span>Total: {totalResults}</span>
                    </div>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado elementos</h4>
              )}
            </Loader>
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ReporteProduccionDetalleView };
