import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { toTitleCase } from '../utils/utils';

function ModalConfirmarBorrar({
  isModalConfirmarBorrarShown,
  hideModalConfirmarBorrar,
  showModalConfirmarBorrar,
  selectedOportunidad,
  borrarOportunidad,
  isBorrarButtonLoading,
}) {
  return (
    <Modal
      show={isModalConfirmarBorrarShown}
      style={{ opacity: 1 }}
      onHide={hideModalConfirmarBorrar}
      onShow={showModalConfirmarBorrar}
    >
      <Modal.Header>
        <Modal.Title>Borrar oportunidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se <b>borrará</b> la oportunidad.
        <div style={{ marginTop: 5 }}>
          <br />
          Empresa: {toTitleCase(selectedOportunidad.empresaDescripcion)}
          <br />
          Cargo: {toTitleCase(selectedOportunidad.cargoDescripcion)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="danger"
          text="Borrar oportunidad"
          submit={borrarOportunidad}
          loading={isBorrarButtonLoading}
          disabled={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalConfirmarBorrar };
