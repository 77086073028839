const winston = require("winston");

// Niveles de log
// en orden de importancia
// descendente
const LOGGER_LEVELS = {
  emerg: "emerg",
  alert: "alert",
  crit: "crit",
  error: "error",
  warning: "warning",
  notice: "notice",
  info: "info",
  debug: "debug",
};

// Nivel mínimo a loggear
// (niveles más bajos se ignoran)
const MIN_LEVEL = process.env.REACT_APP_WINSTON_MIN_LVL;

const consoleFormat = winston.format.combine(

  winston.format.timestamp(),
  winston.format.json(),
  winston.format.printf((info) => {
    const args = info[Symbol.for('splat')];
    let strArgs = ', ';
    if (args && args.length > 0) {
      strArgs += args.map((row) => JSON.stringify(row)).join(' ');
    } else {
      strArgs = '';
    }
    if (typeof info.message === 'object') {
      info.message = `${JSON.stringify(info.message, null, 3)}${strArgs}`;
    }
    return `{ level: ${info.level}, message: ${
      info.message
    }${strArgs} }`;
  }),
);
// eslint-disable-next-line new-cap
export const logger = new winston.createLogger({
  levels: winston.config.syslog.levels,
  format: winston.format.json(),
  transports: [
    new winston.transports.Console({
      level: MIN_LEVEL,
      timestamp: true,
      format: consoleFormat,

    }),
  ],
});

export const log = (datos, nivel = 'debug', req = null) => {
  const transaccionId = req?.transaccionId;
  const url = req?.originalUrl;
  const nivelValido = Object.keys(LOGGER_LEVELS).includes(nivel);
  const data = {
    datos,
  };
  if (transaccionId && url) {
    data.benco = {
      url: req?.originalUrl,
      transaccionId,
    };
  }
  const debugLevel = nivelValido ? nivel : 'debug';
  logger[debugLevel](data);
};

export const logError = (err) => {
  const mensaje = err?.response?.data?.message || err?.response?.data?.mensaje || err?.response?.data || err.message;
  const stack = err?.stack;
  logger.debug({ mensaje, stack });
};

