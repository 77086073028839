/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Content,
  Row,
  Col,
  Box,
  Button,
  Pagination,
  Inputs,
} from 'adminlte-2-react';
import Highlighter from 'react-highlight-words';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { Alert, alertTypes } from '../ui/alert';
import {
  constants,
  getDateUtc,
  log,
  toTitleCase,
  openWhatsapp,
  procesarNota,
  submitSearchOnEnterPress,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { ModalAgregarAOportunidad } from './ModalAgregarAOportunidad';
import { ModalNotas } from './ModalNotas';
import { CheckBox } from '../ui/Checkbox';
import { ModalGenerico } from '../ui/ModalGenerico';

import './ListaCandidatos.css';

const { Checkbox } = Inputs;

const MemoHighlighter = React.memo(function TextHighlighter({
  highlightClassName,
  searchWords,
  autoEscape,
  textToHighlight,
}) {
  return (
    <Highlighter
      highlightClassName={highlightClassName}
      searchWords={searchWords}
      autoEscape={autoEscape}
      textToHighlight={textToHighlight}
      highlightStyle={{ backgroundColor: '#faff70' }}
    />
  );
});

const MemoizedTable = React.memo(
  function Table({
    modelCandidatos,
    candidatos,
    oportunidadId,
    candidatosVistosHoy,
    busquedaAplicada,
    getCV,
    addCandidatoVistoHoy,
    setCandidatoIdNumerico,
    setExpectativaSalarial,
    handleSelection,
  }) {
    return (
      <Loader isLoading={modelCandidatos.loading}>
        {candidatos && candidatos.length > 0 ? (
          <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
            <div className="table-responsive">
              <DataTable
                lazy
                pt={{
                  table: {
                    className: 'table table-hover',
                  },
                }}
                value={candidatos}
                emptyMessage="No se encontraron registros"
              >
                {oportunidadId && (
                  <Column
                    body={(candidato) => {
                      return (
                        <Checkbox
                          name={candidato.idNumerico}
                          disabled={oportunidadId ? false : true}
                          onChange={(evt) =>
                            handleSelection(evt.target.name, evt.target.checked)
                          }
                        />
                      );
                    }}
                  ></Column>
                )}

                <Column
                  field="nombreCompleto"
                  header="Nombre"
                  body={(candidato) => {
                    return (
                      <a
                        href={`/editarCandidato/${candidato.idNumerico}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: candidatosVistosHoy?.[candidato._id]
                            ? '#b623fa'
                            : 'rgb(60, 141, 188)',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          addCandidatoVistoHoy(candidato, true);
                        }}
                      >
                        <MemoHighlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={busquedaAplicada}
                          autoEscape={true}
                          textToHighlight={toTitleCase(
                            candidato.nombreCompleto ?? ''
                          )}
                        />
                      </a>
                    );
                  }}
                ></Column>

                <Column
                  field="curriculum"
                  header="Curriculum"
                  body={(candidato) => {
                    return (
                      <Button
                        type="default"
                        text="Ver CV"
                        size="sm"
                        onClick={() => getCV(candidato.idNumerico)}
                      />
                    );
                  }}
                ></Column>
                <Column
                  header="Acciones"
                  body={(candidato) => (
                    <ButtonWithLoading
                      text="Editar"
                      size="sm"
                      type="default"
                      submit={() => {
                        addCandidatoVistoHoy(candidato);
                      }}
                    />
                  )}
                ></Column>
                <Column
                  field="telefono2"
                  header="Telefono"
                  body={(candidato) => {
                    return (
                      <a
                        href="javascript:;"
                        onClick={() =>
                          openWhatsapp({
                            telefono: candidato.telefono2,
                            mensaje: `Hola ${toTitleCase(
                              candidato.nombreCompleto
                            )}`,
                          })
                        }
                      >
                        <MemoHighlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={busquedaAplicada}
                          autoEscape={true}
                          textToHighlight={candidato.telefono2 ?? ''}
                        />
                      </a>
                    );
                  }}
                ></Column>
                <Column
                  field="experiencias"
                  header="Experiencia"
                  body={(candidato) =>
                    candidato?.experiencias?.length > 0
                      ? candidato.experiencias.slice(0, 2).map((exp, index) => {
                          return (
                            <Experiencia
                              key={index}
                              datos={exp}
                              busquedaAplicada={busquedaAplicada}
                            />
                          );
                        })
                      : 'N/A'
                  }
                  style={{ minWidth: 90 }}
                ></Column>

                <Column
                  header="Notas"
                  style={{ minWidth: 90 }}
                  body={(candidato) => {
                    if (!candidato.ultimaNota) {
                      return (
                        <Button
                          type="default"
                          text="Ver más"
                          size="sm"
                          onClick={() =>
                            setCandidatoIdNumerico(candidato.idNumerico)
                          }
                        />
                      );
                    }
                    return (
                      <>
                        <strong>
                          <MemoHighlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={busquedaAplicada}
                            autoEscape={true}
                            textToHighlight={toTitleCase(
                              candidato.ultimaNota.titulo ?? ''
                            )}
                          />
                        </strong>
                        <br />
                        <p>
                          <MemoHighlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={busquedaAplicada}
                            autoEscape={true}
                            textToHighlight={procesarNota(
                              candidato.ultimaNota ?? ''
                            )}
                          />
                        </p>
                        <Button
                          type="default"
                          text="Ver más"
                          size="sm"
                          onClick={() => {
                            setCandidatoIdNumerico(candidato.idNumerico);
                            setExpectativaSalarial(
                              candidato.expectativaSalarial
                            );
                          }}
                        />
                      </>
                    );
                  }}
                ></Column>
              </DataTable>
            </div>
          </Box>
        ) : (
          <h4>No se han encontrado candidatos</h4>
        )}
      </Loader>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.modelCandidatos.loading === nextProps.modelCandidatos.loading &&
      prevProps.candidatos === nextProps.candidatos &&
      prevProps.candidatosVistosHoy === nextProps.candidatosVistosHoy
    );
  }
);

function Experiencia({ datos, busquedaAplicada }) {
  return (
    <div style={{ marginBottom: 5 }}>
      <span style={{ display: 'block' }}>
        <strong>
          {datos.empresaDescripcion ? (
            toTitleCase(datos.empresaDescripcion)
          ) : (
            // <span className="text-muted">(Sin especificar)</span>
            <span className="text-muted">N/A</span>
          )}
        </strong>
      </span>
      <span style={{ display: 'block' }}>
        <MemoHighlighter
          highlightClassName="YourHighlightClass"
          searchWords={busquedaAplicada}
          autoEscape={true}
          textToHighlight={toTitleCase(datos.cargoDescripcion ?? '')}
        />
      </span>
    </div>
  );
}

function ListaCandidatosView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  candidatos,
  busquedaRapida,
  setBusquedaRapida,
  rubroOption,
  setRubroOption,
  cargoOption,
  setCargoOption,
  modelCandidatos,
  cargosOptions,
  rubrosOptions,
  handleSelection,
  oportunidadId,
  modelIncluirEnOportunidad,
  isModalAgregarShown,
  hideModalAgregar,
  showModalAgregar,
  incluirEnOportunidad,
  etapaOption,
  etapasOptions,
  setEtapaOption,
  oportunidad,
  arrayMarcados,
  empresaOption,
  setEmpresaOption,
  empresaOptions,
  isModalNotasShown,
  hideModalNotas,
  showModalNotas,
  notas,
  candidatoIdNumerico,
  setCandidatoIdNumerico,
  handlePageNotas,
  totalResultsNotas,
  activePageNotas,
  pageSizeNotas,
  buscarEnNotas,
  setBuscarEnNotas,
  setTitulo,
  setContenido,
  submitNota,
  titulo,
  contenido,
  setArrayMarcados,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,
  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,
  getCV,
  expectativaSalarial,
  setExpectativaSalarial,
  cargoPotSubmitAlert,
  cargosPotenciales,
  modelGetCargosPot,
  modelCrearCargoPot,
  agregarCargoPot,
  setPageSizeSelected,
  pageSizeSelected,
  setPageSize,
  busquedaAplicada,
  candidatosVistosHoy,
  addCandidatoVistoHoy,
  cargoOptionNota,
  setCargoOptionNota,
  notaFavoritaSet,
  setearNotaFavorita,
}) {
  const pageSizeOptions = [
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
  ];
  const [width, setWidth] = useState(window.innerWidth);
  const [isModalGenericoShown, setIsModalGenericoShown] = useState(false);
  const [pageAux, setPageAux] = useState(false);

  const hideModalGenerico = () => {
    setIsModalGenericoShown(false);
  };

  const showModalGenerico = (page = null) => {
    if (page) {
      setPageAux(page);
    } else {
      setPageAux(0);
    }
    setIsModalGenericoShown(true);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1200;

  const goToNextPage = (page) => {
    arrayMarcados.length > 0 ? showModalGenerico(page) : handlePage(page);
  };

  return (
    <Content
      title="Candidatos"
      subTitle=""
      browserTitle="Candidatos"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Loader isLoading={oportunidadId && !oportunidad}>
        {oportunidad && (
          <Alert
            title="Incluir candidatos a oportunidad"
            type={alertTypes.info}
            text={
              <>
                <div style={{ marginBottom: 5 }}>
                  Título: {oportunidad.titulo} - Empresa:{' '}
                  {toTitleCase(oportunidad.empresaDescripcion)}
                  <br />
                  Cargo: {toTitleCase(oportunidad.cargoDescripcion)} - Rubro:{' '}
                  {toTitleCase(oportunidad.rubroDescripcion)}
                </div>
                <span style={{ fontWeight: 'bold' }}>
                  * Los candidatos ya incluidos no se muestran en la lista
                </span>
              </>
            }
          />
        )}

        {oportunidad && (
          <ModalAgregarAOportunidad
            isModalAgregarShown={isModalAgregarShown}
            hideModalAgregar={hideModalAgregar}
            showModalAgregar={showModalAgregar}
            incluirEnOportunidad={incluirEnOportunidad}
            isIncluirButtonDisabled={!etapaOption?.value}
            isIncluirButtonLoading={modelIncluirEnOportunidad.loading}
            etapaOption={etapaOption}
            etapasOptions={etapasOptions}
            setEtapaOption={setEtapaOption}
            oportunidad={oportunidad}
          />
        )}

        <ModalGenerico
          isModalShown={isModalGenericoShown}
          hideModal={hideModalGenerico}
          title={'Alerta'}
          body={
            'Al filtrar, tu seleccion sera borrada. Esta seguro que desea filtrar?'
          }
          buttonText={'Filtrar'}
          submit={() => {
            setArrayMarcados([]);
            hideModalGenerico();
            handlePage(pageAux);
          }}
        />

        <ModalNotas
          candidatoIdNumerico={candidatoIdNumerico}
          isModalShown={isModalNotasShown}
          hideModal={hideModalNotas}
          showModal={showModalNotas}
          notas={notas}
          history={history}
          activePage={activePageNotas}
          pageSize={pageSizeNotas}
          totalResults={totalResultsNotas}
          handlePage={handlePageNotas}
          submitNota={submitNota}
          contenido={contenido}
          setContenido={setContenido}
          titulo={titulo}
          setTitulo={setTitulo}
          notaSelected={notaSelected}
          setNotaSelected={setNotaSelected}
          userIdNumerico={userIdNumerico}
          deleteNota={deleteNota}
          cuentaFiltroChecked={cuentaFiltroChecked}
          setCuentaFiltroChecked={setCuentaFiltroChecked}
          entrevistaCortesiaChecked={entrevistaCortesiaChecked}
          setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
          resetNotaInputs={resetNotaInputs}
          expectativaSalarial={expectativaSalarial}
          setExpectativaSalarial={setExpectativaSalarial}
          cargoPotSubmitAlert={cargoPotSubmitAlert}
          cargosPotenciales={cargosPotenciales}
          modelGetCargosPot={modelGetCargosPot}
          modelCrearCargoPot={modelCrearCargoPot}
          agregarCargoPot={agregarCargoPot}
          cargosOptions={cargosOptions}
          cargoOption={cargoOptionNota}
          setCargoOption={setCargoOptionNota}
          notaFavoritaSet={notaFavoritaSet}
          setearNotaFavorita={setearNotaFavorita}
        />

        <Row>
          <Col xs="12" sm="4" lg="3">
            <Box title="Filtro">
              <TextInput
                label="Búsqueda rápida"
                placeholder="Nombre, apellido, teléfono"
                initialValue={busquedaRapida}
                setValue={setBusquedaRapida}
                onKeyDown={(event) =>
                  submitSearchOnEnterPress(event, () => handlePage(0))
                }
              />

              <CheckBox
                checked={buscarEnNotas}
                text="Buscar en notas"
                onChange={setBuscarEnNotas}
              />

              <hr />

              <SelectInput
                label="Rubro"
                value={rubroOption}
                options={rubrosOptions}
                setValue={setRubroOption}
                isMulti={true}
                maxMultiSelection={10}
              />

              <SelectInput
                label="Cargo"
                value={cargoOption}
                options={cargosOptions}
                setValue={setCargoOption}
                isMulti={true}
                maxMultiSelection={10}
              />

              <SelectInput
                label="Empresas"
                value={empresaOption}
                options={empresaOptions}
                setValue={setEmpresaOption}
                isMulti={true}
              />

              <div style={{ float: 'right' }}>
                <div style={{ display: 'inline-block', marginRight: 10 }}>
                  <Button
                    type="primary"
                    text="Filtrar"
                    onClick={() =>
                      arrayMarcados.length > 0
                        ? showModalGenerico()
                        : handlePage(0)
                    }
                  />
                </div>
              </div>
            </Box>
          </Col>

          <Col xs="12" sm="8" lg="9">
            <Box
              title="Lista"
              customOptions={
                <div>
                  <Button
                    size="sm"
                    type="default"
                    text="Agregar"
                    onClick={() => history.push('/crearCandidato')}
                  />
                </div>
              }
            >
              <div style={{ display: 'flex' }}>
                <SelectInput
                  label="Resultados por página"
                  value={pageSizeSelected}
                  options={pageSizeOptions}
                  setValue={(val) => {
                    setPageSizeSelected(val);
                    // se setea a 0 para que el componente de paginacion vuelva a renderizar
                    setPageSize(0);
                  }}
                />
              </div>

              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {pageSize && (
                    <Pagination
                      activePage={activePage}
                      pageSize={pageSize}
                      totalElements={totalResults}
                      onChange={goToNextPage}
                      labels={{
                        first: 'Primera',
                        last: 'Última',
                        previous: 'Anterior',
                        next: 'Siguiente',
                      }}
                    />
                  )}
                </div>
                <div>
                  {oportunidadId && (
                    <div
                      style={{
                        marginTop: isMobile ? 0 : 20,
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                      }}
                    >
                      <ButtonWithLoading
                        text="Incluir en oportunidad"
                        className="btn"
                        submit={() => showModalAgregar()}
                        disabled={!oportunidad || !arrayMarcados.length}
                      />
                    </div>
                  )}
                </div>
              </div>

              <MemoizedTable
                modelCandidatos={modelCandidatos}
                candidatos={candidatos}
                oportunidadId={oportunidadId}
                candidatosVistosHoy={candidatosVistosHoy}
                busquedaAplicada={busquedaAplicada}
                getCV={getCV}
                addCandidatoVistoHoy={addCandidatoVistoHoy}
                setCandidatoIdNumerico={setCandidatoIdNumerico}
                setExpectativaSalarial={setExpectativaSalarial}
                handleSelection={handleSelection}
              />

              {pageSize && (
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              )}

              <div>
                <span>Total: {totalResults}</span>
              </div>

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { ListaCandidatosView };
