/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';

import { Alert } from '../../utils/Alert';

import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toTitleCase } from '../../utils/utils';

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.cargosPotenciales === prevProps.cargosPotenciales &&
    nextProps.cargoPotSubmitAlert === prevProps.cargoPotSubmitAlert &&
    nextProps.modelGetCargosPot.loading ===
      prevProps.modelGetCargosPot.loading &&
    nextProps.prepareCargoPotEditAndShowModal ===
      prevProps.prepareCargoPotEditAndShowModal
  );
};

const CargosPotenciales = memo(function Componente({
  showModalAgregarCargoPot,
  cargoPotSubmitAlert,
  modelGetCargosPot,
  cargosPotenciales,
  setSelectedCargoPot,
  showModalEliminarCargoPot,
  prepareCargoPotEditAndShowModal,
}) {
  return (
    <Row>
      <Col md="10" mdOffset="1">
        <Box
          title="Cargos potenciales"
          customOptions={
            <div>
              <Button
                size="sm"
                type="default"
                text="Agregar"
                onClick={() => showModalAgregarCargoPot()}
              />
            </div>
          }
        >
          {cargoPotSubmitAlert.text && (
            <Alert
              title={cargoPotSubmitAlert.title}
              type={cargoPotSubmitAlert.type}
              text={cargoPotSubmitAlert.text}
              closable={cargoPotSubmitAlert.closable}
            />
          )}

          <Loader isLoading={modelGetCargosPot.loading}>
            <div style={{ display: 'block' }}>
              <span>Total: {cargosPotenciales.length}</span>
            </div>

            {cargosPotenciales && cargosPotenciales.length > 0 ? (
              <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                <div className="table-responsive">
                  <DataTable
                    lazy
                    pt={{
                      table: {
                        className: 'table table-hover',
                      },
                    }}
                    value={cargosPotenciales}
                    emptyMessage="No se encontraron cargos potenciales"
                  >
                    <Column
                      field="acciones"
                      header="Acciones"
                      body={(cargoPotencial) => (
                        <div style={{ width: 130 }}>
                          <div
                            style={{
                              display: 'inline-block',
                              marginRight: 5,
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="warning"
                              text="Eliminar"
                              submit={() => {
                                setSelectedCargoPot(cargoPotencial);
                                showModalEliminarCargoPot();
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="default"
                              text="Editar"
                              submit={() => {
                                setSelectedCargoPot(cargoPotencial);
                                prepareCargoPotEditAndShowModal(cargoPotencial);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="cargoDescripcion"
                      header="Cargo"
                      body={(cargoPotencial) =>
                        toTitleCase(cargoPotencial.cargoDescripcion)
                      }
                    ></Column>
                    <Column
                      field="actualizacionUsuarioNombre"
                      header="Usuario"
                      body={(cargoPotencial) =>
                        toTitleCase(cargoPotencial.actualizacionUsuarioNombre)
                      }
                    ></Column>
                  </DataTable>
                </div>
              </Box>
            ) : (
              <h4>No se han encontrado cargos potenciales</h4>
            )}
          </Loader>
        </Box>
      </Col>
    </Row>
  );
},
customComparator);

export { CargosPotenciales };
