import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import initFacebookSDK from './initFacebookSDK';
import { PrimeReactProvider } from 'primereact/api';

if (process.env.REACT_APP_USE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://bdd225878cc94eecbc6b1e67e3d668a6@o4504558913454080.ingest.sentry.io/4504881702699008',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const renderApp = () => {
  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
      </Provider>
    </React.Fragment>,
    document.getElementById('root')
  );
};

initFacebookSDK().then(() => {
  renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
