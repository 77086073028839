import React, { useEffect, useRef } from 'react';
import { MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';

function Autocomplete({
  label,
  required,
  minInputLength = 3, // will execute "dispatchGetDataList" on this or more characters
  delayInMs = 200, // delay to execute "dispatchGetDataList" after user stops typing
  dispatchGetDataList,
  dataList = [],
  setDataList,
  error,
  setError,
  value,
  setValue,
  onItemClick,
  onLastItemClick,
  lastItemText,
  placeholder = 'Buscar...',
  renderItem,
  showSearchButton,
  onSearchClick,
  disabled,
  showLastItem = false,
}) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (value.length >= minInputLength) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(dispatchGetDataList, delayInMs);
    }
  }, [value]);

  // Cleanup function to avoid memory leaks
  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div>
      <div className={showSearchButton ? 'input-group' : ''}>
        <input
          className="form-control"
          type="text"
          value={value}
          onChange={(e) => {
            setDataList([]);
            setError('');
            setValue(e.target.value);
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        {showSearchButton && (
          <span className="input-group-btn">
            <button
              className="btn btn-info"
              type="button"
              onClick={onSearchClick}
              disabled={disabled}
            >
              <FontAwesomeIcon icon="search" />
            </button>
          </span>
        )}
      </div>
      <div className="dropdown">
        <ul
          className="dropdown-menu"
          style={{
            paddingBottom: 0,
            display:
              error || (value.length && dataList?.length) ? 'block' : 'none',
            width: '100%',
            border: '1px solid #ccc',
            maxHeight: 300,
            overflowY: 'auto',
          }}
        >
          {error && (
            <MenuItem disabled>
              <div style={{ padding: 10 }}>{error}</div>
            </MenuItem>
          )}
          {!error &&
            dataList.map((item) => (
              <MenuItem key={item._id} onClick={() => onItemClick(item)}>
                {renderItem(item)}
              </MenuItem>
            ))}

          {/* <MenuItem divider /> */}
          {!!showLastItem && <li onClick={onLastItemClick}>{lastItemText}</li>}
        </ul>
      </div>
    </div>
  );
}

export { Autocomplete };
