import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../../utils/SelectInput';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { TextInput } from '../../utils/TextInput';

function ModalEditarCandForm({
  isModalEditarCandFormShown,
  setIsModalEditarCandFormShown,
  institutoDescripcion,
  titulacionOption,
  titulacionesOptions,
  setTitulacionOption,
  editarCandForm,
  isEditarCandFormButtonLoading,
  institutoOtro,
  titulacionOtro,
  irASeleccionar,
}) {

  return (
    <Modal
      show={isModalEditarCandFormShown}
      onHide={() => setIsModalEditarCandFormShown(false)}
      onShow={() => setIsModalEditarCandFormShown(true)}
    >
      <Modal.Header>
        <Modal.Title>Editar formación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <>
            {institutoOtro && (
              <>
                Instituto ingresado por candidato: <span>{institutoOtro}</span>
                <br />
                <br />
              </>
            )}
            <TextInput
              label="Instituto seleccionado"
              initialValue={institutoDescripcion ?? null}
              disabled
            />

            <ButtonWithLoading
              text="Ir a seleccionar"
              size="sm"
              type="default"
              submit={irASeleccionar}
            />
          </>
          <br />
          <br />

          {titulacionOtro && (
            <>
              Título ingresado por candidato: <span>{titulacionOtro}</span>
              <br />
              <br />
            </>
          )}

          <SelectInput
            label="Titulación seleccionada"
            value={titulacionOption}
            options={titulacionesOptions}
            setValue={setTitulacionOption}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Guardar cambios"
          submit={editarCandForm}
          disabled={isEditarCandFormButtonLoading}
          loading={isEditarCandFormButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEditarCandForm };
