/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { Alert } from '../ui/alert';
import {
  constants,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { ModalGenerico } from '../ui/ModalGenerico';
import { useWindowSize } from '../utils/useWindowSize';

function ListaContactoMensajesView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  lista,
  busquedaRapida,
  setBusquedaRapida,
  modelGetContactoMensajes,
}) {
  const { isMobile } = useWindowSize();

  const goToNextPage = (page) => {
    handlePage(page);
  };

  return (
    <Content title="Empresas" subTitle="" browserTitle="Empresas" show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Row>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <TextInput
              label="Búsqueda rápida"
              placeholder="Nombre, ruc"
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={(event) =>
                submitSearchOnEnterPress(event, () => handlePage(0))
              }
            />

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>

        <Col xs="12" sm="8" lg="9">
          <Box title="Lista" customOptions={<div></div>}>
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              </div>
            </div>

            <Loader isLoading={modelGetContactoMensajes.loading}>
              {lista && lista.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={lista}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column
                        header="Nombre"
                        body={(mensaje) => toTitleCase(mensaje.nombreCompleto)}
                      ></Column>

                      <Column
                        header="Email"
                        body={(mensaje) => mensaje.email}
                      ></Column>

                      <Column
                        header="Mensaje"
                        body={(mensaje) => (
                          <div style={{}}>{mensaje.mensaje}</div>
                        )}
                      ></Column>

                      <Column
                        header="Página"
                        body={(mensaje) => toTitleCase(mensaje.origen)}
                      ></Column>
                    </DataTable>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado mensajes de contacto</h4>
              )}
            </Loader>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={goToNextPage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div>
              <span>Total: {totalResults}</span>
            </div>

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ListaContactoMensajesView };
