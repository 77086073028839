import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Etiqueta from './etiquetaObligatorio/Etiqueta';

function DateInput({
  label = null,
  dateFormat = 'yyyy/MM/dd',
  selected,
  onChange,
  customInput = null,
  required = false,
  minDate = null,
  disabled = false,
  placeholderText = '',
  showYearDropdown = false,
  yearDropdownItemNumber,
  scrollableYearDropdown = false,
}) {
  const mCustomInput = customInput ? (
    customInput
  ) : (
    <input type="text" className="form-control" />
  );
  return (
    <div className="form-group">
      {Boolean(label) && (
        <label>
          <Etiqueta texto={label} obligatorio={required} />
        </label>
      )}
      <DatePicker
        dateFormat={dateFormat}
        customInput={mCustomInput}
        selected={selected}
        onChange={onChange}
        // onFocus={(e) => e.target.blur()} // para impedir que escriban en input
        popperClassName="zIndexParaCalendario"
        minDate={minDate}
        disabled={disabled}
        placeholderText={placeholderText}
        showYearDropdown={showYearDropdown}
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown={scrollableYearDropdown}
      />
    </div>
  );
}
export { DateInput };
