/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';
import Loader from '../../utils/Loader';
import { Alert } from '../../ui/alert';
import { submitSearchOnEnterPress } from '../../utils/utils';
import { TextInput } from '../../utils/TextInput';
import { EmCandidatosTable } from './EmCandidatosTable';
import { useWindowSize } from '../../utils/useWindowSize';
import { ModalGenerico } from '../../ui/ModalGenerico';
import { ModalEmCandidatoEdit } from './ModalEmCandidatoEdit';
import { ModalNotas } from '../../candidatos/ModalNotas';
import { ModalExportar } from './ModalExportar';

function ListaEmCandidatosView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  page,
  pageSize,
  handlePage,
  busquedaRapida,
  setBusquedaRapida,
  emCandidatosGet,
  groupedCandidates,
  emCandidatoDelete,
  deleteEmCandidato,
  selectedCandidato,
  setSelectedCandidato,
  candidatoSelectedForEdit,
  setCandidatoSelectedForEdit,
  emCandidatoEdit,
  editEmCandidato,
  parametroOption,
  parametroOptions,
  setParametroOption,
  condicionesActuales,
  setCondicionesActuales,
  condicionesContratacion,
  setCondicionesContratacion,
  resumen,
  setResumen,
  candidatoIdNumerico,
  isModalNotasShown,
  hideModalNotas,
  showModalNotas,
  notas,
  activePageNotas,
  pageSizeNotas,
  totalResultsNotas,
  handlePageNotas,
  submitNota,
  contenido,
  setContenido,
  titulo,
  setTitulo,
  notaSelected,
  setNotaSelected,
  userIdNumerico,
  deleteNota,
  cuentaFiltroChecked,
  setCuentaFiltroChecked,
  entrevistaCortesiaChecked,
  setEntrevistaCortesiaChecked,
  resetNotaInputs,
  expectativaSalarial,
  setExpectativaSalarial,
  cargoPotSubmitAlert,
  cargosPotenciales,
  modelGetCargosPot,
  modelCrearCargoPot,
  agregarCargoPot,
  cargosOptions,
  cargoOptionNota,
  setCargoOptionNota,
  notaFavoritaSet,
  setearNotaFavorita,
  setCandidatoIdNumerico,
  notaFavorita,
  notaFavoritaGet,
  token,
  estudioMercadoIdNumerico,
  queryParams,
}) {
  const { isMobile } = useWindowSize();
  const [testMostrarModal, setTestMostrarModal] = useState(false);
  const [isModalExportarShown, setIsModalExportarShown] = useState(false);

  return (
    <Content
      title="Candidatos"
      subTitle=""
      browserTitle="EM - Candidatos"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {selectedCandidato && (
        <ModalGenerico
          isModalShown={!!selectedCandidato}
          hideModal={() => setSelectedCandidato(null)}
          title="Eliminar"
          body="Se eliminará el candidato. ¿Continuar?"
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={emCandidatoDelete.loading}
          buttonLoading={emCandidatoDelete.loading}
          submit={() => deleteEmCandidato(selectedCandidato)}
        />
      )}

      <ModalNotas
        candidatoIdNumerico={candidatoIdNumerico}
        isModalShown={isModalNotasShown}
        hideModal={hideModalNotas}
        showModal={showModalNotas}
        notas={notas}
        history={history}
        activePage={activePageNotas}
        pageSize={pageSizeNotas}
        totalResults={totalResultsNotas}
        handlePage={handlePageNotas}
        submitNota={submitNota}
        contenido={contenido}
        setContenido={setContenido}
        titulo={titulo}
        setTitulo={setTitulo}
        notaSelected={notaSelected}
        setNotaSelected={setNotaSelected}
        userIdNumerico={userIdNumerico}
        deleteNota={deleteNota}
        cuentaFiltroChecked={cuentaFiltroChecked}
        setCuentaFiltroChecked={setCuentaFiltroChecked}
        entrevistaCortesiaChecked={entrevistaCortesiaChecked}
        setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
        resetNotaInputs={resetNotaInputs}
        expectativaSalarial={expectativaSalarial}
        setExpectativaSalarial={setExpectativaSalarial}
        cargoPotSubmitAlert={cargoPotSubmitAlert}
        cargosPotenciales={cargosPotenciales}
        modelGetCargosPot={modelGetCargosPot}
        modelCrearCargoPot={modelCrearCargoPot}
        agregarCargoPot={agregarCargoPot}
        cargosOptions={cargosOptions}
        cargoOption={cargoOptionNota}
        setCargoOption={setCargoOptionNota}
        notaFavoritaSet={notaFavoritaSet}
        setearNotaFavorita={setearNotaFavorita}
      />

      {candidatoSelectedForEdit && (
        <ModalEmCandidatoEdit
          isModalShown={!!candidatoSelectedForEdit}
          title="Editar candidato"
          emCandidatoEdit={emCandidatoEdit}
          editEmCandidato={editEmCandidato}
          parametroOption={parametroOption}
          parametroOptions={parametroOptions}
          setParametroOption={setParametroOption}
          condicionesActuales={condicionesActuales}
          setCondicionesActuales={setCondicionesActuales}
          condicionesContratacion={condicionesContratacion}
          setCondicionesContratacion={setCondicionesContratacion}
          resumen={resumen}
          setResumen={setResumen}
          candidatoSelectedForEdit={candidatoSelectedForEdit}
          setCandidatoSelectedForEdit={setCandidatoSelectedForEdit}
          testMostrarModal={testMostrarModal}
          setTestMostrarModal={setTestMostrarModal}
          setCandidatoIdNumerico={setCandidatoIdNumerico}
          setExpectativaSalarial={setExpectativaSalarial}
          notaFavorita={notaFavorita}
          notaFavoritaGet={notaFavoritaGet}
        />
      )}

      {isModalExportarShown && (
        <ModalExportar
          token={token}
          estudioMercadoIdNumerico={estudioMercadoIdNumerico}
          isModalShown={isModalExportarShown}
          hideModal={() => setIsModalExportarShown(false)}
        />
      )}

      <Loader isLoading={emCandidatosGet.loading}>
        <Row>
          <Col xs="12" sm="12" lg="12">
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: 350, marginRight: 20 }}>
                <Box title="Filtro">
                  <TextInput
                    label="Búsqueda rápida"
                    placeholder=""
                    initialValue={busquedaRapida}
                    setValue={setBusquedaRapida}
                    onKeyDown={(event) =>
                      submitSearchOnEnterPress(event, () => handlePage(1))
                    }
                  />

                  <hr />

                  <div style={{ float: 'right' }}>
                    <div style={{ display: 'inline-block', marginRight: 0 }}>
                      <Button
                        type="primary"
                        text="Filtrar"
                        onClick={() => handlePage(1)}
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" lg="12">
            <Box
              title="Lista"
              customOptions={
                <div style={{ display: 'flex' }}>
                  {/* <form
                    method="POST"
                    action="http://localhost:4000/v1/estudio/candidatos/export/pdf"
                  >
                    <button type="submit">Exportar a PDF</button>
                  </form> */}

                  <div style={{ marginRight: 10 }}>
                    <Button
                      type="default"
                      size="sm"
                      text="Incluir candidato"
                      onClick={() => {
                        const params = {
                          estudioMercadoIdNumerico,
                          queryParams,
                        };
                        localStorage.setItem(
                          'emParams',
                          JSON.stringify(params)
                        );
                        history.push('/candidatos');
                      }}
                    />
                  </div>

                  <div>
                    <Button
                      type="default"
                      size="sm"
                      text="Exportar"
                      onClick={() => {
                        setIsModalExportarShown(true);
                      }}
                    />
                  </div>
                </div>
              }
            >
              <div style={{ display: 'block' }}>
                <span>Total: {totalResults}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {pageSize && (
                    <Pagination
                      activePage={page - 1}
                      pageSize={pageSize}
                      totalElements={totalResults}
                      onChange={(page) => handlePage(page + 1)}
                      labels={{
                        first: 'Primera',
                        last: 'Última',
                        previous: 'Anterior',
                        next: 'Siguiente',
                      }}
                    />
                  )}
                </div>
              </div>

              <EmCandidatosTable
                emCandidatosGet={emCandidatosGet}
                groupedCandidates={groupedCandidates}
                setSelectedCandidato={setSelectedCandidato}
                setCandidatoSelectedForEdit={setCandidatoSelectedForEdit}
              />

              {pageSize && (
                <Pagination
                  activePage={page - 1}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={(page) => handlePage(page + 1)}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              )}

              <div>
                <span>Total: {totalResults}</span>
              </div>

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { ListaEmCandidatosView };
