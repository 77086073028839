import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getCandidatosDuck,
  getCandidatosThunk,
  incluirEnOportunidadDuck,
  incluirEnOportunidadThunk,
  getNotasDuck,
  getNotasThunk,
  crearNotaDuck,
  crearNotaThunk,
  updateNotaThunk,
  updateNotaDuck,
  deleteNotaDuck,
  deleteNotaThunk,
  getCvDuck,
  getCvThunk,
  crearCargoPotThunk,
  crearCargoPotDuck,
  getCargosPotDuck,
  getCargosPotThunk,
  notaFavoritaSetDuck,
  notaFavoritaSetThunk,
} from './duck';

import {
  constants,
  responseSelector,
  log,
  prepareDatosAuxOptions,
  parseJwt,
  prepareEmpresasOptions,
  getDate,
} from '../utils/utils';

import { ListaCandidatosView } from './ListaCandidatosView';
import {
  getOportunidadDuck,
  getOportunidadThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  getRubrosThunk,
  getCargosDuck,
  getRubrosDuck,
  getCargosThunk,
} from '../oportunidades/duck';
import { oportunidadesUtils } from '../oportunidades/utils';

const queryString = require('query-string');

const getCandidatosVistos = (dateStr) => {
  const candidatosVistos = localStorage.getItem('candidatosVistos');
  const candidatosVistosParsed = JSON.parse(candidatosVistos);
  const candidatosVistosHoy = candidatosVistosParsed?.[dateStr] || null;
  return candidatosVistosHoy;
};

function ListaCandidatos({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const initialPage = parsedQueryParams?.page ? parsedQueryParams?.page - 1 : 0;

  const oportunidadId = parsedQueryParams.oportunidadId;

  const dispatch = useDispatch();

  const modelGetCargosPot = useSelector((state) => state.getCargosPot);
  const modelCrearCargoPot = useSelector((state) => state.crearCargoPot);

  const modelGetCV = useSelector((state) => state.getCv);
  const modelGetEmpresas = useSelector((state) => state.getEmpresas);
  const modelCandidatos = useSelector((state) => state.getCandidatos);
  const modelNotas = useSelector((state) => state.getNotas);
  const modelCrearNota = useSelector((state) => state.crearNota);
  const modelUpdateNota = useSelector((state) => state.updateNota);
  const modelDeleteNota = useSelector((state) => state.deleteNota);
  const modelGetOportunidad = useSelector((state) => state.getOportunidad);
  const modelIncluirEnOportunidad = useSelector(
    (state) => state.incluirEnOportunidad
  );
  const modelGetCargos = useSelector((state) => state.getCargos);
  const modelGetRubros = useSelector((state) => state.getRubros);

  const notaFavoritaSet = useSelector((state) => state.notaFavoritaSet);

  const [candidatos, setCandidatos] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const [pageSize, setPageSize] = useState(parsedQueryParams?.limit ?? '20');
  const [pageSizeSelected, setPageSizeSelected] = useState(
    parsedQueryParams?.limit
      ? { value: parsedQueryParams?.limit, label: parsedQueryParams?.limit }
      : {
          value: '20',
          label: '20',
        }
  );

  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );

  const [salario, setSalario] = useState(parsedQueryParams?.salario || null);

  const [cargosOptions, setCargosOptions] = useState([]);

  const cargosSelected = parsedQueryParams?.cargosIdsNumericos;

  useEffect(() => {
    if (pageSizeSelected?.value) {
      setPageSize(pageSizeSelected?.value);
      handlePage(initialPage, pageSizeSelected?.value);
    }
  }, [pageSizeSelected]);

  const [busquedaAplicada, setBusquedaAplicada] = useState();

  const [candidatosVistosHoy, setCandidatosVistosHoy] = useState(
    getCandidatosVistos(getDate())
  );

  const addCandidatoVistoHoy = (candidato, newTab = false) => {
    const newCandidatosVistosHoy = {
      ...candidatosVistosHoy,
      [candidato._id]: true,
    };
    setCandidatosVistosHoy(newCandidatosVistosHoy);

    const rawCurrentCandidatosVistos = localStorage.getItem('candidatosVistos');

    const parsedCurrentCandidatosVistos = rawCurrentCandidatosVistos
      ? JSON.parse(rawCurrentCandidatosVistos)
      : {};

    const newParsedCurrentCandidatosVistos = {
      ...parsedCurrentCandidatosVistos,
      [getDate()]: newCandidatosVistosHoy,
    };

    localStorage.setItem(
      'candidatosVistos',
      JSON.stringify(newParsedCurrentCandidatosVistos)
    );

    if (newTab) {
      window.open(
        `/editarCandidato/${candidato.idNumerico}`,
        '_blank',
        'noopener,noreferrer'
      );
    } else {
      history.push(`/editarCandidato/${candidato.idNumerico}`);
    }
  };

  const getSelectedCargosOptions = () => {
    if (!cargosSelected) {
      return [];
    }

    if (typeof cargosSelected === 'string' && cargosSelected !== 'todos') {
      return [{ value: cargosSelected, label: '...' }];
    } else if (Array.isArray(cargosSelected)) {
      return cargosSelected.map((cargo) => ({ value: cargo, label: '...' }));
    } else {
      log('Cargos de URL no validos');
      return [];
    }
  };

  const [cargoOption, setCargoOption] = useState(getSelectedCargosOptions());
  const [cargoOptionNota, setCargoOptionNota] = useState();

  const [cargoPotencialOption, setCargoPotencialOption] = useState([]);
  const [cargosPotenciales, setCargosPotenciales] = useState([]);

  const empresasSelected = parsedQueryParams?.empresasIdsNumericos;
  const getSelectedEmpresas = () => {
    if (!empresasSelected) {
      return [];
    }

    if (typeof empresasSelected === 'string' && empresasSelected !== 'todos') {
      return [{ value: empresasSelected, label: '...' }];
    } else if (Array.isArray(empresasSelected)) {
      return empresasSelected.map((empresa) => ({
        value: empresa,
        label: '...',
      }));
    } else {
      log('Empresas de URL no validas');
      return [];
    }
  };
  const [empresaOption, setEmpresaOption] = useState(getSelectedEmpresas());

  const [empresaOptions, setEmpresaOptions] = useState([]);
  const [rubrosOptions, setRubrosOptions] = useState([]);

  const rubrosSelected = parsedQueryParams?.rubrosIdsNumericos;

  const getSelectedRubrosOptions = () => {
    if (!rubrosSelected) {
      return [];
    }

    if (typeof rubrosSelected === 'string' && rubrosSelected !== 'todos') {
      return [{ value: rubrosSelected, label: '...' }];
    } else if (Array.isArray(rubrosSelected)) {
      return rubrosSelected.map((rubro) => ({ value: rubro, label: '...' }));
    } else {
      log('Rubros de URL no validos');
      return [];
    }
  };

  const [rubroOption, setRubroOption] = useState(getSelectedRubrosOptions());

  const [arrayMarcados, setArrayMarcados] = useState([]);

  const [isModalAgregarShown, setIsModalAgregarShown] = useState(false);
  const [etapaOption, setEtapaOption] = useState();
  const [etapasOptions, setEtapasOptions] = useState();
  const [oportunidad, setOportunidad] = useState();

  const [notaSelected, setNotaSelected] = useState('');
  const [isModalNotasShown, setIsModalNotasShown] = useState();
  const [notas, setNotas] = useState([]);
  const [candidatoIdNumerico, setCandidatoIdNumerico] = useState();
  const [totalResultsNotas, setTotalResultsNotas] = useState(0);
  const [activePageNotas, setActivePageNotas] = useState(0);
  const [pageSizeNotas, setPageSizeNotas] = useState(5);
  const [buscarEnNotas, setBuscarEnNotas] = useState(false);
  const [titulo, setTitulo] = useState();
  const [userIdNumerico, setUserIdNumerico] = useState();
  const [contenido, setContenido] = useState();
  const [cuentaFiltroChecked, setCuentaFiltroChecked] = useState(true);
  const [entrevistaCortesiaChecked, setEntrevistaCortesiaChecked] =
    useState(false);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const [expectativaSalarial, setExpectativaSalarial] = useState('0');
  const [cargoPotSubmitAlert, setCargoPotSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  function agregarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico,
      cargoIdNumerico: cargoOptionNota?.value,
    };
    dispatch(crearCargoPotThunk(data));
  }

  useEffect(() => {
    if (token?.raw) {
      const decodedToken = parseJwt(token.raw);
      setUserIdNumerico(decodedToken?.idNumerico);
    }
  }, [token]);

  useEffect(() => {
    if (candidatoIdNumerico) {
      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );
    }
  }, [candidatoIdNumerico]);

  const hideModalNotas = () => {
    setCandidatoIdNumerico(null);
    setNotaSelected(null);
    setIsModalNotasShown(false);
  };

  const showModalNotas = () => {
    setIsModalNotasShown(true);
  };

  const showSelectedEmpresasNames = (datosAuxOptions) => {
    if (typeof empresasSelected === 'string' && empresasSelected !== 'todos') {
      const opcionEmpresa = datosAuxOptions.filter(
        (elem) => elem.value === empresasSelected
      );

      if (opcionEmpresa[0]) {
        setEmpresaOption([opcionEmpresa[0]]);
      }
    } else if (Array.isArray(empresasSelected)) {
      setEmpresaOption(
        empresasSelected.map((empresaIdNumerico) => {
          const mEmpresaOption = datosAuxOptions.find(
            (da) => da.value == empresaIdNumerico
          );
          if (mEmpresaOption) {
            return mEmpresaOption;
          }
          return { value: empresaIdNumerico, label: 'Desconocido' };
        })
      );
    } else {
      log('invalidSelectedEmpresas');
    }
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasDuck.actions.clear());
    }

    if (data) {
      const mEmpresasOptions = prepareEmpresasOptions(data.items);

      setEmpresaOptions(mEmpresasOptions);

      showSelectedEmpresasNames(mEmpresasOptions);

      dispatch(getEmpresasDuck.actions.clear());
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCandidatos);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatosDuck.actions.clear());
    }

    if (data) {
      setCandidatos(data.items);
      setTotalResults(data.total);

      dispatch(getCandidatosDuck.actions.clear());
    }
  }, [modelCandidatos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelNotas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasDuck.actions.clear());
    }

    if (data) {
      setNotas(data.items);
      setTotalResultsNotas(data.total);
      showModalNotas();

      dispatch(getNotasDuck.actions.clear());
    }
  }, [modelNotas]);

  const resetNotaInputs = () => {
    setTitulo('');
    setContenido('');
    setCuentaFiltroChecked(true);
    setEntrevistaCortesiaChecked(false);
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(crearNotaDuck.actions.clear());
    }

    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota creada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearNotaDuck.actions.clear());
    }
  }, [modelCrearNota]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(notaFavoritaSet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(notaFavoritaSetDuck.actions.clear());
    }

    if (httpStatus) {
      handlePageNotas(activePageNotas);

      dispatch(notaFavoritaSetDuck.actions.clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notaFavoritaSet]);

  const getCV = (idNumerico) => {
    dispatch(
      getCvThunk({
        idNumerico,
        userToken: token.raw,
      })
    );
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCV);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(getCvDuck.actions.clear());
    }
    if (data) {
      window.open(data, '_blank');
      dispatch(getCvDuck.actions.clear());
    }
  }, [modelGetCV]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(deleteNotaDuck.actions.clear());
    }
    if (data) {
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(deleteNotaDuck.actions.clear());
    }
  }, [modelDeleteNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUpdateNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(updateNotaDuck.actions.clear());
    }
    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota actualizada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
  }, [modelUpdateNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportunidad);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadDuck.actions.clear());
    }

    if (data) {
      setOportunidad(data.oportunidad);
      setEtapasOptions(
        oportunidadesUtils.prepareEtapasOptions(
          data.oportunidad.etapasWithDescription
        )
      );

      dispatch(getOportunidadDuck.actions.clear());
    }
  }, [modelGetOportunidad]);

  const showSelectedCargosNames = (datosAuxOptions) => {
    if (typeof cargosSelected === 'string' && cargosSelected !== 'todos') {
      const opcionCargo = datosAuxOptions.filter(
        (elem) => elem.value === cargosSelected
      );

      if (opcionCargo[0]) {
        setCargoOption([opcionCargo[0]]);
      }
    } else if (Array.isArray(cargosSelected)) {
      setCargoOption(
        cargosSelected.map((cargoIdNumerico) => {
          const mCargoOption = datosAuxOptions.find(
            (da) => da.value == cargoIdNumerico
          );
          if (mCargoOption) {
            return mCargoOption;
          }
          return { value: cargoIdNumerico, label: 'Desconocido' };
        })
      );
    } else {
      log('invalidSelectedCargos');
    }
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const cargos = data.items;

      const datosAuxOptions = prepareDatosAuxOptions(cargos);

      setCargosOptions(datosAuxOptions);

      showSelectedCargosNames(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  const showSelectedRubrosNames = (datosAuxOptions) => {
    if (typeof rubrosSelected === 'string' && rubrosSelected !== 'todos') {
      const opcionRubro = datosAuxOptions.filter(
        (elem) => elem.value === rubrosSelected
      );

      if (opcionRubro[0]) {
        setRubroOption([opcionRubro[0]]);
      }
    } else if (Array.isArray(rubrosSelected)) {
      setRubroOption(
        rubrosSelected.map((rubroIdNumerico) => {
          const mRubroOption = datosAuxOptions.find(
            (da) => da.value == rubroIdNumerico
          );
          if (mRubroOption) {
            return mRubroOption;
          }
          return { value: rubroIdNumerico, label: 'Desconocido' };
        })
      );
    } else {
      log('invalidSelectedRubros');
    }
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargosPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCargosPot.data': data });

      dispatch(getCargosPotDuck.actions.clear());

      setCargosPotenciales(data.cargosPotenciales);
    }
  }, [modelGetCargosPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCargoPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(crearCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial agregado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelCrearCargoPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setRubrosOptions(datosAuxOptions);

      showSelectedRubrosNames(datosAuxOptions);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelIncluirEnOportunidad);

    if (error) {
      setTopAlert({
        title: null,
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalAgregar();

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }

    if (data) {
      let itemsMsgs = [];

      if (data.incluidosExitosamente.length) {
        itemsMsgs.push(
          `Se incluyeron exitosamente ${data.incluidosExitosamente.length} candidatos.`
        );
      }

      if (data.omitidos.length) {
        itemsMsgs.push(
          `Se omitieron los candidatos con ID: ${data.omitidos.join(
            ', '
          )} porque ya son parte de la oportunidad.`
        );
      }

      setTopAlert({
        title: null,
        text: (
          <ul>
            {itemsMsgs.map((msj) => (
              <li>{msj}</li>
            ))}
          </ul>
        ),
        type: alertTypes.success,
        closable: false,
      });

      setArrayMarcados([]);

      hideModalAgregar();

      handlePage(0);

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }
  }, [modelIncluirEnOportunidad]);

  useEffect(() => {
    if (oportunidadId) {
      dispatch(
        getOportunidadThunk({
          idNumerico: oportunidadId,
          userToken: token.raw,
        })
      );
    }
    dispatch(
      getEmpresasThunk({
        esCliente: true,
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  const handlePage = useCallback(
    (page, customPageSize = null) => {
      // //prueba
      // return;

      setActivePage(page);

      setCandidatos([]);

      const requestParams = {
        page: page + 1,
        limit: customPageSize ? customPageSize : pageSize,
        busquedaRapida,
        salario,
        buscarEnNotas,
      };

      if (oportunidadId) {
        requestParams.oportunidadId = oportunidadId;
      }

      const rubroValues = rubroOption.map((elem) => elem.value);
      requestParams.rubrosIdsNumericos = rubroValues;

      const cargoValues = cargoOption.map((elem) => elem.value);
      requestParams.cargosIdsNumericos = cargoValues;
      requestParams.cargosPotencialesIdsNumericos = cargoValues;

      const empresasValues = empresaOption.map((elem) => elem.value);
      requestParams.empresasIdsNumericos = empresasValues;

      const urlParams = {
        ...requestParams,
      };

      const newUrl = `${history.location.pathname}?${queryString.stringify(
        urlParams,
        { arrayFormat: 'none' }
      )}`;

      history.replace(newUrl);

      setBusquedaAplicada(busquedaRapida?.split(' ') ?? []);

      dispatch(
        getCandidatosThunk({
          ...requestParams,
          userToken: token.raw,
          consultaTipo: 'omitirYaIncluidos',
        })
      );
    },
    [
      busquedaRapida,
      salario,
      buscarEnNotas,
      cargoOption,
      cargoOption,
      rubroOption,
      empresaOption,
      pageSize,
    ]
  );

  useEffect(() => {
    if (candidatoIdNumerico) {
      handlePageNotas(0);
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    if (notaSelected) {
      setTitulo(notaSelected.titulo);
      setContenido(notaSelected.contenido);
      setCandidatoIdNumerico(notaSelected.candidatoIdNumerico);
      setCuentaFiltroChecked(notaSelected.cuentaFiltro);
      setEntrevistaCortesiaChecked(notaSelected.entrevistaCortesia);
    }
  }, [notaSelected]);

  function handlePageNotas(page) {
    setActivePageNotas(page);

    setNotas([]);

    const params = {
      page: page + 1,
      limit: pageSizeNotas,
      candidatoIdNumerico,
    };

    dispatch(
      getNotasThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function handleSelection(name, value) {
    if (value === true) {
      setArrayMarcados((arrayMarcados) => [...arrayMarcados, name]);
    } else {
      setArrayMarcados((arrayMarcados) =>
        arrayMarcados.filter((elem) => elem !== name)
      );
    }
  }

  function incluirEnOportunidad() {
    dispatch(
      incluirEnOportunidadThunk({
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
        candidatosIds: arrayMarcados,
        etapa: etapaOption?.value,
      })
    );
  }

  function hideModalAgregar() {
    setIsModalAgregarShown(false);
  }

  function showModalAgregar() {
    setIsModalAgregarShown(true);
  }

  function submitNota() {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    if (cargosPotenciales.length < 3) {
      setTopAlert({
        text: 'El candidato debe tener al menos 3 cargos potenciales para crear la nota',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    if (!titulo || !contenido) {
      setTopAlert({
        text: 'La nota debe incluir titulo y contenido',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    setNotas([]);
    setCandidatos([]);
    if (!notaSelected) {
      dispatch(
        crearNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    } else {
      dispatch(
        updateNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          idNumerico: notaSelected.idNumerico,
          creacionUsuario: notaSelected.creacionUsuario,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
          expectativaSalarial,
        })
      );
    }
  }

  function deleteNota(idNumerico, creacionUsuario) {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    dispatch(
      deleteNotaThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        idNumerico: idNumerico,
        creacionUsuario: creacionUsuario,
      })
    );
  }

  function setearNotaFavorita({ notaIdNumerico, candidatoIdNumerico, setear }) {
    dispatch(
      notaFavoritaSetThunk({
        userToken: token.raw,
        notaIdNumerico,
        candidatoIdNumerico,
        setear,
      })
    );
  }

  return (
    <ListaCandidatosView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      candidatos={candidatos}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      rubroOption={rubroOption}
      setRubroOption={setRubroOption}
      cargoOption={cargoOption}
      setCargoOption={setCargoOption}
      salario={salario}
      setSalario={setSalario}
      modelCandidatos={modelCandidatos}
      modelIncluirEnOportunidad={modelIncluirEnOportunidad}
      cargosOptions={cargosOptions}
      rubrosOptions={rubrosOptions}
      handleSelection={handleSelection}
      oportunidadId={oportunidadId}
      incluirEnOportunidad={incluirEnOportunidad}
      isModalAgregarShown={isModalAgregarShown}
      hideModalAgregar={hideModalAgregar}
      showModalAgregar={showModalAgregar}
      etapaOption={etapaOption}
      etapasOptions={etapasOptions}
      setEtapaOption={setEtapaOption}
      oportunidad={oportunidad}
      arrayMarcados={arrayMarcados}
      cargoPotencialOption={cargoPotencialOption}
      setCargoPotencialOption={setCargoPotencialOption}
      empresaOption={empresaOption}
      setEmpresaOption={setEmpresaOption}
      empresaOptions={empresaOptions}
      isModalNotasShown={isModalNotasShown}
      hideModalNotas={hideModalNotas}
      showModalNotas={showModalNotas}
      notas={notas}
      totalResultsNotas={totalResultsNotas}
      activePageNotas={activePageNotas}
      pageSizeNotas={pageSizeNotas}
      candidatoIdNumerico={candidatoIdNumerico}
      setCandidatoIdNumerico={setCandidatoIdNumerico}
      handlePageNotas={handlePageNotas}
      buscarEnNotas={buscarEnNotas}
      setBuscarEnNotas={setBuscarEnNotas}
      setTitulo={setTitulo}
      titulo={titulo}
      setContenido={setContenido}
      contenido={contenido}
      submitNota={submitNota}
      setArrayMarcados={setArrayMarcados}
      notaSelected={notaSelected}
      setNotaSelected={setNotaSelected}
      userIdNumerico={userIdNumerico}
      deleteNota={deleteNota}
      cuentaFiltroChecked={cuentaFiltroChecked}
      setCuentaFiltroChecked={setCuentaFiltroChecked}
      entrevistaCortesiaChecked={entrevistaCortesiaChecked}
      setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
      resetNotaInputs={resetNotaInputs}
      getCV={getCV}
      expectativaSalarial={expectativaSalarial}
      setExpectativaSalarial={setExpectativaSalarial}
      cargoPotSubmitAlert={cargoPotSubmitAlert}
      cargosPotenciales={cargosPotenciales}
      modelGetCargosPot={modelGetCargosPot}
      modelCrearCargoPot={modelCrearCargoPot}
      agregarCargoPot={agregarCargoPot}
      setPageSizeSelected={setPageSizeSelected}
      pageSizeSelected={pageSizeSelected}
      setPageSize={setPageSize}
      busquedaAplicada={busquedaAplicada}
      candidatosVistosHoy={candidatosVistosHoy}
      addCandidatoVistoHoy={addCandidatoVistoHoy}
      cargoOptionNota={cargoOptionNota}
      setCargoOptionNota={setCargoOptionNota}
      notaFavoritaSet={notaFavoritaSet}
      setearNotaFavorita={setearNotaFavorita}
    />
  );
}

export { ListaCandidatos };
