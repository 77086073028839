import React from "react";
import { Alert as AdminLteAlert } from "adminlte-2-react";

const alertTypes = {
  default: "default",
  primary: "primary",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
  error: "error",
};

const TopAlert = ({ alert }) => (
  <Alert
    title={alert.title}
    type={alert.type}
    text={alert.text}
    closable={alert.closable}
  />
);

function Alert({
  visible = true,
  type,
  text,
  title,
  icon,
  closable,
  onDismiss,
  containerStyle = { maxWidth: 450 },
  containerClassName = "center-block",
}) {
  if (!visible) {
    return null;
  }

  const mType = type ? type : alertTypes.primary;

  return (
    <div className={containerClassName}>
      <AdminLteAlert
        type={mType}
        title={title}
        icon={icon}
        closable={closable}
        onDismiss={onDismiss}
      >
        {text}
      </AdminLteAlert>
    </div>
  );
}

export { Alert, alertTypes, TopAlert };
