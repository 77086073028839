import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonWithLoading } from "../utils/ButtonWithLoading";
import { getDateOrEmpty } from "../utils/utils";

function ModalConfirmarDesactivar({
  isModalConfirmarDesactivarShown,
  hideModalConfirmarDesactivar,
  showModalConfirmarDesactivar,
  selectedCandidato,
  etapasById,
  desactivarCandidato,
  isDesactivarButtonLoading,
}) {
  return (
    <Modal
      show={isModalConfirmarDesactivarShown}
      style={{ opacity: 1 }}
      onHide={hideModalConfirmarDesactivar}
      onShow={showModalConfirmarDesactivar}
    >
      <Modal.Header>
        <Modal.Title>Desactivar candidato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Se <b>desactivará</b> al candidato.
        <div style={{ marginTop: 5 }}>
          <br />
          Nombre: {selectedCandidato.nombreCompleto}
          <br />
          Etapa actual:{" "}
          {etapasById?.[selectedCandidato.etapaInfo.etapaIdNumerico]
            ?.descripcion || "N/A"}
          <br />
          Fecha de avance a etapa:{" "}
          {getDateOrEmpty(selectedCandidato.etapaInfo.fecha)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="danger"
          text="Desactivar candidato"
          submit={desactivarCandidato}
          loading={isDesactivarButtonLoading}
          disabled={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalConfirmarDesactivar };
