import React from 'react';

function CheckBox({ text, onChange, checked }) {
  return (
    <label
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'normal',
      }}
    >
      <input
        type="checkbox"
        onChange={(evt) => onChange(evt.target.checked)}
        style={{ margin: 0 }}
        checked={checked}
      />

      <div style={{ marginLeft: 5 }}>{text}</div>
    </label>
  );
}

export { CheckBox };
