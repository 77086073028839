/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { Alert } from '../ui/alert';
import {
  constants,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useWindowSize } from '../utils/useWindowSize';
import { ModalCreateDatoAuxiliar } from './ModalCreateDatoAuxiliar';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { datosAuxiliaresOptions } from '../utils/constants';
import { ModalGenerico } from '../ui/ModalGenerico';
import { TextInput } from '../utils/TextInput';

function ListaDatosAuxiliaresView({
  history,
  topAlert,
  submitAlert,
  totalResults,
  activePage,
  pageSize,
  handlePage,
  lista,
  concepto,
  setConcepto,
  modelGetDatosAuxiliares,
  isModalCreateDatoAuxiliarShown,
  setIsModalCreateDatoAuxiliarShown,
  modelCreateDatoAuxiliar,
  createDatoAuxiliar,
  valor,
  setValor,
  formConcepto,
  setFormConcepto,
  selectedDatoAuxiliar,
  setSelectedDatoAuxiliar,
  isModalEditDatoAuxiliarShown,
  setIsModalEditDatoAuxiliarShown,
  modelEditDatoAuxiliar,
  editDatoAuxiliar,
  prepareEditDatoAuxiliar,
  busquedaRapida,
  setBusquedaRapida,
  isDeleteConfirmModalShown,
  setIsDeleteConfirmModalShown,
  modelDeleteDatoAuxiliar,
  deleteDatoAuxiliar,
}) {
  const goToNextPage = (page) => {
    handlePage(page);
  };

  const { width, isMobile } = useWindowSize();

  return (
    <Content
      title="Datos auxiliares"
      subTitle=""
      browserTitle="Datos auxiliares"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <ModalCreateDatoAuxiliar
        title="Crear dato auxiliar"
        isModalShown={isModalCreateDatoAuxiliarShown}
        hideModal={() => setIsModalCreateDatoAuxiliarShown(false)}
        buttonDisabled={!modelCreateDatoAuxiliar}
        buttonLoading={modelCreateDatoAuxiliar.loading}
        submit={createDatoAuxiliar}
        datosAuxiliaresOptions={datosAuxiliaresOptions}
        concepto={formConcepto}
        setConcepto={setFormConcepto}
        valor={valor}
        setValor={setValor}
      />

      {selectedDatoAuxiliar && (
        <ModalCreateDatoAuxiliar
          title="Editar dato auxiliar"
          isModalShown={isModalEditDatoAuxiliarShown}
          hideModal={() => setIsModalEditDatoAuxiliarShown(false)}
          buttonDisabled={!modelEditDatoAuxiliar}
          buttonLoading={modelEditDatoAuxiliar.loading}
          submit={editDatoAuxiliar}
          datosAuxiliaresOptions={datosAuxiliaresOptions}
          concepto={formConcepto}
          setConcepto={setFormConcepto}
          valor={valor}
          setValor={setValor}
          conceptoSelectDisabled={true}
        />
      )}

      {selectedDatoAuxiliar && (
        <ModalGenerico
          isModalShown={isDeleteConfirmModalShown}
          hideModal={() => setIsDeleteConfirmModalShown(false)}
          title="Eliminar"
          body="Se eliminará el dato auxiliar. ¿Continuar?"
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={modelDeleteDatoAuxiliar.loading}
          buttonLoading={modelDeleteDatoAuxiliar.loading}
          submit={deleteDatoAuxiliar}
        />
      )}

      <Row>
        <Col xs="12" sm="4" lg="3">
          <Box title="Filtro">
            <TextInput
              label="Búsqueda rápida"
              placeholder=""
              initialValue={busquedaRapida}
              setValue={setBusquedaRapida}
              onKeyDown={(event) =>
                submitSearchOnEnterPress(event, () => handlePage(0))
              }
            />

            <SelectInput
              label="Concepto"
              value={concepto}
              options={datosAuxiliaresOptions}
              setValue={setConcepto}
            />

            <div style={{ float: 'right' }}>
              <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                  type="primary"
                  text="Filtrar"
                  onClick={() => handlePage(0)}
                />
              </div>
            </div>
          </Box>
        </Col>

        <Col xs="12" sm="8" lg="9">
          <Box
            title="Lista"
            customOptions={
              <div>
                <Button
                  size="sm"
                  type="default"
                  text="Agregar"
                  onClick={() => setIsModalCreateDatoAuxiliarShown(true)}
                />
              </div>
            }
          >
            <div style={{ display: 'block' }}>
              <span>Total: {totalResults}</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Pagination
                  activePage={activePage}
                  pageSize={pageSize}
                  totalElements={totalResults}
                  onChange={goToNextPage}
                  labels={{
                    first: 'Primera',
                    last: 'Última',
                    previous: 'Anterior',
                    next: 'Siguiente',
                  }}
                />
              </div>
            </div>

            <Loader isLoading={modelGetDatosAuxiliares.loading}>
              {lista && lista.length > 0 ? (
                <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                  <div className="table-responsive">
                    <DataTable
                      lazy
                      pt={{
                        table: {
                          className: 'table table-hover',
                        },
                      }}
                      value={lista}
                      emptyMessage="No se encontraron registros"
                    >
                      <Column
                        header="Acciones"
                        body={(datoAuxiliar) => (
                          <>
                            <ButtonWithLoading
                              text="Editar"
                              size="sm"
                              type="default"
                              submit={() =>
                                prepareEditDatoAuxiliar(datoAuxiliar)
                              }
                            />
                          </>
                        )}
                      ></Column>

                      <Column
                        field="etiqueta"
                        header="Concepto"
                        body={(datoAuxiliar) =>
                          toTitleCase(datoAuxiliar.etiqueta)
                        }
                      ></Column>
                      <Column field="idNumerico" header="ID"></Column>
                      <Column
                        field="descripcion"
                        header="Valor"
                        body={(datoAuxiliar) =>
                          toTitleCase(datoAuxiliar.descripcion)
                        }
                      ></Column>
                      <Column
                        header="Eliminar"
                        body={(datoAuxiliar) => (
                          <>
                            <ButtonWithLoading
                              text="Eliminar"
                              size="sm"
                              type="warning"
                              submit={() => {
                                setSelectedDatoAuxiliar(datoAuxiliar);
                                setIsDeleteConfirmModalShown(true);
                              }}
                            />
                          </>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </Box>
              ) : (
                <h4>No se han encontrado datos auxiliares</h4>
              )}
            </Loader>
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              totalElements={totalResults}
              onChange={goToNextPage}
              labels={{
                first: 'Primera',
                last: 'Última',
                previous: 'Anterior',
                next: 'Siguiente',
              }}
            />
            <div>
              <span>Total: {totalResults}</span>
            </div>

            {submitAlert.text && (
              <Alert
                title={submitAlert.title}
                type={submitAlert.type}
                text={submitAlert.text}
                closable={submitAlert.closable}
              />
            )}
          </Box>
        </Col>
      </Row>
    </Content>
  );
}

export { ListaDatosAuxiliaresView };
