import { combineReducers } from 'redux';

import auth from './../auth/duck';
import invitacion from './../invitacion/duck';
import olvidePass from './../olvidePass/duck';

import {
  duckGetImagenesTemporalesV3,
  duckEliminarImgTmpV3,
} from './../imageUploadV3/duck';

import {
  duck,
  duckUsuario,
  duckInvitacion,
  confirmarRegistroDuck,
} from '../registro/duck';

import {
  createEmpresaDuck,
  deleteEmpresaDuck,
  getEmpresasWithPaginationDuck,
  searchEmpresasByNameDuck,
} from '../empresas/duck';

import {
  crearCandidatoDuck,
  crearCandidatoExpDuck,
  crearCargoPotDuck,
  editarCandidatoDuck,
  editarCandidatoExpDuck,
  editarCargoPotDuck,
  eliminarCandidatoExpDuck,
  eliminarCargoPotDuck,
  getCandidatoDuck,
  getCandidatoExperienciasDuck,
  getCandidatosDuck,
  getCargosPotDuck,
  getSalidaMotivosDuck,
  incluirEnOportunidadDuck,
  getNotasDuck,
  crearNotaDuck,
  updateNotaDuck,
  deleteNotaDuck,
  getPaisesDuck,
  getRegionesDuck,
  getCiudadesDuck,
  getNacionalidadesDuck,
  borrarOportunidadDuck,
  getCandidatoFormacionesDuck,
  crearCandidatoFormacionDuck,
  getInstitutosDuck,
  getTitulacionesDuck,
  eliminarCandFormDuck,
  editarCandFormDuck,
  eliminarFotoDeCandidatoDuck,
  getCandIdiomasDuck,
  crearCandIdiomaDuck,
  eliminarCandIdiomaDuck,
  editarCandIdiomaDuck,
  getIdiomasDuck,
  getCandArchivosDuck,
  eliminarCandArchivoDuck,
  getCvDuck,
  getCandidatosV1Duck,
  getContactoCandidatosDuck,
  searchCandidatosByMobileDuck,
  editarExpectativaSalarialDuck,
  notaFavoritaSetDuck,
} from '../candidatos/duck';
import {
  avanzarAEtapaDuck,
  crearOportunidadDuck,
  desactivarCandidatoDuck,
  descartarCandidatoDuck,
  generarReposicionDuck,
  getCandidatosEnOportDuck,
  getCargosDuck,
  getEmpresasDuck,
  getEtapasDuck,
  getMotivosDescarteDuck,
  getOportunidadDuck,
  getOportunidadesDuck,
  getResumenEtapasDuck,
  getRubrosDuck,
  getUsuariosDuck,
  guardarEdicionOportDuck,
  getUsuariosForSelectDuck,
  editarDetallesOportunidadDuck,
  getOportFlyerDuck,
  eliminarOportFlyerDuck,
  getEmpresaDuck,
  editEmpresaDuck,
  enviarMailInicioDuck,
  getOportunidadesCandidatoDuck,
  getOportRelevamientoDuck,
  eliminarOportRelevamientoDuck,
} from '../oportunidades/duck';
import {
  createInstitutoDuck,
  deleteInstitutoDuck,
  editInstitutoDuck,
  getInstitutoDuck,
} from '../institutos/duck';
import {
  createDatoAuxiliarDuck,
  editDatoAuxiliarDuck,
  getDatosAuxiliaresDuck,
  getGeografiaDuck,
  createDatoGeograficoDuck,
  editDatoGeograficoDuck,
  deleteDatoAuxiliarDuck,
  deleteDatoGeograficoDuck,
} from '../datosAuxiliares/duck';
import {
  getReporteProduccionDuck,
  getReporteProduccionSupervisorDuck,
  getNotasReporteProduccionDuck,
} from '../reportes/duck';
import { getContactoMensajesDuck } from '../contactoMensajes/duck';
import { estudioMercadoReducers } from '../estudiosMercado/reducers';

const loginStandardReducer = auth.duck.reducer;
const loginSocialReducer = auth.duckSocial.reducer;

const registroReducer = duck.reducer;
const usuarioReducer = duckUsuario.reducer;
const getInvitacionReducer = duckInvitacion.reducer;
const confirmarRegistroReducer = confirmarRegistroDuck.reducer;

const getInvitacionesReducer = invitacion.duckGetInvitaciones.reducer;
const invitacionReducer = invitacion.duck.reducer;
const olvidePassReducer = olvidePass.duck.reducer;
const changePassByTokenReducer = olvidePass.duckChangePassByToken.reducer;
const checkResetTokenReducer = olvidePass.duckCheckResetToken.reducer;
const editContrasenaReducer = auth.editContrasenaDuck.reducer;

const getImagenesTemporalesV3Reducer = duckGetImagenesTemporalesV3.reducer;
const eliminarImgTmpV3Reducer = duckEliminarImgTmpV3.reducer;

export default combineReducers({
  loginStandard: loginStandardReducer,
  loginSocial: loginSocialReducer,

  registro: registroReducer,
  invitacion: invitacionReducer,
  usuario: usuarioReducer,
  getInvitacion: getInvitacionReducer,
  confirmarRegistro: confirmarRegistroReducer,

  olvidePass: olvidePassReducer,
  changePassByToken: changePassByTokenReducer,
  checkResetToken: checkResetTokenReducer,
  getInvitaciones: getInvitacionesReducer,
  editContrasena: editContrasenaReducer,

  getImagenesTemporalesV3: getImagenesTemporalesV3Reducer,
  eliminarImgTmpV3: eliminarImgTmpV3Reducer,

  getUsuarios: getUsuariosDuck.reducer,

  getCandidato: getCandidatoDuck.reducer,
  getCandidatos: getCandidatosDuck.reducer,
  crearCandidato: crearCandidatoDuck.reducer,
  editarCandidato: editarCandidatoDuck.reducer,
  getCandidatoExperiencias: getCandidatoExperienciasDuck.reducer,
  crearCandidatoExp: crearCandidatoExpDuck.reducer,
  getSalidaMotivos: getSalidaMotivosDuck.reducer,
  eliminarCandidatoExp: eliminarCandidatoExpDuck.reducer,
  editarCandidatoExp: editarCandidatoExpDuck.reducer,
  searchCandidatosByMobile: searchCandidatosByMobileDuck.reducer,
  editarExpectativaSalarial: editarExpectativaSalarialDuck.reducer,

  getNotas: getNotasDuck.reducer,
  crearNota: crearNotaDuck.reducer,
  updateNota: updateNotaDuck.reducer,
  deleteNota: deleteNotaDuck.reducer,

  getCandidatoFormaciones: getCandidatoFormacionesDuck.reducer,
  crearCandidatoFormacion: crearCandidatoFormacionDuck.reducer,
  eliminarCandForm: eliminarCandFormDuck.reducer,
  editarCandForm: editarCandFormDuck.reducer,
  getInstitutos: getInstitutosDuck.reducer,
  getTitulaciones: getTitulacionesDuck.reducer,

  getOportunidad: getOportunidadDuck.reducer,
  getResumenEtapas: getResumenEtapasDuck.reducer,
  getOportunidades: getOportunidadesDuck.reducer,
  crearOportunidad: crearOportunidadDuck.reducer,
  guardarEdicionOport: guardarEdicionOportDuck.reducer,
  incluirEnOportunidad: incluirEnOportunidadDuck.reducer,
  getCandidatosEnOport: getCandidatosEnOportDuck.reducer,
  desactivarCandidato: desactivarCandidatoDuck.reducer,
  generarReposicion: generarReposicionDuck.reducer,
  getUsuariosForSelect: getUsuariosForSelectDuck.reducer,
  editarDetallesOportunidad: editarDetallesOportunidadDuck.reducer,
  borrarOportunidad: borrarOportunidadDuck.reducer,

  descartarCandidato: descartarCandidatoDuck.reducer,
  getMotivosDescarte: getMotivosDescarteDuck.reducer,

  getEmpresas: getEmpresasDuck.reducer,
  getEmpresa: getEmpresaDuck.reducer,
  createEmpresa: createEmpresaDuck.reducer,
  editEmpresa: editEmpresaDuck.reducer,
  deleteEmpresa: deleteEmpresaDuck.reducer,
  searchEmpresasByName: searchEmpresasByNameDuck.reducer,

  getCargos: getCargosDuck.reducer,
  getRubros: getRubrosDuck.reducer,

  getEtapas: getEtapasDuck.reducer,
  avanzarAEtapa: avanzarAEtapaDuck.reducer,

  getCargosPot: getCargosPotDuck.reducer,
  crearCargoPot: crearCargoPotDuck.reducer,
  editarCargoPot: editarCargoPotDuck.reducer,
  eliminarCargoPot: eliminarCargoPotDuck.reducer,

  getPaises: getPaisesDuck.reducer,
  getRegiones: getRegionesDuck.reducer,
  getCiudades: getCiudadesDuck.reducer,
  getNacionalidades: getNacionalidadesDuck.reducer,

  eliminarFotoDeCandidato: eliminarFotoDeCandidatoDuck.reducer,

  getIdiomas: getIdiomasDuck.reducer,
  getCandIdiomas: getCandIdiomasDuck.reducer,
  crearCandIdioma: crearCandIdiomaDuck.reducer,
  eliminarCandIdioma: eliminarCandIdiomaDuck.reducer,
  editarCandIdiomas: editarCandIdiomaDuck.reducer,

  getCandArchivos: getCandArchivosDuck.reducer,
  eliminarCandArchivo: eliminarCandArchivoDuck.reducer,
  getCv: getCvDuck.reducer,

  getEmpresasWithPagination: getEmpresasWithPaginationDuck.reducer,

  getOportFlyer: getOportFlyerDuck.reducer,
  eliminarOportFlyer: eliminarOportFlyerDuck.reducer,

  getOportRelevamiento: getOportRelevamientoDuck.reducer,
  eliminarOportRelevamiento: eliminarOportRelevamientoDuck.reducer,

  getInstituto: getInstitutoDuck.reducer,
  createInstituto: createInstitutoDuck.reducer,
  editInstituto: editInstitutoDuck.reducer,
  deleteInstituto: deleteInstitutoDuck.reducer,

  enviarMailInicio: enviarMailInicioDuck.reducer,

  getOportunidadesCandidato: getOportunidadesCandidatoDuck.reducer,

  getDatosAuxiliares: getDatosAuxiliaresDuck.reducer,
  createDatoAuxiliar: createDatoAuxiliarDuck.reducer,
  editDatoAuxiliar: editDatoAuxiliarDuck.reducer,
  deleteDatoAuxiliar: deleteDatoAuxiliarDuck.reducer,

  getGeografia: getGeografiaDuck.reducer,
  createDatoGeografico: createDatoGeograficoDuck.reducer,
  editDatoGeografico: editDatoGeograficoDuck.reducer,
  deleteDatoGeografico: deleteDatoGeograficoDuck.reducer,

  getCandidatosV1: getCandidatosV1Duck.reducer,
  getContactoCandidatos: getContactoCandidatosDuck.reducer,

  getReporteProduccion: getReporteProduccionDuck.reducer,

  getReporteProduccionSupervisor: getReporteProduccionSupervisorDuck.reducer,
  getNotasReporteProduccion: getNotasReporteProduccionDuck.reducer,

  getContactoMensajes: getContactoMensajesDuck.reducer,

  notaFavoritaSet: notaFavoritaSetDuck.reducer,

  ...estudioMercadoReducers,
});
