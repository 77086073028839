import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getInstitutoDuck = createDuck('GET_INSTITUTO', newActions, newReducers);

const getInstitutoThunk = (params) => {
  log({ 'getInstitutoThunk.params': params });
  const url = `${baseUrl}/v1/instituto`;

  return createThunk(axios, 'get', getInstitutoDuck.actions, url, null, params);
};

const getInstitutosDuck = createDuck('GET_INSTITUTOS', newActions, newReducers);

const getInstitutosThunk = (params) => {
  const url = `${baseUrl}/v1/institutos`;

  return createThunk(
    axios,
    'get',
    getInstitutosDuck.actions,
    url,
    null,
    params
  );
};

const deleteInstitutoDuck = createDuck(
  'DELETE_INSTITUTO',
  newActions,
  newReducers
);

const deleteInstitutoThunk = (params) => {
  const url = `${baseUrl}/v1/instituto`;

  return createThunk(axios, 'delete', deleteInstitutoDuck.actions, url, params);
};

const createInstitutoDuck = createDuck(
  'CREATE_INSTITUTO',
  newActions,
  newReducers
);

const createInstitutoThunk = (params) => {
  const url = `${baseUrl}/v1/instituto`;
  return createThunk(axios, 'post', createInstitutoDuck.actions, url, params);
};

const editInstitutoDuck = createDuck(
  'GUARDAR_EDICION_OPORT',
  newActions,
  newReducers
);

const editInstitutoThunk = (params) => {
  log('editInstitutoThunk.start');

  const url = `${baseUrl}/v1/instituto`;

  return createThunk(axios, 'patch', editInstitutoDuck.actions, url, params);
};

export {
  getInstitutoDuck,
  getInstitutoThunk,
  getInstitutosDuck,
  getInstitutosThunk,
  deleteInstitutoDuck,
  deleteInstitutoThunk,
  createInstitutoDuck,
  createInstitutoThunk,
  editInstitutoDuck,
  editInstitutoThunk,
};
