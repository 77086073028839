import React from "react";
import { Content } from "adminlte-2-react";

function NotFound() {
  return (
    <Content
      browserTitle="404 | URL no encontrada"
      show={false}
    >
      <div className="error-page">
        <h2 className="headline text-yellow">
          404
        </h2>
        <div className="error-content">
          <br />
          <h3>
            <i className="fa fa-warning text-yellow"></i> No se encontró la página
          </h3>
          <p>
            No pudimos encontrar la página que buscabas.
            <br />
            <br />
            <a href="/">Retornar al inicio</a>
          </p>
        </div>
      </div>
    </Content>
  );
}

export { NotFound };
