import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  prepareDatosAuxOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import { estudioMercadoCreateDuck, estudioMercadoCreateThunk } from './duck';

import {
  getCargosDuck,
  getCargosThunk,
  getRubrosDuck,
  getRubrosThunk,
  getOportunidadesDuck,
  getOportunidadesThunk,
} from '../oportunidades/duck';

import { CrearEstudioMercadoView } from './CrearEstudioMercadoView';
import { allEtapasOptions } from '../utils/constants';

function CrearEstudioMercado({ history, match, token }) {
  const dispatch = useDispatch();

  const estudioMercadoCreate = useSelector(
    (state) => state.estudioMercadoCreate
  );
  const modelGetCargos = useSelector((state) => state.getCargos);
  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelOportunidades = useSelector((state) => state.getOportunidades);

  const [cargosOptions, setCargosOptions] = useState([]);
  const [cargoOption, setCargoOption] = useState();
  const [rubrosOptions, setRubrosOptions] = useState([]);
  const [rubroOption, setRubroOption] = useState();
  const [oportunidadOption, setOportunidadOption] = useState();
  const [oportunidadOptions, setOportunidadOptions] = useState([]);

  // guarda las oportunidades para luego poder buscar
  // sus datos
  const [oportunidades, setOportunidades] = useState([]);
  const [etapaOptions, setEtapaOptions] = useState([]);
  const [etapaOption, setEtapaOption] = useState();
  const [tipoOption, setTipoOption] = useState();
  const [origenOption, setOrigenOption] = useState();
  const [titulo, setTitulo] = useState();
  const [cargoRubroFiltroOption, setCargoRubroFiltroOption] = useState();

  const {
    topAlert,
    setTopAlert,
    submitAlert,
    setSubmitAlert,
    setErrorTopAlert,
  } = useAlerts();

  const prepareOportunidadesOptions = (oportunidades) => {
    const options = oportunidades.map((oportunidad) => ({
      value: oportunidad.idNumerico,
      label: toTitleCase(
        `${oportunidad.empresaDescripcion} - ${oportunidad.titulo}`
      ),
    }));

    return options;
  };

  useEffect(() => {
    dispatch(
      getOportunidadesThunk({
        userToken: token.raw,
        campos: ['idNumerico', 'titulo', 'etapas', 'empresaDescripcion'],
      })
    );
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelOportunidades);

    if (error) {
      setErrorTopAlert(errorMessage);
      dispatch(getOportunidadesDuck.actions.clear());
    }

    if (data) {
      const mOportunidadesOptions = prepareOportunidadesOptions(data.items);

      setOportunidades(data.items);
      setOportunidadOptions(mOportunidadesOptions);

      dispatch(getOportunidadesDuck.actions.clear());
    }
  }, [modelOportunidades]);

  useEffect(() => {
    if (oportunidadOption?.value) {
      const oportunidadData = oportunidades.find((mOportunidad) => {
        return mOportunidad.idNumerico === oportunidadOption.value;
      });

      const etapasOptions = [];

      oportunidadData.etapas.forEach((etapa) => {
        const etapaOptionFound = allEtapasOptions.find((etapaOption) => {
          return etapaOption.value === etapa;
        });

        if (etapaOptionFound) {
          etapasOptions.push(etapaOptionFound);
        }
      });

      setEtapaOptions(etapasOptions);
    }
  }, [oportunidadOption]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      const datosAuxOptions = prepareDatosAuxOptions(data.items);

      setRubrosOptions([{ value: null, label: 'Todos' }, ...datosAuxOptions]);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    const { data, httpStatus, error, errorMessage } =
      responseSelector(estudioMercadoCreate);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudioMercadoCreateDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setSubmitAlert({
        text: 'Estudio de mercado creado correctamente',
        type: alertTypes.success,
        closable: false,
      });

      history.push(`/estudio/${data.estudioMercadoIdNumerico}`);

      dispatch(estudioMercadoCreateDuck.actions.clear());
    }
  }, [estudioMercadoCreate]);

  function submit() {
    const mCargoRubroFiltros = cargoRubroFiltroOption?.map(
      (elem) => elem.value
    );

    let data = {
      userToken: token.raw,
      titulo,
      cargoIdNumerico: cargoOption?.value,
      rubroIdNumerico: rubroOption?.value,
      origenCandidatos: origenOption?.value,
      cargoRubroFiltros: mCargoRubroFiltros,
      oportunidadIdNumerico: oportunidadOption?.value,
      etapaIdNumerico: etapaOption?.value,
      tipo: tipoOption?.value,
    };

    log({ 'submit.data': data });

    dispatch(estudioMercadoCreateThunk(data));
  }

  return (
    <CrearEstudioMercadoView
      topAlert={topAlert}
      modelOportunidades={modelOportunidades}
      tipoOption={tipoOption}
      setTipoOption={setTipoOption}
      titulo={titulo}
      setTitulo={setTitulo}
      cargoOption={cargoOption}
      cargosOptions={cargosOptions}
      setCargoOption={setCargoOption}
      rubroOption={rubroOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      cargoRubroFiltroOption={cargoRubroFiltroOption}
      setCargoRubroFiltroOption={setCargoRubroFiltroOption}
      oportunidadOption={oportunidadOption}
      oportunidadOptions={oportunidadOptions}
      setOportunidadOption={setOportunidadOption}
      etapaOption={etapaOption}
      etapaOptions={etapaOptions}
      setEtapaOption={setEtapaOption}
      submit={submit}
      estudioMercadoCreate={estudioMercadoCreate}
      origenOption={origenOption}
      setOrigenOption={setOrigenOption}
      submitAlert={submitAlert}
    />
  );
}

export { CrearEstudioMercado };
