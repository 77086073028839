import {
  emCandidatoDeleteDuck,
  emCandidatoEditDuck,
  emCandidatoIncluirDuck,
  emCandidatosConteoGetDuck,
  emCandidatosGetDuck,
  emForInclusionGetDuck,
  estudioMercadoCreateDuck,
  estudioMercadoEditDuck,
  estudioMercadoEspecificoDeleteDuck,
  estudioMercadoGeneralDeleteDuck,
  estudiosMercadoEspecificosGetDuck,
  estudiosMercadoGeneralesGetDuck,
  notaFavoritaGetDuck,
} from './duck';

const estudioMercadoReducers = {
  estudioMercadoCreate: estudioMercadoCreateDuck.reducer,
  emCandidatosGet: emCandidatosGetDuck.reducer,
  emCandidatoEdit: emCandidatoEditDuck.reducer,
  emCandidatoDelete: emCandidatoDeleteDuck.reducer,
  notaFavoritaGet: notaFavoritaGetDuck.reducer,
  emForInclusionGet: emForInclusionGetDuck.reducer,
  emCandidatoIncluir: emCandidatoIncluirDuck.reducer,
  emCandidatosConteoGet: emCandidatosConteoGetDuck.reducer,
  estudiosMercadoGeneralesGet: estudiosMercadoGeneralesGetDuck.reducer,
  estudiosMercadoEspecificosGet: estudiosMercadoEspecificosGetDuck.reducer,
  estudioMercadoGeneralDelete: estudioMercadoGeneralDeleteDuck.reducer,
  estudioMercadoEspecificoDelete: estudioMercadoEspecificoDeleteDuck.reducer,
  estudioMercadoEdit: estudioMercadoEditDuck.reducer,
};

export { estudioMercadoReducers };
