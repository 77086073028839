import React, { useEffect, useState } from 'react';
import { Button } from 'adminlte-2-react';
import { Modal } from 'react-bootstrap';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { TextArea } from '../../utils/TextArea';
import { SelectInput } from '../../utils/SelectInput';
import Loader from '../../utils/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { responseSelector, toTitleCase } from '../../utils/utils';
import { Alert, alertTypes, useAlerts } from '../../ui/alert';
import {
  emForInclusionGetDuck,
  emForInclusionGetThunk,
  emCandidatoIncluirDuck,
  emCandidatoIncluirThunk,
} from '../../estudiosMercado/duck';
const queryString = require('query-string');

const prepareEmOptions = (datos) => {
  const options = datos.map((dato) => ({
    value: dato.idNumerico,
    label: toTitleCase(dato.titulo),
  }));

  options.unshift({
    value: null,
    label: 'Sin especificar',
  });

  return options;
};

function ModalIncluirEm({
  isModalIncluirEmShown,
  hideModal,
  yaIncluidoEnEmGeneral,
  token,
  candidatoIdNumerico,
  history,
}) {
  const dispatch = useDispatch();

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  const emParams = localStorage.getItem('emParams');
  const parsedEmParams = emParams ? JSON.parse(emParams) : null;
  const estudioMercadoIdNumerico = parsedEmParams?.estudioMercadoIdNumerico;
  const estudioMercadoQueryParams = parsedEmParams?.queryParams;

  const [emEspecificoOptions, setEmEspecificoOptions] = useState([]);
  const [emEspecificoOption, setEmEspecificoOption] = useState(null);
  const [emGeneralOptions, setEmGeneralOptions] = useState([]);
  const [emGeneralOption, setEmGeneralOption] = useState(null);

  const emForInclusionGet = useSelector((state) => state.emForInclusionGet);
  const emCandidatoIncluir = useSelector((state) => state.emCandidatoIncluir);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(emForInclusionGet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(emForInclusionGetDuck.actions.clear());
    }

    if (data) {
      const mEmEspecificoOptions = prepareEmOptions(data.emEspecificos);
      setEmEspecificoOptions(mEmEspecificoOptions);

      const mEmGeneralOptions = prepareEmOptions(data.emGenerales);
      setEmGeneralOptions(mEmGeneralOptions);

      if (emParams) {
        // Setear la opcion que coincida con el estudio de mercado
        // desde donde vino. Deberia coincidir con uno de ellos nomas
        // (general o especifico).

        const mEmGeneralOption = mEmGeneralOptions.find((option) => {
          return option.value === estudioMercadoIdNumerico;
        });

        if (mEmGeneralOption) {
          setEmGeneralOption(mEmGeneralOption);
        }

        const mEmEspecificoOption = mEmEspecificoOptions.find(
          (option) => option.value === estudioMercadoIdNumerico
        );

        if (mEmEspecificoOption) {
          setEmEspecificoOption(mEmEspecificoOption);
        }
      }

      dispatch(emForInclusionGetDuck.actions.clear());
    }
  }, [emForInclusionGet]);

  useEffect(() => {
    dispatch(
      emForInclusionGetThunk({
        userToken: token.raw,
      })
    );
  }, []);

  useEffect(() => {
    const { message, httpStatus, error, errorMessage } =
      responseSelector(emCandidatoIncluir);

    if (error) {
      setSubmitAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(emCandidatoIncluirDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setSubmitAlert({
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      dispatch(emCandidatoIncluirDuck.actions.clear());

      if (estudioMercadoQueryParams) {
        let redirect = `/estudio/${estudioMercadoIdNumerico}`;
        const qParams = queryString.stringify(estudioMercadoQueryParams);
        redirect = `${redirect}?${qParams}`;
        localStorage.removeItem('emParams');
        history.push(redirect);
      }
    }
  }, [emCandidatoIncluir]);

  function incluirCandidatoAEm() {
    dispatch(
      emCandidatoIncluirThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        emGeneralIdNumerico: emGeneralOption?.value,
        emEspecificoIdNumerico: emEspecificoOption?.value,
      })
    );
  }

  // if (!isModalIncluirEmShown) {
  //   return null;
  // }

  return (
    <Modal
      show={isModalIncluirEmShown}
      style={{ opacity: 1 }}
      onHide={hideModal}
    >
      <Modal.Header>
        <Modal.Title>Incluir candidato a EM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader isLoading={emForInclusionGet.loading}>
          <SelectInput
            label="Estudio de mercado general"
            value={emGeneralOption}
            options={emGeneralOptions}
            setValue={setEmGeneralOption}
            required
          />

          <SelectInput
            label="Estudio de mercado específico"
            value={emEspecificoOption}
            options={emEspecificoOptions}
            setValue={setEmEspecificoOption}
            required
          />

          {submitAlert?.text && (
            <Alert
              title={submitAlert.title}
              type={submitAlert.type}
              text={submitAlert.text}
              closable={submitAlert.closable}
            />
          )}
        </Loader>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonWithLoading
            type="primary"
            text="Incluir"
            disabled={!yaIncluidoEnEmGeneral && !emGeneralOption}
            loading={emCandidatoIncluir.loading}
            submit={() => {
              setSubmitAlert({ text: '' });
              incluirCandidatoAEm();
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export { ModalIncluirEm };
