import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  getNumberOrNull,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  getInstitutoDuck,
  getInstitutoThunk,
  editInstitutoDuck,
  editInstitutoThunk,
} from './duck';
import { CrearInstitutoView } from './CrearInstitutoView';

function EditarInstituto({ history, match, token }) {
  const institutoId = match?.params?.institutoId;

  const dispatch = useDispatch();

  const modelGetInstituto = useSelector((state) => state.getInstituto);
  const modelEditInstituto = useSelector((state) => state.editInstituto);

  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [contactoNombre, setContactoNombre] = useState('');
  const [contactoTelefono, setContactoTelefono] = useState('');

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  useEffect(() => {
    dispatch(
      getInstitutoThunk({
        userToken: token.raw,
        idNumerico: institutoId,
      })
    );
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetInstituto);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getInstitutoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(getInstitutoDuck.actions.clear());

      setNombre(data.instituto.nombre);
      setDireccion(data.instituto.direccion);
      setTelefono(data.instituto.telefono);
      setContactoNombre(data.instituto.contacto?.nombre ?? '');
      setContactoTelefono(data.instituto.contacto?.telefono ?? '');
    }
  }, [modelGetInstituto]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditInstituto);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editInstitutoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editInstitutoDuck.actions.clear());

      setTopAlert({
        text: 'Instituto editado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelEditInstituto]);

  function submit() {
    let data = {
      userToken: token.raw,
      institutoId,
      nombre,
      direccion,
      telefono,
      contactoNombre,
      contactoTelefono,
    };

    log({ 'submit.data': data });

    dispatch(editInstitutoThunk(data));
  }

  return (
    <CrearInstitutoView
      mode="edit"
      history={history}
      match={match}
      modelCreateInstituto={modelEditInstituto}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      nombre={nombre}
      setNombre={setNombre}
      direccion={direccion}
      setDireccion={setDireccion}
      telefono={telefono}
      setTelefono={setTelefono}
      contactoNombre={contactoNombre}
      setContactoNombre={setContactoNombre}
      contactoTelefono={contactoTelefono}
      setContactoTelefono={setContactoTelefono}
      modelGetInstituto={modelGetInstituto}
    />
  );
}

export { EditarInstituto };
