/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Box } from 'adminlte-2-react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { toTitleCase } from '../../utils/utils';
import Loader from '../../utils/Loader';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { emParametrosDescByName } from '../../utils/constants';
import { log } from '../../utils/logs';

const EmCandidatosTable = React.memo(
  function Table({
    emCandidatosGet,
    groupedCandidates,
    setSelectedCandidato,
    setCandidatoSelectedForEdit,
  }) {
    log('EmCandidatosTable.render');

    if (emCandidatosGet.loading) {
      return <Loader isLoading />;
    }

    return Object.entries(groupedCandidates).map(([rubroId, group]) => (
      <div key={rubroId}>
        <b>Rubro: {group.rubroDescripcion ?? 'Rubro Sin Nombre'}</b>
        <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
          <div className="table-responsive">
            <DataTable
              lazy
              pt={{
                table: {
                  className: 'table table-hover',
                },
              }}
              value={group.candidates}
              emptyMessage="No se encontraron registros"
            >
              <Column
                field="acciones"
                header="Acciones"
                body={(candidato) => (
                  <>
                    <ButtonWithLoading
                      size="sm"
                      type="danger"
                      icon="fa-trash"
                      submit={() => {
                        setSelectedCandidato(candidato.idNumerico);
                      }}
                      margin
                    />

                    <ButtonWithLoading
                      size="sm"
                      type="info"
                      icon="fa-pencil-alt"
                      submit={() => {
                        setCandidatoSelectedForEdit(candidato);
                      }}
                    />
                  </>
                )}
                style={{ minWidth: 110 }}
              ></Column>

              <Column
                field="parametro"
                header="Parámetro"
                body={(candidato) =>
                  candidato.parametro
                    ? emParametrosDescByName[candidato.parametro]
                    : ''
                }
                style={{ minWidth: 90 }}
              ></Column>

              <Column
                field="experienciaEmpresa"
                header="Empresa"
                body={(candidato) =>
                  toTitleCase(candidato?.lastExperience?.empresaDescripcion)
                }
                style={{ minWidth: 70 }}
              ></Column>

              <Column
                field="nombreCompleto"
                header="Nombre"
                body={(candidato) => {
                  return (
                    <a
                      href={`/editarCandidato/${candidato.idNumerico}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {toTitleCase(
                        candidato.candidatoDetails?.nombreCompleto ?? ''
                      )}
                    </a>
                  );
                }}
              ></Column>

              <Column
                field="experienciaCargo"
                header="Cargo"
                body={(candidato) =>
                  toTitleCase(candidato?.lastExperience?.cargoDescripcion)
                }
                style={{ minWidth: 70 }}
              ></Column>

              <Column
                field="experienciaRubro"
                header="Rubro"
                body={(candidato) =>
                  toTitleCase(candidato?.lastExperience?.rubroDescripcion)
                }
                style={{ minWidth: 70 }}
              ></Column>

              <Column
                field="notaFavorita"
                header="Nota favorita"
                body={(candidato) => (
                  <div>
                    {candidato.candidatoDetails?.notaFavorita?.contenido && (
                      <textarea
                        disabled
                        style={{ width: 180, height: 200 }}
                        value={
                          candidato.candidatoDetails?.notaFavorita?.contenido
                        }
                      />
                    )}
                  </div>
                )}
                style={{ minWidth: 90 }}
              ></Column>

              <Column
                field="condicionesActuales"
                header="Condiciones actuales"
                body={(candidato) => (
                  <div>
                    {candidato.condicionesActuales && (
                      <textarea
                        disabled
                        style={{ width: 150, height: 200 }}
                        value={candidato.condicionesActuales}
                      />
                    )}
                  </div>
                )}
              ></Column>

              <Column
                field="condicionesContratacion"
                header="Condiciones contratación"
                body={(candidato) => (
                  <div>
                    {candidato.condicionesContratacion && (
                      <textarea
                        disabled
                        style={{ width: 150, height: 200 }}
                        value={candidato.condicionesContratacion}
                      />
                    )}
                  </div>
                )}
              ></Column>

              <Column
                field="resumen"
                header="Resumen"
                body={(candidato) => (
                  <div>
                    {candidato.resumen && (
                      <textarea
                        disabled
                        style={{ width: 150, height: 200 }}
                        value={candidato.resumen}
                      />
                    )}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </Box>
      </div>
    ));
  },
  (prevProps, nextProps) => {
    return (
      prevProps.emCandidatosGet === nextProps.emCandidatosGet &&
      prevProps.groupedCandidates === nextProps.groupedCandidates
    );
  }
);

export { EmCandidatosTable };
