import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonWithLoading } from "../utils/ButtonWithLoading";
import { TextInput } from "../utils/TextInput";
import { SelectInput } from "../utils/SelectInput";

function ModalCreateDatoGeografico({
  isModalShown,
  hideModal,
  title,
  concepto,
  datosAuxiliaresOptions,
  setConcepto,
  valor,
  setValor,
  buttonDisabled = false,
  buttonLoading,
  submit,
  conceptoSelectDisabled = false,
  padreId,
  setPadreId,
  regionesOptions
}) {

  return (
    <Modal show={isModalShown} style={{ opacity: 1 }} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectInput
          label="Concepto"
          value={concepto}
          options={datosAuxiliaresOptions}
          setValue={setConcepto}
          disabled={conceptoSelectDisabled}
        />
        {concepto?.value === "ciudad" && (
          <SelectInput
            label={'Region'}
            value={padreId}
            options={regionesOptions}
            setValue={setPadreId}
            disabled={conceptoSelectDisabled}
          />
        )}
        <TextInput
          label="Valor"
          initialValue={valor}
          setValue={setValor}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="primary"
          text="Guardar"
          disabled={buttonDisabled}
          loading={buttonLoading}
          submit={submit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalCreateDatoGeografico };
