import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { constants, responseSelector, toTitleCase } from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import {
  getCandidatoDuck,
  getCandidatoThunk,
  eliminarFotoDeCandidatoDuck,
  eliminarFotoDeCandidatoThunk,
} from './duck';
import { EditarFotoCandidatoView } from './EditarFotoCandidatoView';
import config from '../config/index';
const queryString = require('query-string');

function EditarFotoCandidato({ history, match, token }) {
  const dispatch = useDispatch();

  const candidatoIdNumerico = match.params.candidatoId;

  const modelGetCandidato = useSelector((state) => state.getCandidato);
  const modeleliminarFotoDeCandidato = useSelector(
    (state) => state.eliminarFotoDeCandidato
  );

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  // id de mongo
  const [candidatoId, setCandidatoId] = useState(null);

  const [imagenes, setImagenes] = useState();

  const [imagenesListaLoading, setImagenesListaLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getCandidatoThunk({
        userToken: token.raw,
        idNumerico: candidatoIdNumerico,
      })
    );
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidato);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setImagenesListaLoading(false);

      dispatch(getCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log({ 'getCandidato.data': data });

      dispatch(getCandidatoDuck.actions.clear());

      setCandidatoId(data.candidato._id);

      if (data?.candidato?.fotosV2) {
        setImagenes(data?.candidato?.fotosV2);
      }

      setImagenesListaLoading(false);
    }
  }, [modelGetCandidato]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modeleliminarFotoDeCandidato);
    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      setImagenesListaLoading(false);
      dispatch(eliminarFotoDeCandidatoDuck.actions.clear());
    }

    if (data) {
      dispatch(
        getCandidatoThunk({
          userToken: token.raw,
          idNumerico: candidatoIdNumerico,
        })
      );
      dispatch(eliminarFotoDeCandidatoDuck.actions.clear());
    }
  }, [modeleliminarFotoDeCandidato]);

  const doEliminarImagen = (imagenID) => {
    setImagenesListaLoading(true);

    let data = {
      imagenID,
      candidatoId: candidatoId,
      token,
      userToken: token.raw,
    };
    dispatch(eliminarFotoDeCandidatoThunk(data));
  };

  const navigateToSubirImagenes = () => {
    log('navigateToSubirImagenes');

    const baseUrl = config.api.baseUrl;
    const key = config.api.key;

    const concepto = 'candidatoFoto';
    const conceptoId = candidatoId;

    log({ concepto });
    log({ config });
    log({
      imgRestrictionsByConcepto: config.imgRestrictionsByConcepto[concepto],
    });

    const uploadConfigVariant = 'upload';
    const cropConfigVariant = 'crop';

    const uploadUrl = `${baseUrl}/agregarImagen/${concepto}/${conceptoId}/?userToken=${token.raw}&esTemporal=true&configVariant=${uploadConfigVariant}`;
    const uploadHeaders = {
      'x-access-token': key,
      userToken: token.raw,
    };

    const cropUploadUrl = `${baseUrl}/agregarImagen/${concepto}/${conceptoId}/?userToken=${token.raw}&esTemporal=false&configVariant=${cropConfigVariant}`;
    const cropConfig =
      config.imgRestrictionsByConcepto[concepto][cropConfigVariant];

    const nextStepUrl = `/cortarImagenes`;
    let lastStepUrl = `/editarCandidato/${candidatoIdNumerico}`;

    const params = queryString.stringify({
      conceptoId,
      concepto,
      uploadUrl,
      rawUploadHeaders: JSON.stringify(uploadHeaders),
      nextStepUrl,
      pageTitle: 'Subida de imágenes',
      pageSubtitle: 'Paso 1 : Subida',
      nextButtonText: 'Siguiente',
      cropConfig: JSON.stringify(cropConfig),
      cropUploadUrl,
      saveInConceptUrl: `${baseUrl}/v1/agregarFotoACandidato`,
      lastStepUrl,
      ...config.imgRestrictionsByConcepto[concepto][uploadConfigVariant],
    });

    log('AddLogo.paramsBeforeNavigate');
    log(params);

    history.push(`/subirImagenes?${params}`);
  };

  return (
    <EditarFotoCandidatoView
      history={history}
      match={match}
      modelGetCandidato={modelGetCandidato}
      submitAlert={submitAlert}
      topAlert={topAlert}
      navigateToSubirImagenes={navigateToSubirImagenes}
      imagenes={imagenes}
      imagenesListaLoading={imagenesListaLoading}
      doEliminarImagen={doEliminarImagen}
    />
  );
}

export { EditarFotoCandidato };
