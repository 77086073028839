import React from 'react';

import { Box, Button } from 'adminlte-2-react';

function ImagenesLista({
  imagenes,
  doEliminarImagen,
  navigateToSubirThumbnail,
  loading,
}) {
  if (loading) {
    return <p>Cargando...</p>;
  }
  return (
    <div>
      {!imagenes || !imagenes.length ? (
        <div>Sin imágenes para listar</div>
      ) : null}
      {imagenes
        ? imagenes.map((imagenObj, index) => {
            return (
              <Box key={index}>
                <div style={{ marginBottom: 20 }}>
                  <img src={imagenObj.url} style={{ maxWidth: 300 }} />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <p>
                    <b>Nombre de archivo:</b>
                    <br />
                    {imagenObj.url.split('/').pop()}
                  </p>
                  {/* <a href={imagenObj.url}>Ver imagen</a> */}
                </div>

                {imagenObj?.thumbnail?.url && (
                  <div>
                    <p>
                      <b>Thumbnail:</b>
                      <br />
                      {imagenObj.thumbnail.url.split('/').pop()}
                    </p>
                    {/* <a href={imagenObj.thumbnail.url}>Ver thumbnail</a> */}
                  </div>
                )}
                {navigateToSubirThumbnail && (
                  <Button
                    type="primary"
                    text="Subir thumbnail"
                    pullRight={true}
                    onClick={() => navigateToSubirThumbnail(imagenObj._id)}
                  />
                )}
                <Button
                  type="primary"
                  text="Eliminar"
                  pullRight={true}
                  onClick={() => doEliminarImagen(imagenObj._id)}
                />
              </Box>
            );
          })
        : null}
    </div>
  );
}

export default ImagenesLista;
