import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';
import {
  getInstitutosDuck,
  getInstitutosThunk,
  deleteInstitutoDuck,
  deleteInstitutoThunk,
} from './duck';
import { constants, responseSelector } from '../utils/utils';
import { ListaInstitutosView } from './ListaInstitutosView';
import { log } from '../utils/logs';

const queryString = require('query-string');

function ListaInstitutos({ history, match, test, token }) {
  const queryParams = history?.location?.search;
  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetInstitutos = useSelector((state) => state.getInstitutos);
  const modelDeleteInstituto = useSelector((state) => state.deleteInstituto);

  const [seleccion] = useState(parsedQueryParams?.seleccion);
  const [seleccionRetorno] = useState(parsedQueryParams?.seleccionRetorno);

  const [institutos, setInstitutos] = useState();
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );

  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] =
    useState(false);

  const [selectedInstituto, setSelectedInstituto] = useState();

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    handlePage(0);
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteInstituto);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      setIsDeleteConfirmModalShown(false);

      dispatch(deleteInstitutoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(deleteInstitutoDuck.actions.clear());

      handlePage(0);

      setTopAlert({
        text: 'Instituto eliminado',
        type: alertTypes.success,
        closable: false,
      });

      setIsDeleteConfirmModalShown(false);
    }
  }, [modelDeleteInstituto]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetInstitutos);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getInstitutosDuck.actions.clear());
    }

    if (data) {
      setInstitutos(data.items);
      setTotalResults(data.total);
      dispatch(getInstitutosDuck.actions.clear());
    }
  }, [modelGetInstitutos]);

  function handlePage(page) {
    setActivePage(page);

    const params = {
      page: page + 1,
      limit: pageSize,
      busquedaRapida,
      sortField: '_id',
      sortOrder: -1,
    };

    log({ 'handlePage.params': params });

    let newUrl = `${history.location.pathname}?${queryString.stringify({
      ...parsedQueryParams,
      ...params,
    })}`;

    history.replace(newUrl);

    dispatch(
      getInstitutosThunk({
        ...params,
        userToken: token.raw,
        busquedaRapida,
      })
    );
  }

  function deleteInstituto() {
    dispatch(
      deleteInstitutoThunk({
        userToken: token.raw,
        institutoId: selectedInstituto?.idNumerico,
      })
    );
  }

  return (
    <ListaInstitutosView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      institutos={institutos}
      modelGetInstitutos={modelGetInstitutos}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      deleteInstituto={deleteInstituto}
      isDeleteConfirmModalShown={isDeleteConfirmModalShown}
      setIsDeleteConfirmModalShown={setIsDeleteConfirmModalShown}
      selectedInstituto={selectedInstituto}
      setSelectedInstituto={setSelectedInstituto}
      modelDeleteInstituto={modelDeleteInstituto}
      seleccion={seleccion}
      seleccionRetorno={seleccionRetorno}
    />
  );
}

export { ListaInstitutos };
