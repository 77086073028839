/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';

import { Alert } from '../../utils/Alert';

import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDateOrEmpty, toTitleCase } from '../../utils/utils';
import { empleoTipoDescById } from '../../utils/constants';

const customComparator = (prevProps, nextProps) => {
  const result =
    nextProps.experiencias === prevProps.experiencias &&
    nextProps.experienciaSubmitAlert === prevProps.experienciaSubmitAlert &&
    nextProps.modelGetCandidatoExperiencias.loading ===
      prevProps.modelGetCandidatoExperiencias.loading &&
    nextProps.prepareExpEditAndShowModal ===
      prevProps.prepareExpEditAndShowModal;
  return result;
};

const Experiencias = memo(function Componente({
  experiencias,
  showModalAgregarExp,
  experienciaSubmitAlert,
  modelGetCandidatoExperiencias,
  showModalEliminarCandExp,
  setSelectedExperiencia,
  prepareExpEditAndShowModal,
}) {
  return (
    <Row>
      <Col md="10" mdOffset="1">
        <Box
          title="Experiencias"
          customOptions={
            <div>
              <Button
                size="sm"
                type="default"
                text="Agregar"
                onClick={() => showModalAgregarExp()}
              />
            </div>
          }
        >
          {experienciaSubmitAlert.text && (
            <Alert
              title={experienciaSubmitAlert.title}
              type={experienciaSubmitAlert.type}
              text={experienciaSubmitAlert.text}
              closable={experienciaSubmitAlert.closable}
            />
          )}

          <Loader isLoading={modelGetCandidatoExperiencias.loading}>
            <div style={{ display: 'block' }}>
              <span>Total: {experiencias.length}</span>
            </div>

            {experiencias && experiencias.length > 0 ? (
              <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                <div className="table-responsive">
                  <DataTable
                    lazy
                    pt={{
                      table: {
                        className: 'table table-hover',
                      },
                    }}
                    value={experiencias}
                    emptyMessage="No se encontraron experiencias"
                  >
                    <Column field="idNumerico" header="ID"></Column>
                    <Column
                      field="acciones"
                      header="Acciones"
                      body={(experiencia) => (
                        <div style={{ width: 130 }}>
                          <div
                            style={{
                              display: 'inline-block',
                              marginRight: 5,
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="warning"
                              text="Eliminar"
                              submit={() => {
                                setSelectedExperiencia(experiencia);
                                showModalEliminarCandExp();
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="default"
                              text="Editar"
                              submit={() => {
                                setSelectedExperiencia(experiencia);
                                prepareExpEditAndShowModal(experiencia);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="empresaDescripcion"
                      header="Empresa"
                      body={(experiencia) =>
                        toTitleCase(experiencia.empresaDescripcion)
                      }
                    ></Column>
                    <Column
                      field="cargoDescripcion"
                      header="Cargo"
                      body={(experiencia) =>
                        toTitleCase(experiencia.cargoDescripcion)
                      }
                    ></Column>
                    <Column
                      field="tipoEmpleo"
                      header="Tipo empleo"
                      body={(experiencia) => {
                        return (
                          empleoTipoDescById[experiencia.tipoEmpleo] ?? 'N/A'
                        );
                      }}
                    ></Column>
                    <Column
                      field="vigente"
                      header="Actualmente aquí"
                      body={(experiencia) =>
                        experiencia.vigente ? 'Sí' : 'No'
                      }
                    ></Column>
                    <Column
                      field="inicioFecha"
                      header="Fecha inicio"
                      style={{ minWidth: 90 }}
                      body={(experiencia) =>
                        getDateOrEmpty(experiencia.inicioFecha)
                      }
                    ></Column>
                    <Column
                      field="observacion"
                      header="Descripción del cargo"
                    ></Column>
                  </DataTable>
                </div>
              </Box>
            ) : (
              <h4>No se han encontrado experiencias</h4>
            )}
          </Loader>
        </Box>
      </Col>
    </Row>
  );
},
customComparator);

export { Experiencias };
