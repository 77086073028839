/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Button } from 'adminlte-2-react';
import Loader from '../utils/Loader';
import {
  getDateOrEmpty,
  openWhatsapp,
  procesarNota,
  toTitleCase,
} from '../utils/utils';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function getNota(candidato) {
  if (!candidato.ultimaNota) {
    return null;
  }

  return (
    <>
      <strong>{toTitleCase(candidato.ultimaNota.titulo)}</strong>
      <br />
      <p>{procesarNota(candidato.ultimaNota)}</p>
    </>
  );
}

function getNotaAndButton(
  candidato,
  oportunidadId,
  setCandidatoIdNumerico,
  setNotaAlert
) {
  const nota = getNota(candidato, oportunidadId);
  return (
    <>
      {nota}{' '}
      <Button
        type="default"
        text="Ver más"
        size="sm"
        onClick={() => {
          setNotaAlert({
            text: null,
          });
          setCandidatoIdNumerico(candidato.idNumerico);
        }}
      />
    </>
  );
}

const CandidatosEnOportunidad = React.memo(
  function Table({
    modelGetCandidatosEnOport,
    candidatos,
    getEtapas,
    etapasById,
    oportunidadId,
    setCandidatoIdNumerico,
    setNotaAlert,
    setSelectedCandidato,
    setAccion,
    showModalCambioEtapa,
    showModalConfirmarDesactivar,
    oportunidadLoadStatus,
  }) {
    // console.log('CandidatosEnOportunidad.render');

    return (
      <Loader
        isLoading={
          modelGetCandidatosEnOport.loading ||
          oportunidadLoadStatus !== 'LOADED'
        }
      >
        <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
          <div className="table-responsive">
            <DataTable
              lazy
              pt={{
                table: {
                  className: 'table table-hover',
                },
              }}
              value={candidatos}
              emptyMessage="No se encontraron candidatos"
            >
              <Column
                field="telefono"
                header="Teléfono"
                body={(candidato) => (
                  <a
                    href="javascript:;"
                    onClick={() =>
                      openWhatsapp({
                        telefono: candidato.telefono2,
                        mensaje: `Hola ${toTitleCase(
                          candidato.nombreCompleto
                        )}`,
                      })
                    }
                  >
                    {candidato.telefono2}
                  </a>
                )}
              ></Column>
              <Column
                field="nombreCompleto"
                header="Nombre"
                body={(candidato) => (
                  <a
                    href={`/editarCandidato/${candidato.idNumerico}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {toTitleCase(candidato.nombreCompleto)}
                  </a>
                )}
              ></Column>
              <Column
                field="etapa"
                header="Etapas"
                body={(candidato) => getEtapas({ candidato, etapasById })}
              ></Column>
              <Column
                field="fecha"
                header="Fecha etapa"
                body={(candidato) => {
                  return (
                    <div style={{ width: 75 }}>
                      {getDateOrEmpty(candidato?.etapaInfo?.fecha)}
                    </div>
                  );
                }}
              ></Column>
              <Column
                field="estado"
                header="Estado"
                body={(candidato) =>
                  candidato?.etapaInfo?.inactivo ? 'Inactivo' : 'Activo'
                }
              ></Column>
              <Column
                field="descartado"
                header="Descarte"
                body={(candidato) =>
                  candidato?.etapaInfo?.descartado
                    ? `Descartado: ${candidato?.etapaInfo?.motivoDescarteDescripcion}`
                    : null
                }
              ></Column>
              <Column
                field="formaciones"
                header="Notas"
                style={{ minWidth: 90 }}
                body={(candidato) =>
                  getNotaAndButton(
                    candidato,
                    oportunidadId,
                    setCandidatoIdNumerico,
                    setNotaAlert
                  )
                }
              ></Column>
              <Column
                field="origenPostulacion"
                header="Origen"
                body={(candidato) =>
                  candidato?.origenPostulacion === 'portal'
                    ? 'Portal'
                    : 'Selector'
                }
              ></Column>
              <Column
                field="cambioEtapa"
                header="Acciones"
                body={(candidato) => {
                  return (
                    <div style={{ width: 275 }}>
                      <div
                        style={{
                          marginRight: 5,
                          display: 'inline-block',
                        }}
                      >
                        <ButtonWithLoading
                          text="Avanzar de etapa"
                          size="sm"
                          submit={() => {
                            setSelectedCandidato(candidato);
                            setAccion('avance');
                            showModalCambioEtapa();
                          }}
                          disabled={false}
                        />
                      </div>
                      {!candidato?.etapaInfo?.inactivo && (
                        <div
                          style={{
                            marginRight: 5,
                            display: 'inline-block',
                          }}
                        >
                          <ButtonWithLoading
                            text="Desactivar"
                            size="sm"
                            type="default"
                            submit={() => {
                              setSelectedCandidato(candidato);
                              showModalConfirmarDesactivar();
                            }}
                            disabled={false}
                          />
                        </div>
                      )}
                      {!candidato?.etapaInfo?.descartado && (
                        <div
                          style={{
                            display: 'inline-block',
                          }}
                        >
                          <ButtonWithLoading
                            text="Descartar"
                            size="sm"
                            type="warning"
                            submit={() => {
                              setSelectedCandidato(candidato);
                              setAccion('descarte');
                              showModalCambioEtapa();
                            }}
                            disabled={false}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              ></Column>
            </DataTable>
          </div>
        </Box>
      </Loader>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.modelGetCandidatosEnOport ===
        nextProps.modelGetCandidatosEnOport &&
      prevProps.candidatos === nextProps.candidatos &&
      // prevProps.getEtapas === nextProps.getEtapas &&
      prevProps.etapasById === nextProps.etapasById &&
      // prevProps.getNotaAndButton === nextProps.getNotaAndButton &&
      // prevProps.oportunidadId === nextProps.oportunidadId &&
      // prevProps.setCandidatoIdNumerico === nextProps.setCandidatoIdNumerico &&
      // prevProps.setNotaAlert === nextProps.setNotaAlert &&
      // prevProps.setSelectedCandidato === nextProps.setSelectedCandidato &&
      // prevProps.setAccion === nextProps.setAccion &&
      // prevProps.showModalCambioEtapa === nextProps.showModalCambioEtapa &&
      // prevProps.showModalConfirmarDesactivar ===
      //   nextProps.showModalConfirmarDesactivar &&
      prevProps.oportunidadLoadStatus === nextProps.oportunidadLoadStatus
    );
  }
);

export { CandidatosEnOportunidad };
