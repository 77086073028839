import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { alertTypes, useAlerts } from "../ui/alert";
import {
  constants,
  getDate,
  getDateOrEmpty,
  responseSelector,
  sumarDias,
} from "../utils/utils";
import { log } from "../utils/logs";

import {
  getNotasReporteProduccionDuck,
  getNotasReporteProduccionThunk,
} from "./duck";
import { ReporteProduccionDetalleView } from "./ReporteProduccionDetalleView";
import { getUsuariosDuck, getUsuariosThunk } from "../oportunidades/duck";

const queryString = require("query-string");

function ReporteProduccionDetalle({ history, match, test, token }) {
  const queryParams = history?.location?.search;
  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetReporteProduccion = useSelector(
    (state) => state.getNotasReporteProduccion
  );
  const modelUsuarios = useSelector((state) => state.getUsuarios);
  const [usuariosLoadStatus, setUsuariosLoadStatus] = useState("IDLE");
  const tipoOptions = [
    { value: "selector", label: "Selector" },
    { value: "supervisor", label: "Lider" },
  ];
  const [tipo, setTipo] = useState(
    parsedQueryParams?.tipo === "supervisor"
      ? { value: "supervisor", label: "Lider" }
      : { value: "selector", label: "Selector" }
  );
  const [usuariosOptions, setUsuariosOptions] = useState();
  const [usuarioOption, setUsuarioOption] = useState(
    parsedQueryParams?.colaboradorId
      ? { value: parsedQueryParams?.colaboradorId, label: '...' }
      : null
  );
  const [reporteProduccion, setReporteProduccion] = useState();

  const minDate = sumarDias(new Date(), -30);

  const dateForInicio = parsedQueryParams?.creacionTsDesde
    ? moment(parsedQueryParams?.creacionTsDesde).toDate()
    : new Date(minDate);

  const [inicio, setInicio] = useState(dateForInicio);

  const dateForFin = parsedQueryParams?.creacionTsHasta
    ? moment(parsedQueryParams?.creacionTsHasta).toDate()
    : new Date();

  const [fin, setFin] = useState(dateForFin);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  function handlePage(page = 0) {
    if (!tipo?.value || !usuarioOption?.value || !inicio || !fin) {
      setTopAlert({
        title: "Error",
        text: "Debe completar todos los filtros",
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    setTopAlert({
      title: "",
      text: "",
      type: alertTypes.danger,
      closable: false,
    });

    setActivePage(page);

    const params = {
      page: page + 1,
      limit: pageSize,
      colaboradorId: usuarioOption?.value,
      fechaInicio: getDateOrEmpty(inicio),
      fechaFin: getDateOrEmpty(fin),
      tipo: tipo?.value,
    };

    let newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getNotasReporteProduccionThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  const prepareUsuariosOptions = (usuarios) => {
    const mUsuariosOptions =
      usuarios &&
      usuarios.map((usuario) => ({
        value: usuario?.idNumerico,
        label: usuario?.nombre,
      }));

    return [...mUsuariosOptions];
  };

  useEffect(() => {
    setUsuariosLoadStatus("LOADING");
    dispatch(
      getUsuariosThunk({
        userToken: token.raw,
      })
    );
    if (usuarioOption?.value && inicio && fin && tipo) {
      handlePage(0);
    }
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUsuarios);

    if (error) {
      setTopAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getUsuariosDuck.actions.clear());
    }

    if (data) {
      const mUsuariosOptions = prepareUsuariosOptions(data.items);

      setUsuariosOptions(mUsuariosOptions);

      if (parsedQueryParams?.colaboradorId) {
        const usuarioSeleccionado = mUsuariosOptions.find(
          (usuarioOption) =>
            usuarioOption.value == parsedQueryParams?.colaboradorId
        );

        if (usuarioSeleccionado) {
          setUsuarioOption(usuarioSeleccionado);
        } else {
          setUsuarioOption({
            value: parsedQueryParams?.colaboradorId,
            label: 'Desconocido',
          });
        }
      } else {
        setUsuarioOption(null);
      }

      setUsuariosLoadStatus("LOADED");
      dispatch(getUsuariosDuck.actions.clear());
    }
  }, [modelUsuarios]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetReporteProduccion);

    if (error) {
      setTopAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasReporteProduccionDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setReporteProduccion(data.items);
      setTotalResults(data.total);
      dispatch(getNotasReporteProduccionDuck.actions.clear());
    }
  }, [modelGetReporteProduccion]);

  return (
    <ReporteProduccionDetalleView
      history={history}
      topAlert={topAlert}
      modelGetReporteProduccion={modelGetReporteProduccion}
      usuariosLoadStatus={usuariosLoadStatus}
      usuarioOption={usuarioOption}
      usuariosOptions={usuariosOptions}
      setUsuarioOption={setUsuarioOption}
      inicio={inicio}
      setInicio={setInicio}
      fin={fin}
      setFin={setFin}
      tipo={tipo}
      setTipo={setTipo}
      tipoOptions={tipoOptions}
      submit={handlePage}
      reporteProduccion={reporteProduccion}
      handlePage={handlePage}
      activePage={activePage}
      pageSize={pageSize}
      totalResults={totalResults}
    />
  );
}

export { ReporteProduccionDetalle };
