/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../utils/Loader';
import {
  getDateOrEmpty,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { Alert } from '../ui/alert';
import { useWindowSize } from '../utils/useWindowSize';
import { ListaEmTable } from './ListaEmTable';

function ListaEmWithSearch({
  titulo,
  busquedaRapida,
  setBusquedaRapida,
  estudiosMercadoGet,
  handlePage,
  history,
  totalResults,
  pageSize,
  page,
  estudiosMercado,
  setSelectedEm,
  setSelectedEmForEdit,
}) {
  const { isMobile } = useWindowSize();

  return (
    <Row>
      <Col xs="12" sm="4" lg="3">
        <Box title={`Filtro de ${titulo.toLowerCase()}`}>
          <TextInput
            label="Búsqueda rápida"
            placeholder=""
            initialValue={busquedaRapida}
            setValue={setBusquedaRapida}
            onKeyDown={(event) =>
              submitSearchOnEnterPress(event, () => handlePage(1))
            }
          />

          <div style={{ float: 'right' }}>
            <div style={{ display: 'inline-block', marginRight: 10 }}>
              <ButtonWithLoading
                type="primary"
                text="Filtrar"
                submit={() => handlePage(1)}
                loading={estudiosMercadoGet.loading}
              />
            </div>
          </div>
        </Box>
      </Col>
      <Col xs="12" sm="8" lg="9">
        <Box
          title={titulo}
          border={false}
          customOptions={
            <div>
              {/* <Button
                size="sm"
                type="default"
                text="Crear"
                onClick={() => history.push('/estudio/crear')}
              /> */}
            </div>
          }
        >
          <ListaEmTable
            estudiosMercadoGet={estudiosMercadoGet}
            totalResults={totalResults}
            isMobile={isMobile}
            pageSize={pageSize}
            page={page}
            handlePage={handlePage}
            estudiosMercado={estudiosMercado}
            setSelectedEm={setSelectedEm}
            history={history}
            setSelectedEmForEdit={setSelectedEmForEdit}
          />
        </Box>
      </Col>
    </Row>
  );
}

export { ListaEmWithSearch };
