import React from 'react';
import { Content, Row, Col, Box, Button } from 'adminlte-2-react';

import { SelectInput } from '../utils/SelectInput';
import Loader from '../utils/Loader';
import { DateRangeInput } from '../ui/DateRangeInput';
import { Alert } from '../ui/alert';
import { etapasIdByName } from '../utils/constants';

import './ReporteProduccionSelector.css';

function ReporteProduccionSelectorView({
  history,
  topAlert,
  modelGetReporteProduccion,
  usuariosLoadStatus,
  usuarioOption,
  usuariosOptions,
  setUsuarioOption,
  inicio,
  setInicio,
  fin,
  setFin,
  submit,
  reporteProduccion,
}) {
  return (
    <Content
      title="Reporte de producción"
      subTitle="Selector"
      browserTitle="Reporte de producción: selector"
      show={false}
    >
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Loader isLoading={usuariosLoadStatus !== 'LOADED'}>
            <Box title="Filtro">
              <SelectInput
                label="Selector"
                hint="Busca oportunidades a cargo del selector"
                value={usuarioOption}
                options={usuariosOptions}
                setValue={setUsuarioOption}
                disabled={usuariosLoadStatus !== 'LOADED'}
                isLoading={usuariosLoadStatus !== 'LOADED'}
              />

              <div className="form-group">
                <label>Fecha</label>

                <DateRangeInput
                  showLabels
                  labelStyle={{ fontWeight: 'normal' }}
                  inicio={inicio}
                  setInicio={setInicio}
                  fin={fin}
                  setFin={setFin}
                  xs={12}
                  md={12}
                />
              </div>

              <div style={{ float: 'right' }}>
                <div style={{ display: 'inline-block', marginRight: 10 }}>
                  <Button
                    type="primary"
                    text="Filtrar"
                    onClick={() => submit()}
                    disabled={!usuarioOption?.value || !inicio || !fin}
                  />
                </div>
              </div>
            </Box>
          </Loader>
        </Col>
      </Row>

      {reporteProduccion?.idsOportunidades?.length > 0 && (
        <Row>
          <Col xs="12" md="10" mdOffset="1">
            <h3>Resumen</h3>
            <Box>
              <div style={{ marginLeft: 50, marginRight: 50 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="contenedor">
                    <div className="cuadro">
                      <div className="titulo">Cantidad de oportunidades</div>
                      <div className="numero">
                        {reporteProduccion.idsOportunidades.length}
                      </div>
                    </div>
                    <div className="cuadro">
                      <div className="titulo">Promedio de efectividad</div>
                      <div className="numero">
                        {reporteProduccion.promedioEfectividad}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Col>
        </Row>
      )}

      {reporteProduccion?.idsOportunidades?.length > 0 && (
        <Row>
          <Col xs="12" md="10" mdOffset="1">
            <h3>Producción por oportunidad</h3>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs="12" md="10" mdOffset="1">
          <Loader isLoading={modelGetReporteProduccion.loading}>
            {reporteProduccion?.idsOportunidades?.length > 0 &&
              reporteProduccion.idsOportunidades.map((oportunidadId) => {
                const datosAvancesPorEtapa =
                  reporteProduccion?.cantAvancesPorSelectorYEtapa?.[
                    oportunidadId
                  ];

                const contAvancesPorEtapa =
                  datosAvancesPorEtapa?.conteoPorEtapa;

                const cantidadReclutamientoSelector =
                  contAvancesPorEtapa?.[etapasIdByName.reclutamiento] || 0;

                const cantAvnReclutPorOport =
                  reporteProduccion?.cantAvancesReclutamientoPorOport?.[
                    oportunidadId
                  ];

                const cantidadReclutamientoPortal =
                  cantAvnReclutPorOport?.portal || 0;

                const cantidadReclutamientoInterno =
                  cantAvnReclutPorOport?.interno || 0;

                const cantidadReclutamientoTotal =
                  cantidadReclutamientoPortal + cantidadReclutamientoInterno;

                const cantidadPrimerFiltro =
                  contAvancesPorEtapa?.[etapasIdByName.primerFiltro] || 0;

                const efectividadFiltro =
                  datosAvancesPorEtapa?.porcentajeEfectividadFiltro || 0;

                const cantidadEntrevista =
                  reporteProduccion?.cantEntrevistasSelectorPorOport?.[
                    oportunidadId
                  ] || 0;

                const cantidadCierre =
                  contAvancesPorEtapa?.[etapasIdByName.cierre] || 0;

                const efectividadEntrevista =
                  reporteProduccion?.porcEfectEntrPorOport?.[oportunidadId] ||
                  0;

                return (
                  <Box
                    title={`${reporteProduccion?.oportunidadesById?.[oportunidadId]?.titulo} - ${reporteProduccion?.oportunidadesById?.[oportunidadId]?.empresaDescripcion}`}
                  >
                    <div style={{ marginLeft: 50, marginRight: 50 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="contenedor">
                          <div className="cuadro">
                            <div className="titulo">Reclutamiento</div>
                            <div className="numero chico">
                              {cantidadReclutamientoTotal} total
                            </div>
                            <div className="numero chico">
                              {cantidadReclutamientoPortal} portal
                            </div>
                            <div className="numero chico">
                              {cantidadReclutamientoSelector} selector
                            </div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Primer filtro</div>
                            <div className="numero">{cantidadPrimerFiltro}</div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Efectividad</div>
                            <div className="numero">{efectividadFiltro}%</div>
                          </div>
                        </div>
                        <div className="contenedor">
                          <div className="cuadro">
                            <div className="titulo">Entrevista</div>
                            <div className="numero">{cantidadEntrevista}</div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Cierre</div>
                            <div className="numero">{cantidadCierre}</div>
                          </div>
                          <div className="cuadro">
                            <div className="titulo">Efectividad</div>
                            <div className="numero">
                              {efectividadEntrevista}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                );
              })}
          </Loader>
        </Col>
      </Row>
    </Content>
  );
}

export { ReporteProduccionSelectorView };
