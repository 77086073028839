import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import Chart from 'chart.js/auto';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { errorMessageSelector, responseSelector } from '../../utils/utils';
import { Alert, alertTypes, useAlerts } from '../../ui/alert';
import { emCandidatosConteoGetDuck, emCandidatosConteoGetThunk } from '../duck';
import config from '../../config';
import moment from 'moment';
import Loader from '../../utils/Loader';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { SelectInput } from '../../utils/SelectInput';

const baseUrl = config.api.baseUrl;

// Define the custom plugin
const dataValuePlugin = {
  id: 'dataValuePlugin',
  afterDatasetsDraw(chart, args, options) {
    const { ctx } = chart;
    chart.data.datasets.forEach((dataset, i) => {
      // Calculate the total of all dataset values
      const total = dataset.data.reduce((acc, value) => acc + value, 0);
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // Calculate position for the text
          const position = element.tooltipPosition();
          // Set text options
          ctx.fillStyle = options.textColor || 'black';
          const fontSize = options.fontSize || '12';
          ctx.font = `${fontSize}px Arial`;
          ctx.textAlign = 'center';

          ctx.textBaseline = 'middle';

          const value = dataset.data[index];

          // Calculate percentage
          const percentage = ((value / total) * 100).toFixed();
          // Get the data to display
          const dataString = `${percentage}%`;
          // Draw the text
          ctx.fillText(dataString, position.x, position.y);
        });
      }
    });
  },
};

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

const columnaOptions = [
  { value: 'empresaDescripcion', label: 'Empresa' },
  { value: 'nombre', label: 'Nombre' },
  { value: 'cargo', label: 'Cargo' },
  { value: 'rubro', label: 'Rubro' },
  { value: 'notaFavorita', label: 'Nota favorita' },
  { value: 'condicionesActuales', label: 'Condiciones actuales' },
  { value: 'condicionesContratacion', label: 'Condiciones contratación' },
  { value: 'resumen', label: 'Resumen' },
];

const handleExcelDownload = async ({ formData }) => {
  // Upload all files in a single request

  const response = await axios.post(
    `${baseUrl}/v1/estudio/candidatos/export/excel`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob', // Ensure the response is treated as a Blob
    }
  );

  let filename = `EM ${moment().format('DD-MM-YYYY')}.xlsx`;

  // Create a Blob from the PDF Stream
  const file = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create an URL for the blob
  const fileURL = URL.createObjectURL(file);

  // Create a temp <a> tag to download file
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename); // Set the file name
  document.body.appendChild(fileLink);

  fileLink.click();

  // Clean up
  document.body.removeChild(fileLink);
  URL.revokeObjectURL(fileURL);
};

const handlePdfDownload = async ({ formData }) => {
  // Upload all files in a single request

  const response = await axios.post(
    `${baseUrl}/v1/estudio/candidatos/export/pdf`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob', // Ensure the response is treated as a Blob
    }
  );

  let filename = `EM ${moment().format('DD-MM-YYYY')}.pdf`;

  // Create a Blob from the PDF Stream
  const file = new Blob([response.data], {
    type: 'application/pdf',
  });

  // Create an URL for the blob
  const fileURL = URL.createObjectURL(file);

  // Create a temp <a> tag to download file
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename); // Set the file name
  document.body.appendChild(fileLink);

  fileLink.click();

  // Clean up
  document.body.removeChild(fileLink);
  URL.revokeObjectURL(fileURL);
};

const makeRequest = async ({ tipoExportacion, formData }) => {
  if (tipoExportacion === 'excel') {
    return await handleExcelDownload({ formData });
  } else if (tipoExportacion === 'pdf') {
    return await handlePdfDownload({ formData });
  }
};

const ModalExportar = ({
  token,
  estudioMercadoIdNumerico,
  isModalShown,
  hideModal,
}) => {
  const { topAlert, setTopAlert, setErrorTopAlert } = useAlerts();

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const dispatch = useDispatch();

  const emCandidatosConteoGet = useSelector(
    (state) => state.emCandidatosConteoGet
  );

  const [data, setData] = useState({
    labels: [],
    values: [],
  });
  const [uploadStatus, setUploadStatus] = useState();
  const [columnaOption, setColumnaOption] = useState([
    { value: 'rubro', label: 'Rubro' },
    { value: 'empresaDescripcion', label: 'Empresa' },
    { value: 'cargo', label: 'Cargo' },
    { value: 'notaFavorita', label: 'Nota favorita' },
    { value: 'condicionesActuales', label: 'Condiciones actuales' },
    { value: 'condicionesContratacion', label: 'Condiciones contratación' },
    { value: 'resumen', label: 'Resumen' },
  ]);
  const [tipoExportacionOption, setTipoExportacionOption] = useState({
    value: 'excel',
    label: 'Excel',
  });

  useEffect(() => {
    dispatch(
      emCandidatosConteoGetThunk({
        userToken: token.raw,
        estudioMercadoIdNumerico,
      })
    );
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current === null) {
      return;
    }

    const ctx = chartRef.current.getContext('2d');

    chartInstance.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: 'Pie Chart',
            data: data.values,
            backgroundColor: data.backgroundColor,
            borderWidth: 0,
          },
        ],
      },
      options: {
        animation: false,
        plugins: {
          // Register the plugin
          dataValuePlugin: {
            textColor: '#000', // Set text color
            fontSize: '12', // Set font size
          },
          legend: {
            display: false,
          },
        },
      },
      plugins: [dataValuePlugin],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  useEffect(() => {
    const { data, error, errorMessage } = responseSelector(
      emCandidatosConteoGet
    );

    if (error) {
      setErrorTopAlert(errorMessage);
      dispatch(emCandidatosConteoGetDuck.actions.clear());
    }

    if (data) {
      setData(data.conteo);
      dispatch(emCandidatosConteoGetDuck.actions.clear());
    }
  }, [emCandidatosConteoGet]);

  const handleDownload = async () => {
    const chartData = chartRef.current.toDataURL();

    await submit({
      dataUri: chartData,
      fields: columnaOption.map((columna) => columna.value),
    });
  };

  const submit = async ({ fields, dataUri }) => {
    try {
      setUploadStatus('LOADING');

      const params = {
        userToken: token.raw,
        estudioMercadoIdNumerico,
        fields,
      };

      // Create a FormData instance
      const formData = new FormData();

      const blob = dataURIToBlob(dataUri);

      formData.append('files[]', blob, 'grafico.png');

      // Append params to the FormData instance

      Object.keys(params).forEach((key) => {
        if (params[key] === undefined || params[key] === null) {
          return;
        }
        formData.append(key, params[key]);
      });

      await makeRequest({
        tipoExportacion: tipoExportacionOption.value,
        formData,
      });

      setTopAlert({
        title: 'Éxito',
        text: 'Procesado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    } catch (error) {
      console.log(error);

      let errorToShow = 'Ocurrió un error al procesar la solicitud';

      const errorMessage = errorMessageSelector({ error }, errorToShow);

      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
    } finally {
      setUploadStatus('IDLE');
    }
  };

  return (
    <Modal show={isModalShown} style={{ opacity: 1 }} onHide={hideModal}>
      <Modal.Header>
        <Modal.Title>Exportar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader
          isLoading={
            uploadStatus === 'LOADING' || emCandidatosConteoGet.loading
          }
        >
          {topAlert.text && (
            <Alert
              title={topAlert.title}
              type={topAlert.type}
              text={topAlert.text}
              closable={topAlert.closable}
            />
          )}

          <SelectInput
            label="Columnas"
            value={columnaOption}
            options={columnaOptions}
            setValue={setColumnaOption}
            isMulti
          />

          <SelectInput
            label="Formato"
            value={tipoExportacionOption}
            options={[
              { value: 'pdf', label: 'PDF' },
              { value: 'excel', label: 'Excel' },
            ]}
            setValue={setTipoExportacionOption}
          />
        </Loader>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: 230 }}>
            <canvas ref={chartRef} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonWithLoading
            type="default"
            text="Volver"
            submit={() => hideModal()}
          />

          <ButtonWithLoading
            type="primary"
            text="Exportar"
            disabled={
              uploadStatus === 'LOADING' ||
              emCandidatosConteoGet.loading ||
              !tipoExportacionOption?.value
            }
            loading={
              uploadStatus === 'LOADING' || emCandidatosConteoGet.loading
            }
            submit={handleDownload}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export { ModalExportar };
