import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getContactoCandidatosDuck,
  getContactoCandidatosThunk,
  getNotasDuck,
  getNotasThunk,
  crearNotaDuck,
  crearNotaThunk,
  updateNotaThunk,
  updateNotaDuck,
  deleteNotaDuck,
  deleteNotaThunk,
  getCvDuck,
  getCvThunk,
  notaFavoritaSetDuck,
  notaFavoritaSetThunk,
} from './duck';

import { responseSelector, parseJwt, sumarDias, getDate } from '../utils/utils';

import { ContactoCandidatosView } from './ContactoCandidatosView';
import moment from 'moment';

const queryString = require('query-string');

function ContactoCandidatos({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetCV = useSelector((state) => state.getCv);
  const modelCandidatos = useSelector((state) => state.getContactoCandidatos);
  const modelNotas = useSelector((state) => state.getNotas);
  const modelCrearNota = useSelector((state) => state.crearNota);
  const modelUpdateNota = useSelector((state) => state.updateNota);
  const modelDeleteNota = useSelector((state) => state.deleteNota);

  const notaFavoritaSet = useSelector((state) => state.notaFavoritaSet);

  const [candidatos, setCandidatos] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isModalAgregarShown, setIsModalAgregarShown] = useState(false);

  const [notaSelected, setNotaSelected] = useState('');
  const [isModalNotasShown, setIsModalNotasShown] = useState();
  const [notas, setNotas] = useState([]);
  const [candidatoIdNumerico, setCandidatoIdNumerico] = useState();
  const [totalResultsNotas, setTotalResultsNotas] = useState(0);
  const [activePageNotas, setActivePageNotas] = useState(0);
  const [pageSizeNotas, setPageSizeNotas] = useState(5);

  const [titulo, setTitulo] = useState();
  const [userIdNumerico, setUserIdNumerico] = useState();
  const [contenido, setContenido] = useState();
  const [cuentaFiltroChecked, setCuentaFiltroChecked] = useState(true);
  const [entrevistaCortesiaChecked, setEntrevistaCortesiaChecked] =
    useState(false);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const minDate = sumarDias(new Date(), -8);

  const dateForInicio = parsedQueryParams?.creacionTsDesde
    ? moment(parsedQueryParams?.creacionTsDesde).toDate()
    : new Date(minDate);

  const [inicio, setInicio] = useState(dateForInicio);

  const dateForFin = parsedQueryParams?.creacionTsHasta
    ? moment(parsedQueryParams?.creacionTsHasta).toDate()
    : new Date();

  const [fin, setFin] = useState(dateForFin);

  useEffect(() => {
    if (token?.raw) {
      const decodedToken = parseJwt(token.raw);
      setUserIdNumerico(decodedToken?.idNumerico);
    }
  }, [token]);

  const hideModalNotas = () => {
    setCandidatoIdNumerico(null);
    setNotaSelected(null);
    setIsModalNotasShown(false);
  };
  const showModalNotas = () => {
    setIsModalNotasShown(true);
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCandidatos);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getContactoCandidatosDuck.actions.clear());
    }

    if (data) {
      setCandidatos(data.items);
      setTotalResults(data.total);

      dispatch(getContactoCandidatosDuck.actions.clear());
    }
  }, [modelCandidatos]);

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(notaFavoritaSet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(notaFavoritaSetDuck.actions.clear());
    }

    if (httpStatus) {
      handlePageNotas(activePageNotas);

      dispatch(notaFavoritaSetDuck.actions.clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notaFavoritaSet]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelNotas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasDuck.actions.clear());
    }

    if (data) {
      setNotas(data.items);
      setTotalResultsNotas(data.total);
      showModalNotas();

      dispatch(getNotasDuck.actions.clear());
    }
  }, [modelNotas]);

  const resetNotaInputs = () => {
    setTitulo('');
    setContenido('');
    setCuentaFiltroChecked(true);
    setEntrevistaCortesiaChecked(false);
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(crearNotaDuck.actions.clear());
    }

    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota creada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearNotaDuck.actions.clear());
    }
  }, [modelCrearNota]);

  const getCV = (idNumerico) => {
    dispatch(
      getCvThunk({
        idNumerico,
        userToken: token.raw,
      })
    );
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCV);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(getCvDuck.actions.clear());
    }
    if (data) {
      window.open(data, '_blank');
      dispatch(getCvDuck.actions.clear());
    }
  }, [modelGetCV]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(deleteNotaDuck.actions.clear());
    }
    if (data) {
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(deleteNotaDuck.actions.clear());
    }
  }, [modelDeleteNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUpdateNota);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(activePage);
      dispatch(updateNotaDuck.actions.clear());
    }
    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      handlePage(activePage);
      setTopAlert({
        text: 'Nota actualizada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
  }, [modelUpdateNota]);

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    setCandidatos([]);

    const params = {
      page: page + 1,
      limit: pageSize,
      fechaInicio: inicio ? getDate(inicio) : null,
      fechaFin: fin ? getDate(fin) : null,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getContactoCandidatosThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  useEffect(() => {
    if (candidatoIdNumerico) {
      handlePageNotas(0);
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    if (notaSelected) {
      setTitulo(notaSelected.titulo);
      setContenido(notaSelected.contenido);
      setCandidatoIdNumerico(notaSelected.candidatoIdNumerico);
      setCuentaFiltroChecked(notaSelected.cuentaFiltro);
      setEntrevistaCortesiaChecked(notaSelected.entrevistaCortesia);
    }
  }, [notaSelected]);

  function handlePageNotas(page) {
    setActivePageNotas(page);

    setNotas([]);

    const params = {
      page: page + 1,
      limit: pageSizeNotas,
      candidatoIdNumerico,
    };

    dispatch(
      getNotasThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function hideModalAgregar() {
    setIsModalAgregarShown(false);
  }

  function showModalAgregar() {
    setIsModalAgregarShown(true);
  }

  function submitNota() {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });
    if (!titulo || !contenido) {
      setTopAlert({
        text: 'La nota debe incluir titulo y contenido',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }
    setNotas([]);
    setCandidatos([]);
    if (!notaSelected) {
      dispatch(
        crearNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
        })
      );
    } else {
      dispatch(
        updateNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          idNumerico: notaSelected.idNumerico,
          creacionUsuario: notaSelected.creacionUsuario,
          cuentaFiltro: cuentaFiltroChecked,
          entrevistaCortesia: entrevistaCortesiaChecked,
        })
      );
    }
  }

  function deleteNota(idNumerico, creacionUsuario) {
    setTopAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    dispatch(
      deleteNotaThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        idNumerico: idNumerico,
        creacionUsuario: creacionUsuario,
      })
    );
  }

  function setearNotaFavorita({ notaIdNumerico, candidatoIdNumerico, setear }) {
    dispatch(
      notaFavoritaSetThunk({
        userToken: token.raw,
        notaIdNumerico,
        candidatoIdNumerico,
        setear,
      })
    );
  }

  return (
    <ContactoCandidatosView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      candidatos={candidatos}
      modelCandidatos={modelCandidatos}
      isModalAgregarShown={isModalAgregarShown}
      hideModalAgregar={hideModalAgregar}
      showModalAgregar={showModalAgregar}
      isModalNotasShown={isModalNotasShown}
      hideModalNotas={hideModalNotas}
      showModalNotas={showModalNotas}
      notas={notas}
      totalResultsNotas={totalResultsNotas}
      activePageNotas={activePageNotas}
      pageSizeNotas={pageSizeNotas}
      candidatoIdNumerico={candidatoIdNumerico}
      setCandidatoIdNumerico={setCandidatoIdNumerico}
      handlePageNotas={handlePageNotas}
      setTitulo={setTitulo}
      titulo={titulo}
      setContenido={setContenido}
      contenido={contenido}
      submitNota={submitNota}
      notaSelected={notaSelected}
      setNotaSelected={setNotaSelected}
      userIdNumerico={userIdNumerico}
      deleteNota={deleteNota}
      cuentaFiltroChecked={cuentaFiltroChecked}
      setCuentaFiltroChecked={setCuentaFiltroChecked}
      entrevistaCortesiaChecked={entrevistaCortesiaChecked}
      setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
      resetNotaInputs={resetNotaInputs}
      getCV={getCV}
      inicio={inicio}
      fin={fin}
      setInicio={setInicio}
      setFin={setFin}
      notaFavoritaSet={notaFavoritaSet}
      setearNotaFavorita={setearNotaFavorita}
    />
  );
}

export { ContactoCandidatos };
