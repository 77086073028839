import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { alertTypes, useAlerts } from '../ui/alert';
import {
  constants,
  getDate,
  getDateOrEmpty,
  responseSelector,
  sumarDias,
} from '../utils/utils';
import { log } from '../utils/logs';

import {
  getReporteProduccionSupervisorDuck,
  getReporteProduccionSupervisorThunk,
} from './duck';
import { ReporteProduccionSupervisorView } from './ReporteProduccionSupervisorView';
import { getUsuariosDuck, getUsuariosThunk } from '../oportunidades/duck';

const queryString = require('query-string');

function ReporteProduccionSupervisor({ history, match, test, token }) {
  const queryParams = history?.location?.search;
  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetReporteProduccionSupervisor = useSelector(
    (state) => state.getReporteProduccionSupervisor
  );
  const modelUsuarios = useSelector((state) => state.getUsuarios);
  const [usuariosLoadStatus, setUsuariosLoadStatus] = useState('IDLE');
  const [usuariosOptions, setUsuariosOptions] = useState();
  const [usuarioOption, setUsuarioOption] = useState(
    parsedQueryParams?.selectorId
      ? { value: parsedQueryParams?.selectorId, label: '...' }
      : null
  );
  const [reporteProduccion, setReporteProduccion] = useState();

  const minDate = sumarDias(new Date(), -30);

  const dateForInicio = parsedQueryParams?.creacionTsDesde
    ? moment(parsedQueryParams?.creacionTsDesde).toDate()
    : new Date(minDate);

  const [inicio, setInicio] = useState(dateForInicio);

  const dateForFin = parsedQueryParams?.creacionTsHasta
    ? moment(parsedQueryParams?.creacionTsHasta).toDate()
    : new Date();

  const [fin, setFin] = useState(dateForFin);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  const prepareUsuariosOptions = (usuarios) => {
    const mUsuariosOptions =
      usuarios &&
      usuarios.map((usuario) => ({
        value: usuario?.idNumerico,
        label: usuario?.nombre,
      }));

    return mUsuariosOptions;
  };

  useEffect(() => {
    setUsuariosLoadStatus('LOADING');
    dispatch(
      getUsuariosThunk({
        userToken: token.raw,
      })
    );
    if (usuarioOption?.value && inicio && fin) {
      submit();
    }
  }, []);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUsuarios);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getUsuariosDuck.actions.clear());
    }

    if (data) {
      log({ 'modelUsuarios.useEffect.data': data });

      const mUsuariosOptions = prepareUsuariosOptions(data.items);
      log({ mUsuariosOptions });

      setUsuariosOptions(mUsuariosOptions);

      if (
        parsedQueryParams?.selectorId &&
        parsedQueryParams?.selectorId !== 'todos'
      ) {
        log('usuarioEnUrl');

        const usuarioSeleccionado = mUsuariosOptions.find(
          (usuarioOption) => usuarioOption.value == parsedQueryParams?.selectorId
        );

        if (usuarioSeleccionado) {
          log('usuarioEnUrlEncontrado');

          setUsuarioOption(usuarioSeleccionado);
        } else {
          log('usuarioEnUrlDesconocido');

          setUsuarioOption({
            value: parsedQueryParams?.selectorId,
            label: 'Desconocido',
          });
        }
      } else {
        log('noUsuarioEnUrl');
        setUsuarioOption(null);
      }

      setUsuariosLoadStatus('LOADED');
      dispatch(getUsuariosDuck.actions.clear());
    }
  }, [modelUsuarios]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetReporteProduccionSupervisor);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getReporteProduccionSupervisorDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log({ 'modelGetReporteProduccionSupervisor.useEffect.data': data });
      setReporteProduccion(data);
      dispatch(getReporteProduccionSupervisorDuck.actions.clear());
    }
  }, [modelGetReporteProduccionSupervisor]);

  function submit() {
    const params = {
      selectorId: usuarioOption?.value,
      fechaInicio: getDateOrEmpty(inicio),
      fechaFin: getDateOrEmpty(fin),
    };

    log({ 'submit.params': params });

    let newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getReporteProduccionSupervisorThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  return (
    <ReporteProduccionSupervisorView
      history={history}
      topAlert={topAlert}
      modelGetReporteProduccionSupervisor={modelGetReporteProduccionSupervisor}
      usuariosLoadStatus={usuariosLoadStatus}
      usuarioOption={usuarioOption}
      usuariosOptions={usuariosOptions}
      setUsuarioOption={setUsuarioOption}
      inicio={inicio}
      setInicio={setInicio}
      fin={fin}
      setFin={setFin}
      submit={submit}
      reporteProduccion={reporteProduccion}
    />
  );
}

export { ReporteProduccionSupervisor };
