import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';
import { log } from '../utils/logs';
import {
  estudiosMercadoGeneralesGetDuck,
  estudiosMercadoGeneralesGetThunk,
  estudiosMercadoEspecificosGetDuck,
  estudiosMercadoEspecificosGetThunk,
  estudioMercadoGeneralDeleteDuck,
  estudioMercadoGeneralDeleteThunk,
  estudioMercadoEspecificoDeleteDuck,
  estudioMercadoEspecificoDeleteThunk,
} from './duck';
import { ListaEstudiosMercadoView } from './ListaEstudiosMercadoView';
import { responseSelector } from '../utils/utils';
import { useQueryParams } from '../utils/useQueryParams';

const queryString = require('query-string');

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const objectsAreEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  const keys1 = obj1 ? Object.keys(obj1) : [];
  const keys2 = obj2 ? Object.keys(obj2) : [];

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
};

function ListaEstudiosMercado({ history, match, token }) {
  const dispatch = useDispatch();

  const { topAlert, setTopAlert, setErrorTopAlert } = useAlerts();

  const [busquedaRapidaEmG, setBusquedaRapidaEmG] = useState('');
  const [busquedaRapidaEmE, setBusquedaRapidaEmE] = useState('');

  const [estudiosMercadoGenerales, setEstudiosMercadoGenerales] = useState([]);
  const [estudiosMercadoEspecificos, setEstudiosMercadoEspecificos] = useState(
    []
  );

  const [totalResultsEmG, setTotalResultsEmG] = useState(0);
  const [totalResultsEmE, setTotalResultsEmE] = useState(0);

  const [pageSizeEmG, setPageSizeEmG] = useState(10);
  const [pageSizeEmE, setPageSizeEmE] = useState(10);

  const [pageEmG, setPageEmG] = useState(1);
  const [pageEmE, setPageEmE] = useState(1);

  const [filtersEmE, setFiltersEmE] = useState({});
  const [filtersEmG, setFiltersEmG] = useState({});

  const prevfiltersEmG = usePrevious(filtersEmG);
  const prevfiltersEmE = usePrevious(filtersEmE);

  const [selectedEmGeneral, setSelectedEmGeneral] = useState(null);
  const [selectedEmEspecifico, setSelectedEmEspecifico] = useState(null);

  const [selectedEmForEdit, setSelectedEmForEdit] = useState(null);

  const estudiosMercadoGeneralesGet = useSelector(
    (state) => state.estudiosMercadoGeneralesGet
  );
  const estudiosMercadoEspecificosGet = useSelector(
    (state) => state.estudiosMercadoEspecificosGet
  );
  const estudioMercadoGeneralDelete = useSelector(
    (state) => state.estudioMercadoGeneralDelete
  );
  const estudioMercadoEspecificoDelete = useSelector(
    (state) => state.estudioMercadoEspecificoDelete
  );

  const { queryParams, queryParamsloaded } = useQueryParams(history);

  const _getFiltersFromQueryParams = (queryParams) => {
    return {
      filtersEmG: queryParams.filtersEmG
        ? JSON.parse(queryParams.filtersEmG)
        : {},
      filtersEmE: queryParams.filtersEmE
        ? JSON.parse(queryParams.filtersEmE)
        : {},
    };
  };

  useEffect(() => {
    if (!queryParamsloaded) return;
    // Si no tenemos queryParams _getFiltersFromQueryParams
    // trae los filtros por defecto

    const filters = _getFiltersFromQueryParams(queryParams);

    setFiltersEmG(filters.filtersEmG);
    setFiltersEmE(filters.filtersEmE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (prevfiltersEmG && objectsAreEqual(filtersEmG, prevfiltersEmG)) {
      return;
    }

    setBusquedaRapidaEmG(filtersEmG.busquedaRapida ?? '');
    setPageEmG(filtersEmG.page ? parseInt(filtersEmG.page, 10) : 1);

    getEmG(filtersEmG);
  }, [filtersEmG]);

  useEffect(() => {
    if (prevfiltersEmE && objectsAreEqual(filtersEmE, prevfiltersEmE)) {
      return;
    }

    setBusquedaRapidaEmE(filtersEmE.busquedaRapida ?? '');
    setPageEmE(filtersEmE.page ? parseInt(filtersEmE.page, 10) : 1);

    getEmE(filtersEmE);
  }, [filtersEmE]);

  useEffect(() => {
    const { httpStatus, data, error, errorMessage } = responseSelector(
      estudiosMercadoGeneralesGet
    );

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudiosMercadoGeneralesGetDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setEstudiosMercadoGenerales(data.items);
      setTotalResultsEmG(data.total);

      dispatch(estudiosMercadoGeneralesGetDuck.actions.clear());
    }
  }, [estudiosMercadoGeneralesGet]);

  useEffect(() => {
    const { httpStatus, data, error, errorMessage } = responseSelector(
      estudiosMercadoEspecificosGet
    );

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudiosMercadoEspecificosGetDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setEstudiosMercadoEspecificos(data.items);
      setTotalResultsEmE(data.total);

      dispatch(estudiosMercadoEspecificosGetDuck.actions.clear());
    }
  }, [estudiosMercadoEspecificosGet]);

  useEffect(() => {
    const { httpStatus, data, error, errorMessage } = responseSelector(
      estudioMercadoGeneralDelete
    );

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudioMercadoGeneralDeleteDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setTopAlert({
        text: 'Estudio de mercado eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });
      dispatch(estudioMercadoGeneralDeleteDuck.actions.clear());
      getEmG(filtersEmG);
      setSelectedEmGeneral(null);
    }
  }, [estudioMercadoGeneralDelete]);

  useEffect(() => {
    const { httpStatus, data, error, errorMessage } = responseSelector(
      estudioMercadoEspecificoDelete
    );

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(estudioMercadoEspecificoDeleteDuck.actions.clear());
    }

    if (httpStatus === 200) {
      setTopAlert({
        text: 'Estudio de mercado eliminado correctamente',
        type: alertTypes.success,
        closable: false,
      });
      dispatch(estudioMercadoEspecificoDeleteDuck.actions.clear());
      getEmE(filtersEmE);
      setSelectedEmEspecifico(null);
    }
  }, [estudioMercadoEspecificoDelete]);

  function handlePageEmG(page) {
    setParamsInUrl({ pageEmG: page, pageEmE });
  }

  function handlePageEmE(page) {
    setParamsInUrl({ pageEmE: page, pageEmG });
  }

  function setParamsInUrl({ pageEmE, pageEmG }) {
    const strNewParams = queryString.stringify({
      filtersEmG: JSON.stringify({
        limit: pageSizeEmG,
        page: pageEmG,
        busquedaRapida: busquedaRapidaEmG,
      }),
      filtersEmE: JSON.stringify({
        limit: pageSizeEmE,
        page: pageEmE,
        busquedaRapida: busquedaRapidaEmE,
      }),
    });

    history.replace(`${history.location.pathname}?${strNewParams}`);
  }

  function getEmG(filters) {
    const { page, limit, busquedaRapida } = filters;

    dispatch(
      estudiosMercadoGeneralesGetThunk({
        userToken: token.raw,
        page,
        limit: 10,
        busquedaRapida,
      })
    );
  }

  function getEmE(filters) {
    const { page, limit, busquedaRapida } = filters;

    dispatch(
      estudiosMercadoEspecificosGetThunk({
        userToken: token.raw,
        page,
        limit: 10,
        busquedaRapida,
      })
    );
  }

  function eliminarEstudioMercadoGeneral() {
    dispatch(
      estudioMercadoGeneralDeleteThunk({
        userToken: token.raw,
        estudioMercadoIdNumerico: selectedEmGeneral,
      })
    );
  }

  function eliminarEstudioMercadoEspecifico() {
    dispatch(
      estudioMercadoEspecificoDeleteThunk({
        userToken: token.raw,
        estudioMercadoIdNumerico: selectedEmEspecifico,
      })
    );
  }

  function recargarEstudiosEspecificos() {
    getEmE(filtersEmE);
  }
  function recargarEstudiosGenerales() {
    getEmG(filtersEmG);
  }

  return (
    <ListaEstudiosMercadoView
      history={history}
      topAlert={topAlert}
      estudiosMercadoGeneralesGet={estudiosMercadoGeneralesGet}
      estudiosMercadoEspecificosGet={estudiosMercadoEspecificosGet}
      estudiosMercadoGenerales={estudiosMercadoGenerales}
      estudiosMercadoEspecificos={estudiosMercadoEspecificos}
      selectedEmGeneral={selectedEmGeneral}
      setSelectedEmGeneral={setSelectedEmGeneral}
      selectedEmEspecifico={selectedEmEspecifico}
      setSelectedEmEspecifico={setSelectedEmEspecifico}
      busquedaRapidaEmG={busquedaRapidaEmG}
      setBusquedaRapidaEmG={setBusquedaRapidaEmG}
      handlePageEmG={handlePageEmG}
      pageSizeEmG={pageSizeEmG}
      pageEmG={pageEmG}
      totalResultsEmG={totalResultsEmG}
      busquedaRapidaEmE={busquedaRapidaEmE}
      setBusquedaRapidaEmE={setBusquedaRapidaEmE}
      handlePageEmE={handlePageEmE}
      pageSizeEmE={pageSizeEmE}
      pageEmE={pageEmE}
      totalResultsEmE={totalResultsEmE}
      estudioMercadoGeneralDelete={estudioMercadoGeneralDelete}
      estudioMercadoEspecificoDelete={estudioMercadoEspecificoDelete}
      eliminarEstudioMercadoGeneral={eliminarEstudioMercadoGeneral}
      eliminarEstudioMercadoEspecifico={eliminarEstudioMercadoEspecifico}
      selectedEmForEdit={selectedEmForEdit}
      setSelectedEmForEdit={setSelectedEmForEdit}
      token={token}
      setTopAlert={setTopAlert}
      recargarEstudiosEspecificos={recargarEstudiosEspecificos}
      recargarEstudiosGenerales={recargarEstudiosGenerales}
    />
  );
}

export { ListaEstudiosMercado };
