import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import { getContactoMensajesDuck, getContactoMensajesThunk } from './duck';

import { constants, responseSelector } from '../utils/utils';

import { ListaContactoMensajesView } from './ListaContactoMensajesView';

const queryString = require('query-string');

function ListaContactoMensajes({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelGetContactoMensajes = useSelector(
    (state) => state.getContactoMensajes
  );

  const [lista, setLista] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(
    parsedQueryParams?.activePage || 0
  );
  const [pageSize, setPageSize] = useState(10);
  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetContactoMensajes);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getContactoMensajesDuck.actions.clear());
    }

    if (data) {
      setTotalResults(data.count);
      setLista(data.items);

      dispatch(getContactoMensajesDuck.actions.clear());
    }
  }, [modelGetContactoMensajes]);

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    setLista([]);

    const params = {
      page: page + 1,
      limit: pageSize,
      busquedaRapida,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify({
      ...parsedQueryParams,
      ...params,
    })}`;

    history.replace(newUrl);

    dispatch(
      getContactoMensajesThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  return (
    <ListaContactoMensajesView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      lista={lista}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      modelGetContactoMensajes={modelGetContactoMensajes}
    />
  );
}

export { ListaContactoMensajes };
