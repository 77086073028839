import React from 'react';
import { ButtonWithLoading } from '../../utils/ButtonWithLoading';
import { procesarNota, toTitleCase } from '../../utils/utils';

function UltimaNota({ ultimaNota }) {
  if (!ultimaNota) {
    return null;
  }
  return (
    <>
      <strong>{toTitleCase(ultimaNota.titulo)}</strong>
      <br />
      <p>{procesarNota(ultimaNota)}</p>
    </>
  );
}

export { UltimaNota };
