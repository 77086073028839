import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getReporteProduccionDuck = createDuck(
  'GET_REPORTE_PRODUCCION',
  newActions,
  newReducers
);

const getReporteProduccionThunk = (params) => {
  const url = `${baseUrl}/v1/colaboradores/reporte/selector/produccion`;

  return createThunk(
    axios,
    'get',
    getReporteProduccionDuck.actions,
    url,
    null,
    params
  );
};

const getReporteProduccionSupervisorDuck = createDuck(
  'GET_REPORTE_PRODUCCION_SUPERVISOR',
  newActions,
  newReducers
);

const getNotasReporteProduccionDuck = createDuck(
  'GET_NOTAS_BY_CREADOR',
  newActions,
  newReducers
);

const getReporteProduccionSupervisorThunk = (params) => {
  const url = `${baseUrl}/v1/colaboradores/reporte/supervisor/produccion`;
  return createThunk(
    axios,
    'get',
    getReporteProduccionSupervisorDuck.actions,
    url,
    null,
    params
  );
};

const getNotasReporteProduccionThunk = (params) => {
  const url = `${baseUrl}/v1/colaboradores/reporte/selector/produccion/detalle`;
  return createThunk(
    axios,
    'get',
    getNotasReporteProduccionDuck.actions,
    url,
    null,
    params
  );
};

export {
  getReporteProduccionDuck,
  getReporteProduccionThunk,
  getReporteProduccionSupervisorDuck,
  getReporteProduccionSupervisorThunk,
  getNotasReporteProduccionDuck,
  getNotasReporteProduccionThunk,
};
