import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { getDate, sumarDias, log } from '../utils/utils';
let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getCandidatoDuck = createDuck('GET_CANDIDATO', newActions, newReducers);

const getCandidatoThunk = (params) => {
  const url = `${baseUrl}/v1/candidato`;

  return createThunk(axios, 'get', getCandidatoDuck.actions, url, null, params);
};

const getCandidatoExperienciasDuck = createDuck(
  'GET_CANDIDATO_EXPERIENCIAS',
  newActions,
  newReducers
);

const getCandidatoExperienciasThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/experiencias`;

  return createThunk(
    axios,
    'get',
    getCandidatoExperienciasDuck.actions,
    url,
    null,
    params
  );
};

const getCandidatoFormacionesDuck = createDuck(
  'GET_CANDIDATO_FORMACIONES',
  newActions,
  newReducers
);

const getCandidatoFormacionesThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/formaciones`;

  return createThunk(
    axios,
    'get',
    getCandidatoFormacionesDuck.actions,
    url,
    null,
    params
  );
};

const getCandIdiomasDuck = createDuck(
  'GET_CANDIDATO_IDIOMAS',
  newActions,
  newReducers
);

const getCandIdiomasThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/idiomas`;

  return createThunk(
    axios,
    'get',
    getCandIdiomasDuck.actions,
    url,
    null,
    params
  );
};

const getCargosPotDuck = createDuck(
  'GET_CANDIDATO_CARGOS_POT',
  newActions,
  newReducers
);

const getCargosPotThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/cargos-potenciales`;

  return createThunk(axios, 'get', getCargosPotDuck.actions, url, null, params);
};

const getCandidatosV1Duck = createDuck(
  'GET_CANDIDATOS_V1',
  newActions,
  newReducers
);

const getCandidatosV1Thunk = (params) => {
  const url = `${baseUrl}/v1/candidatos/v1`;

  return createThunk(
    axios,
    'get',
    getCandidatosV1Duck.actions,
    url,
    null,
    params
  );
};

const getCandidatosDuck = createDuck('GET_CANDIDATOS', newActions, newReducers);

const getCandidatosThunk = (params) => {
  const url = `${baseUrl}/v1/candidatos`;

  return createThunk(
    axios,
    'get',
    getCandidatosDuck.actions,
    url,
    null,
    params
  );
};

const searchCandidatosByMobileDuck = createDuck(
  'SEARCH_CANDIDATOS_BY_MOBILE',
  newActions,
  newReducers
);

const searchCandidatosByMobileThunk = (params) => {
  const url = `${baseUrl}/v1/candidatos/searchByMobile`;

  return createThunk(
    axios,
    'get',
    searchCandidatosByMobileDuck.actions,
    url,
    null,
    params
  );
};

const incluirEnOportunidadDuck = createDuck(
  'INCLUIR_EN_OPORTUNIDAD',
  newActions,
  newReducers
);

const incluirEnOportunidadThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/candidato/incluir`;
  return createThunk(
    axios,
    'post',
    incluirEnOportunidadDuck.actions,
    url,
    params
  );
};

const crearCandidatoDuck = createDuck(
  'CREAR_CANDIDATO',
  newActions,
  newReducers
);

const crearCandidatoThunk = (params) => {
  const url = `${baseUrl}/v1/candidato`;
  return createThunk(axios, 'post', crearCandidatoDuck.actions, url, params);
};

const editarCandidatoDuck = createDuck(
  'EDITAR_CANDIDATO',
  newActions,
  newReducers
);

const editarCandidatoThunk = (params) => {
  const url = `${baseUrl}/v1/candidato`;
  return createThunk(axios, 'patch', editarCandidatoDuck.actions, url, params);
};

const crearCargoPotDuck = createDuck(
  'CREAR_CANDIDATO_CARGO_POT',
  newActions,
  newReducers
);

const crearCargoPotThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/cargo-potencial`;
  return createThunk(axios, 'post', crearCargoPotDuck.actions, url, params);
};

const crearCandidatoExpDuck = createDuck(
  'CREAR_CANDIDATO_EXP',
  newActions,
  newReducers
);

const crearCandidatoExpThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/experiencia`;
  return createThunk(axios, 'post', crearCandidatoExpDuck.actions, url, params);
};

const crearCandidatoFormacionDuck = createDuck(
  'CREAR_CANDIDATO_FORMACION',
  newActions,
  newReducers
);

const crearCandidatoFormacionThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/formacion`;
  return createThunk(
    axios,
    'post',
    crearCandidatoFormacionDuck.actions,
    url,
    params
  );
};

const crearCandIdiomaDuck = createDuck(
  'CREAR_CANDIDATO_IDIOMA',
  newActions,
  newReducers
);

const crearCandIdiomaThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/idioma`;
  return createThunk(axios, 'post', crearCandIdiomaDuck.actions, url, params);
};

const eliminarCandidatoExpDuck = createDuck(
  'ELIMINAR_CANDIDATO_EXP',
  newActions,
  newReducers
);

const eliminarCandidatoExpThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/experiencia`;
  return createThunk(
    axios,
    'delete',
    eliminarCandidatoExpDuck.actions,
    url,
    params
  );
};

const eliminarCandFormDuck = createDuck(
  'ELIMINAR_CANDIDATO_FORMACION',
  newActions,
  newReducers
);

const eliminarCandFormThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/formacion`;
  return createThunk(
    axios,
    'delete',
    eliminarCandFormDuck.actions,
    url,
    params
  );
};

const eliminarCandIdiomaDuck = createDuck(
  'ELIMINAR_CANDIDATO_IDIOMA',
  newActions,
  newReducers
);

const eliminarCandIdiomaThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/idioma`;
  return createThunk(
    axios,
    'delete',
    eliminarCandIdiomaDuck.actions,
    url,
    params
  );
};

const eliminarCargoPotDuck = createDuck(
  'ELIMINAR_CANDIDATO_CARGO_POT',
  newActions,
  newReducers
);

const eliminarCargoPotThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/cargo-potencial`;
  return createThunk(
    axios,
    'delete',
    eliminarCargoPotDuck.actions,
    url,
    params
  );
};

const editarCandidatoExpDuck = createDuck(
  'EDITAR_CANDIDATO_EXP',
  newActions,
  newReducers
);

const editarCandidatoExpThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/experiencia`;
  return createThunk(
    axios,
    'patch',
    editarCandidatoExpDuck.actions,
    url,
    params
  );
};

const editarCandFormDuck = createDuck(
  'EDITAR_CANDIDATO_FORMACION',
  newActions,
  newReducers
);

const editarCandFormThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/formacion`;
  return createThunk(axios, 'patch', editarCandFormDuck.actions, url, params);
};

const editarCandIdiomaDuck = createDuck(
  'EDITAR_CANDIDATO_IDIOMA',
  newActions,
  newReducers
);

const editarCandIdiomaThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/idioma`;
  return createThunk(axios, 'patch', editarCandIdiomaDuck.actions, url, params);
};

const editarCargoPotDuck = createDuck(
  'EDITAR_CANDIDATO_CARGO_POT',
  newActions,
  newReducers
);

const editarCargoPotThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/cargo-potencial`;
  return createThunk(axios, 'patch', editarCargoPotDuck.actions, url, params);
};

const getSalidaMotivosDuck = createDuck(
  'GET_SALIDA_MOTIVOS',
  newActions,
  newReducers
);

const getSalidaMotivosThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/experiencia/salida/motivos`;

  return createThunk(
    axios,
    'get',
    getSalidaMotivosDuck.actions,
    url,
    null,
    params
  );
};

const getNotasDuck = createDuck('GET_NOTAS', newActions, newReducers);

const getNotasThunk = (params) => {
  const url = `${baseUrl}/v1/notas`;

  return createThunk(axios, 'get', getNotasDuck.actions, url, null, params);
};

const crearNotaDuck = createDuck('CREAR_NOTA', newActions, newReducers);

const crearNotaThunk = (params) => {
  const url = `${baseUrl}/v1/crearNota`;

  return createThunk(axios, 'post', crearNotaDuck.actions, url, params);
};

const getPaisesDuck = createDuck('GET_PAISES', newActions, newReducers);

const getPaisesThunk = (params) => {
  const url = `${baseUrl}/v1/geografia/paises`;

  return createThunk(axios, 'get', getPaisesDuck.actions, url, null, params);
};

const getRegionesDuck = createDuck('GET_REGIONES', newActions, newReducers);

const getRegionesThunk = (params) => {
  const url = `${baseUrl}/v1/geografia/regiones`;

  return createThunk(axios, 'get', getRegionesDuck.actions, url, null, params);
};

const getCiudadesDuck = createDuck('GET_CIUDADES', newActions, newReducers);

const getCiudadesThunk = (params) => {
  const url = `${baseUrl}/v1/geografia/ciudades`;
  return createThunk(axios, 'get', getCiudadesDuck.actions, url, null, params);
};

const getNacionalidadesDuck = createDuck(
  'GET_NACIONALIDADES',
  newActions,
  newReducers
);

const getNacionalidadesThunk = (params) => {
  const url = `${baseUrl}/v1/nacionalidades`;

  return createThunk(
    axios,
    'get',
    getNacionalidadesDuck.actions,
    url,
    null,
    params
  );
};

const getIdiomasDuck = createDuck('GET_IDIOMAS', newActions, newReducers);

const getIdiomasThunk = (params) => {
  const url = `${baseUrl}/v1/idiomas`;

  return createThunk(axios, 'get', getIdiomasDuck.actions, url, null, params);
};

const updateNotaDuck = createDuck('UPDATE_NOTA', newActions, newReducers);

const updateNotaThunk = (params) => {
  const url = `${baseUrl}/v1/updateNota`;

  return createThunk(axios, 'post', updateNotaDuck.actions, url, params);
};

const deleteNotaDuck = createDuck('DELETE_NOTA', newActions, newReducers);

const deleteNotaThunk = (params) => {
  const url = `${baseUrl}/v1/deleteNota`;

  return createThunk(axios, 'post', deleteNotaDuck.actions, url, params);
};

const borrarOportunidadDuck = createDuck(
  'BORRAR_OPORTUNIDAD',
  newActions,
  newReducers
);

const borrarOportunidadThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad`;

  return createThunk(
    axios,
    'delete',
    borrarOportunidadDuck.actions,
    url,
    null,
    params
  );
};

const getInstitutosDuck = createDuck('GET_INSTITUTOS', newActions, newReducers);

const getInstitutosThunk = (params) => {
  log('getInstitutosThunk');

  const url = `${baseUrl}/v1/institutos`;

  return createThunk(
    axios,
    'get',
    getInstitutosDuck.actions,
    url,
    null,
    params
  );
};

const getTitulacionesDuck = createDuck(
  'GET_TITULACIONES',
  newActions,
  newReducers
);

const getTitulacionesThunk = (params) => {
  const url = `${baseUrl}/v1/titulaciones`;

  return createThunk(
    axios,
    'get',
    getTitulacionesDuck.actions,
    url,
    null,
    params
  );
};

const eliminarFotoDeCandidatoDuck = createDuck(
  'ELIMINAR_FOTO_CANDIDATO',
  newActions,
  newReducers
);

const eliminarFotoDeCandidatoThunk = (params) => {
  const url = `${baseUrl}/v1/eliminarFotoDeCandidato`;

  return createThunk(
    axios,
    'post',
    eliminarFotoDeCandidatoDuck.actions,
    url,
    params
  );
};

const getCandArchivosDuck = createDuck(
  'GET_CAND_ARCHIVOS',
  newActions,
  newReducers
);

const getCandArchivosThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/archivos`;

  return createThunk(
    axios,
    'get',
    getCandArchivosDuck.actions,
    url,
    null,
    params
  );
};

const eliminarCandArchivoDuck = createDuck(
  'ELIMINAR_CAND_ARCHIVO',
  newActions,
  newReducers
);

const eliminarCandArchivoThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/archivo`;

  return createThunk(
    axios,
    'delete',
    eliminarCandArchivoDuck.actions,
    url,
    params
  );
};

const getCvDuck = createDuck('GET_CV', newActions, newReducers);

const getCvThunk = (params) => {
  const url = `${baseUrl}/v1/cvcandidato?idNumerico=${params.idNumerico}&userToken=${params.userToken}`;

  return createThunk(axios, 'get', getCvDuck.actions, url, null, {});
};

const getContactoCandidatosDuck = createDuck(
  'GET_CONTACTO_CANDIDATOS',
  newActions,
  newReducers
);

const getContactoCandidatosThunk = (params) => {
  const url = `${baseUrl}/v1/candidatos/contacto`;

  return createThunk(
    axios,
    'get',
    getContactoCandidatosDuck.actions,
    url,
    null,
    params
  );
};

const editarExpectativaSalarialDuck = createDuck(
  'EDITAR_CANDIDATO_EXPECTATIVA_SALARIAL',
  newActions,
  newReducers
);

const editarExpectativaSalarialThunk = (params) => {
  const url = `${baseUrl}/v1/candidato/expectativaSalarial`;
  return createThunk(
    axios,
    'patch',
    editarExpectativaSalarialDuck.actions,
    url,
    params
  );
};

const notaFavoritaSetDuck = createDuck(
  'NOTA_FAVORITA_SET',
  newActions,
  newReducers
);

const notaFavoritaSetThunk = (params) => {
  const url = `${baseUrl}/v1/nota/favorita`;
  return createThunk(
    axios,
    'post',
    notaFavoritaSetDuck.actions,
    url,
    params
  );
};

export {
  getCandidatosDuck,
  getCandidatosThunk,
  incluirEnOportunidadDuck,
  incluirEnOportunidadThunk,
  crearCandidatoDuck,
  crearCandidatoThunk,
  getCandidatoDuck,
  getCandidatoThunk,
  editarCandidatoDuck,
  editarCandidatoThunk,
  getCandidatoExperienciasDuck,
  getCandidatoExperienciasThunk,
  getCandidatoFormacionesDuck,
  getCandidatoFormacionesThunk,
  crearCandidatoExpDuck,
  crearCandidatoExpThunk,
  crearCandidatoFormacionDuck,
  crearCandidatoFormacionThunk,
  getSalidaMotivosDuck,
  getSalidaMotivosThunk,
  eliminarCandidatoExpDuck,
  eliminarCandidatoExpThunk,
  editarCandidatoExpDuck,
  editarCandidatoExpThunk,
  getCargosPotDuck,
  getCargosPotThunk,
  eliminarCargoPotDuck,
  eliminarCargoPotThunk,
  editarCargoPotDuck,
  editarCargoPotThunk,
  crearCargoPotDuck,
  crearCargoPotThunk,
  getNotasDuck,
  getNotasThunk,
  crearNotaDuck,
  crearNotaThunk,
  updateNotaDuck,
  updateNotaThunk,
  deleteNotaDuck,
  deleteNotaThunk,
  getPaisesDuck,
  getPaisesThunk,
  getRegionesDuck,
  getRegionesThunk,
  getCiudadesDuck,
  getCiudadesThunk,
  getNacionalidadesDuck,
  getNacionalidadesThunk,
  borrarOportunidadDuck,
  borrarOportunidadThunk,
  getInstitutosDuck,
  getInstitutosThunk,
  getTitulacionesDuck,
  getTitulacionesThunk,
  eliminarCandFormDuck,
  eliminarCandFormThunk,
  editarCandFormDuck,
  editarCandFormThunk,
  eliminarFotoDeCandidatoDuck,
  eliminarFotoDeCandidatoThunk,
  editarCandIdiomaDuck,
  editarCandIdiomaThunk,
  crearCandIdiomaDuck,
  crearCandIdiomaThunk,
  eliminarCandIdiomaDuck,
  eliminarCandIdiomaThunk,
  getCandIdiomasDuck,
  getCandIdiomasThunk,
  getIdiomasDuck,
  getIdiomasThunk,
  getCandArchivosDuck,
  getCandArchivosThunk,
  eliminarCandArchivoDuck,
  eliminarCandArchivoThunk,
  getCvDuck,
  getCvThunk,
  getCandidatosV1Duck,
  getCandidatosV1Thunk,
  getContactoCandidatosDuck,
  getContactoCandidatosThunk,
  searchCandidatosByMobileDuck,
  searchCandidatosByMobileThunk,
  editarExpectativaSalarialDuck,
  editarExpectativaSalarialThunk,
  notaFavoritaSetDuck,
  notaFavoritaSetThunk,
};
