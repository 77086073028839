import React from 'react';
import { Content, Row, Col, Box, Button, Inputs } from 'adminlte-2-react';
import { ReferenciaObligatorios } from '../utils/etiquetaObligatorio/ReferenciaObligatorios';
import { Alert } from '../utils/Alert';
import { log } from '../utils/logs';
import { SelectInput } from '../utils/SelectInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Etiqueta from '../utils/etiquetaObligatorio/Etiqueta';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { CheckBox } from '../ui/Checkbox';
import { MonedaInputV2 } from '../ui/moneda';
import { CampoPublicacion } from './CampoPublicacion';
import Loader from '../utils/Loader';
import { ModalGenerico } from '../ui/ModalGenerico';
import { fuentesOptions } from '../utils/constants';
const { TextInput } = require('../utils/TextInput');

function CrearOportunidadView({
  mode,
  history,
  match,
  modelCrearOportunidad,
  empresaOption,
  titulo,
  tituloPublicacion,
  cargoOption,
  rubroOption,
  rubroPublicacion,
  esConfidencial,
  publicarWeb,
  solicitudFecha,
  estadoOption,
  cierreEsperadoFecha,
  observacion,
  empresasOptions,
  setEmpresaOption,
  setTitulo,
  setTituloPublicacion,
  cargosOptions,
  setCargoOption,
  rubrosOptions,
  setRubroOption,
  setRubroPublicacion,
  setEsConfidencial,
  setSolicitudFecha,
  estadosOptions,
  setEstadoOption,
  setCierreEsperadoFecha,
  setObservacion,
  setPublicarWeb,
  etapas,
  handleSelection,
  salarioMinimo,
  setSalarioMinimo,
  salarioMaximo,
  setSalarioMaximo,
  pagoFormaOption,
  setPagoFormaOption,
  pagoFormaOptions,
  empleoTipoOption,
  setEmpleoTipoOption,
  empleoTipoOptions,
  submit,
  submitAlert,
  topAlert,
  aCargo,
  setACargo,
  usuarioOptions,
  usuarioSelected,
  setUsuarioSelected,
  rolSelected,
  setRolSelected,
  handleACargo,
  deleteACargo,
  ciudadSelected,
  setCiudadSelected,
  monedaSelected,
  setMonedaSelected,
  proposRef,
  responRef,
  conEspRef,
  conDesRef,
  expReqRef,
  aptitudesRef,
  reqAdRef,
  condContRef,
  muestraPosicion,
  setMuestraPosicion,
  muestraResponsabilidad,
  setMuestraResponsabilidad,
  muestraConocimientoEspecifico,
  setMuestraConocimientoEspecifico,
  muestraConocimientoDeseable,
  setMuestraConocimientoDeseable,
  muestraExperienciaRequerida,
  setMuestraExperienciaRequerida,
  muestraAptitud,
  setMuestraAptitud,
  muestraRequisitoAdicional,
  setMuestraRequisitoAdicional,
  muestraCondicionContratacion,
  setMuestraCondicionContratacion,
  ciudadesOptions,
  proposito,
  responsabilidad,
  conocimientoEspecifico,
  conocimientoDeseable,
  experienciaRequerida,
  aptitud,
  requisitoAdicional,
  condicionContratacion,
  loadOportunidadStatus,
  flyerSubmitAlert,
  flyer,
  oportunidadIdNumerico,
  eliminarFlyer,
  modalRedirect,
  setModalRedirect,
  isModalEliminarFlyerShown,
  setIsModalEliminarFlyerShown,
  oportunidadId,
  modelEliminarOportFlyer,
  modelGetOportFlyer,
  fuenteOption,
  setFuenteOption,
  isModalEliminarRelevamientoShown,
  setIsModalEliminarRelevamientoShown,
  modelEliminarOportRelevamiento,
  eliminarRelevamiento,
  modelGetOportRelevamiento,
  relevamientoSubmitAlert,
  relevamiento,
  creado,
}) {
  const rolOptions = [
    {
      value: 'selector',
      label: 'Selector',
    },
    {
      value: 'supervisor',
      label: 'Supervisor',
    },
  ];

  const aCargoKeys = Object.keys(aCargo);

  const title = mode === 'edit' ? 'Editar oportunidad' : 'Crear oportunidad';

  return (
    <Content title={title} browserTitle={title} show={false}>
      {topAlert.text && (
        <Alert
          title={topAlert.title}
          type={topAlert.type}
          text={topAlert.text}
          closable={topAlert.closable}
        />
      )}

      {modalRedirect && (
        <ModalGenerico
          isModalShown={!!modalRedirect}
          hideModal={() => setModalRedirect(null)}
          title="Alerta"
          body="Si no guardaste los cambios hechos en el formulario, se perderán. ¿Continuar?"
          buttonText="Continuar"
          submit={() => {
            history.push(modalRedirect);
          }}
        />
      )}

      {oportunidadId && isModalEliminarFlyerShown && (
        <ModalGenerico
          isModalShown={isModalEliminarFlyerShown}
          hideModal={() => setIsModalEliminarFlyerShown(false)}
          title="Eliminar permanentemente"
          body={
            <>
              Se eliminará <b>permanentemente</b> el archivo y no se podrá
              recuperar. ¿Continuar?
            </>
          }
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={!oportunidadId}
          buttonLoading={modelEliminarOportFlyer.loading}
          submit={eliminarFlyer}
        />
      )}

      {oportunidadId && isModalEliminarRelevamientoShown && (
        <ModalGenerico
          isModalShown={isModalEliminarRelevamientoShown}
          hideModal={() => setIsModalEliminarRelevamientoShown(false)}
          title="Eliminar permanentemente"
          body={
            <>
              Se eliminará <b>permanentemente</b> el archivo y no se podrá
              recuperar. ¿Continuar?
            </>
          }
          buttonText="Eliminar"
          buttonType="danger"
          buttonDisabled={!oportunidadId}
          buttonLoading={modelEliminarOportRelevamiento.loading}
          submit={eliminarRelevamiento}
        />
      )}

      <Loader isLoading={mode === 'edit' && loadOportunidadStatus !== 'LOADED'}>
        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Datos principales">
              <ReferenciaObligatorios />
              <SelectInput
                label="Empresa"
                value={empresaOption}
                options={empresasOptions}
                setValue={setEmpresaOption}
                required
                disabled={mode === 'edit'}
              />

              <TextInput
                label="Título"
                initialValue={titulo}
                setValue={setTitulo}
                required
              />
              <TextInput
                label="Título a publicar"
                initialValue={tituloPublicacion}
                setValue={setTituloPublicacion}
              />
              <SelectInput
                label="Cargo"
                value={cargoOption}
                options={cargosOptions}
                setValue={setCargoOption}
                required
              />
              <SelectInput
                label="Rubro"
                value={rubroOption}
                options={rubrosOptions}
                setValue={setRubroOption}
                required
              />
              <TextInput
                label="Rubro a publicar"
                initialValue={rubroPublicacion}
                setValue={setRubroPublicacion}
              />
              {/* <div className="form-group">
                <CheckBox
                  checked={esConfidencial}
                  text={'Es confidencial'}
                  onChange={setEsConfidencial}
                />
              </div> */}
              <div className="form-group">
                <CheckBox
                  checked={publicarWeb}
                  text={'Publicar en la web'}
                  onChange={setPublicarWeb}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <label>
                  <Etiqueta texto={'Fecha de solicitud'} obligatorio={true} />
                </label>
                <DatePicker
                  customInput={<input type="text" className="form-control" />}
                  selected={solicitudFecha}
                  onChange={(date) => setSolicitudFecha(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <SelectInput
                label="Estado"
                value={estadoOption}
                options={estadosOptions}
                setValue={setEstadoOption}
                required
              />
              <div className="form-group">
                <label>
                  <Etiqueta texto="Observación" obligatorio={false} />
                </label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={observacion}
                  onChange={(evt) => setObservacion(evt.target.value)}
                />
              </div>

              {mode === 'edit' && (
                <SelectInput
                  label="Fuente"
                  value={fuenteOption}
                  options={[
                    { value: null, label: 'Sin fuente' },
                    ...fuentesOptions,
                  ]}
                  setValue={setFuenteOption}
                />
              )}

              <CampoPublicacion
                titulo="Condiciones de contratación"
                mRef={condContRef}
                publicar={muestraCondicionContratacion}
                setPublicar={setMuestraCondicionContratacion}
                initialValue={condicionContratacion}
                permiteCambiarValorPublicar={false}
                required={true}
              />
            </Box>
          </Col>
        </Row>
        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Rango de salario">
              <MonedaInputV2
                label="Salario mínimo"
                initialValue={salarioMinimo}
                setValue={setSalarioMinimo}
                selectorMonedaDisabled={true}
              />
              <MonedaInputV2
                label="Salario máximo"
                initialValue={salarioMaximo}
                setValue={setSalarioMaximo}
                selectorMonedaDisabled={true}
              />
            </Box>
          </Col>
        </Row>

        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Selectores y supervisores">
              <SelectInput
                label="Usuario"
                value={usuarioSelected}
                options={usuarioOptions}
                setValue={setUsuarioSelected}
                required
              />
              <SelectInput
                label="Rol"
                value={rolSelected}
                options={rolOptions}
                setValue={setRolSelected}
                required
              />
              {aCargoKeys.length > 0 && (
                <>
                  <h4>Usuarios asignados</h4>
                  {aCargoKeys.map((key) => {
                    return (
                      <>
                        <span>Nombre: {aCargo[key]?.name}</span>
                        <br />
                        <span>Rol: {aCargo[key]?.rolLabel}</span>
                        <br />
                        <div style={{ marginTop: 10 }}>
                          <Button
                            type="warning"
                            text="Eliminar"
                            size="sm"
                            onClick={() => deleteACargo(key)}
                          />
                        </div>
                        <br />
                      </>
                    );
                  })}
                </>
              )}

              <Button
                pullRight
                type="default"
                text="Agregar"
                size="sm"
                disabled={!usuarioSelected || !rolSelected}
                onClick={() => handleACargo()}
              />
            </Box>
          </Col>
        </Row>

        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Información adicional">
              <SelectInput
                label="Ciudad"
                value={ciudadSelected}
                options={ciudadesOptions}
                setValue={setCiudadSelected}
                required
              />
              <SelectInput
                label="Forma de pago"
                value={pagoFormaOption}
                options={pagoFormaOptions}
                setValue={setPagoFormaOption}
                required
              />
              <SelectInput
                label="Tipo de empleo"
                value={empleoTipoOption}
                options={empleoTipoOptions}
                setValue={setEmpleoTipoOption}
                required
              />
            </Box>
          </Col>
        </Row>

        {mode === 'edit' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box
                title="Flyer"
                customOptions={
                  <ButtonWithLoading
                    text="Ir a agregar"
                    type="default"
                    size="sm"
                    loading={false}
                    submit={() =>
                      setModalRedirect(
                        `/oportunidad/flyer/${oportunidadIdNumerico}`
                      )
                    }
                  />
                }
              >
                <Loader isLoading={modelGetOportFlyer.loading}>
                  {flyerSubmitAlert.text && (
                    <Alert
                      title={flyerSubmitAlert.title}
                      type={flyerSubmitAlert.type}
                      text={flyerSubmitAlert.text}
                      closable={flyerSubmitAlert.closable}
                    />
                  )}
                  {flyer ? (
                    <>
                      Archivo:{' '}
                      <a href={flyer.url} target="_blank" rel="noreferrer">
                        {flyer.key.split('/').pop()}
                      </a>
                      <br />
                      <br />
                      <div>
                        <div style={{ display: 'inline-block' }}>
                          <ButtonWithLoading
                            text="Eliminar"
                            type="warning"
                            size="sm"
                            loading={modelEliminarOportFlyer.loading}
                            submit={() => setIsModalEliminarFlyerShown(true)}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    'Sin flyer'
                  )}
                </Loader>
              </Box>
            </Col>
          </Row>
        )}

        {mode === 'edit' && (
          <Row>
            <Col md="10" mdOffset="1">
              <Box
                title="Formulario de relevamiento"
                customOptions={
                  <ButtonWithLoading
                    text="Ir a agregar"
                    type="default"
                    size="sm"
                    loading={false}
                    submit={() =>
                      setModalRedirect(
                        `/oportunidad/relevamiento/${oportunidadIdNumerico}`
                      )
                    }
                  />
                }
              >
                <Loader isLoading={modelGetOportRelevamiento.loading}>
                  {relevamientoSubmitAlert.text && (
                    <Alert
                      title={relevamientoSubmitAlert.title}
                      type={relevamientoSubmitAlert.type}
                      text={relevamientoSubmitAlert.text}
                      closable={relevamientoSubmitAlert.closable}
                    />
                  )}
                  {relevamiento ? (
                    <>
                      Archivo:{' '}
                      <a
                        href={relevamiento.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {relevamiento.key.split('/').pop()}
                      </a>
                      <br />
                      <br />
                      <div>
                        <div style={{ display: 'inline-block' }}>
                          <ButtonWithLoading
                            text="Eliminar"
                            type="warning"
                            size="sm"
                            loading={modelEliminarOportRelevamiento.loading}
                            submit={() =>
                              setIsModalEliminarRelevamientoShown(true)
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    'Sin formulario de relevamiento'
                  )}
                </Loader>
              </Box>
            </Col>
          </Row>
        )}

        <Row>
          <Col md="10" mdOffset="1">
            <Box title="Publicación">
              <CampoPublicacion
                titulo="Descripción principal para la web"
                mRef={proposRef}
                publicar={muestraPosicion}
                setPublicar={setMuestraPosicion}
                initialValue={proposito}
              />

              <CampoPublicacion
                titulo="Funciones"
                mRef={responRef}
                publicar={muestraResponsabilidad}
                setPublicar={setMuestraResponsabilidad}
                initialValue={responsabilidad}
              />

              <CampoPublicacion
                titulo="Conocimientos específicos"
                mRef={conEspRef}
                publicar={muestraConocimientoEspecifico}
                setPublicar={setMuestraConocimientoEspecifico}
                initialValue={conocimientoEspecifico}
              />

              <CampoPublicacion
                titulo="Formación académica"
                mRef={conDesRef}
                publicar={muestraConocimientoDeseable}
                setPublicar={setMuestraConocimientoDeseable}
                initialValue={conocimientoDeseable}
              />

              <CampoPublicacion
                titulo="Experiencia requerida"
                mRef={expReqRef}
                publicar={muestraExperienciaRequerida}
                setPublicar={setMuestraExperienciaRequerida}
                initialValue={experienciaRequerida}
              />

              {/* <CampoPublicacion
                titulo="Aptitudes"
                mRef={aptitudesRef}
                publicar={muestraAptitud}
                setPublicar={setMuestraAptitud}
                initialValue={aptitud}
              /> */}

              <CampoPublicacion
                titulo="Requisitos adicionales"
                mRef={reqAdRef}
                publicar={muestraRequisitoAdicional}
                setPublicar={setMuestraRequisitoAdicional}
                initialValue={requisitoAdicional}
              />

              {submitAlert.text && (
                <Alert
                  title={submitAlert.title}
                  type={submitAlert.type}
                  text={submitAlert.text}
                  closable={submitAlert.closable}
                />
              )}

              <ButtonWithLoading
                text="Guardar"
                submit={submit}
                pullRight
                loading={modelCrearOportunidad.loading}
                disabled={modelCrearOportunidad.loading || creado}
              />
            </Box>
          </Col>
        </Row>
      </Loader>
    </Content>
  );
}

export { CrearOportunidadView };
