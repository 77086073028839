import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../../ui/alert';

import { getCandidatosV1Duck, getCandidatosV1Thunk } from '../duck';

import {
  constants,
  responseSelector,
  getAndRemoveFromStorage,
  log,
  toTitleCase,
} from '../../utils/utils';

import { ListaCandidatosNormalizarView } from './ListaCandidatosNormalizarView';

const queryString = require('query-string');

function ListaCandidatosNormalizar({ history, match, test, token }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const dispatch = useDispatch();

  const modelCandidatos = useSelector((state) => state.getCandidatosV1);

  const [candidatos, setCandidatos] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [busquedaRapida, setBusquedaRapida] = useState(
    parsedQueryParams?.busquedaRapida || null
  );

  const { submitAlert, setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    handlePage(0);
  }, []);

  function handlePage(page) {
    setActivePage(page);

    const params = {
      page: page + 1,
      limit: pageSize,
      busquedaRapida,
      normalizado: 'n',
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);

    dispatch(
      getCandidatosV1Thunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCandidatos);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatosV1Duck.actions.clear());
    }

    if (data) {
      setCandidatos(data.items);
      setTotalResults(data.total);

      dispatch(getCandidatosV1Duck.actions.clear());
    }
  }, [modelCandidatos]);

  return (
    <ListaCandidatosNormalizarView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      candidatos={candidatos}
      busquedaRapida={busquedaRapida}
      setBusquedaRapida={setBusquedaRapida}
      modelCandidatos={modelCandidatos}
    />
  );
}

export { ListaCandidatosNormalizar };
