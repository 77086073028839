/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { Row, Col, Box, Button } from 'adminlte-2-react';
import Loader from '../../utils/Loader';

import { Alert } from '../../utils/Alert';

import { ButtonWithLoading } from '../../utils/ButtonWithLoading';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toTitleCase } from '../../utils/utils';
import { idiomaNivelDescById } from '../../utils/constants';

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.candIdiomas === prevProps.candIdiomas &&
    nextProps.idiomaSubmitAlert === prevProps.idiomaSubmitAlert &&
    nextProps.modelGetCandIdiomas.loading ===
      prevProps.modelGetCandIdiomas.loading &&
    nextProps.idiomaById === prevProps.idiomaById
  );
};

const Idiomas = memo(function Componente({
  setIsModalAgregarCandIdiomaShown,
  idiomaSubmitAlert,
  modelGetCandIdiomas,
  candIdiomas,
  idiomaById,
  setSelectedCandIdioma,
  setIsModalEliminarCandIdiomaShown,
  prepareCandIdiomaFormEditAndShowModal,
}) {
  return (
    <Row>
      <Col md="10" mdOffset="1">
        <Box
          title="Idiomas"
          customOptions={
            <div>
              <Button
                size="sm"
                type="default"
                text="Agregar"
                onClick={() => setIsModalAgregarCandIdiomaShown(true)}
              />
            </div>
          }
        >
          {idiomaSubmitAlert.text && (
            <Alert
              title={idiomaSubmitAlert.title}
              type={idiomaSubmitAlert.type}
              text={idiomaSubmitAlert.text}
              closable={idiomaSubmitAlert.closable}
            />
          )}

          <Loader isLoading={modelGetCandIdiomas.loading || !idiomaById}>
            <div style={{ display: 'block' }}>
              <span>Total: {candIdiomas?.length}</span>
            </div>

            {candIdiomas && candIdiomas.length > 0 ? (
              <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
                <div className="table-responsive">
                  <DataTable
                    lazy
                    pt={{
                      table: {
                        className: 'table table-hover',
                      },
                    }}
                    value={candIdiomas}
                    emptyMessage="No se encontró idiomas"
                  >
                    <Column
                      field="acciones"
                      header="Acciones"
                      body={(idioma) => (
                        <div style={{ width: 130 }}>
                          <div
                            style={{
                              display: 'inline-block',
                              marginRight: 5,
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="warning"
                              text="Eliminar"
                              submit={() => {
                                setSelectedCandIdioma(idioma);
                                setIsModalEliminarCandIdiomaShown(true);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <ButtonWithLoading
                              size="sm"
                              type="default"
                              text="Editar"
                              submit={() => {
                                setSelectedCandIdioma(idioma);
                                prepareCandIdiomaFormEditAndShowModal(idioma);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="idiomaDescripcion"
                      header="Idioma"
                      body={(idioma) =>
                        toTitleCase(
                          idiomaById[idioma.idiomaIdNumerico]?.descripcion ??
                            'N/A'
                        )
                      }
                    ></Column>
                    <Column
                      field="lee"
                      header="Lee"
                      body={(idioma) =>
                        toTitleCase(idiomaNivelDescById[idioma.lee])
                      }
                    ></Column>
                    <Column
                      field="escribe"
                      header="Escribe"
                      body={(idioma) =>
                        toTitleCase(idiomaNivelDescById[idioma.escribe])
                      }
                    ></Column>
                    <Column
                      field="habla"
                      header="Habla"
                      body={(idioma) =>
                        toTitleCase(idiomaNivelDescById[idioma.habla])
                      }
                    ></Column>
                  </DataTable>
                </div>
              </Box>
            ) : (
              <h4>No se han encontrado registros de idiomas</h4>
            )}
          </Loader>
        </Box>
      </Col>
    </Row>
  );
},
customComparator);

export { Idiomas };
