import createDuck, {
  createAction,
  createThunk,
  defaultActions,
} from '../utils/actions';
import axios from '../net/axios';
import config from '../config';
import { log } from '../utils/logs';

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction('NAME', name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const getOportunidadDuck = createDuck(
  'GET_OPORTUNIDAD',
  newActions,
  newReducers
);

const getOportunidadThunk = (params) => {
  log({ 'getOportunidadThunk.params': params });
  const url = `${baseUrl}/v1/oportunidad`;

  return createThunk(
    axios,
    'get',
    getOportunidadDuck.actions,
    url,
    null,
    params
  );
};

const getOportunidadesDuck = createDuck(
  'GET_OPORTUNIDADES',
  newActions,
  newReducers
);

const getOportunidadesThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidades`;

  return createThunk(
    axios,
    'get',
    getOportunidadesDuck.actions,
    url,
    null,
    params
  );
};

const getOportFlyerDuck = createDuck(
  'GET_OPORT_FLYER',
  newActions,
  newReducers
);

const getOportFlyerThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/flyer`;

  return createThunk(
    axios,
    'get',
    getOportFlyerDuck.actions,
    url,
    null,
    params
  );
};

const eliminarOportFlyerDuck = createDuck(
  'ELIMINAR_OPORT_FLYER',
  newActions,
  newReducers
);

const eliminarOportFlyerThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/flyer`;

  return createThunk(
    axios,
    'delete',
    eliminarOportFlyerDuck.actions,
    url,
    params
  );
};

const getOportRelevamientoDuck = createDuck(
  'GET_OPORT_RELEVAMIENTO',
  newActions,
  newReducers
);

const getOportRelevamientoThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/relevamiento`;

  return createThunk(
    axios,
    'get',
    getOportRelevamientoDuck.actions,
    url,
    null,
    params
  );
};

const eliminarOportRelevamientoDuck = createDuck(
  'ELIMINAR_OPORT_RELEVAMIENTO',
  newActions,
  newReducers
);

const eliminarOportRelevamientoThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/flyer`;

  return createThunk(
    axios,
    'delete',
    eliminarOportRelevamientoDuck.actions,
    url,
    params
  );
};

const getUsuariosDuck = createDuck('GET_USUARIOS', newActions, newReducers);

const getUsuariosThunk = ({ userToken }) => {
  const url = `${baseUrl}/usuarios`;

  return createThunk(axios, 'get', getUsuariosDuck.actions, url, null, {
    userToken,
  });
};

const getCargosDuck = createDuck('GET_CARGOS', newActions, newReducers);

const getCargosThunk = ({ userToken }) => {
  const url = `${baseUrl}/v1/cargos`;

  return createThunk(axios, 'get', getCargosDuck.actions, url, null, {
    userToken,
  });
};

const getRubrosDuck = createDuck('GET_RUBROS', newActions, newReducers);

const getRubrosThunk = ({ userToken }) => {
  const url = `${baseUrl}/v1/rubros`;

  return createThunk(axios, 'get', getRubrosDuck.actions, url, null, {
    userToken,
  });
};

const getEmpresaDuck = createDuck('GET_EMPRESA', newActions, newReducers);

const getEmpresaThunk = (params) => {
  const url = `${baseUrl}/v1/empresa`;
  return createThunk(axios, 'get', getEmpresaDuck.actions, url, null, params);
};

const getEmpresasDuck = createDuck('GET_EMPRESAS', newActions, newReducers);

const getEmpresasThunk = (params) => {
  const url = `${baseUrl}/v1/empresas`;

  return createThunk(axios, 'get', getEmpresasDuck.actions, url, null, params);
};

const editEmpresaDuck = createDuck('EDIT_EMPRESA', newActions, newReducers);

const editEmpresaThunk = (params) => {
  const url = `${baseUrl}/v1/empresa`;
  return createThunk(axios, 'patch', getEmpresaDuck.actions, url, params);
};

const crearOportunidadDuck = createDuck(
  'CREAR_OPORTUNIDAD',
  newActions,
  newReducers
);

const crearOportunidadThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad`;
  return createThunk(axios, 'post', crearOportunidadDuck.actions, url, params);
};

const getEtapasDuck = createDuck('GET_ETAPAS', newActions, newReducers);

const getEtapasThunk = (params) => {
  const url = `${baseUrl}/v1/etapas`;

  return createThunk(axios, 'get', getEtapasDuck.actions, url, null, params);
};

const getCandidatosEnOportDuck = createDuck(
  'GET_CANDIDATOS_EN_OPORT',
  newActions,
  newReducers
);

const getCandidatosEnOportThunk = (params) => {
  log({ 'getCandidatosEnOportThunk.params': params });

  const url = `${baseUrl}/v1/oportunidad/candidatos`;

  return createThunk(
    axios,
    'get',
    getCandidatosEnOportDuck.actions,
    url,
    null,
    params
  );
};

const avanzarAEtapaDuck = createDuck('AVANZAR_ETAPA', newActions, newReducers);

const avanzarAEtapaThunk = (params) => {
  log('avanzarAEtapaThunk.start');

  const url = `${baseUrl}/v1/oportunidad/candidato/avanzar`;

  return createThunk(axios, 'post', avanzarAEtapaDuck.actions, url, params);
};

const guardarEdicionOportDuck = createDuck(
  'GUARDAR_EDICION_OPORT',
  newActions,
  newReducers
);

const guardarEdicionOportThunk = (params) => {
  log('guardarEdicionOportThunk.start');

  const url = `${baseUrl}/v1/oportunidad`;

  return createThunk(
    axios,
    'patch',
    guardarEdicionOportDuck.actions,
    url,
    params
  );
};

const desactivarCandidatoDuck = createDuck(
  'DESACTIVAR_CANDIDATO',
  newActions,
  newReducers
);

const desactivarCandidatoThunk = (params) => {
  log('desactivarCandidatoThunk.start');

  const url = `${baseUrl}/v1/oportunidad/candidato/desactivar`;

  return createThunk(
    axios,
    'post',
    desactivarCandidatoDuck.actions,
    url,
    params
  );
};

const descartarCandidatoDuck = createDuck(
  'DESCARTAR_CANDIDATO',
  newActions,
  newReducers
);

const descartarCandidatoThunk = (params) => {
  log('descartarCandidatoThunk.start');

  const url = `${baseUrl}/v1/oportunidad/candidato/descartar`;

  return createThunk(
    axios,
    'post',
    descartarCandidatoDuck.actions,
    url,
    params
  );
};

const generarReposicionDuck = createDuck(
  'GENERAR_REPOSICION',
  newActions,
  newReducers
);

const generarReposicionThunk = (params) => {
  log('generarReposicionThunk.start');
  const url = `${baseUrl}/v1/oportunidad/reposicion`;
  return createThunk(axios, 'post', generarReposicionDuck.actions, url, params);
};

const getMotivosDescarteDuck = createDuck(
  'GET_MOTIVOS_DESCARTE',
  newActions,
  newReducers
);

const getMotivosDescarteThunk = ({ userToken }) => {
  const url = `${baseUrl}/v1/oportunidad/descarte/motivos`;

  return createThunk(axios, 'get', getMotivosDescarteDuck.actions, url, null, {
    userToken,
  });
};

const getResumenEtapasDuck = createDuck(
  'GET_RESUMEN_ETAPAS',
  newActions,
  newReducers
);

const getResumenEtapasThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/etapas/resumen`;

  return createThunk(
    axios,
    'get',
    getResumenEtapasDuck.actions,
    url,
    null,
    params
  );
};

const getUsuariosForSelectDuck = createDuck(
  'GET_USUARIOS_SELECT',
  newActions,
  newReducers
);

const getUsuariosForSelectThunk = ({ userToken }) => {
  const url = `${baseUrl}/v1/getUsuariosForSelect`;

  return createThunk(
    axios,
    'get',
    getUsuariosForSelectDuck.actions,
    url,
    null,
    {
      userToken,
    }
  );
};

const editarDetallesOportunidadDuck = createDuck(
  'EDITAR_DETALLE_OPORTUNIDAD',
  newActions,
  newReducers
);

const editarDetallesOportunidadThunk = (params) => {
  const url = `${baseUrl}/v1/detallesOportunidad`;
  return createThunk(
    axios,
    'patch',
    editarDetallesOportunidadDuck.actions,
    url,
    params
  );
};

const enviarMailInicioDuck = createDuck(
  'ENVIAR_MAIL_INICIO',
  newActions,
  newReducers
);

const enviarMailInicioThunk = (params) => {
  const url = `${baseUrl}/v1/oportunidad/mail/inicio`;
  return createThunk(axios, 'post', enviarMailInicioDuck.actions, url, params);
};

const getOportunidadesCandidatoDuck = createDuck(
  'GET_OPORTUNIDADES',
  newActions,
  newReducers
);

const getOportunidadesCandidatoThunk = (params) => {
  const url = `${baseUrl}/v1/getOportunidadesCandidato`;

  return createThunk(
    axios,
    'get',
    getOportunidadesCandidatoDuck.actions,
    url,
    null,
    params
  );
};

export {
  getOportunidadDuck,
  getOportunidadThunk,
  getOportunidadesDuck,
  getOportunidadesThunk,
  getUsuariosDuck,
  getUsuariosThunk,
  getCargosDuck,
  getCargosThunk,
  getRubrosDuck,
  getRubrosThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  crearOportunidadDuck,
  crearOportunidadThunk,
  getEtapasDuck,
  getEtapasThunk,
  getCandidatosEnOportDuck,
  getCandidatosEnOportThunk,
  avanzarAEtapaDuck,
  avanzarAEtapaThunk,
  guardarEdicionOportDuck,
  guardarEdicionOportThunk,
  desactivarCandidatoDuck,
  desactivarCandidatoThunk,
  generarReposicionDuck,
  generarReposicionThunk,
  descartarCandidatoDuck,
  descartarCandidatoThunk,
  getMotivosDescarteDuck,
  getMotivosDescarteThunk,
  getResumenEtapasDuck,
  getResumenEtapasThunk,
  getUsuariosForSelectDuck,
  getUsuariosForSelectThunk,
  editarDetallesOportunidadDuck,
  editarDetallesOportunidadThunk,
  getOportFlyerDuck,
  getOportFlyerThunk,
  eliminarOportFlyerDuck,
  eliminarOportFlyerThunk,
  getOportRelevamientoDuck,
  getOportRelevamientoThunk,
  eliminarOportRelevamientoDuck,
  eliminarOportRelevamientoThunk,
  getEmpresaDuck,
  getEmpresaThunk,
  editEmpresaDuck,
  editEmpresaThunk,
  enviarMailInicioDuck,
  enviarMailInicioThunk,
  getOportunidadesCandidatoDuck,
  getOportunidadesCandidatoThunk,
};
