import React, { useEffect, useState } from 'react';
import AdminLTE, { Sidebar, Navbar } from 'adminlte-2-react';
import { useSelector, useDispatch } from 'react-redux';

import { Login, Logout } from './auth';

import {
  errorSelector,
  getDate,
  parseJwt,
  responseSelector,
} from './utils/utils';
import {
  duckUsuario,
  usuarioThunk,
  updateFirebaseTokenThunk,
} from './registro/duck';
import OlvidePass from './olvidePass/OlvidePass';
import ChangePassByToken from './olvidePass/ChangePassByToken';
import WithAuthV2 from './utils/WithAuthV2';

import { NotFound } from './utils/NotFound';

import { log } from './utils/utils';

import {
  messaging,
  getToken,
  onMessage,
  firebaseVapidKey,
} from './firebaseSetup';
import Landing from './landing/Landing';

import { ContactoCandidatos } from './candidatos/ContactoCandidatos';
import { ListaCandidatos } from './candidatos/ListaCandidatos';
import { ListaOportunidades } from './oportunidades/ListaOportunidades';
import { CrearOportunidad } from './oportunidades/CrearOportunidad';
import { EditarOportunidad } from './oportunidades/EditarOportunidad';
import { EditarDetallesOportunidad } from './oportunidades/EditarDetallesOportunidad';
import { CrearCandidato } from './candidatos/CrearCandidato';

import SubirImagenes from './imageUploadV3/SubirImagenes';
import CortarImagenesConcepto from './imageUploadV3/CortarImagenesConcepto';
import { EditarFotoCandidato } from './candidatos/EditarFotoCandidato';
import { EditarCandidato } from './candidatos/editar/EditarCandidato';
import { SubirArchivo } from './candidatos/archivo/SubirArchivo';
import { SubirOportFlyer } from './oportunidades/flyer/SubirOportFlyer';

import { ListaEmpresas } from './empresas/ListaEmpresas';
import { ListaInstitutos } from './institutos/ListaInstitutos';
import { CrearInstituto } from './institutos/CrearInstituto';
import { EditarInstituto } from './institutos/EditarInstituto';
import { CrearEmpresa } from './empresas/CrearEmpresa';
import { EditarEmpresa } from './empresas/EditarEmpresa';
import { ListaDatosAuxiliares } from './datosAuxiliares/ListaDatosAuxiliares';
import { ListaDatosGeografia } from './datosAuxiliares/ListaDatosGeografia';
import { ListaCandidatosNormalizar } from './candidatos/normalizacion/ListaCandidatosNormalizar';
import { NormalizarCandidato } from './candidatos/normalizacion/NormalizarCandidato';

import RegistroSuper from './registro/RegistroSuper';
import Confirmacion from './registro/Confirmacion';
import { CandidatoListaOportunidades } from './oportunidades/CandidatoListaOportunidades';
import Loader from './utils/Loader';

import { ReporteProduccionSelector } from './reportes/ReporteProduccionSelector';
import { ReporteProduccionSupervisor } from './reportes/ReporteProduccionSupervisor';
import { ReporteProduccionDetalle } from './reportes/ReporteProduccionDetalle';
import { SoloParaDemo } from './landing/SoloParaDemo';
import { ListaContactoMensajes } from './contactoMensajes/ListaContactoMensajes';
import { SubirOportRelevamiento } from './oportunidades/relevamiento/SubirOportRelevamiento';
import { CrearEstudioMercado } from './estudiosMercado/CrearEstudioMercado';
import { ListaEmCandidatos } from './estudiosMercado/candidatos/ListaEmCandidatos';
import { ListaEstudiosMercado } from './estudiosMercado/ListaEstudiosMercado';

const { Entry } = Navbar;

const { Item, UserPanel, Header } = Sidebar;

const queryString = require('query-string');

function App({ history }) {
  const dispatch = useDispatch();

  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const oportunidadId = parsedQueryParams.postularA;

  if (oportunidadId) {
    localStorage.setItem('postularA', oportunidadId);
    history.replace(history.location.pathname);
  }

  const token = localStorage.getItem('token');
  const modelUsuario = useSelector((state) => state.usuario);

  const [datosUsuario, setDatosUsuario] = useState({
    status: 'PENDING',
    data: null,
  });

  const [nombre, setNombre] = useState(null);
  const [candidatoId, setCandidatoId] = useState(null);
  const [actualRol, setActualRol] = useState(null);
  const [notificationPermission, setNotificationPermission] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState({
    token: null,
    status: 'PENDING',
  });

  const [candidatoHistoryDeletionStatus, setCandidatoHistoryDeletionStatus] =
    useState('PENDING');

  const [alertSuscripcionLeida, setAlertSuscripcionLeida] = useState(false);

  const requestPermission = async () => {
    log('Requesting permission...');
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      log('Notification permission granted.');
      setNotificationPermission(true);
    } else {
      log('Not granted');
      setFirebaseToken({ token: null, status: 'ERROR' });
    }
  };

  const deletePreviousCandidatoHistory = () => {
    const rawCurrentCandidatosVistos = localStorage.getItem('candidatosVistos');
    const parsedCurrentCandidatosVistos = JSON.parse(
      rawCurrentCandidatosVistos
    );
    const today = getDate();
    // loop through fields of parsed currentCandidatosVistos and delete
    // the ones that are not equal to today.
    for (const key in parsedCurrentCandidatosVistos) {
      if (key !== today) {
        delete parsedCurrentCandidatosVistos[key];
      }
    }
    localStorage.setItem(
      'candidatosVistos',
      JSON.stringify(parsedCurrentCandidatosVistos)
    );
    setCandidatoHistoryDeletionStatus('DONE');
  };

  //TODO: cuando entro al home y no estoy logueado, regirigir a login
  useEffect(() => {
    if (token) {
      const decodedToken = parseJwt(token);
      setActualRol(decodedToken.rol);
      dispatch(usuarioThunk(decodedToken.id));
    }

    _checkAuth();

    deletePreviousCandidatoHistory();
  }, []);

  /**
   * Message listener while foreground
   */
  const onFirebaseMessageReceived = (payload) => {
    log('onMessage.payload');
    log(payload);

    // Mostrar notificacion

    new Notification(payload.notification.title, {
      body: payload.notification.body,
    });

    // Consultar cantidad de no leídos
  };

  const setUpNotifications = async () => {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: firebaseVapidKey,
      });

      if (!currentToken) {
        log('No registration token available');
        setFirebaseToken({ token: null, status: 'ERROR' });
        return;
      }

      log('currentToken');
      log(currentToken);

      // Setear qué hacer cuando se recibe un mensaje en foreground

      onMessage(messaging, onFirebaseMessageReceived);

      setFirebaseToken({ token: currentToken, status: 'LOADED' });
    } catch (error) {
      log('An error occurred while retrieving token. ');
      log(error);
      setFirebaseToken({ token: null, status: 'ERROR' });
    }
  };

  useEffect(() => {
    if (notificationPermission) {
      setUpNotifications();
    }
  }, [notificationPermission]);

  useEffect(() => {
    const loginToken = token;
    if (loginToken && firebaseToken.token) {
      // Guardar token en tabla de usuario
      dispatch(
        updateFirebaseTokenThunk({
          firebaseToken: firebaseToken.token,
          userToken: loginToken,
        })
      );
    }
  }, [firebaseToken]);

  function _checkAuth() {
    let authTokenCookieAuxi = document.cookie
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
          '$1'
        )
      : null;

    if (authTokenCookieAuxi) {
      localStorage.setItem('token', authTokenCookieAuxi);
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const { data } = responseSelector(modelUsuario);
    const error = errorSelector(modelUsuario);

    if (error) {
      setNombre('N/A');
      dispatch(duckUsuario.actions.clear());
    }
    if (data) {
      setDatosUsuario({ ...datosUsuario, status: 'LOADED', data });
      const nombrePartido = data.nombre.split(' ');
      setCandidatoId(data.candidatoIdNumerico);
      setNombre(nombrePartido[0]);
      setAlertSuscripcionLeida(false);
      dispatch(duckUsuario.actions.clear());
    }
  }, [modelUsuario]);

  function sidebarUnlogged() {
    return [
      <Item
        key="bolsa-de-trabajo"
        text={
          <a href={`${process.env.REACT_APP_WEB_URL}/bolsa-de-trabajo.html`}>
            Bolsa de trabajo
          </a>
        }
      />,
    ];
  }

  function sidebarSuper() {
    return [
      <UserPanel
        username={nombre}
        imageUrl={'/defaultImg.png'}
        status={'Online'}
      />,
      <Header text={'Rol: ' + actualRol} />,
      <Item key="logout" text="Logout" to="/logout" />,
    ];
  }

  function sidebarCandidato() {
    return [
      <UserPanel
        username={nombre}
        imageUrl={'/defaultImg.png'}
        status={'Online'}
      />,
      <Header text={'Rol: ' + actualRol} />,

      <Item
        key="bolsa-de-trabajo"
        text={
          <a href={`${process.env.REACT_APP_WEB_URL}/bolsa-de-trabajo.html`}>
            Bolsa de trabajo
          </a>
        }
      />,

      <Item
        key="oportunidadesCandidato"
        text="Mis postulaciones"
        to={`/misOportunidades/${candidatoId}`}
      />,
      <Item
        key="editarCandidato"
        text="Mis datos"
        to={`/editarCandidato/${candidatoId}`}
      />,
      <Item key="logout" text="Logout" to="/logout" />,
    ];
  }

  function sidebarAdmin() {
    return [
      <UserPanel
        key="userPanel"
        username={nombre}
        imageUrl={'/defaultImg.png'}
        status={'Online'}
      />,
      <Header text={'Rol: ' + actualRol} />,

      <Item
        icon="fa-user"
        key="candidatos"
        text="Candidatos"
        to="/candidatos"
      />,

      <Item
        icon="fa-id-card"
        key="oportunidades"
        text="Oportunidades"
        to="/oportunidades"
      />,

      <Item icon="fa-user" key="empresas" text="Empresas" to="/empresas" />,
      <Item
        icon="fa-user"
        key="datosAuxiliares"
        text="Datos auxiliares"
        to="/datosAuxiliares"
      />,

      <Item
        icon="fa-user"
        key="datosGeograficos"
        text="Datos geográficos"
        to="/datosGeograficos"
      />,
      <Item
        icon="fa-user"
        key="institutos"
        text="Institutos"
        to="/institutos"
      />,
      <Item
        icon="fa-user"
        key="candidatosActualizar"
        text="Candidatos a actualizar"
        to="/candidatosActualizar"
      />,
      <Item
        icon="fa-info"
        key="reportes"
        text="Reportes"
        children={[
          <Item
            key="estudioMercado"
            text="Estudio de mercado"
            to="/estudios"
          />,
          <Item
            key="contactoCandidatos"
            text="Contacto candidatos"
            to="/reportes/contactoCandidatos"
          />,
          <Item
            key="reporteProduccionSelectorLanding"
            text="Producción: selector"
            to="/reporte/produccion/selector"
          />,
          <Item
            key="reporteProduccionSupervisorLanding"
            text="Producción: supervisor"
            to="/reporte/produccion/supervisor"
          />,
          <Item
            key="reporteProduccionDetalleLanding"
            text="Producción: detalle"
            to="/reporte/produccion/detalle"
          />,
        ]}
      />,

      <Item icon="fa-envelope" key="mensajes" text="Mensajes" to="/mensajes" />,

      <Item
        icon="fa-laptop-house"
        key="web"
        text={
          <a
            href={process.env.REACT_APP_WEB_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Web
          </a>
        }
      />,

      <Item key="logout" text="Logout" to="/logout" />,
    ];
  }

  //TODO: SideBar component separado que reciba rol y lo que necesite
  function _buildSidebar(rol) {
    switch (rol) {
      case 'superadmin':
        return sidebarSuper();
      case 'admin':
        return sidebarAdmin();
      case 'selector':
        return sidebarAdmin();
      case 'candidato':
        return sidebarCandidato();
      default:
        return sidebarUnlogged();
    }
  }

  function renderNavBar() {
    return (
      <Navbar.Core>
        <Entry
          icon="fas-power-off"
          onClick={() => window.location.replace('/logout')}
        />
      </Navbar.Core>
    );
  }

  if (candidatoHistoryDeletionStatus !== 'DONE') {
    return <Loader isLoading={true}>{null}</Loader>;
  }

  return (
    <React.Fragment>
      <AdminLTE
        title={['INCENTIVA']}
        titleShort={['INCENTIVA']}
        theme="blue"
        sidebar={_buildSidebar(actualRol)}
      >
        {token ? renderNavBar() : <React.Fragment />}

        <RegistroSuper path="/registro" />

        <Confirmacion path="/register/:invitacionId" />

        <ChangePassByToken path="/changePass/:resetToken" />

        <OlvidePass path="/recuperarPassword" />

        <Login path="/login" firebaseToken={firebaseToken} />

        <Logout path="/logout" />

        <WithAuthV2
          path="/candidatos"
          children={ListaCandidatos}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/reportes/contactoCandidatos"
          children={ContactoCandidatos}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/empresas"
          children={ListaEmpresas}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/oportunidades"
          children={ListaOportunidades}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/misOportunidades/:candidatoId"
          children={CandidatoListaOportunidades}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/crearOportunidad"
          children={CrearOportunidad}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/editarOportunidad/:oportunidadId"
          children={EditarOportunidad}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/editarDetalleOportunidad/:oportunidadId"
          children={EditarDetallesOportunidad}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/crearCandidato"
          children={CrearCandidato}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/editarCandidato/:candidatoId"
          children={EditarCandidato}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/editarFotoCandidato/:candidatoId"
          children={EditarFotoCandidato}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/subirImagenes"
          children={SubirImagenes}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          path="/cortarImagenes"
          children={CortarImagenesConcepto}
          datosUsuario={datosUsuario}
          allowWithoutOnboarding={true}
        />

        <WithAuthV2
          exact
          path="/landing"
          children={Landing}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/candidato/archivo/:candidatoId"
          children={SubirArchivo}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/oportunidad/flyer/:oportunidadId"
          children={SubirOportFlyer}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/oportunidad/relevamiento/:oportunidadId"
          children={SubirOportRelevamiento}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/institutos"
          children={ListaInstitutos}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/instituto/agregar"
          children={CrearInstituto}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/instituto/editar/:institutoId"
          children={EditarInstituto}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/empresa/agregar"
          children={CrearEmpresa}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/empresa/editar/:empresaId"
          children={EditarEmpresa}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/datosAuxiliares"
          children={ListaDatosAuxiliares}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/datosGeograficos"
          children={ListaDatosGeografia}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/candidatosActualizar"
          children={ListaCandidatosNormalizar}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/actualizarCandidato/:candidatoId"
          children={NormalizarCandidato}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/reporte/produccion/selector"
          children={ReporteProduccionSelector}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/reporte/produccion/supervisor"
          children={ReporteProduccionSupervisor}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/reporte/produccion/detalle"
          children={ReporteProduccionDetalle}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/mensajes"
          children={ListaContactoMensajes}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/soloParaDemo"
          children={SoloParaDemo}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/estudio/crear"
          children={CrearEstudioMercado}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/estudio/:estudioMercadoIdNumerico"
          children={ListaEmCandidatos}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/estudios"
          children={ListaEstudiosMercado}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <WithAuthV2
          exact
          path="/"
          children={Landing}
          allowWithoutOnboarding={true}
          datosUsuario={datosUsuario}
        />

        <NotFound path="*" />
      </AdminLTE>
    </React.Fragment>
  );
}

export default App;
