import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function ModalAgregarCargoPot({
  isModalAgregarCargoPotShown,
  hideModalAgregarCargoPot,
  showModalAgregarCargoPot,
  agregarCargoPot,
  isAgregarCargoPotButtonDisabled,
  isAgregarCargoPotButtonLoading,
  cargoOption,
  cargosOptions,
  setCargoOption,
}) {
  return (
    <Modal
      show={isModalAgregarCargoPotShown}
      onHide={hideModalAgregarCargoPot}
      onShow={showModalAgregarCargoPot}
    >
      <Modal.Header>
        <Modal.Title>Agregar cargo potencial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectInput
            label="Cargo"
            value={cargoOption}
            options={cargosOptions}
            setValue={setCargoOption}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Agregar cargo potencial"
          submit={agregarCargoPot}
          disabled={isAgregarCargoPotButtonDisabled}
          loading={isAgregarCargoPotButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalAgregarCargoPot };
