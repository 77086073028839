import React from 'react';

import { PhoneInput } from 'react-international-phone';
import '../../../node_modules/react-international-phone/build/index.css';
import '../../ui/PhoneInput/customCss.css';
import Etiqueta from '../../utils/etiquetaObligatorio/Etiqueta';
import { translatedCountries } from './translatedCountries';

function PhoneInputV3({ label, value, setValue, required = false }) {
  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ marginBottom: 5 }}>
        <Etiqueta texto={<b>{label}</b>} obligatorio={required} />
      </div>
      <div>
        <PhoneInput
          defaultCountry="py"
          countries={translatedCountries}
          preferredCountries={[
            'py',
            'uy',
            'ar',
            'br',
            've',
            'co',
            'pe',
            'bo',
            'ec',
            'cl',
          ]}
          value={value}
          onChange={(phone) => setValue(phone)}
          style={{
            '--react-international-phone-border-radius': 0,
          }}
        />
      </div>
    </div>
  );
}

export { PhoneInputV3 };
