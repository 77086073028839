/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Content, Row, Col, Box, Button, Pagination } from 'adminlte-2-react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../utils/Loader';
import {
  getDateOrEmpty,
  submitSearchOnEnterPress,
  toTitleCase,
} from '../utils/utils';
import { TextInput } from '../utils/TextInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { Alert } from '../ui/alert';
import { useWindowSize } from '../utils/useWindowSize';

const ListaEmTable = React.memo(
  function Table({
    estudiosMercadoGet,
    totalResults,
    isMobile,
    pageSize,
    page,
    handlePage,
    estudiosMercado,
    setSelectedEm,
    history,
    setSelectedEmForEdit,
  }) {
    return (
      <Loader isLoading={estudiosMercadoGet.loading}>
        <div style={{ display: 'block' }}>
          <span>Total: {totalResults}</span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {pageSize && (
              <Pagination
                activePage={page - 1}
                pageSize={pageSize}
                totalElements={totalResults}
                onChange={(page) => handlePage(page + 1)}
                labels={{
                  first: 'Primera',
                  last: 'Última',
                  previous: 'Anterior',
                  next: 'Siguiente',
                }}
              />
            )}
          </div>
        </div>

        <Box noPadding={true} border={false} style={{ borderTop: 0 }}>
          <div className="table-responsive">
            <DataTable
              lazy
              pt={{
                table: {
                  className: 'table table-hover',
                },
              }}
              value={estudiosMercado}
              emptyMessage="No se encontraron registros"
            >
              <Column
                field="eliminar"
                header="Eliminar"
                body={(estudioMercado) => (
                  <>
                    <ButtonWithLoading
                      size="sm"
                      type="danger"
                      icon="fa-trash"
                      submit={() => {
                        setSelectedEm(estudioMercado.idNumerico);
                      }}
                    />
                  </>
                )}
                style={{ minWidth: 110 }}
              ></Column>

              <Column
                field="idNumerico"
                header="Nro."
                body={(estudioMercado) => (
                  <a
                    href={`/estudio/${estudioMercado.idNumerico}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/estudio/${estudioMercado.idNumerico}`);
                    }}
                  >
                    {estudioMercado.idNumerico}
                  </a>
                )}
                style={{ minWidth: 90 }}
              ></Column>

              <Column
                field="titulo"
                header="Título"
                body={(candidato) => toTitleCase(candidato.titulo)}
                style={{ minWidth: 70 }}
              ></Column>

              <Column
                field="creacionUsuarioNombre"
                header="Creado por"
                body={(candidato) =>
                  toTitleCase(candidato.creacionUsuarioNombre)
                }
              ></Column>

              <Column
                field="creacionTs"
                header="Fecha creación"
                body={(candidato) => getDateOrEmpty(candidato.creacionTs)}
              ></Column>

              <Column
                field="editar"
                header="Editar"
                body={(estudioMercado) => (
                  <>
                    <ButtonWithLoading
                      size="sm"
                      type="info"
                      icon="fa-pencil-alt"
                      submit={() => {
                        setSelectedEmForEdit(estudioMercado);
                      }}
                    />
                  </>
                )}
                style={{ minWidth: 110 }}
              ></Column>
            </DataTable>
          </div>
        </Box>
        {pageSize && (
          <Pagination
            activePage={page - 1}
            pageSize={pageSize}
            totalElements={totalResults}
            onChange={(page) => handlePage(page + 1)}
            labels={{
              first: 'Primera',
              last: 'Última',
              previous: 'Anterior',
              next: 'Siguiente',
            }}
          />
        )}
        <div style={{ display: 'block' }}>
          <span>Total: {totalResults}</span>
        </div>
      </Loader>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.estudiosMercadoGet === nextProps.estudiosMercadoGet &&
      prevProps.totalResults === nextProps.totalResults &&
      prevProps.isMobile === nextProps.isMobile &&
      prevProps.pageSize === nextProps.pageSize &&
      prevProps.page === nextProps.page &&
      // && prevProps.handlePage === nextProps.handlePage
      prevProps.estudiosMercado === nextProps.estudiosMercado
      // && prevProps.setSelectedEm === nextProps.setSelectedEm
      // && prevProps.history === nextProps.history
      // && prevProps.setSelectedEmForEdit === nextProps.setSelectedEmForEdit
    );
  }
);

export { ListaEmTable };
