import React, { useEffect } from 'react';
import AdminLTE, { Content } from 'adminlte-2-react';
import { Row, Col, Box, Button } from 'adminlte-2-react';

import Loader from '../utils/Loader';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';
import { TextInput } from '../utils/TextInput';
import { useState } from 'react';

function SoloParaDemo({ token, history, datosUsuario }) {
  const [value, setValue] = useState('');
  return (
    <Loader isLoading={false}>
      <Content title="Demo" browserTitle="Demo" show={false}>
        <Row>
          <Col md="10" mdOffset="1">
            <TextInput
              label="ID de oportunidad"
              initialValue={value}
              setValue={setValue}
            />
            <ButtonWithLoading
              text="Postularme"
              submit={() => {
                localStorage.setItem('postularA', value);
                history.push('/');
              }}
            />
          </Col>
        </Row>
      </Content>
    </Loader>
  );
}

export { SoloParaDemo };
