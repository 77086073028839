import React from 'react';
import { Modal } from 'react-bootstrap';

import { SelectInput } from '../utils/SelectInput';
import { ButtonWithLoading } from '../utils/ButtonWithLoading';

function ModalEditarCargoPot({
  isModalEditarCargoPotShown,
  hideModalEditarCargoPot,
  showModalEditarCargoPot,
  editarCargoPot,
  isEditarCargoPotButtonDisabled,
  isEditarCargoPotButtonLoading,
  cargoOption,
  cargosOptions,
  setCargoOption,
}) {
  return (
    <Modal
      show={isModalEditarCargoPotShown}
      onHide={hideModalEditarCargoPot}
      onShow={showModalEditarCargoPot}
    >
      <Modal.Header>
        <Modal.Title>Editar cargo potencial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectInput
            label="Cargo"
            value={cargoOption}
            options={cargosOptions}
            setValue={setCargoOption}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoading
          type="success"
          text="Guardar"
          submit={editarCargoPot}
          disabled={isEditarCargoPotButtonDisabled}
          loading={isEditarCargoPotButtonLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEditarCargoPot };
