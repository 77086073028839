import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { logger } from "../utils/utils";
import {
  getImagenesTemporalesV3Thunk,
  duckGetImagenesTemporalesV3,
  eliminarImgTmpV3Thunk,
  duckEliminarImgTmpV3
} from "./duck";
import CortarImagenes from "./CortarImagenes";

import { Button, Content, Box, Row, Col } from "adminlte-2-react";

export default function CortarImagenesConcepto({ history }) {
  logger.debug("CortarImagenesConcepto.render");

  const [token] = useState(() => localStorage.getItem("token"));
  logger.debug("CortarImagenesConcepto.token");
  logger.debug(token);

  const params = queryString.parse(history.location.search);
  logger.debug("CortarImagenesConcepto.parsedQueryString", params);

  const {
    concepto,
    conceptoId,
    saveInConceptUrl,
    cropConfig,
    lastStepUrl,
    rawUploadHeaders,
    cropUploadUrl,
    relatedImageId,
    cancelUrl
  } = params;

  const parsedCropConfig = JSON.parse(cropConfig);

  const {
    minCroppedWidth,
    minCroppedHeight,
    maxCroppedWidth,
    maxCroppedHeight,
    aspectRatioNumerator,
    aspectRatioDenominator,
  } = parsedCropConfig;

  logger.debug("CortarImagenesConcepto.parsedCropConfig");
  logger.debug({ parsedCropConfig });

  const uploadHeaders = JSON.parse(rawUploadHeaders);

  const dispatch = useDispatch();

  const [eliminacionLoading, setEliminacionLoading] = useState(false);
  const [imagenesTemporales, setImagenesTemporales] = useState([]);
  const [imgTmpRespuestaRecibida, setImgTmpRespuestaRecibida] = useState(false);

  useEffect(() => {
    // componentDidMount

    dispatch(getImagenesTemporalesV3Thunk({ concepto, conceptoId }));
    return () => {
      dispatch(duckGetImagenesTemporalesV3.actions.clear());
    };
  }, []);

  const modelImagenesTemporales = useSelector((state) => {
    return state.getImagenesTemporalesV3;
  });

  useEffect(() => {
    // TODO usar "selectors" para data y error

    const data = modelImagenesTemporales?.data?.data?.data;
    const error = modelImagenesTemporales?.error?.response?.data?.mensaje;

    if (error) {
      alert(error);
      dispatch(duckGetImagenesTemporalesV3.actions.clear());
      setImgTmpRespuestaRecibida(true);
    }
    if (data) {
     
      setImagenesTemporales(data);

      dispatch(duckGetImagenesTemporalesV3.actions.clear());
      setImgTmpRespuestaRecibida(true);
    }
  }, [modelImagenesTemporales, dispatch]);

  const modelEliminacionImgTmp = useSelector((state) => {
    return state.eliminarImgTmpV3;
  });

  const doEliminarImgTmp = (imagenID) => {
    setEliminacionLoading(true);

    let data = {
      imagenID,
      // conceptoId,
      token,
    };
    dispatch(eliminarImgTmpV3Thunk(data));
  };

  useEffect(() => {
    // TODO usar "selectors" para data y error

    const data = modelEliminacionImgTmp?.data?.data?.data;
    const error = modelEliminacionImgTmp?.error;
    const errorMessage = modelEliminacionImgTmp?.error?.response?.data?.mensaje;

    if (error) {
      if (errorMessage) {
        alert(errorMessage);
      }
      setEliminacionLoading(false);
      dispatch(duckEliminarImgTmpV3.actions.clear());
    }

    if (data) {
      setImagenesTemporales([]);
      setImgTmpRespuestaRecibida(false);
      dispatch(
        getImagenesTemporalesV3Thunk({ concepto, conceptoId })
      );
    }
  }, [modelEliminacionImgTmp, dispatch, concepto, conceptoId]);

  const onAllDone = () => {
    history.push(lastStepUrl);
    return <>Redireccionando...</>;
  };

  return (
    <Content title="Subida de imágenes" browserTitle="Subida de imágenes">
      <Row>
        <Col xs="12">
          <CortarImagenes
            imagenesTemporales={imagenesTemporales}
            imgTmpRespuestaRecibida={imgTmpRespuestaRecibida}
            uploadUrl={cropUploadUrl}
            uploadHeaders={uploadHeaders}
            minCroppedWidth={minCroppedWidth}
            minCroppedHeight={minCroppedHeight}
            maxCroppedWidth={maxCroppedWidth}
            maxCroppedHeight={maxCroppedHeight}
            aspectRatioNumerator={aspectRatioNumerator}
            aspectRatioDenominator={aspectRatioDenominator}
            eliminacionLoading={eliminacionLoading}
            onClickEliminar={doEliminarImgTmp}
            onAllDone={onAllDone}
            conceptoId={conceptoId}
            saveInConceptUrl={saveInConceptUrl}
            relatedImageId={relatedImageId}
            cancelUrl={cancelUrl}
            history={history}
          />
        </Col>
      </Row>
    </Content>
  );
}
