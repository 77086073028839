import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { alertTypes, useAlerts } from "../ui/alert";
import { getOportunidadesCandidatoDuck, getOportunidadesCandidatoThunk } from "./duck";
import { responseSelector } from "../utils/utils";
import { CandidatoListaOportunidadesView } from "./CandidatoListaOportunidadesView";

import { log } from "../utils/logs";

const queryString = require("query-string");

function CandidatoListaOportunidades({
  history,
  match,
  token,
  datosUsuario,
}) {
  const candidatoIdNumerico = match.params.candidatoId;

  const dispatch = useDispatch();

  const modelOportunidades = useSelector((state) => state.getOportunidadesCandidato);

  const [candidatoIdNumericoSesion, setCandidatoIdNumericoSesion] =
    useState("");
  const [oportunidades, setOportunidades] = useState();
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { setTopAlert, topAlert } = useAlerts();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelOportunidades);

    if (error) {
      setTopAlert({
        title: "Error",
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadesCandidatoDuck.actions.clear());
    }

    if (data) {
      setOportunidades(data.items);
      setTotalResults(data.total);
      dispatch(getOportunidadesCandidatoDuck.actions.clear());
    }
  }, [modelOportunidades]);

  useEffect(() => {
    if (candidatoIdNumericoSesion) {
      handlePage(0);
    }
  }, [candidatoIdNumericoSesion]);

  function handlePage(page) {
    setActivePage(page);

    const params = {
      page: page + 1,
      limit: pageSize,
    };

    log({ "handlePage.params": params });

    let newUrl = `${history.location.pathname}?${queryString.stringify(
      params
    )}`;

    history.replace(newUrl);
    const data = {
      page: page + 1,
      limit: pageSize,
      candidatoIdNumerico
    };
    dispatch(
      getOportunidadesCandidatoThunk({
        ...data,
        userToken: token.raw,
      })
    );
  }

  useEffect(() => {
    if (datosUsuario?.status === "LOADED") {
      if (
        token?.decoded?.rol === "candidato" &&
        datosUsuario?.data?.candidatoIdNumerico !== candidatoIdNumerico
      ) {
        history.push("/logout");
      } else {
        setCandidatoIdNumericoSesion(datosUsuario?.data?.candidatoIdNumerico);
      }
    }
  }, [datosUsuario]);

  return (
    <CandidatoListaOportunidadesView
      topAlert={topAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      oportunidades={oportunidades}
      modelOportunidades={modelOportunidades}
    />
  );
}

export { CandidatoListaOportunidades };
