import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { alertTypes, useAlerts } from '../ui/alert';

import {
  getCandidatosEnOportDuck,
  getCandidatosEnOportThunk,
  avanzarAEtapaDuck,
  avanzarAEtapaThunk,
  desactivarCandidatoThunk,
  desactivarCandidatoDuck,
  guardarEdicionOportDuck,
  guardarEdicionOportThunk,
  generarReposicionDuck,
  generarReposicionThunk,
  descartarCandidatoDuck,
  descartarCandidatoThunk,
  getMotivosDescarteDuck,
  getMotivosDescarteThunk,
  getResumenEtapasThunk,
  getResumenEtapasDuck,
  enviarMailInicioDuck,
  enviarMailInicioThunk,
  getCargosDuck,
  getCargosThunk,
} from './duck';

import {
  getNotasDuck,
  getNotasThunk,
  crearNotaDuck,
  crearNotaThunk,
  updateNotaThunk,
  updateNotaDuck,
  deleteNotaDuck,
  deleteNotaThunk,
  getCargosPotDuck,
  getCargosPotThunk,
  crearCargoPotDuck,
  crearCargoPotThunk,
  notaFavoritaSetDuck,
  notaFavoritaSetThunk,
} from '../candidatos/duck';

import {
  constants,
  responseSelector,
  log,
  prepareDatosAuxOptions,
  parseJwt,
} from '../utils/utils';

import { EditarOportunidadView } from './EditarOportunidadView';
import { getOportunidadDuck, getOportunidadThunk } from './duck';
import {
  oportunidadEstadoOptions,
  oportunidadOptionsByValue,
} from '../utils/constants';
import { todosOption } from './EditarOportunidadShared';

const queryString = require('query-string');

const filtroOrigenPostulacionOptions = [
  todosOption,
  { value: 'selector', label: 'Selector' },
  { value: 'portal', label: 'Portal' },
];

function EditarOportunidad({ history, match, test, token }) {
  const dispatch = useDispatch();

  const modelGetCandidatosEnOport = useSelector(
    (state) => state.getCandidatosEnOport
  );

  const notaFavoritaSet = useSelector((state) => state.notaFavoritaSet);
  const modelGetCargosPot = useSelector((state) => state.getCargosPot);
  const modelCrearCargoPot = useSelector((state) => state.crearCargoPot);
  const modelGetCargos = useSelector((state) => state.getCargos);
  const [cargosOptions, setCargosOptions] = useState([]);

  const [cargoOption, setCargoOption] = useState();

  const [cargosPotenciales, setCargosPotenciales] = useState([]);

  const modelGetOportunidad = useSelector((state) => state.getOportunidad);
  const modelAvanzarAEtapa = useSelector((state) => state.avanzarAEtapa);
  const modelGuardarEdicionOport = useSelector(
    (state) => state.guardarEdicionOport
  );
  const modelDesactivarCandidato = useSelector(
    (state) => state.desactivarCandidato
  );
  const modelGenerarReposicion = useSelector(
    (state) => state.generarReposicion
  );
  const modelGetMotivosDescarte = useSelector(
    (state) => state.getMotivosDescarte
  );
  const modelDescartarCandidato = useSelector(
    (state) => state.descartarCandidato
  );
  const modelGetResumenEtapas = useSelector((state) => state.getResumenEtapas);

  const modelEnviarMailInicio = useSelector((state) => state.enviarMailInicio);

  const modelNotas = useSelector((state) => state.getNotas);
  const modelCrearNota = useSelector((state) => state.crearNota);
  const modelUpdateNota = useSelector((state) => state.updateNota);
  const modelDeleteNota = useSelector((state) => state.deleteNota);

  const [oportunidadId, setOportunidadId] = useState();
  const [candidatos, setCandidatos] = useState([]);
  const [candidatosLoadStatus, setCandidatosLoadStatus] = useState('IDLE');
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [isModalCambioEtapaShown, setIsModalCambioEtapaShown] = useState(false);
  const [etapaOption, setEtapaOption] = useState();
  const [etapasOptions, setEtapasOptions] = useState();
  const [oportunidad, setOportunidad] = useState();
  const [oportunidadLoadStatus, setOportunidadLoadStatus] = useState('IDLE');
  const [etapasById, setEtapasById] = useState();
  const [selectedCandidato, setSelectedCandidato] = useState();
  const [isModalConfirmarDesactivarShown, setIsModalConfirmarDesactivarShown] =
    useState();
  const [estadoOption, setEstadoOption] = useState();
  const estadosOptions = oportunidadEstadoOptions;
  const [isModalConfirmarReposicionShown, setIsModalConfirmarReposicionShown] =
    useState(false);
  const [incluirCandidatos, setIncluirCandidatos] = useState(false);

  const { submitAlert, setTopAlert, topAlert } = useAlerts();
  const descarteAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [descarteAlert, setDescarteAlert] = useState(descarteAlertDefaultValue);

  const notaAlertDefaultValue = {
    text: null,
    type: null,
    title: null,
    closable: false,
  };
  const [notaAlert, setNotaAlert] = useState(notaAlertDefaultValue);

  const [etapasEnOportunidad, setEtapasEnOportunidad] = useState();
  const [conteoPorEtapa, setConteoPorEtapa] = useState();

  const [isModalDescarteShown, setIsModalDescarteShown] = useState();
  function hideModalDescarte() {
    setIsModalDescarteShown(false);
  }
  function showModalDescarte() {
    setIsModalDescarteShown(true);
  }

  const [motivoOption, setMotivoOption] = useState();
  const [motivosOptions, setMotivosOptions] = useState();

  const [notaSelected, setNotaSelected] = useState();
  const [isModalNotasShown, setIsModalNotasShown] = useState(false);
  const [notas, setNotas] = useState([]);
  const [candidatoIdNumerico, setCandidatoIdNumerico] = useState();
  const [totalResultsNotas, setTotalResultsNotas] = useState(0);
  const [activePageNotas, setActivePageNotas] = useState(0);
  const [pageSizeNotas, setPageSizeNotas] = useState(5);
  const [titulo, setTitulo] = useState('');
  const [userIdNumerico, setUserIdNumerico] = useState();
  const [contenido, setContenido] = useState('');

  const [cuentaFiltroChecked, setCuentaFiltroChecked] = useState(true);
  const [entrevistaCortesiaChecked, setEntrevistaCortesiaChecked] = useState();

  const [expectativaSalarial, setExpectativaSalarial] = useState('0');
  const [cargoPotSubmitAlert, setCargoPotSubmitAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });

  const [filtroNombre, setFiltroNombre] = useState('');

  const [filtroEtapaOption, setFiltroEtapaOption] = useState(todosOption);
  const [filtroEtapaOptions, setFiltroEtapaOptions] = useState();

  const [filtroMotivoDescarteOption, setFiltroMotivoDescarteOption] =
    useState(todosOption);
  const [filtroMotivoDescarteOptions, setFiltroMotivoDescarteOptions] =
    useState();

  const [filtroOrigenPostulacionOption, setFiltroOrigenPostulacionOption] =
    useState(todosOption);

  const [accion, setAccion] = useState();

  function agregarCargoPot() {
    const data = {
      userToken: token.raw,
      candidatoIdNumerico: candidatoIdNumerico || selectedCandidato?.idNumerico,
      cargoIdNumerico: cargoOption?.value,
    };

    log({ 'agregarCargoPot.data': data });

    dispatch(crearCargoPotThunk(data));
  }

  useEffect(() => {
    setFiltroMotivoDescarteOptions([
      todosOption,
      ...(motivosOptions?.length ? motivosOptions : []),
    ]);
  }, [motivosOptions]);

  useEffect(() => {
    setFiltroEtapaOptions([
      todosOption,
      ...(etapasOptions?.length ? etapasOptions : []),
    ]);
  }, [etapasOptions]);

  useEffect(() => {
    if (candidatoIdNumerico) {
      const candidato = candidatos.filter(
        (elem) => elem.idNumerico === candidatoIdNumerico
      );
      if (candidato?.[0]) {
        setExpectativaSalarial(candidato?.[0]?.expectativaSalarial);
      } else {
        setExpectativaSalarial('0');
      }
      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico,
        })
      );
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    if (selectedCandidato) {
      const candidato = candidatos.filter(
        (elem) => elem.idNumerico === selectedCandidato.idNumerico
      );
      if (candidato?.[0]) {
        setExpectativaSalarial(candidato?.[0]?.expectativaSalarial);
      } else {
        setExpectativaSalarial('0');
      }
      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico: selectedCandidato.idNumerico,
        })
      );
    }
  }, [selectedCandidato]);

  const [correoTopAlert, setCorreoTopAlert] = useState({
    text: null,
    type: null,
    title: null,
    closable: false,
  });
  const [correoEnviado, setCorreoEnviado] = useState(false);

  const resetNotaInputs = () => {
    setTitulo('');
    setContenido('');
    setCuentaFiltroChecked(true);
    setEntrevistaCortesiaChecked(false);
  };

  useEffect(() => {
    if (token?.raw) {
      const decodedToken = parseJwt(token.raw);
      setUserIdNumerico(decodedToken?.idNumerico);
    }
  }, [token]);

  const hideModalNotas = () => {
    setCandidatoIdNumerico(null);
    setNotaSelected(null);
    setIsModalNotasShown(false);
  };

  const showModalNotas = () => {
    setIsModalNotasShown(true);
  };

  useEffect(() => {
    const { httpStatus, error, errorMessage } =
      responseSelector(notaFavoritaSet);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(notaFavoritaSetDuck.actions.clear());
    }

    if (httpStatus) {
      handlePageNotas(activePageNotas);

      dispatch(notaFavoritaSetDuck.actions.clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notaFavoritaSet]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEnviarMailInicio);

    if (error) {
      setCorreoTopAlert({
        title: null,
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(enviarMailInicioDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      setCorreoTopAlert({
        title: null,
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      setCorreoEnviado(true);

      dispatch(enviarMailInicioDuck.actions.clear());
    }
  }, [modelEnviarMailInicio]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelNotas);

    if (error) {
      setDescarteAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getNotasDuck.actions.clear());
    }

    if (data) {
      setNotas(data.items);
      setTotalResultsNotas(data.total);
      showModalNotas();

      dispatch(getNotasDuck.actions.clear());
    }
  }, [modelNotas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearNota);

    if (error) {
      setNotaAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      dispatch(crearNotaDuck.actions.clear());
    }

    if (data) {
      resetNotaInputs();
      handlePageNotas(0);
      handlePage(0);
      setNotaAlert({
        text: 'Nota creada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(crearNotaDuck.actions.clear());
    }
  }, [modelCrearNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDeleteNota);

    if (error) {
      setDescarteAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(0);
      dispatch(deleteNotaDuck.actions.clear());
    }
    if (data) {
      handlePageNotas(0);
      handlePage(0);
      setDescarteAlert({
        text: 'Nota eliminada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(deleteNotaDuck.actions.clear());
    }
  }, [modelDeleteNota]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelUpdateNota);

    if (error) {
      setDescarteAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });
      handlePage(0);
      dispatch(updateNotaDuck.actions.clear());
    }
    if (data) {
      resetNotaInputs();
      setNotaSelected(null);
      handlePageNotas(0);
      handlePage(0);
      setDescarteAlert({
        text: 'Nota actualizada correctamente',
        type: alertTypes.success,
        closable: false,
      });

      dispatch(updateNotaDuck.actions.clear());
    }
  }, [modelUpdateNota]);

  useEffect(() => {
    if (candidatoIdNumerico) {
      handlePageNotas(0);
    }
  }, [candidatoIdNumerico]);

  useEffect(() => {
    if (notaSelected) {
      setTitulo(notaSelected.titulo);
      setContenido(notaSelected.contenido);
      setCandidatoIdNumerico(notaSelected.candidatoIdNumerico);
      setCuentaFiltroChecked(notaSelected.cuentaFiltro);
    }
  }, [notaSelected]);

  function handlePageNotas(page) {
    setActivePageNotas(page);

    setNotas([]);

    const params = {
      page: page + 1,
      limit: pageSizeNotas,
      candidatoIdNumerico,
    };

    dispatch(
      getNotasThunk({
        ...params,
        userToken: token.raw,
      })
    );
  }

  function submitNota() {
    setDescarteAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });
    if (cargosPotenciales.length < 3) {
      setNotaAlert({
        text: 'El candidato debe tener al menos 3 cargos potenciales para crear la nota',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    if (!titulo || !contenido) {
      setNotaAlert({
        text: 'La nota debe incluir titulo y contenido',
        type: alertTypes.danger,
        closable: false,
      });
      return;
    }

    setNotas([]);
    setCandidatos([]);

    if (!notaSelected) {
      dispatch(
        crearNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          oportunidadId,
          expectativaSalarial,
          cuentaFiltro: cuentaFiltroChecked,
        })
      );
    } else {
      dispatch(
        updateNotaThunk({
          userToken: token.raw,
          titulo,
          contenido,
          candidatoIdNumerico,
          idNumerico: notaSelected.idNumerico,
          creacionUsuario: notaSelected.creacionUsuario,
          expectativaSalarial,
          cuentaFiltro: cuentaFiltroChecked,
        })
      );
    }
  }

  function deleteNota(idNumerico, creacionUsuario) {
    setDescarteAlert({
      title: null,
      text: null,
      type: null,
      closable: false,
    });

    dispatch(
      deleteNotaThunk({
        userToken: token.raw,
        candidatoIdNumerico,
        idNumerico: idNumerico,
        creacionUsuario: creacionUsuario,
      })
    );
  }

  function enviarMailInicio(oportunidad) {
    dispatch(
      enviarMailInicioThunk({
        userToken: token.raw,
        oportunidadId: oportunidad.idNumerico,
      })
    );
  }

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCandidatosEnOport);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCandidatosEnOportDuck.actions.clear());
    }

    if (data) {
      setCandidatos(data.items);
      setTotalResults(data.total);
      setCandidatosLoadStatus('LOADED');
      dispatch(getCandidatosEnOportDuck.actions.clear());
    }
  }, [modelGetCandidatosEnOport]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetResumenEtapas);

    if (error) {
      setTopAlert({
        title: 'Error',
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getResumenEtapasDuck.actions.clear());
    }

    if (data) {
      setEtapasEnOportunidad(data.etapasEnOportunidad);
      setConteoPorEtapa(data.conteoPorEtapa);
      dispatch(getResumenEtapasDuck.actions.clear());
    }
  }, [modelGetResumenEtapas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetMotivosDescarte);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getMotivosDescarteDuck.actions.clear());
    }

    if (data) {
      setMotivosOptions(prepareDatosAuxOptions(data.items));
      dispatch(getMotivosDescarteDuck.actions.clear());
    }
  }, [modelGetMotivosDescarte]);

  const prepareEtapasOptions = (etapas) => {
    const mEtapasOptions = etapas.map((etapa) => ({
      value: etapa.idNumerico,
      label: etapa.descripcion,
    }));

    return mEtapasOptions;
  };

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetOportunidad);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getOportunidadDuck.actions.clear());
    }

    if (data) {
      setOportunidad(data.oportunidad);
      setEtapasOptions(
        prepareEtapasOptions(data.oportunidad.etapasWithDescription)
      );
      const mEtapasByIds = {};
      data.oportunidad.etapasWithDescription?.forEach((etapa) => {
        mEtapasByIds[etapa.idNumerico] = etapa;
      });
      setEtapasById(mEtapasByIds);
      setEstadoOption(oportunidadOptionsByValue[data.oportunidad.estado]);
      setOportunidadLoadStatus('LOADED');

      dispatch(getOportunidadDuck.actions.clear());
    }
  }, [modelGetOportunidad]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelAvanzarAEtapa);

    log('modelAvanzarAEtapa.useEffect');

    if (error) {
      log('modelAvanzarAEtapa.useEffect.error');

      setDescarteAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalCambioEtapa();
      resetNotaInputs();
      dispatch(avanzarAEtapaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log('modelAvanzarAEtapa.useEffect.success');

      setDescarteAlert({
        text: 'Avance de etapa exitoso',
        type: alertTypes.success,
        closable: false,
      });

      resetNotaInputs();
      handlePage(0);
      dispatch(
        getResumenEtapasThunk({
          userToken: token.raw,
          oportunidadId,
        })
      );
      hideModalCambioEtapa();

      dispatch(avanzarAEtapaDuck.actions.clear());
    }
  }, [modelAvanzarAEtapa]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGuardarEdicionOport);

    log('modelGuardarEdicionOport.useEffect');

    if (error) {
      log('modelGuardarEdicionOport.useEffect.error');

      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(guardarEdicionOportDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log('modelGuardarEdicionOport.useEffect.success');

      setTopAlert({
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      dispatch(
        getOportunidadThunk({
          idNumerico: oportunidadId,
          userToken: token.raw,
        })
      );

      dispatch(guardarEdicionOportDuck.actions.clear());
    }
  }, [modelGuardarEdicionOport]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDesactivarCandidato);

    log('modelDesactivarCandidato.useEffect');

    if (error) {
      log('modelDesactivarCandidato.useEffect.error');

      setDescarteAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalConfirmarDesactivar();

      dispatch(desactivarCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log('modelDesactivarCandidato.useEffect.success');

      setDescarteAlert({
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      handlePage(0);

      hideModalConfirmarDesactivar();

      dispatch(desactivarCandidatoDuck.actions.clear());
    }
  }, [modelDesactivarCandidato]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelDescartarCandidato);

    log('modelDescartarCandidato.useEffect');

    if (error) {
      log('modelDescartarCandidato.useEffect.error');

      setDescarteAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      hideModalCambioEtapa();

      dispatch(descartarCandidatoDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log('modelDescartarCandidato.useEffect.success');

      setDescarteAlert({
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      handlePage(0);

      resetNotaInputs();

      hideModalCambioEtapa();

      dispatch(descartarCandidatoDuck.actions.clear());
    }
  }, [modelDescartarCandidato]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGenerarReposicion);

    log('modelGenerarReposicion.useEffect');

    if (error) {
      log('modelGenerarReposicion.useEffect.error');

      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(generarReposicionDuck.actions.clear());
      hideModalConfirmarReposicion();
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      log('modelGenerarReposicion.useEffect.success');

      setTopAlert({
        text: message,
        type: alertTypes.success,
        closable: false,
      });

      dispatch(generarReposicionDuck.actions.clear());
      hideModalConfirmarReposicion();
      history.push(`/editarOportunidad/${data.reposicionOportId}`);
    }
  }, [modelGenerarReposicion]);

  useEffect(() => {
    log('matchParamsOportunidadId.useEffect');
    setOportunidadId(match.params.oportunidadId);
  }, [match.params.oportunidadId]);

  useEffect(() => {
    log('oportunidadId.useEffect');

    if (oportunidadId) {
      log('oportunidadId.useEffect.insideIf');
      handlePage(0);
      dispatch(
        getOportunidadThunk({
          idNumerico: oportunidadId,
          userToken: token.raw,
        })
      );

      dispatch(
        getResumenEtapasThunk({
          userToken: token.raw,
          oportunidadId,
        })
      );
    }

    dispatch(
      getMotivosDescarteThunk({
        userToken: token.raw,
      })
    );
  }, [oportunidadId]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargos);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosDuck.actions.clear());
    }

    if (data) {
      const cargos = data.items;

      const datosAuxOptions = prepareDatosAuxOptions(cargos);

      setCargosOptions(datosAuxOptions);

      dispatch(getCargosDuck.actions.clear());
    }
  }, [modelGetCargos]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetCargosPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getCargosPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      // log({ 'getCargosPot.data': data });

      dispatch(getCargosPotDuck.actions.clear());

      setCargosPotenciales(data.cargosPotenciales);
    }
  }, [modelGetCargosPot]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelCrearCargoPot);

    if (error) {
      setCargoPotSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(crearCargoPotDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(crearCargoPotDuck.actions.clear());

      dispatch(
        getCargosPotThunk({
          userToken: token.raw,
          candidatoIdNumerico:
            candidatoIdNumerico || selectedCandidato?.idNumerico,
        })
      );

      setCargoPotSubmitAlert({
        text: 'Cargo potencial agregado correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelCrearCargoPot]);

  function handlePage(page) {
    setActivePage(page);

    setCandidatos([]);

    const newUrlParams = {
      page: page + 1,
      limit: pageSize,
      nombre: filtroNombre,
      etapa: filtroEtapaOption.value,
      motivoDescarte: filtroMotivoDescarteOption.value,
      origenPostulacion: filtroOrigenPostulacionOption.value,
    };

    const newUrl = `${history.location.pathname}?${queryString.stringify(
      newUrlParams
    )}`;

    history.replace(newUrl);

    const wsParams = {
      ...newUrlParams,
      oportunidadId,
    };
    dispatch(
      getCargosThunk({
        userToken: token.raw,
      })
    );
    dispatch(
      getCandidatosEnOportThunk({
        ...wsParams,
        userToken: token.raw,
      })
    );
  }

  function hideModalCambioEtapa() {
    log('hideModalCambioEtapa');
    setIsModalCambioEtapaShown(false);
  }

  function showModalCambioEtapa() {
    log('showModalCambioEtapa');
    setIsModalCambioEtapaShown(true);
  }

  function avanzarAEtapa() {
    log({
      'avanzarEtapa.params': {
        candidatoIdNumerico: selectedCandidato.idNumerico,
        etapaIdNumerico: etapaOption.value,
      },
    });
    dispatch(
      avanzarAEtapaThunk({
        userToken: token.raw,
        candidatoIdNumerico: selectedCandidato.idNumerico,
        etapaIdNumerico: etapaOption.value,
        oportunidadIdNumerico: oportunidadId,
        titulo,
        contenido,
        expectativaSalarial,
      })
    );
  }

  function hideModalConfirmarDesactivar() {
    setIsModalConfirmarDesactivarShown(false);
  }

  function showModalConfirmarDesactivar() {
    setIsModalConfirmarDesactivarShown(true);
  }

  function hideModalConfirmarReposicion() {
    setIsModalConfirmarReposicionShown(false);
  }

  function showModalConfirmarReposicion() {
    log('showModalConfirmarReposicion');
    setIsModalConfirmarReposicionShown(true);
  }

  function desactivarCandidato() {
    dispatch(
      desactivarCandidatoThunk({
        userToken: token.raw,
        candidatoIdNumerico: selectedCandidato.idNumerico,
        oportunidadIdNumerico: oportunidadId,
      })
    );
  }

  function guardarEdicion() {
    dispatch(
      guardarEdicionOportThunk({
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
        estado: estadoOption?.value,
      })
    );
  }

  function confirmarReposicion() {
    dispatch(
      generarReposicionThunk({
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
        incluirCandidatos,
      })
    );
  }

  function descartarCandidato() {
    dispatch(
      descartarCandidatoThunk({
        userToken: token.raw,
        candidatoIdNumerico: selectedCandidato.idNumerico,
        oportunidadIdNumerico: oportunidadId,
        motivoDescarteId: motivoOption?.value,
        titulo,
        contenido,
        expectativaSalarial,
      })
    );
  }

  function setearNotaFavorita({ notaIdNumerico, candidatoIdNumerico, setear }) {
    dispatch(
      notaFavoritaSetThunk({
        userToken: token.raw,
        notaIdNumerico,
        candidatoIdNumerico,
        setear,
      })
    );
  }

  return (
    <EditarOportunidadView
      history={history}
      topAlert={topAlert}
      submitAlert={submitAlert}
      totalResults={totalResults}
      activePage={activePage}
      pageSize={pageSize}
      handlePage={handlePage}
      candidatos={candidatos}
      modelGetCandidatosEnOport={modelGetCandidatosEnOport}
      oportunidadId={oportunidadId}
      hideModalCambioEtapa={hideModalCambioEtapa}
      showModalCambioEtapa={showModalCambioEtapa}
      etapaOption={etapaOption}
      etapasOptions={etapasOptions}
      setEtapaOption={setEtapaOption}
      oportunidad={oportunidad}
      etapasById={etapasById}
      oportunidadLoadStatus={oportunidadLoadStatus}
      selectedCandidato={selectedCandidato}
      setSelectedCandidato={setSelectedCandidato}
      isModalCambioEtapaShown={isModalCambioEtapaShown}
      avanzarAEtapa={avanzarAEtapa}
      modelAvanzarAEtapa={modelAvanzarAEtapa}
      isModalConfirmarDesactivarShown={isModalConfirmarDesactivarShown}
      hideModalConfirmarDesactivar={hideModalConfirmarDesactivar}
      showModalConfirmarDesactivar={showModalConfirmarDesactivar}
      guardarEdicion={guardarEdicion}
      estadosOptions={estadosOptions}
      setEstadoOption={setEstadoOption}
      estadoOption={estadoOption}
      modelGuardarEdicionOport={modelGuardarEdicionOport}
      desactivarCandidato={desactivarCandidato}
      modelDesactivarCandidato={modelDesactivarCandidato}
      isModalConfirmarReposicionShown={isModalConfirmarReposicionShown}
      hideModalConfirmarReposicion={hideModalConfirmarReposicion}
      showModalConfirmarReposicion={showModalConfirmarReposicion}
      incluirCandidatos={incluirCandidatos}
      setIncluirCandidatos={setIncluirCandidatos}
      confirmarReposicion={confirmarReposicion}
      modelGenerarReposicion={modelGenerarReposicion}
      etapasEnOportunidad={etapasEnOportunidad}
      conteoPorEtapa={conteoPorEtapa}
      isModalDescarteShown={isModalDescarteShown}
      hideModalDescarte={hideModalDescarte}
      showModalDescarte={showModalDescarte}
      descartarCandidato={descartarCandidato}
      motivoOption={motivoOption}
      motivosOptions={motivosOptions}
      setMotivoOption={setMotivoOption}
      modelDescartarCandidato={modelDescartarCandidato}
      descarteAlert={descarteAlert}
      modelGetResumenEtapas={modelGetResumenEtapas}
      modelGetOportunidad={modelGetOportunidad}
      isModalNotasShown={isModalNotasShown}
      hideModalNotas={hideModalNotas}
      showModalNotas={showModalNotas}
      notas={notas}
      totalResultsNotas={totalResultsNotas}
      activePageNotas={activePageNotas}
      pageSizeNotas={pageSizeNotas}
      candidatoIdNumerico={candidatoIdNumerico}
      setCandidatoIdNumerico={setCandidatoIdNumerico}
      handlePageNotas={handlePageNotas}
      setTitulo={setTitulo}
      titulo={titulo}
      setContenido={setContenido}
      contenido={contenido}
      submitNota={submitNota}
      notaSelected={notaSelected}
      setNotaSelected={setNotaSelected}
      userIdNumerico={userIdNumerico}
      deleteNota={deleteNota}
      cuentaFiltroChecked={cuentaFiltroChecked}
      setCuentaFiltroChecked={setCuentaFiltroChecked}
      entrevistaCortesiaChecked={entrevistaCortesiaChecked}
      setEntrevistaCortesiaChecked={setEntrevistaCortesiaChecked}
      resetNotaInputs={resetNotaInputs}
      enviarMailInicio={enviarMailInicio}
      modelEnviarMailInicio={modelEnviarMailInicio}
      correoTopAlert={correoTopAlert}
      correoEnviado={correoEnviado}
      agregarCargoPot={agregarCargoPot}
      cargoOption={cargoOption}
      setCargoOption={setCargoOption}
      cargosOptions={cargosOptions}
      modelCrearCargoPot={modelCrearCargoPot}
      cargoPotSubmitAlert={cargoPotSubmitAlert}
      modelGetCargosPot={modelGetCargosPot}
      cargosPotenciales={cargosPotenciales}
      expectativaSalarial={expectativaSalarial}
      setExpectativaSalarial={setExpectativaSalarial}
      notaAlert={notaAlert}
      setNotaAlert={setNotaAlert}
      filtroNombre={filtroNombre}
      setFiltroNombre={setFiltroNombre}
      filtroEtapaOption={filtroEtapaOption}
      setFiltroEtapaOption={setFiltroEtapaOption}
      filtroEtapaOptions={filtroEtapaOptions}
      filtroMotivoDescarteOption={filtroMotivoDescarteOption}
      setFiltroMotivoDescarteOption={setFiltroMotivoDescarteOption}
      filtroMotivoDescarteOptions={filtroMotivoDescarteOptions}
      filtroOrigenPostulacionOption={filtroOrigenPostulacionOption}
      filtroOrigenPostulacionOptions={filtroOrigenPostulacionOptions}
      setFiltroOrigenPostulacionOption={setFiltroOrigenPostulacionOption}
      accion={accion}
      setAccion={setAccion}
      notaFavoritaSet={notaFavoritaSet}
      setearNotaFavorita={setearNotaFavorita}
    />
  );
}

export { EditarOportunidad };
