import React from "react";

import IconoObligatorio from "./IconoObligatorio";

function ReferenciaObligatorios() {
  return (
    <div style={{ display: "block", marginBottom: 10 }}>
      <IconoObligatorio />{" "}
      <small className="text-muted">Campos obligatorios</small>
    </div>
  );
}

export { ReferenciaObligatorios };
