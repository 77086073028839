import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  constants,
  getNumberOrNull,
  prepareDatosAuxOptions,
  prepareEmpresasOptions,
  responseSelector,
  toTitleCase,
} from '../utils/utils';
import { useAlerts } from '../ui/alert';
import { alertTypes } from '../utils/Alert';
import { log } from '../utils/logs';
import { editEmpresaDuck, editEmpresaThunk } from './duck';
import { CrearEmpresaView } from './CrearEmpresaView';
import {
  getEmpresaDuck,
  getEmpresaThunk,
  getEmpresasDuck,
  getEmpresasThunk,
  getRubrosDuck,
  getRubrosThunk,
} from '../oportunidades/duck';

const sinGrupoEmpOption = { value: null, label: 'Sin grupo' };

function EditarEmpresa({ history, match, token }) {
  const empresaId = match.params.empresaId;

  const dispatch = useDispatch();

  const modelGetRubros = useSelector((state) => state.getRubros);
  const modelEditEmpresa = useSelector((state) => state.editEmpresa);
  const modelGetEmpresas = useSelector((state) => state.getEmpresas);
  const modelGetEmpresa = useSelector((state) => state.getEmpresa);

  const [grupoEmpresarialOptions, setGrupoEmpresarialOptions] = useState([]);
  const [grupoEmpresarialOption, setGrupoEmpresarialOption] = useState();

  const [nombre, setNombre] = useState('');
  // empresaNombreOriginal guarda el nombre que tiene actualmente la empresa en la BD.
  // Sirve solamente para mostrar el nombre en la lista de oportunidades.
  const [empresaNombreOriginal, setEmpresaNombreOriginal] = useState();
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [documento, setDocumento] = useState('');
  const [gerenteGral, setGerenteGral] = useState('');
  const [gerenteRrhh, setGerenteRrhh] = useState('');
  const [esCliente, setEsCliente] = useState(false);
  const [esGrupo, setEsGrupo] = useState(false);
  const [rubrosOptions, setRubrosOptions] = useState();
  const [rubroOption, setRubroOption] = useState();
  const [contactoNombre, setContactoNombre] = useState('');
  const [contactoTelefono, setContactoTelefono] = useState('');
  const [empresaLoadStatus, setEmpresaLoadStatus] = useState('IDLE');
  const [modalRedirect, setModalRedirect] = useState(null);

  const { topAlert, setTopAlert, submitAlert, setSubmitAlert } = useAlerts();

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresa);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(getEmpresaDuck.actions.clear());

      const empresa = data.empresa;

      setNombre(empresa.nombre);
      setEmpresaNombreOriginal(empresa.nombre);
      setDireccion(empresa.direccion);
      setTelefono(empresa.telefono);
      setCorreo(empresa.correo);
      setRazonSocial(empresa.razonSocial);
      setDocumento(empresa.documento);
      setGerenteGral(empresa.gerenteGral);
      setGerenteRrhh(empresa.gerenteRrhh);
      setEsCliente(empresa.esCliente);
      setEsGrupo(empresa.esGrupo);
      setRubroOption(
        rubrosOptions.find(
          (rubroOption) => empresa.rubroIdNumerico === rubroOption.value
        )
      );
      setContactoNombre(empresa.contacto?.nombre);
      setContactoTelefono(empresa.contacto?.telefono);
      const grupoEmp = grupoEmpresarialOptions.find(
        (grupoEmpresarialOption) =>
          empresa.empresaPadreId === grupoEmpresarialOption.value
      );
      setGrupoEmpresarialOption(grupoEmp ?? sinGrupoEmpOption);
      setEmpresaLoadStatus('LOADED');
    }
  }, [modelGetEmpresa]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetEmpresas);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getEmpresasDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(getEmpresasDuck.actions.clear());

      setGrupoEmpresarialOptions([
        sinGrupoEmpOption,
        ...prepareEmpresasOptions(data.items),
      ]);
    }
  }, [modelGetEmpresas]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelEditEmpresa);

    if (error) {
      setSubmitAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(editEmpresaDuck.actions.clear());
    }

    if (httpStatus === constants.HTTP_SUCCESS) {
      dispatch(editEmpresaDuck.actions.clear());

      setSubmitAlert({
        text: 'Empresa editada correctamente',
        type: alertTypes.success,
        closable: false,
      });
    }
  }, [modelEditEmpresa]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelGetRubros);

    if (error) {
      setTopAlert({
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(getRubrosDuck.actions.clear());
    }

    if (data) {
      // log({ 'modelGetRubros.useEffect.data': data });

      const datosAuxOptions = prepareDatosAuxOptions(data.items);
      // log({ datosAuxOptions });

      setRubrosOptions([{ value: null, label: 'Ninguno' }, ...datosAuxOptions]);

      dispatch(getRubrosDuck.actions.clear());
    }
  }, [modelGetRubros]);

  useEffect(() => {
    dispatch(
      getEmpresasThunk({
        esGrupo: 'SI',
        userToken: token.raw,
      })
    );
    dispatch(
      getRubrosThunk({
        userToken: token.raw,
      })
    );
  }, []);

  useEffect(() => {
    if (rubrosOptions && grupoEmpresarialOptions) {
      setEmpresaLoadStatus('LOADING');
      dispatch(
        getEmpresaThunk({
          idNumerico: empresaId,
          userToken: token.raw,
        })
      );
    }
  }, [rubrosOptions, grupoEmpresarialOptions]);

  function submit() {
    let data = {
      empresaId,
      userToken: token.raw,
      nombre,
      direccion,
      telefono,
      correo,
      razonSocial,
      documento,
      gerenteGral,
      gerenteRrhh,
      esCliente,
      esGrupo,
      rubroIdNumerico: rubroOption?.value,
      contactoNombre,
      contactoTelefono,
      empresaPadreId: grupoEmpresarialOption?.value,
    };

    log({ 'submit.data': data });

    dispatch(editEmpresaThunk(data));
  }

  return (
    <CrearEmpresaView
      mode="edit"
      history={history}
      match={match}
      modelCreateEmpresa={modelEditEmpresa}
      submit={submit}
      submitAlert={submitAlert}
      topAlert={topAlert}
      nombre={nombre}
      setNombre={setNombre}
      direccion={direccion}
      setDireccion={setDireccion}
      telefono={telefono}
      setTelefono={setTelefono}
      correo={correo}
      setCorreo={setCorreo}
      razonSocial={razonSocial}
      setRazonSocial={setRazonSocial}
      documento={documento}
      setDocumento={setDocumento}
      gerenteGral={gerenteGral}
      setGerenteGral={setGerenteGral}
      gerenteRrhh={gerenteRrhh}
      setGerenteRrhh={setGerenteRrhh}
      esCliente={esCliente}
      setEsCliente={setEsCliente}
      esGrupo={esGrupo}
      setEsGrupo={setEsGrupo}
      contactoNombre={contactoNombre}
      setContactoNombre={setContactoNombre}
      contactoTelefono={contactoTelefono}
      setContactoTelefono={setContactoTelefono}
      rubroOption={rubroOption}
      rubrosOptions={rubrosOptions}
      setRubroOption={setRubroOption}
      grupoEmpresarialOptions={grupoEmpresarialOptions}
      grupoEmpresarialOption={grupoEmpresarialOption}
      setGrupoEmpresarialOption={setGrupoEmpresarialOption}
      modelGetEmpresa={modelGetEmpresa}
      empresaId={empresaId}
      empresaNombreOriginal={empresaNombreOriginal}
      empresaLoadStatus={empresaLoadStatus}
      modalRedirect={modalRedirect}
      setModalRedirect={setModalRedirect}
    />
  );
}

export { EditarEmpresa };
