import React, { useEffect } from 'react';
import AdminLTE, { Content } from 'adminlte-2-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  incluirEnOportunidadDuck,
  incluirEnOportunidadThunk,
} from '../candidatos/duck';
import Loader from '../utils/Loader';
import { Alert, alertTypes, useAlerts } from '../ui/alert';
import { responseSelector } from '../utils/utils';
const queryString = require('query-string');

function Landing({ history, token, datosUsuario }) {
  const queryParams = history?.location?.search;

  const parsedQueryParams = queryString.parse(queryParams);

  const oportunidadId = parsedQueryParams.postularA;

  const dispatch = useDispatch();

  const modelIncluirEnOportunidad = useSelector(
    (state) => state.incluirEnOportunidad
  );

  const { setTopAlert, topAlert } = useAlerts();

  function incluirEnOportunidad() {
    const etapaReclutamientoId = 200;

    dispatch(
      incluirEnOportunidadThunk({
        origen: 'portal',
        userToken: token.raw,
        oportunidadIdNumerico: oportunidadId,
        // oportunidadIdNumerico: '986',
        candidatosIds: [datosUsuario.data.candidatoIdNumerico],
        etapa: etapaReclutamientoId,
      })
    );
  }

  useEffect(() => {
    if (datosUsuario.status === 'LOADED' && oportunidadId) {
      incluirEnOportunidad();
    }
  }, [datosUsuario]);

  useEffect(() => {
    const { data, code, message, httpStatus, error, errorMessage } =
      responseSelector(modelIncluirEnOportunidad);

    if (error) {
      setTopAlert({
        title: null,
        text: errorMessage,
        type: alertTypes.danger,
        closable: false,
      });

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }

    if (data) {
      let itemsMsgs = [];

      if (data.incluidosExitosamente.length) {
        itemsMsgs.push(
          `Te postulaste exitosamente a la oportunidad seleccionada`
        );
      }

      if (data.omitidos.length) {
        itemsMsgs.push(
          `Ya te postulaste exitosamente a la oportunidad seleccionada`
        );
      }

      setTopAlert({
        title: null,
        text: (
          <ul>
            {itemsMsgs.map((msj) => (
              <li>{msj}</li>
            ))}
          </ul>
        ),
        type: alertTypes.success,
        closable: false,
      });

      history.replace('/');

      dispatch(incluirEnOportunidadDuck.actions.clear());
    }
  }, [modelIncluirEnOportunidad]);

  return (
    <Loader
      isLoading={
        datosUsuario.status !== 'LOADED' || modelIncluirEnOportunidad.loading
      }
    >
      <Content title="Bienvenido" browserTitle="Bienvenido" show={false}>
        {topAlert.text && (
          <Alert
            title={topAlert.title}
            type={topAlert.type}
            text={topAlert.text}
            closable={topAlert.closable}
          />
        )}
      </Content>
    </Loader>
  );
}

export default Landing;
